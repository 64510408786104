import lookups from "./lookups";
import { CalendarItemFormStages } from "./calendarItem";
import { CalendarItemType, DayOfWeek } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const ProductCalendarFormStages = [
  {
    title: "Product Calendar",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Calendar Items",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_type",
              className: "col-name",
              type: "picklist",
              enum: CalendarItemType,
            },
            {
              name: "g4b_startdate",
              className: "col-date",
              dateFormat: "DD/MM/yyyy",
              type: "datetime",
            },
            {
              name: "g4b_enddate",
              className: "col-date",
              dateFormat: "DD/MM/yyyy",
              type: "datetime",
            },
            {
              name: "g4b_dayofweek",
              className: "col-name",
              type: "picklist",
              enum: DayOfWeek,
            },
            {
              name: "g4b_opentime",
              className: "col-date",
              dateFormat: "HH:mm",
              type: "datetime",
            },
            {
              name: "g4b_closetime",
              className: "col-date",
              dateFormat: "HH:mm",
              type: "datetime",
            },
            {
              name: "g4b_peaktime",
              className: "col-date",
              dateFormat: "HH:mm",
              type: "datetime",
            },
            {
              name: "g4b_peakend",
              className: "col-date",
              dateFormat: "HH:mm",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4b_productcalendarid",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/calendar-item",
          linkedEntityId: "g4b_productcalendarid",
          loadStateRelatedEntityName: "g4b_calendaritem",
          lookup: lookups.g4b_calendaritem.all,
          subFormStages: CalendarItemFormStages,
          useAlphabetPagination: true,
          useSubForm: true,
        },
      },
    ],
  },
];
