import classNames from "classnames";
import { ButtonStyle } from "../../js/enums";

function CloseButton({
  className,
  disabled,
  label,
  onClick,
  style = ButtonStyle.Close,
  type = "button",
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames("btn", style, className)}
      disabled={disabled}
      aria-label={label}
    ></button>
  );
}

export default CloseButton;
