import { useFormReducer } from "../FormContext";
import SidebarForm from "../SidebarForm";
import { Button, Loading } from "../../elements/_Elements";
import { ButtonStyle, LoadingState } from "../../../js/enums";
import GetTriggerFormStages from "./TriggerFormStages";
import { cancelTriggerChanges, getLookupForTrigger } from "./Helpers";
import { useLookupOptions } from "../Helpers";
import { useGlobalReducer } from "../../../GlobalContext";
import { useState } from "react";
import lookups from "../../../forms/lookups";

function EditTrigger({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  state,
  touched,
  values,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [loading, setLoading] = useState(LoadingState.NotLoaded);

  const communicationLookup = getLookupForTrigger(values.trigger);

  const lookupOptions = [
    communicationLookup,
    lookups.g4b_productgroup.all,
  ];

  useLookupOptions(
    dispatch,
    globalDispatch,
    loading,
    lookupOptions,
    setLoading,
    state
  );

  return loading !== LoadingState.Loaded ? (
    <Loading />
  ) : (
    <>
      <div className="sidebar-content d-flex justify-content-end mb-3">
        <Button
          text="Cancel"
          onClick={() => cancelTriggerChanges(dispatch, values)}
          style={ButtonStyle.Link}
        />
      </div>
      <div className="sidebar-content position-relative flex-fill my-3">
        <div className="position-absolute top-0 end-0 bottom-0 start-0 p-2 overflow-y-scroll">
          <SidebarForm
            {...{
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
              state,
              touched,
            }}
            handleCheckListChange={(event) => {
              handleChange(event);
            }}
            handlePicklistChange={(event) => {
              handleChange(event);
            }}
            handleFilteredPicklistChange={(event, _, name) => {
              const value = event ? event.value : "";
              setFieldValue(name, value);
            }}
            stages={GetTriggerFormStages(state, values)}
            useTabs={false}
            values={values}
          />
        </div>
      </div>
      <div className="sidebar-content d-flex justify-content-end">
        <Button
          text="Apply and close"
          style={ButtonStyle.Primary}
          type="submit"
        />
      </div>
    </>
  );
}

export default EditTrigger;
