import { ProductCalendarFormStages } from "./productCalendar";
import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const ProductCalendarsFormStages = [
  {
    title: "Product Calendars",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/product-calendar",
          lookup: lookups.g4b_productcalendar.all,
          subFormStages: ProductCalendarFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
