import { useState } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { Card, DesignSection } from "../../elements/_Elements";
import Sidebar from "./Sidebar";
import HorizontalRule from "./HorizontalRule";
import VerticalRule from "./VerticalRule";
import Layout from "./Layout";
import LayoutOptions from "./LayoutOptions";
import { handleDragEnd } from "./Helpers";
import DragIndicators from "./DragIndicators";

const MaxSnapshots = 5;

function Designer({
  errors,
  handleChange,
  handlePicklistChange,
  setFieldValue,
  state,
  touched,
  values,
}) {
  const [designerState, setDesignerState] = useState({
    activeId: "",
    active: {},
    dragDelta: {},
    currentState: values,
    layoutIndex: 0,
    redo: [],
    snapIncrement: 1,
    undo: [],
    zoom: "100",
  });

  const { activeId, currentState, layoutIndex, snapIncrement, zoom } =
    designerState;
  const { back, front } = currentState ? currentState : values;
  const zoomFactor = zoom / 100;
  const page = layoutIndex === 0 ? front : back;
  const { Height, Width } = page;

  const saveUndoSnapshot = (snapshot) => {
    const undo = [...designerState.undo];
    // Push the new value to the snapshots array
    undo.push(snapshot);

    // Check if an item needs to be removed
    if (undo.length > MaxSnapshots) {
      undo.shift();
    }

    setDesignerState({
      ...designerState,
      redo: [],
      undo: undo,
    });
  };

  const saveCurrentState = (currentState) => {
    setDesignerState({
      ...designerState,
      undo: [...designerState.undo],
      redo: [...designerState.redo],
      currentState: currentState,
    });
  };

  if (
    !values.editField &&
    currentState &&
    values &&
    currentState !== values
  ) {
    saveCurrentState(values);
  }

  const onDragStart = (event) => {
    const { id } = event.active;
    const identifiers = id.split("-");
    if (identifiers.length !== 4) {
      return;
    }

    const field = page.Layers[identifiers[2]].Fields[identifiers[3]];

    setDesignerState({
      ...designerState,
      activeId: event.active.id,
      active: {
        Height: field.Height,
        Left: field.Left,
        Top: field.Top,
        Width: field.Width,
      },
    });
  };

  const onDragMove = (event) => {
    setDesignerState({ ...designerState, dragDelta: event.delta });
  };

  const onDragEnd = (event) => {
    setDesignerState({
      ...designerState,
      activeId: null,
    });

    handleDragEnd(
      event,
      layoutIndex,
      setFieldValue,
      snapIncrement,
      state,
      values,
      zoomFactor
    );
  };

  return (
    <DndContext
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
    >
      <DesignSection
        className={
          (activeId ? "is-dragging" : "",
          values.editField ? "is-editing" : "")
        }
      >
        <DesignSection.Content>
          <LayoutOptions
            {...{ designerState, setDesignerState, values }}
          />
          <Card>
            <div className="overflow-auto">
              <div
                className="position-relative overflow-hidden"
                style={{
                  height: `${Height * zoomFactor + 10}mm`,
                  width: `${Width * zoomFactor + 10}mm`,
                }}
              >
                {activeId && (
                  <DragIndicators
                    active={designerState.active}
                    dragDelta={designerState.dragDelta}
                    page={page}
                    snapIncrement={snapIncrement}
                    zoomFactor={zoomFactor}
                  />
                )}
                <HorizontalRule
                  width={Width}
                  zoomFactor={zoomFactor}
                />
                <div className="d-flex flex-row">
                  <VerticalRule
                    height={Height}
                    zoomFactor={zoomFactor}
                  />
                  <Layout
                    {...{
                      layoutIndex,
                      page,
                      setFieldValue,
                      snapIncrement,
                      values,
                      zoomFactor,
                    }}
                    activeId={activeId}
                    allowEdit={true}
                  />
                </div>
              </div>
            </div>
          </Card>
        </DesignSection.Content>
        <DesignSection.Sidebar>
          <Sidebar
            {...{
              currentState,
              errors,
              handleChange,
              handlePicklistChange,
              saveUndoSnapshot,
              setFieldValue,
              state,
              touched,
              values,
            }}
          />
        </DesignSection.Sidebar>
      </DesignSection>
      <DragOverlay>
        {designerState.activeId ? <div id="drag-overlay" /> : null}
      </DragOverlay>
    </DndContext>
  );
}

export default Designer;
