import { MagnifyIcon, MinusIcon, PlusIcon } from "../../Icons";
import { DraggableIconCard } from "../../elements/_Elements";

function RuleElements({ values }) {
  const { rule_data } = values;

  return (
    <>
      <h5 className="sidebar-header">Rules</h5>
      <DraggableIconCard
        className="mb-3 sidebar-content"
        icon={PlusIcon}
        iconClassName="sidebar-content rounded-start text-white bg-success"
        id="add-rule"
        title="Add"
      />
      {rule_data && rule_data.length > 0 && (
        <>
          <DraggableIconCard
            className="mb-3 sidebar-content"
            icon={MagnifyIcon}
            iconClassName="rounded-start text-white bg-warning"
            id="keep-rule"
            title="Keep"
          />
          <DraggableIconCard
            className="mb-3 sidebar-content"
            icon={MinusIcon}
            iconClassName="rounded-start text-white bg-danger"
            id="remove-rule"
            title="Remove"
          />
        </>
      )}
    </>
  );
}

export default RuleElements;
