import Filters from "./Filters";
import InactiveFilter from "./InactiveFilter";
import SubmitButtons from "./SubmitButtons";

function FilterSection({
  disabled,
  displayIcons,
  filterDescription,
  filters,
  filterByStatus,
  handleSubmitFilter,
  isSubmitting,
  loadedRecords,
  orderedBy,
  orderedByAsc,
  recordsPerPage,
  setCurrentPage,
  setFilters,
  state,
  subFormStages,
  table,
  values,
}) {
  return (
    <>
      <div className="d-flex">
        <div className="flex-grow-1 me-3">
          <div className="row">
            <Filters
              {...{
                displayIcons,
                filters,
                setFilters,
                state,
                subFormStages,
                values,
              }}
              allowExpandFilters={!filterByStatus}
              allRecords={loadedRecords}
              tableFilters={table.filters}
            />
            {filterByStatus && (
              <InactiveFilter {...{ filters, setFilters, table }} />
            )}
          </div>
        </div>
        <SubmitButtons
          {...{
            disabled,
            filterDescription,
            handleSubmitFilter,
            isSubmitting,
            orderedBy,
            orderedByAsc,
            recordsPerPage,
            setCurrentPage,
            setFilters,
            table,
          }}
        />
      </div>
      {filterDescription && (
        <p>
          <em>{filterDescription}</em>
        </p>
      )}
    </>
  );
}

export default FilterSection;
