export const SeatClassFormStages = [
  {
    title: "Seat Class",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4b_colour",
            description: "",
            label: "Colour",
            type: "colourpicker",
          },
        ],
      },
    ],
  },
];
