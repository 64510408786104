import lookups from "./lookups";
import { RegionFormStages } from "./region";

export const CityFormStages = [
  {
    title: "City",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4_regionid",
            allowAdd: true,
            allowEdit: true,
            description: "The region the city belongs to",
            label: "Region",
            lookup: lookups.g4_region.all,
            required: true,
            subFormStages: RegionFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
