const DragType = Object.freeze({
  NewRule: 1,
  MoveRule: 2,
});

const ExportType = Object.freeze({
  File: "file",
  "Marketing List": "list",
});

const FileType = Object.freeze({
  CSV: "csv",
  XML: "xml",
});

const MarketingListType = Object.freeze({
  New: "new",
  Existing: "existing",
});

export { DragType, ExportType, FileType, MarketingListType };
