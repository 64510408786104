import classNames from "classnames";

function Card({
  children,
  className,
  footer,
  header,
  subTitle,
  title,
}) {
  return (
    <div className={classNames("card", className)}>
      {header && <h3 className="card-header">{header}</h3>}
      <div className="card-body">
        {title && <h5 className="card-title">{title}</h5>}
        {subTitle && (
          <h6 className="card-subtitle mb-2 text-muted">
            {subTitle}
          </h6>
        )}
        {children}
      </div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
}

export default Card;
