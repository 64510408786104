function PaymentMandate({ properties }) {
  const {
    accountName,
    accountNumber,
    bankName,
    paymentMandateBackgroundColour,
    paymentMandateBorderColour,
    paymentMandateBorderThickness,
    paymentMandateBorderType,
    paymentMandateFontFamily,
    paymentMandateFontSize,
    paymentMandateFontWeight,
    paymentMandateMargin,
    paymentMandatePadding,
    paymentMandateTextColour,
    referenceNumber,
    serviceNumber,
    sortCode,
  } = properties;

  return (
    <div
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='PaymentMandate'"
    >
      <table className="full-width zero-margin" cellSpacing="0">
        <tbody>
          <tr>
            <td style={{ padding: paymentMandateMargin }}>
              <table
                className="full-width zero-margin"
                cellSpacing="0"
                style={{
                  borderColor: paymentMandateBorderColour,
                  borderStyle: paymentMandateBorderType,
                  borderWidth: paymentMandateBorderThickness,
                }}
              >
                <tbody>
                  <tr
                    style={{
                      backgroundColor: paymentMandateBackgroundColour,
                    }}
                  >
                    <td
                      style={{
                        color: paymentMandateTextColour,
                        fontFamily: paymentMandateFontFamily,
                        fontSize: paymentMandateFontSize,
                        fontWeight: paymentMandateFontWeight,
                        padding: paymentMandatePadding,
                      }}
                    >
                      <p>
                        <strong>{bankName} </strong>
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="PaymentMandate/BankName" />',
                          }}
                        ></span>
                        <span className="remove-item">My bank</span>
                      </p>
                      <p>
                        <strong>{accountName} </strong>
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="PaymentMandate/AccountName" />',
                          }}
                        ></span>
                        <span className="remove-item">Mr ****</span>
                      </p>
                      <p>
                        <strong>{accountNumber} </strong>
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="PaymentMandate/AccountNumber" />',
                          }}
                        ></span>
                        <span className="remove-item">12****78</span>
                      </p>
                      <p>
                        <strong>{sortCode} </strong>
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="PaymentMandate/SortCode" />',
                          }}
                        ></span>
                        <span className="remove-item">111111</span>
                      </p>
                      <p>
                        <strong>{serviceNumber} </strong>
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="PaymentMandate/ExternalReference" />',
                          }}
                        ></span>
                        <span className="remove-item">123456</span>
                      </p>
                      <p>
                        <strong>{referenceNumber} </strong>
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="PaymentMandate/PaymentMandateReference" />',
                          }}
                        ></span>
                        <span className="remove-item">123456</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PaymentMandate;
