import lookups from "./lookups";
import DirectDebitProcessing from "../components/elements/DirectDebitProcessing";
import AddRemoveRecordsTable from "../components/elements/AddRemoveRecordsTable";
import { ButtonStyle, DirectDebitOutcome } from "../js/enums";
import Button from "../components/elements/Button";
import { downloadAnnotation } from "../js/utility";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const PaymentBatchFormStages = [
  {
    title: "Payment Batch",
    id: "payment-batch",
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "name",
            className: "col-md-12",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "ddProcess",
            className: "col-md-12",
            type: "component",
            component: DirectDebitProcessing,
            displayIfFromState: (state) =>
              state.g4b_reference && state.g4b_reference !== "",
          },
        ],
      },
    ],
  },
  {
    title: "Additional Information",
    id: "additional-information",
    editOnOverview: false,
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_reference",
            className: "col-md-6",
            label: "Reference",
            type: "nvarchar",
            getReadOnlyValue: (state) => {
              return state["g4b_reference"];
            },
          },
          {
            name: "g4b_batchdate",
            className: "col-md-6",
            description: "",
            label: "Batch Date",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Payment Batch Items",
    id: "payment-batch-items",
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    displayEditButtonFromState: () => false,
    editOnOverview: true,
    sections: [
      {
        fields: [
          {
            name: "paymentbatchitemtable",
            className: "col-12",
            component: AddRemoveRecordsTable,
            existingRecordsEntitySearchName: "g4b_paymentbatchitem",
            existingRecordsEntitySearchFilter:
              "ignoreresultexceedcheck",
            existingRecordsSearchFilterArray: [
              {
                name: "firstname",
                description: "",
                label: "First Name",
              },
              {
                name: "lastname",
                description: "",
                label: "Last Name",
              },

              {
                name: "itemprocessed",
                description: "",
                key: "processed",
                label: "Processed",
                type: "bit",
              },
              {
                name: "paymentsuccess",
                description: "",
                label: "Payment Success",
                type: "bit",
              },
            ],
            recordsTableColumns: [
              {
                fieldName: "firstname",
                className: "col-name",
                headingName: "First Name",
              },
              {
                fieldName: "lastname",
                className: "col-name",
                headingName: "Last Name",
              },
              {
                fieldName: "g4m_membershipaccountnumber",
                className: "col-name",
                headingName: "Membership Account Number",
                linkedEntityId: "g4m_membershipaccountid",
                linkedEntityPath: "/membership-account",
              },
              {
                fieldName: "g4b_amount",
                className: "col-number",
                type: "money",
                headingName: "Amount",
              },
              {
                fieldName: "g4b_processed",
                className: "col-bool",
                type: "boolean",
                headingName: "Processed",
              },
              {
                fieldName: "g4b_dateprocessed",
                className: "col-date",
                type: "datetime",
                headingName: "Date Processed",
                dateFormat: "dd/MM/yyyy",
              },
              {
                fieldName: "g4b_paymentsuccess",
                className: "col-bool",
                type: "boolean",
                headingName: "Payment Success",
              },
              {
                name: "g4b_collectionreference",
                className: "col-name",
                type: "nvarchar",
                headingName: "Collection Reference",
              },
              {
                fieldName: "g4b_bookingpaymentidname",
                className: "col-name",
                headingName: "Booking Payment",
              },
              {
                headingName: "Date Created",
                className: "col-date",
                fieldName: "createdon",
                type: "datetime",
              },
            ],
            lookup: lookups.g4b_paymentbatchitem.all,
            type: "component",
            allowEdit: false,
          },
        ],
      },
    ],
  },
  {
    title: "Payment Batch Item Responses",
    id: "payment-batch-item-responses",
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    displayEditButtonFromState: () => false,
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "paymentbatchitemresponsetable",
            className: "col-12",
            component: AddRemoveRecordsTable,
            existingRecordsEntitySearchName:
              "g4b_paymentbatchitemresponse",
            existingRecordsEntitySearchFilter:
              "ignoreresultexceedcheck",
            existingRecordsSearchFilterArray: [
              {
                name: "outcome",
                description: "",
                label: "Outcome",
                type: "picklist",
                enum: DirectDebitOutcome,
              },
              {
                name: "responseprocessed",
                description: "",
                key: "processed",
                label: "Processed",
                type: "bit",
              },
            ],
            recordsTableColumns: [
              {
                fieldName: "g4b_externalaccountname",
                className: "col-name",
                headingName: "Account Name",
              },
              {
                fieldName: "g4b_externalpaymentamount",
                className: "col-number",
                type: "money",
                headingName: "Payment Amount",
              },
              {
                fieldName: "g4b_externalpaymentdate",
                className: "col-date",
                type: "datetime",
                headingName: "Payment Date",
                dateFormat: "dd/MM/yyyy",
              },
              {
                fieldName: "g4b_externaltxid",
                className: "col-name",
                headingName: "External Tansaction Id",
              },
              {
                fieldName: "g4b_outcome",
                className: "col-name",
                headingName: "Outcome",
              },
              {
                fieldName: "g4b_processed",
                className: "col-bool",
                type: "boolean",
                headingName: "Processed",
              },
              {
                fieldName: "g4b_billingfileidname",
                className: "col-name",
                headingName: "Billing File",
              },
              {
                fieldName: "g4b_bookingpaymentidname",
                className: "col-name",
                headingName: "Booking Payment",
              },
              {
                fieldName: "g4b_paymentbatchitemidname",
                className: "col-name",
                headingName: "Payment Batch Item",
              },
              {
                headingName: "Date Created",
                className: "col-date",
                fieldName: "createdon",
                type: "datetime",
              },
            ],
            lookup: lookups.g4b_paymentbatchitemresponse.all,
            type: "component",
            allowEdit: false,
          },
        ],
      },
    ],
  },
  {
    title: "Errors",
    id: "errors",
    displayIfFromState: (state) =>
      state.g4b_reference && state.g4b_reference !== "",
    displayEditButtonFromState: () => false,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "subject",
              label: "Subject",
              className: "col-name",
            },
            {
              name: "mimetype",
              label: "Type",
              className: "col-name",
            },
            {
              name: "filesize",
              displayFieldValue: (value) =>
                `${value.toLocaleString("en-GB", {
                  useGrouping: "true",
                  maximumFractionDigits: "0",
                })}`,
              label: "File Size",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "download",
              displayFieldValue: (_, record) => (
                <Button
                  onClick={() => downloadAnnotation(record.Id)}
                  style={ButtonStyle.Secondary}
                  text="Download"
                />
              ),
              label: "Download",
              className: "col-short-name",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "annotation",
          useSubForm: false,
        },
      },
    ],
  },
];
