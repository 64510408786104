import { useState } from "react";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { DispatchMethods } from "../enums";
import { Button, Modal } from "../../elements/_Elements";
import {
  ButtonStyle,
  GlobalDispatchMethods,
  LoadingState,
} from "../../../js/enums";
import { UnpublishTriggerFlow } from "../../../js/service";

function PublishedActions({ state }) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [actionsState, setActionsState] = useState({
    displayModal: false,
    loading: LoadingState.NotLoaded,
  });

  const { g4c_name, id } = state;
  const { displayModal, loading } = actionsState;

  const unpublish = async () => {
    setActionsState({
      ...actionsState,
      loading: LoadingState.Loading,
    });

    try {
      const [serviceResponse] = await Promise.all([
        UnpublishTriggerFlow(globalDispatch, id),
      ]);

      if (serviceResponse && serviceResponse.data) {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: `${g4c_name} successfully unpublished`,
            success: true,
          },
          dispatch: globalDispatch,
        });

        dispatch({
          type: DispatchMethods.UpdateRecord,
          values: { g4ca_published: false },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setActionsState({
        ...actionsState,
        displayModal: false,
        loading: LoadingState.Loaded,
      });
    }
  };

  return (
    <>
      {displayModal && (
        <Modal
          title="Unpublish"
          modalCloseButtonClick={() => {
            setActionsState({ ...actionsState, displayModal: false });
          }}
        >
          <div className="modal-body">
            <p>
              Do you want to unpublish this flow? You can reactivate
              later, if you wish.
            </p>
            <p>This action will attempt to unpublish the flow.</p>
          </div>
          <div className="modal-footer">
            <Button
              disabled={loading === LoadingState.Loading}
              text={"Unpublish"}
              showSpinner={loading === LoadingState.Loading}
              style={ButtonStyle.Primary}
              onClick={() => unpublish()}
            />
            <Button
              disabled={loading === LoadingState.Loading}
              text={"Cancel"}
              showSpinner={loading === LoadingState.Loading}
              style={ButtonStyle.Info}
              onClick={() =>
                setActionsState({
                  ...actionsState,
                  displayModal: false,
                })
              }
            />
          </div>
        </Modal>
      )}
      <div className="d-flex justify-content-end mb-3">
        <Button
          style={ButtonStyle.Danger}
          text={"Unpublish"}
          onClick={() =>
            setActionsState({ ...actionsState, displayModal: true })
          }
        />
      </div>
    </>
  );
}

export default PublishedActions;
