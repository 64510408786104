import lookups from "./lookups";
import { QuestionFormStages } from "./question";

export const ChannelQuestionFormStages = [
  {
    title: "Channel Question",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_channelid",
            hidden: true,
          },
          {
            name: "g4b_questionid",
            isNameField: true,
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Question",
            lookup: lookups.g4_question.all,
            required: true,
            subFormStages: QuestionFormStages,
            type: "lookup",
          },
          {
            name: "g4b_sequence",
            className: "col-md-3",
            description: "",
            label: "Sequence",
            required: true,
            type: "int",
          },
        ],
      },
    ],
  },
];
