import {
  useGlobalReducer,
  useGlobalState,
} from "../../GlobalContext";
import {
  BackgroundStyle,
  GlobalDispatchMethods,
} from "../../js/enums";
import { Toast } from "../elements/_Elements";

function Notification() {
  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();

  const { notifications } = globalState;

  const removeNotification = (id) => {
    globalDispatch({
      type: GlobalDispatchMethods.RemoveNotification,
      id: id,
    });
  };

  return (
    <div className="toast-container position-fixed bottom-0 end-0 p-2">
      {notifications &&
        notifications.map((notification) => {
          return (
            <Toast
              key={notification.id}
              message={notification.message}
              onClick={() => removeNotification(notification.id)}
              style={
                notification.success
                  ? BackgroundStyle.Success
                  : BackgroundStyle.Danger
              }
            />
          );
        })}
    </div>
  );
}

export default Notification;
