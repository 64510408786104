import { PaymentStatus, CouponVoucherStatus } from "../js/enums";
import lookups from "./lookups";
import { BookingFormStages } from "./booking";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const VoucherFormStages = [
  {
    title: "Voucher",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_referencenumber",
            className: "col-md-6",
            description: "",
            label: "Reference Number",
            type: "nvarchar",
          },
          {
            name: "g4b_voucherstatus",
            className: "col-md-6",
            description: "",
            label: "Voucher Status",
            type: "picklist",
            enum: CouponVoucherStatus,
          },
          {
            name: "g4b_value",
            className: "col-md-6",
            description: "",
            label: "Value",
            type: "money",
          },
          {
            name: "g4b_purchasedate",
            className: "col-md-4",
            description: "",
            label: "Purchase Date",
            type: "datetime",
          },
          {
            name: "g4b_expirydate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Expiry Date",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Purchaser",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_contactid",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "g4b_customername",
            className: "col-md-4",
            description: "",
            label: "Customer Name",
            linkedEntityId: "g4b_contactid",
            linkForNewRecord: "/contact",
            type: "nvarchar",
          },
          {
            name: "emailaddress1",
            className: "col-md-4",
            description: "",
            label: "Email",
            type: "nvarchar",
          },
          {
            name: "telephone1",
            className: "col-md-4",
            description: "",
            label: "Business Phone",
            type: "nvarchar",
          },
          {
            name: "telephone2",
            className: "col-md-4",
            description: "",
            label: "Home Phone",
            type: "nvarchar",
          },
          {
            name: "mobilephone",
            className: "col-md-4",
            description: "",
            label: "Mobile Phone",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Booking Products",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking Reference",
              className: "col-short-name",
            },
            {
              name: "g4b_protoproductidname",
              label: "Product Variant",
              className: "col-short-name",
            },
            {
              name: "g4b_number",
              label: "Quantity",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_bookingprice",
              label: "Booking Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/booking",
          loadStateRelatedEntityName: "g4b_bookingproduct",
          lookup: lookups.g4b_booking.all,
          subFormStages: BookingFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Booking Payments",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      String(state.g4b_voucherstatus) ===
      String(CouponVoucherStatus.Redeemed),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking Reference",
              className: "col-short-name",
            },
            {
              name: "g4b_paymentmethodidname",
              label: "Payment Method",
              className: "col-short-name",
            },
            {
              name: "g4b_paymentamount",
              label: "Payment Amount",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_bookingoperatoridname",
              label: "Booking Operator",
              className: "col-short-name",
            },
            {
              name: "g4b_terminalidname",
              label: "Terminal",
              className: "col-short-name",
            },
            {
              name: "g4b_paymentstatus",
              className: "col-name",
              label: "Payment Status",
              type: "statusbadge",
              enum: PaymentStatus,
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/booking",
          loadStateRelatedEntityName: "g4b_bookingpayment",
          lookup: lookups.g4b_booking.all,
          subFormStages: BookingFormStages,
          useSubForm: false,
        },
      },
    ],
  },
];
