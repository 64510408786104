import lookups from "../../../forms/lookups";
import { getLookupData } from "../../../js/utility";
import { ExportType, FileType, MarketingListType } from "./Enums";

const accountDefaultFields = [
  "address1_city",
  "address1_country",
  "address1_county",
  "address1_line1",
  "address1_line2",
  "address1_line3",
  "address1_name",
  "donotbulkemail",
  "donotemail",
  "donotphone",
  "emailaddress1",
  "name",
];

const contactDefaultFields = [
  "address1_city",
  "address1_country",
  "address1_county",
  "address1_line1",
  "address1_line2",
  "address1_line3",
  "address1_name",
  "birthdate",
  "donotbulkemail",
  "donotemail",
  "donotphone",
  "emailaddress1",
  "firstname",
  "fullname",
  "g4_age",
  "g4c_donotallowsms",
  "gendercode",
  "lastname",
  "middlename",
  "mobilephone",
  "salutation",
  "telephone1",
  "telephone2",
];

const ExportFormStages = [
  {
    title: "",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "exportType",
            label: "Export Type",
            type: "picklist",
            enum: ExportType,
          },
          {
            name: "fileType",
            displayIfFromValues: (values) =>
              values.exportType === ExportType.File,
            label: "File Type",
            type: "picklist",
            enum: FileType,
          },
          {
            name: "displayAllFields",
            displayIfFromValues: (values) =>
              values.exportType === ExportType.File &&
              values.fileType !== "",
            label: "Display All Fields",
            type: "bit",
          },
          {
            name: "exportFields",
            description:
              "Please select the fields to include in the export",
            displayIfFromState: (state) =>
              state.g4c_entitytype === "contact",
            displayIfFromValues: (values) =>
              values.exportType === ExportType.File &&
              values.fileType !== "",
            filterLookupOptions: (options, values) => {
              if (values.displayAllFields) {
                return options;
              }

              return options.filter((option) =>
                contactDefaultFields.includes(option.Key)
              );
            },
            label: "Fields",
            lookup: lookups.contact.attributes,
            type: "checklist",
          },
          {
            name: "exportFields",
            description:
              "Please select the fields to include in the export",
            displayIfFromState: (state) =>
              state.g4c_entitytype === "account",
            displayIfFromValues: (values) =>
              values.exportType === ExportType.File &&
              values.fileType !== "",
            filterLookupOptions: (options, values) => {
              if (values.displayAllFields) {
                return options;
              }

              return options.filter((option) =>
                accountDefaultFields.includes(option.Key)
              );
            },
            label: "Fields",
            lookup: lookups.account.attributes,
            type: "checklist",
          },
          {
            name: "listType",
            displayIfFromValues: (values) =>
              values.exportType === ExportType["Marketing List"],
            label: "Marketing List Type",
            type: "picklist",
            enum: MarketingListType,
          },
          {
            name: "listId",
            allowAdd: false,
            allowEdit: false,
            displayIfFromValues: (values) =>
              values.exportType === ExportType["Marketing List"] &&
              values.listType === MarketingListType.Existing,
            label: "Marketing List",
            lookup: lookups.list.all,
            mapOptions: (_, state) =>
              getLookupData(lookups.list.all.name, state),
            type: "lookup",
          },
          {
            name: "replaceExistingMembers",
            description:
              "Enabling will remove all existing members from the marketing list",
            displayIfFromValues: (values) =>
              values.exportType === ExportType["Marketing List"] &&
              values.listType === MarketingListType.Existing,
            label: "Replace Existing Members",
            type: "bit",
          },
          {
            name: "listName",
            displayIfFromValues: (values) =>
              values.exportType === ExportType["Marketing List"] &&
              values.listType === MarketingListType.New,
            label: "Name",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];

export default ExportFormStages;
