import lookups from "./lookups";
import {
  AllYearsSelectHeader,
  PastYearsSelectHeader,
} from "../components/elements/DateSelectHeader";
import { SerieFormStages } from "./serie";

export const SeriesFormStages = [
  {
    title: "Series",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_code",
              className: "col-short-name",
              description: "",
            },
            {
              name: "g4b_venueid",
              className: "col-filter",
            },
            {
              name: "g4b_venueconfigurationid",
              className: "col-filter",
            },
            {
              name: "g4b_salesplanid",
              className: "col-filter",
            },
            {
              name: "g4b_selectseats",
              className: "col-bool",
            },
            {
              name: "g4b_seriesstartdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_seriesenddate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_code"],
              type: "text",
            },
            {
              name: "g4b_venueid",
              type: "select",
            },
            {
              name: "g4b_venueconfigurationid",
              type: "select",
            },
            {
              name: "g4b_salesplanid",
              type: "select",
            },
            {
              name: "g4b_seriesstartdate",
              renderCustomHeader: AllYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_seriesenddate",
              renderCustomHeader: AllYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/serie",
          lookup: lookups.g4b_series.all,
          subFormStages: SerieFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
