import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ChevronDown, ChevronUp, EnvelopeIcon } from "../../Icons";
import { Alert, IconCard } from "../../elements/_Elements";
import {
  getCanBeCancelledLabel,
  getCaption,
  getCaptionName,
  getIconForFlowTrigger,
  getIconForWhen,
  getWhenText,
} from "./Helpers";
import { AlertStyle } from "../../../js/enums";
import CommunicationTrigger from "./CommunicationTrigger";

function ReadOnlyTrigger({ flowControl }) {
  const [showCommunications, setShowCommunications] = useState(true);

  const {
    CanBeCancelled,
    Communications,
    Interval,
    Timepart,
    Trigger,
    When,
  } = flowControl;

  const { icon, iconClassName } = getIconForFlowTrigger(Trigger);

  const whenIcon = getIconForWhen(When);

  return (
    <IconCard
      className="mb-3"
      icon={icon}
      iconClassName={classNames(
        "rounded-start",
        "text-white",
        iconClassName
      )}
    >
      <h5 className="mb-3">{getCaptionName(Trigger)}</h5>
      <div className="d-flex align-items-center mb-3">
        <FontAwesomeIcon className="me-4" icon={whenIcon} />
        <div>
          <span>{getWhenText(Interval, Timepart, When)}</span>
          <strong>{getCaption(Trigger)}</strong>
        </div>
      </div>
      <div className="d-flex align-items-center mb-3">
        <FontAwesomeIcon className="me-4" icon={EnvelopeIcon} />
        <div>
          {Communications.length === 1 ? (
            <CommunicationTrigger communication={Communications[0]} />
          ) : (
            <span
              onClick={() =>
                setShowCommunications(!showCommunications)
              }
            >
              <FontAwesomeIcon
                className="me-2 cursor-pointer"
                icon={showCommunications ? ChevronUp : ChevronDown}
              />
              Send 1/{Communications.length} communication variants
            </span>
          )}
        </div>
      </div>
      {showCommunications &&
        Communications.length > 1 &&
        Communications.map((communication, index) => {
          return (
            <div key={index} className="ms-5 mb-3">
              {index > 0 && <p className="ms-2 my-2">Otherwise</p>}
              <CommunicationTrigger communication={communication} />
            </div>
          );
        })}
      {CanBeCancelled && (
        <Alert
          className="m-0"
          style={AlertStyle.Warning}
          text={getCanBeCancelledLabel(Trigger)}
        />
      )}
    </IconCard>
  );
}

export default ReadOnlyTrigger;
