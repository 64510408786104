import { useGlobalReducer } from "../GlobalContext";
import { GlobalDispatchMethods } from "../js/enums";
import { getNavigationItems } from "../js/navigationItems";

function useQuickstartStages() {
  const globalDispatch = useGlobalReducer();

  return [
    {
      title: "Company Details",
      id: "company-details",
      sections: [
        {
          name: "",
          fields: [
            {
              name: "name",
              isNameField: true,
              label: "Name",
              required: true,
              type: "nvarchar",
            },
            {
              name: "cityName",
              description:
                "The city in which the company is located.",
              label: "City",
              required: true,
              type: "nvarchar",
            },
            {
              name: "regionName",
              description:
                "The region/county in which the company is located.",
              label: "Region",
              required: true,
              type: "nvarchar",
            },
            {
              name: "companyWebsite",
              description:
                "The URL of the company website e.g. https://www.green4solutions.com",
              label: "Website URL",
              required: true,
              type: "nvarchar",
            },
            {
              name: "customerReferenceFormat",
              default: "{0}",
              description:
                "The customer reference format, where {0} will be substituted for the next number. For example, use 'M-{0}' for a customer reference like M-1000000",
              label: "Customer Reference Format",
              required: true,
              type: "nvarchar",
            },
            {
              name: "customerReferenceStart",
              default: "1000000",
              description:
                "The starting number for the customer reference. This value will be incremented for each customer.",
              label: "Customer Reference Starting Number",
              required: true,
              type: "int",
            },
          ],
        },
      ],
    },
    {
      title: "Email Marketing",
      id: "email-marketing",
      sections: [
        {
          name: "",
          fields: [
            {
              name: "ecommsAgentId",
              label: "Agent Id",
              type: "nvarchar",
            },
            {
              name: "ecommsActivationCode",
              label: "Activation Code",
              type: "nvarchar",
            },
            {
              name: "g4c_customerid",
              label: "Customer Id",
              type: "nvarchar",
            },
          ],
        },
      ],
    },
    {
      title: "Access Control",
      id: "access-control",
      sections: [
        {
          name: "",
          fields: [
            {
              name: "telegramDestinationURL",
              label: "Telegram Destination URL",
              type: "nvarchar",
            },
          ],
        },
      ],
    },
    {
      title: "Sitemap",
      id: "sitemap",
      handleSubmit: function (_, __, submitValues) {
        globalDispatch({
          type: GlobalDispatchMethods.SetSitemap,
          sitemap: submitValues,
        });
      },
      sections: getNavigationItems().map((navigationItem) => {
        return {
          name: navigationItem.text,
          fields: navigationItem.links.map((link) => {
            return {
              name: link.to,
              default: true,
              label: link.text,
              type: "bit",
            };
          }),
        };
      }),
    },
  ];
}

export default useQuickstartStages;
