import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ChevronDown, ChevronUp } from "../Icons";

function NavigationItem({
  children,
  className = "",
  id,
  onClick,
  show,
  text,
}) {
  return (
    <li>
      <button
        className={`btn ${className}`}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
        aria-expanded={show.toString()}
        aria-controls={id}
        onClick={onClick}
      >
        {text}
        {show ? (
          <FontAwesomeIcon icon={ChevronUp} size="sm" />
        ) : (
          <FontAwesomeIcon icon={ChevronDown} size="sm" />
        )}
      </button>
      <div
        className={classNames("collapse", show ? "show" : "")}
        id={id}
      >
        <ul className="nav flex-column">{children}</ul>
      </div>
    </li>
  );
}

export default NavigationItem;
