import { isSameOrLaterDate } from "../js/utility";
import { CalendarItemType, DayOfWeek } from "../js/enums";

export const CalendarItemFormStages = [
  {
    title: "Calendar Item",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_productcalendarid",
            hidden: true,
          },
          {
            name: "g4b_type",
            description: "",
            label: "Type",
            required: true,
            type: "picklist",
            enum: CalendarItemType,
          },
          {
            name: "g4b_startdate",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Start Date",
            required: true,
            type: "datetime",
          },
          {
            name: "g4b_enddate",
            dateFormat: "dd/MM/yyyy",
            description: "",
            filterDate: (date, values) => {
              return values.g4b_startdate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_startdate)
                  )
                : true;
            },
            label: "End Date",
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_startdate
                ? isSameOrLaterDate(date, values.g4b_startdate)
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4b_dayofweek",
            description: "",
            label: "Day of Week",
            type: "picklist",
            enum: DayOfWeek,
          },
          {
            name: "g4b_opentime",
            dateFormat: "HH:mm",
            description: "",
            label: "Open Time",
            showTimeSelect: true,
            showTimeSelectOnly: true,
            type: "datetime",
          },
          {
            name: "g4b_closetime",
            dateFormat: "HH:mm",
            description: "",
            label: "Close Time",
            showTimeSelect: true,
            showTimeSelectOnly: true,
            type: "datetime",
          },
          {
            name: "g4b_peaktime",
            dateFormat: "HH:mm",
            description: "",
            label: "Peak Start Time",
            showTimeSelect: true,
            showTimeSelectOnly: true,
            type: "datetime",
          },
          {
            name: "g4b_peakend",
            dateFormat: "HH:mm",
            description: "",
            label: "Peak End Time",
            showTimeSelect: true,
            showTimeSelectOnly: true,
            type: "datetime",
          },
        ],
      },
    ],
  },
];
