import { Button, Footer } from "./_Elements";
import { ButtonStyle } from "../../js/enums";

function FormNavigationButtons({
  backText = "Back",
  disabled = false,
  isBusy = false,
  onBack,
  submitText = "Next",
}) {
  return (
    <Footer {...{ backText, onBack }}>
      <Button
        type={"submit"}
        style={ButtonStyle.Primary}
        text={submitText}
        disabled={disabled || isBusy}
        showSpinner={isBusy}
      />
    </Footer>
  );
}

export default FormNavigationButtons;
