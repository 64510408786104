import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { QuestionFormStages } from "./question";

export const QuestionsFormStages = [
  {
    title: "Questions",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_widgetgroupid",
              className: "col-name",
            },
            {
              name: "g4_mandatory",
              className: "col-bool",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_widgetgroupid",
              type: "select",
            },
            {
              name: "g4_mandatory",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/question",
          lookup: lookups.g4_question.all,
          subFormStages: QuestionFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
