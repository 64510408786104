import lookups from "./lookups";
export const CustomerAddressFormStages = [
  {
    title: "",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "parentid",
            hidden: true,
          },
          {
            name: "name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "addressnumber",
            className: "col-md-4",
            description: "",
            label: "Address Number",
            required: false,
            type: "nvarchar",
            hidden: true,
          },
          {
            name: "line1",
            className: "col-md-4",
            description: "",
            label: "Address Line 1",
            required: false,
            type: "nvarchar",
          },
          {
            name: "line2",
            className: "col-md-4",
            description: "",
            label: "Address Line 2",
            required: false,
            type: "nvarchar",
          },
          {
            name: "line3",
            className: "col-md-4",
            description: "",
            label: "Address Line 3",
            required: false,
            type: "nvarchar",
          },
          {
            name: "city",
            className: "col-md-4",
            description: "",
            label: "City",
            required: false,
            type: "nvarchar",
          },
          {
            name: "county",
            className: "col-md-4",
            description: "",
            label: "County",
            required: false,
            type: "nvarchar",
          },
          {
            name: "country",
            className: "col-md-4",
            description: "",
            label: "Country",
            required: false,
            allowAdd: false,
            allowEdit: false,
            lookup: lookups.g4_country.all,
            type: "lookup",
            reverseLookup: true,
          },
          {
            name: "postalcode",
            className: "col-md-4",
            description: "",
            label: "Postcode",
            required: false,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
