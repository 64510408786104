function FormLabel({
  className = "form-label",
  label,
  mandatory = false,
  name,
}) {
  return (
    <label htmlFor={name} className={className}>
      {mandatory && <strong>{label}</strong>}
      {!mandatory && label}
    </label>
  );
}

export default FormLabel;
