import { hideNavigationButtons } from "../Helpers";
import { Button, Footer } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";
import CommunicationFlowConfirmPublishModal from "./CommunicationFlowConfirmPublishModal";
import { useGlobalState } from "../../../GlobalContext";

function CommunicationFlowNavigationButtons({
  backText,
  disabled = false,
  errors,
  isBusy = false,
  onBack,
  onSubmit,
  setConfirmationModal,
  setFieldValue,
  showSaveAndCloseButton,
  showSaveButton,
  stage,
  stages,
  state,
  submitText = "Save",
  submitButtonClickEvent,
  saveAndCloseSubmitButtonText = "Save and Close",
  saveAndCloseSubmitButtonClickEvent,
}) {
  const globalState = useGlobalState();

  const handleSaveButton = (action, submitButtonEvent) => {
    if (action === "saveAndPublish") {
      if (errors && Object.keys(errors).length > 0) {
        setConfirmationModal(null);
      } else {
        setConfirmationModal({
          displayModal: true,
          modalComponent: CommunicationFlowConfirmPublishModal,
        });
      }
    } else {
      submitButtonEvent();
      onSubmit();
    }
  };

  return (!onBack && !showSaveButton && !showSaveAndCloseButton) ||
    hideNavigationButtons(globalState, stage, stages, state) ? (
    <></>
  ) : (
    <Footer {...{ backText, disabled, isBusy, onBack }}>
      {showSaveButton && state.statuscode !== 4 && (
        <Button
          style={ButtonStyle.Primary}
          text={submitText}
          disabled={disabled || isBusy}
          showSpinner={isBusy}
          onClick={() =>
            handleSaveButton("save", submitButtonClickEvent)
          }
        />
      )}
      {showSaveButton &&
        state.statuscode !== 4 &&
        state.flow_data.length > 0 && (
          <Button
            className="ms-3"
            style={ButtonStyle.Primary}
            text={"Save and Publish"}
            disabled={disabled || isBusy}
            showSpinner={isBusy}
            onClick={() =>
              handleSaveButton("saveAndPublish", () =>
                setFieldValue("action", "saveAndPublish")
              )
            }
          />
        )}
      {showSaveAndCloseButton && state.statuscode !== 4 && (
        <Button
          className="ms-3"
          type={"submit"}
          style={ButtonStyle.Primary}
          text={saveAndCloseSubmitButtonText}
          disabled={disabled || isBusy}
          showSpinner={isBusy}
          onClick={() =>
            handleSaveButton(
              "saveAndClose",
              saveAndCloseSubmitButtonClickEvent
            )
          }
        />
      )}
    </Footer>
  );
}

export default CommunicationFlowNavigationButtons;
