const TextControlDefaultValues = [
  {
    Name: "text1",
    Value: "<p>Please enter your text</p>",
  },
  {
    Name: "text2",
    Value: "<p>Please enter your text</p>",
  },
  {
    Name: "backgroundColour",
    Value: null,
  },
  {
    Name: "padding",
    Value: "5px",
  },
  {
    Name: "margin",
    Value: "",
  },
  {
    Name: "columns",
    Value: "1",
  },
  {
    Name: "columnSplit",
    Value: "50",
  },
  {
    Name: "showOnMobile",
    Value: true,
  },
  {
    Name: "textColour",
    Value: "#222",
  },
  {
    Name: "fontSize",
    Value: "14px",
  },
  {
    Name: "fontWeight",
    Value: "normal",
  },
  {
    Name: "lineHeight",
    Value: "125%",
  },
  {
    Name: "textAlign",
    Value: "left",
  },
  {
    Name: "borderType",
    Value: "none",
  },
  {
    Name: "borderThickness",
    Value: "",
  },
  {
    Name: "borderColour",
    Value: "",
  },
  {
    Name: "stackColumns",
    Value: "0",
  },
  {
    Name: "fontFamily",
    Value: "Arial, Helvetica, sans-serif",
  },
];

export default TextControlDefaultValues;
