import { DispatchMethods } from "./enums";
import { getOverviewIndex } from "./Helpers";

const formReducer = (state, action) => {
  switch (action.type) {
    case DispatchMethods.SetDefaultValues:
      return setDefaultValues(state, action);
    case DispatchMethods.SetEditRecord:
      return setEditRecord(state, action);
    case DispatchMethods.SetInitialLoad:
      return setInitialLoad(state, action);
    case DispatchMethods.SetLookupOptions:
      return setLookupOptions(state, action);
    case DispatchMethods.SetName:
      return setName(state, action);
    case DispatchMethods.SetPreviousStage:
      return setPreviousStage(state, action);
    case DispatchMethods.SetRecordId:
      return setRecordId(state, action);
    case DispatchMethods.SetRedirect:
      return setRedirect(action);
    case DispatchMethods.SetRelatedEntities:
      return setRelatedEntities(state, action);
    case DispatchMethods.SetReloadValues:
      return setReloadValues(state, action);
    case DispatchMethods.SetStage:
      return setStage(state, action);
    case DispatchMethods.UpdateRecord:
      return setUpdatedRecord(state, action);
    case DispatchMethods.UpdateRecordAndGoToNextStage:
      return setUpdatedRecordAndGoToNextStage(state, action);
    default:
      return state;
  }
};

const setDefaultValues = (state, action) => {
  return {
    ...state,
    initialLoad: false,
    ...action.responseData,
  };
};

const setEditRecord = (state, action) => {
  const { Fields, Id, Name, RelatedEntities } = action.responseData;

  return {
    ...state,
    initialLoad: false,
    stage: action.stage,
    id: Id,
    name: Name,
    ...Fields,
    relatedEntities:
      RelatedEntities != null && RelatedEntities.length > 0
        ? RelatedEntities.map((relatedEntity) => {
            return {
              entityName: relatedEntity.EntityName,
              entities: relatedEntity.Entities.map((entity) => {
                return {
                  Id: entity.Id,
                  Name: entity.Name,
                  Fields: entity.Fields,
                };
              }),
            };
          })
        : [],
  };
};

const setInitialLoad = (state, action) => {
  return {
    ...state,
    initialLoad: action.initialLoad,
  };
};

const setLookupOptions = (state, action) => {
  // overwrite any existing lookupOptions with the new values
  const lookupOptions = [
    ...action.lookupOptions,
    ...(state.lookupOptions
      ? state.lookupOptions.filter(
          (x) => !action.lookupOptions.some((y) => y.name === x.name)
        )
      : []),
  ];
  return {
    ...state,
    lookupOptions: lookupOptions,
  };
};

const setName = (state, action) => {
  return {
    ...state,
    name: action.name,
  };
};

const setPreviousStage = (state, action) => {
  return {
    ...state,
    stage: state.skipToOverview
      ? getOverviewIndex(action.stages)
      : action.stageIndex > 0
      ? action.stageIndex - 1
      : action.stageIndex,
    skipToOverview: false,
  };
};

const setRecordId = (state, action) => {
  // redirect to the element
  return {
    ...state,
    id: action.id,
    redirect: action.redirect ? action.redirect : "",
    replace: action.replace,
  };
};

const setRedirect = (action) => {
  return {
    initialLoad: true,
    redirect: action.redirect,
    replace: false,
    stage: 1,
  };
};

const setRelatedEntities = (state, action) => {
  return {
    ...state,
    relatedEntities: action.relatedEntities,
    highlightRelatedEntities: action.highlightRelatedEntities,
  };
};

const setReloadValues = (state, action) => {
  return {
    ...state,
    reloadValues: action.reloadValues,
  };
};

const setStage = (state, action) => {
  return {
    ...state,
    formData: action.formData,
    reloadValues: null,
    stage: action.stage,
    skipToOverview: action.skipToOverview,
  };
};

const setUpdatedRecord = (state, action) => {
  return {
    ...state,
    ...action.values,
  };
};

const setUpdatedRecordAndGoToNextStage = (state, action) => {
  return {
    ...state,
    ...action.values,
    reloadValues: null,
    stage: state.skipToOverview
      ? getOverviewIndex(action.stages)
      : action.stages.length > 1
      ? action.stageIndex + 1
      : action.stageIndex,
  };
};

export { formReducer };
