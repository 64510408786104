import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { CommunicationFlowFormStages } from "./communicationFlow";
import lookups from "./lookups";

export const CommunicationFlowsFormStages = [
  {
    title: "Flows",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4ca_flowmode",
              className: "col-short-name",
            },
            {
              name: "g4ca_published",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "Live Only",
              type: "bit",
              defaultValue: false,
              filterAction: (filterValue, values) => {
                if (
                  filterValue &&
                  values &&
                  values.Fields &&
                  values.Fields.g4ca_flowmode.Id === 1
                ) {
                  return false;
                }
                return true;
              },
            },
            {
              name: "Published Only",
              type: "bit",
              defaultValue: false,
              filterAction: (filterValue, values) => {
                if (
                  filterValue &&
                  values &&
                  values.Fields &&
                  values.Fields.g4ca_published.Id === 0
                ) {
                  return false;
                }
                return true;
              },
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "Name",
          initialOrderedByAsc: true,
          linkForNewRecord: "/communication-flow",
          lookup: lookups.g4c_triggerflow.all,
          subFormStages: CommunicationFlowFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
