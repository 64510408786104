import classNames from "classnames";
import { DesignMode } from "./enums";
import { DroppableElement } from "../../elements/_Elements";
import EditCommunicationControl from "./EditCommunicationControl";
import CommunicationControl from "./CommunicationControl";
import { Fragment } from "react";

function CommunicationTableRow({
  activeId,
  allowEdit,
  row,
  rowIndex,
  saveCurrentState,
  saveUndoSnapshot,
  setFieldValue,
  values,
}) {
  const allowEditControls =
    allowEdit && values.designMode === String(DesignMode.Controls);

  const columnClass =
    row.Columns.length === 3
      ? "four"
      : row.Columns.length === 2
      ? "six"
      : "twelve";

  return (
    <table key={rowIndex} className="content-table twelve columns">
      <tbody>
        <tr>
          {row.Columns.map((column, columnIndex) => {
            return (
              <td
                key={columnIndex}
                className={classNames(
                  "content-table column",
                  columnClass
                )}
              >
                {allowEditControls && (
                  <>
                    <DroppableElement
                      id={`control-${rowIndex}-${columnIndex}-0`}
                    />
                    {column.Controls.length === 0 && (
                      <div className="empty-content-zone">
                        Empty content zone
                      </div>
                    )}
                  </>
                )}
                {column.Controls.map((control, controlIndex) => {
                  const { Name, Properties, Template } = control;

                  const droppableId = `control-${rowIndex}-${columnIndex}-${
                    controlIndex + 1
                  }`;

                  const properties = Properties.reduce(
                    (result, prop) => {
                      result[prop.Name] = prop.Value;
                      return result;
                    },
                    {}
                  );

                  return allowEditControls ? (
                    <Fragment key={controlIndex}>
                      <EditCommunicationControl
                        name={Name}
                        template={Template}
                        {...{
                          activeId,
                          columnIndex,
                          controlIndex,
                          properties,
                          rowIndex,
                          saveCurrentState,
                          saveUndoSnapshot,
                          setFieldValue,
                          values,
                        }}
                      />
                      <DroppableElement id={droppableId} />
                    </Fragment>
                  ) : (
                    <CommunicationControl
                      key={controlIndex}
                      name={Name}
                      properties={properties}
                      template={Template}
                    />
                  );
                })}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}

export default CommunicationTableRow;
