import lookups from "../../../../forms/lookups";
import { getLookupFieldOptions } from "../../Helpers";
import {
  ColumnsOptions,
  ColumnSplits,
  LinkType,
  PredefinedLink,
  Widths,
} from "../enums";

function Columns(prefix, label = "Columns") {
  return {
    name: prefix ? `${prefix}Columns` : "columns",
    description: "",
    enum: ColumnsOptions,
    label: label,
    type: "picklist",
  };
}

function ColumnSplit(prefix, label = "Column Split") {
  return {
    name: prefix ? `${prefix}ColumnSplit` : "columnSplit",
    description: "",
    enum: ColumnSplits,
    label: label,
    type: "picklist",
  };
}

function LinkProperties(prefix) {
  return [
    {
      name: prefix ? `${prefix}LinkType` : "linkType",
      description: "",
      enum: LinkType,
      label: "Link Type",
      type: "picklist",
    },
    {
      name: prefix ? `${prefix}Link` : "link",
      description: "",
      displayIfFromValues: (values) =>
        String(values.linkType) === LinkType["Web Address"],
      label: "Link",
      type: "nvarchar",
    },
    {
      name: prefix ? `${prefix}EmailAddress` : "emailAddress",
      description: "",
      displayIfFromValues: (values) =>
        String(values.linkType) === LinkType["Email Address"],
      label: "Email Address",
      type: "nvarchar",
    },
    {
      name: prefix ? `${prefix}EmailSubject` : "emailSubject",
      description: "",
      displayIfFromValues: (values) =>
        String(values.linkType) === LinkType["Email Address"],
      label: "Email Subject",
      type: "nvarchar",
    },
    {
      name: prefix ? `${prefix}EmailContent` : "emailContent",
      description: "",
      displayIfFromValues: (values) =>
        String(values.linkType) === LinkType["Email Address"],
      label: "Email Content",
      type: "nvarchar",
    },
    {
      name: prefix ? `${prefix}PredefinedLinks` : "predefinedLinks",
      description: "",
      displayIfFromValues: (values) =>
        String(values.linkType) === LinkType.Predefined,
      enum: PredefinedLink,
      label: "Link",
      type: "picklist",
    },
    {
      name: prefix
        ? `${prefix}SelectedLinkAttribute`
        : "selectedLinkAttribute",
      description: "",
      displayIfFromValues: (values) =>
        String(values.linkType) === LinkType.Attribute,
      label: "Attribute",
      mapOptions: (_, state, values) => {
        const { g4c_listtype } = state;

        let lookup =
          g4c_listtype === "1"
            ? lookups.account.attributes
            : lookups.contact.attributes;

        return getLookupFieldOptions(
          {
            lookup: lookup,
          },
          state,
          values,
          null
        );
      },
      type: "lookup",
    },
  ];
}

function Width(prefix, label = "Width") {
  return {
    name: prefix ? `${prefix}Width` : "width",
    description: "",
    enum: Widths,
    label: label,
    type: "picklist",
  };
}

export { Columns, ColumnSplit, LinkProperties, Width };
