export const SubscriptionGroupFormStages = [
  {
    title: "Subscription Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4c_sequencenumber",
            description: "",
            label: "Sequence",
            type: "int",
          },
          {
            name: "g4c_description",
            className: "col-12",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
