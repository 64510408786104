import { Alert } from "../../../../elements/_Elements";
import { AlertStyle } from "../../../../../js/enums";
import BookingProducts from "./BookingProducts";
import Coupons from "./Coupons";
import DirectDebit from "./DirectDebit";
import Donation from "./Donation";
import Footer from "./Footer";
import Header from "./Header";
import PaymentMandate from "./PaymentMandate";
import PromotionCriteria from "./PromotionCriteria";
import VehicleRegistrationNumbers from "./VehicleRegistrationNumbers";
import Vouchers from "./Vouchers";

function BookingControl({ properties }) {
  const {
    backgroundColour,
    borderColour,
    borderThickness,
    borderType,
    margin,
    padding,
    showBookingSummary,
    showCoupons,
    showDirectDebit,
    showGiftAid,
    showPaymentMandate,
    showPromotionCriteria,
    showVehicleRegistrationNumbers,
    showVouchers,
  } = properties;

  const showAlert =
    !showBookingSummary &&
    !showCoupons &&
    !showDirectDebit &&
    !showGiftAid &&
    !showPaymentMandate &&
    !showPromotionCriteria &&
    !showVehicleRegistrationNumbers &&
    !showVouchers;

  return (
    <div
      className="plugin-placeholder"
      data-plugin-type="Green4.CRM.Agent.Plugins.BookingConfirmationPlugin"
      data-plugin-assembly="Green4.CRM.Agent.Plugins"
      data-contains-xslt="true"
      data-plugin-inline-css="true"
    >
      {showAlert && (
        <Alert
          className="remove-item"
          style={AlertStyle.Warning}
          text="Booking control does not have any content to display."
        />
      )}

      <div
        data-xsl-wrap="xsl:template"
        data-xsl-attributes="match='Booking'"
      >
        {showBookingSummary && (
          <table className="full-width zero-margin">
            <tbody>
              <tr>
                <td style={{ padding: margin }}>
                  <table
                    className="full-width zero-margin"
                    style={{ backgroundColour: backgroundColour }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ padding: padding }}>
                          <table
                            className="full-width zero-margin"
                            style={{
                              borderColor: borderColour,
                              borderStyle: borderType,
                              borderWidth: borderThickness,
                            }}
                          >
                            <tbody>
                              <Header properties={properties} />
                              <BookingProducts
                                properties={properties}
                              />
                              <Footer properties={properties} />
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {showPromotionCriteria && (
          <PromotionCriteria properties={properties} />
        )}
        {showPaymentMandate && (
          <PaymentMandate properties={properties} />
        )}
        {showDirectDebit && <DirectDebit properties={properties} />}
        {showVouchers && <Vouchers properties={properties} />}
        {showCoupons && <Coupons properties={properties} />}
        {showGiftAid && <Donation properties={properties} />}
        {showVehicleRegistrationNumbers && (
          <VehicleRegistrationNumbers properties={properties} />
        )}
      </div>
    </div>
  );
}

export default BookingControl;
