import { Button, Modal } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";

function CommunicationConfirmPublishModal({
  onSubmit,
  setConfirmationModal,
  setFieldValue,
  state,
  values,
}) {
  const getConfirmationModalTitleText = () => {
    //The title will change based on the following:
    //If the audience type is flow
    //If the audience type is existing and the Selected Start Type is Immediately
    //If the audience type is existing and the Selected Start Type is Scheduled
    return String(state.g4c_audiencetype) === "16" //Flow
      ? "Publish Communication For Flow"
      : String(state.g4c_audiencetype) === "4" //Existing
      ? String(values.g4c_starttype) === "0" //Immediately
        ? "Publish Communication Immediately"
        : String(values.g4c_starttype) === "1" //Scheduled
        ? "Publish Scheduled Communication"
        : ""
      : "";
  };

  const getConfirmationModalBodyText = () => {
    //The body text will change based on the following:
    //If the audience type is flow
    //If the audience type is existing and the Selected Start Type is Immediately
    //If the audience type is existing and the Selected Start Type is Scheduled
    return String(state.g4c_audiencetype) === "16" //Flow
      ? "Do you want to publish this communication? This action will attempt to publish this communication for use with a flow."
      : String(state.g4c_audiencetype) === "4" //Existing
      ? String(values.g4c_starttype) === "0" //Immediately
        ? "Do you want to publish this communication? This action will attempt to publish this communication immediately."
        : String(values.g4c_starttype) === "1" //Scheduled
        ? "Do you want to publish this communication?"
        : ""
      : "";
  };

  const getConfirmationModalConfirmButtonText = () => {
    //The body text will change based on the following:
    //If the audience type is flow
    //If the audience type is existing and the Selected Start Type is Immediately
    //If the audience type is existing and the Selected Start Type is Scheduled
    return String(state.g4c_audiencetype) === "16" //Flow
      ? "Publish for Flow"
      : String(state.g4c_audiencetype) === "4" //Existing
      ? String(values.g4c_starttype) === "0" //Immediately
        ? "Publish Immediately"
        : String(values.g4c_starttype) === "1" //Scheduled
        ? "Schedule Publish"
        : ""
      : "Confirm";
  };
  return (
    <Modal
      title={getConfirmationModalTitleText()}
      modalCloseButtonClick={() => {
        setConfirmationModal(null);
      }}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body">
        <small>{getConfirmationModalBodyText()}</small>
      </div>
      <div className="modal-footer">
        <Button
          text={getConfirmationModalConfirmButtonText()}
          style={ButtonStyle.Primary}
          onClick={() => {
            setFieldValue("action", "saveAndPublish");
            setConfirmationModal(null);
            onSubmit();
          }}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => {
            setConfirmationModal(null);
          }}
        />
      </div>
    </Modal>
  );
}

export default CommunicationConfirmPublishModal;
