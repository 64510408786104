import { useDraggable } from "@dnd-kit/core";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { EditElement } from "../../elements/_Elements";
import CommunicationControl from "./CommunicationControl";
import { deleteControl, setEditControl } from "./Helpers";

function EditCommunicationControl({
  activeId,
  columnIndex,
  controlIndex,
  name,
  properties,
  rowIndex,
  saveCurrentState,
  saveUndoSnapshot,
  setFieldValue,
  template,
  values,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const id = `movecontrol-${rowIndex}-${columnIndex}-${controlIndex}`;

  const { attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: id,
    });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const { editControl } = values;
  const isEditing =
    editControl &&
    editControl.rowIndex === rowIndex &&
    editControl.columnIndex === columnIndex &&
    editControl.controlIndex === controlIndex;

  return (
    <EditElement
      isActive={activeId === id}
      isEditing={isEditing}
      moveAttributes={attributes}
      moveListeners={listeners}
      onDelete={() => {
        saveUndoSnapshot(values);
        deleteControl(
          columnIndex,
          controlIndex,
          globalDispatch,
          rowIndex,
          setFieldValue,
          values
        );
      }}
      onEdit={() => {
        saveCurrentState(values);
        setEditControl(
          columnIndex,
          controlIndex,
          dispatch,
          globalDispatch,
          name,
          properties,
          rowIndex,
          template,
          values
        );
      }}
      setNodeRef={setNodeRef}
      showToolbar={!activeId}
      style={style}
    >
      <CommunicationControl
        properties={isEditing ? values : properties}
        template={template}
      />
      {!activeId && <div className="edit-element-overlay"></div>}
    </EditElement>
  );
}

export default EditCommunicationControl;
