import {
  Aligns,
  Directions,
  FontFamilies,
  FontSizes,
  FontWeights,
  LineHeights,
  LineTypes,
  VerticalAligns,
} from "./enums";

function Align(prefix, label = "Align") {
  return {
    name: prefix ? `${prefix}Align` : "align",
    description: "",
    enum: Aligns,
    label: label,
    type: "picklist",
  };
}

function BackgroundColour(prefix, label = "Background Colour") {
  const backgroundPrefix = prefix
    ? `${prefix}Background`
    : "background";
  return Colour(backgroundPrefix, label);
}

function BorderColour(prefix, label = "Border Colour") {
  const borderPrefix = prefix ? `${prefix}Border` : "border";
  return Colour(borderPrefix, label);
}

function BorderRadius(prefix, label = "Border Radius") {
  const borderPrefix = prefix ? `${prefix}Border` : "border";
  return Radius(borderPrefix, label);
}

function BorderThickness(prefix, label = "Border Thickness") {
  return {
    name: prefix ? `${prefix}BorderThickness` : "borderThickness",
    description: "",
    label: label,
    type: "nvarchar",
  };
}

function BorderType(prefix, label = "Border Type") {
  return {
    name: prefix ? `${prefix}BorderType` : "borderType",
    description: "",
    enum: LineTypes,
    label: label,
    type: "picklist",
  };
}

function Colour(prefix, label = "Colour") {
  return {
    name: prefix ? `${prefix}Colour` : "colour",
    description: "",
    label: label,
    type: "colourpicker",
  };
}

function FontFamily(prefix, label = "Font Family") {
  return {
    name: prefix ? `${prefix}FontFamily` : "fontFamily",
    description: "",
    enum: FontFamilies,
    label: label,
    type: "picklist",
  };
}

function FontSize(prefix, label = "Font Size") {
  return {
    name: prefix ? `${prefix}FontSize` : "fontSize",
    description: "",
    enum: FontSizes,
    label: label,
    type: "picklist",
  };
}

function FontWeight(prefix, label = "Font Weight") {
  return {
    name: prefix ? `${prefix}FontWeight` : "fontWeight",
    description: "",
    enum: FontWeights,
    label: label,
    type: "picklist",
  };
}

function LineHeight(prefix, label = "Line Height") {
  return {
    name: prefix ? `${prefix}LineHeight` : "lineHeight",
    description: "",
    enum: LineHeights,
    label: label,
    type: "picklist",
  };
}

function LineThickness(prefix, label = "Line Thickness") {
  return {
    name: prefix ? `${prefix}LineThickness` : "lineThickness",
    description: "",
    label: label,
    type: "nvarchar",
  };
}

function LineType(prefix, label = "Line Type") {
  return {
    name: prefix ? `${prefix}LineType` : "lineType",
    description: "",
    enum: LineTypes,
    label: label,
    type: "picklist",
  };
}

function Margin(prefix, label = "Margin") {
  return {
    name: prefix ? `${prefix}Margin` : "margin",
    description: "",
    label: label,
    type: "nvarchar",
  };
}

function Padding(prefix, label = "Padding") {
  return {
    name: prefix ? `${prefix}Padding` : "padding",
    description: "",
    label: label,
    type: "nvarchar",
  };
}

function Radius(prefix, label = "Radius") {
  return {
    name: prefix ? `${prefix}Radius` : "radius",
    description: "",
    label: label,
    type: "nvarchar",
  };
}

function TextAlign(prefix, label = "Text Align") {
  const textPrefix = prefix ? `${prefix}Text` : "text";
  return Align(textPrefix, label);
}

function TextColour(prefix, label = "Text Colour") {
  const textPrefix = prefix ? `${prefix}Text` : "text";
  return Colour(textPrefix, label);
}

function TextPosition(prefix, label = "Text Position") {
  return {
    name: prefix ? `${prefix}TextPosition` : "textPosition",
    description: "",
    enum: Directions,
    label: label,
    type: "picklist",
  };
}

function VerticalAlign(prefix, label = "Vertical Align") {
  return {
    name: prefix ? `${prefix}VerticalAlign` : "verticalAlign",
    description: "",
    enum: VerticalAligns,
    label: label,
    type: "picklist",
  };
}

export {
  Align,
  BackgroundColour,
  BorderColour,
  BorderRadius,
  BorderThickness,
  BorderType,
  Colour,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  LineThickness,
  LineType,
  Margin,
  Padding,
  Radius,
  TextAlign,
  TextColour,
  TextPosition,
  VerticalAlign,
};
