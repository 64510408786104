import classNames from "classnames";
import { useGlobalReducer } from "../../../GlobalContext";
import { Alert, IconCard, Loading } from "../../elements/_Elements";
import { AlertStyle } from "../../../js/enums";
import {
  getCaptionForAudienceRule,
  getIconForAudienceRule,
  useCommunicationAudience,
} from "../communicationAudience/Helpers";

function ReadOnlyAudienceRulesAndCount({ setFieldValue, values }) {
  const globalDispatch = useGlobalReducer();

  const { loading, formData } = useCommunicationAudience(
    globalDispatch,
    setFieldValue,
    values
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {formData &&
        formData.rules &&
        formData.rules.map((rule, index) => {
          const { Type } = rule;

          const { icon, iconClassName } =
            getIconForAudienceRule(Type);

          const isMissingRule = rule && rule.IsMissing;

          return (
            <IconCard
              key={index}
              className={
                isMissingRule ? "border border-danger mb-3" : "mb-3"
              }
              icon={icon}
              iconClassName={classNames(
                "rounded-start",
                "text-white",
                iconClassName,
                isMissingRule ? "bg-danger" : ""
              )}
            >
              <span>
                <span>
                  {getCaptionForAudienceRule(index + 1, rule)}
                </span>
                {isMissingRule ? " ---MISSING RULE---" : ""}
              </span>
            </IconCard>
          );
        })}
      {formData && (
        <Alert
          className="text-center m-0"
          style={AlertStyle.Info}
          text={`Final count: ${
            formData.finalCount ? formData.finalCount : 0 //If formData.finalCount is null, then its the same as 0
          }`}
        />
      )}
    </>
  );
}

export default ReadOnlyAudienceRulesAndCount;
