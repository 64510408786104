import { getDateString } from "../../Helpers";

function FooterTableCell({
  className,
  properties,
  sample,
  text,
  textAlign,
  xslt,
  whiteSpace,
}) {
  const {
    footerBackgroundColour,
    footerCellPadding,
    footerFontFamily,
    footerFontSize,
    footerFontWeight,
    footerTextColour,
  } = properties;

  return (
    <td
      className={className}
      style={{
        backgroundColor: footerBackgroundColour,
        color: footerTextColour,
        fontFamily: footerFontFamily,
        fontSize: footerFontSize,
        fontWeight: footerFontWeight,
        padding: footerCellPadding,
        textAlign: textAlign,
        whiteSpace: whiteSpace,
      }}
    >
      {xslt && (
        <p
          className="hide-on-designer"
          dangerouslySetInnerHTML={{
            __html: xslt,
          }}
        ></p>
      )}
      {text && <p>{text}</p>}
      {sample && <p className="remove-item">{sample}</p>}
    </td>
  );
}

function Tax({ properties }) {
  return (
    <tr
      data-xsl-wrap="xsl:for-each"
      data-xsl-attributes="select='Taxcomponents/Taxcomponent'"
    >
      <FooterTableCell
        className="three"
        properties={properties}
        textAlign="left"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        sample="Tax"
        textAlign="right"
        xslt='<xsl:value-of select="Name" />'
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£45.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(Taxpaid, ../../CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function TotalPrice({ properties }) {
  const { totalPrice } = properties;

  return (
    <tr>
      <FooterTableCell
        className="three"
        properties={properties}
        textAlign="left"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        text={totalPrice}
        textAlign="right"
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£225.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(TotalPrice, CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function TotalCharges({ properties }) {
  const { totalCharges } = properties;

  return (
    <tr
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test=&quot;format-number(TotalCharges, '#,##0.00', 'Money') {HtmlEncode:>} 0&quot;"
    >
      <FooterTableCell
        className="three"
        properties={properties}
        textAlign="left"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        text={totalCharges}
        textAlign="right"
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£25.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(TotalCharges, CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function TotalDiscount({ properties }) {
  const { totalDiscount } = properties;

  return (
    <tr
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test=&quot;format-number(Discount, '#,##0.00', 'Money') {HtmlEncode:>} 0&quot;"
    >
      <FooterTableCell
        className="three"
        properties={properties}
        textAlign="left"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        text={totalDiscount}
        textAlign="right"
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£25.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(Discount, CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function SubTotal({ properties }) {
  const { subTotal } = properties;

  return (
    <tr
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test=&quot;format-number(Discount, '#,##0.00', 'Money') {HtmlEncode:>} 0&quot;"
    >
      <FooterTableCell
        className="three"
        properties={properties}
        textAlign="left"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        text={subTotal}
        textAlign="right"
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£200.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(TotalPrice - Discount, CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function Payments({ properties }) {
  const { shortDateFormat } = properties;

  return (
    <tr
      data-xsl-wrap="xsl:for-each"
      data-xsl-attributes='select="PaymentsMade/PaymentMade"'
    >
      <FooterTableCell
        className="three"
        properties={properties}
        sample={getDateString(0, 0, "DD/MM/YYYY")}
        textAlign="left"
        xslt={`<xsl:value-of select="ms:format-date(Date, ${shortDateFormat})" />`}
        whiteSpace="nowrap"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        sample="Card Payment"
        textAlign="right"
        xslt='<xsl:value-of select="Name" />'
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£150.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(Amount, ../../CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function TotalPaid({ properties }) {
  const { totalPaid } = properties;

  return (
    <tr>
      <FooterTableCell
        className="three"
        properties={properties}
        textAlign="left"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        text={totalPaid}
        textAlign="right"
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£150.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(Paid, CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function TotalDue({ properties }) {
  const { totalDue } = properties;

  return (
    <tr
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test=&quot;format-number(Paid, '#,##0.00', 'Money') {HtmlEncode:<} format-number(TotalPrice - Discount, '#,##0.00', 'Money')&quot;"
    >
      <FooterTableCell
        className="three"
        properties={properties}
        textAlign="left"
      />
      <FooterTableCell
        className="five"
        properties={properties}
        text={totalDue}
        textAlign="right"
      />
      <FooterTableCell
        className="four"
        properties={properties}
        sample="£50.00"
        textAlign="right"
        xslt='<xsl:value-of select="g4:FormatCurrency(TotalPrice - Discount - Paid, CurrencyCode)" />'
        whiteSpace="nowrap"
      />
    </tr>
  );
}

function Footer({ properties }) {
  const { footerBackgroundColour, showTax } = properties;

  return (
    <tr>
      <td colSpan="3" style={{ textAlign: "right", width: "100%" }}>
        <table
          align="right"
          className="mobile-full"
          style={{
            backgroundColor: footerBackgroundColour,
            marginBottom: "0",
          }}
        >
          <tbody>
            {showTax && <Tax properties={properties} />}
            <TotalPrice properties={properties} />
            <TotalCharges properties={properties} />
            <TotalDiscount properties={properties} />
            <SubTotal properties={properties} />
            <Payments properties={properties} />
            <TotalPaid properties={properties} />
            <TotalDue properties={properties} />
          </tbody>
        </table>
      </td>
    </tr>
  );
}

export default Footer;
