import { Fragment } from "react";
import "./VerticalRule.scss";

function VerticalRule({ height, zoomFactor }) {
  const increments = Array.from(
    { length: height },
    (_, index) => index
  );

  return (
    <div className="vertical-rule">
      <svg
        height={`${height * zoomFactor}mm`}
        viewBox={`0 0 10mm ${height * zoomFactor}mm`}
      >
        {increments.map((increment) => {
          const lineHeight =
            increment % 10 === 0 ? 10 : increment % 5 === 0 ? 5 : 3;

          return (
            <Fragment key={increment}>
              {lineHeight === 10 && (
                <text x="2mm" y={`${increment * zoomFactor + 1}mm`}>
                  {increment}
                </text>
              )}
              <line
                x1="10mm"
                y1={`${increment * zoomFactor}mm`}
                x2={`${10 - lineHeight}mm`}
                y2={`${increment * zoomFactor}mm`}
              ></line>
            </Fragment>
          );
        })}
      </svg>
    </div>
  );
}

export default VerticalRule;
