import { isValidEmail } from "../js/utility";
import lookups from "./lookups";
import ActivationCode from "../components/form/communication/ActivationCode";

export const CommunicationSettingsFormStages = [
  {
    title: "Communication Settings",
    sections: [
      {
        name: "General",
        fields: [
          {
            name: "g4c_name",
            isNameField: true,
            hidden: true,
          },
          {
            name: "g4c_agentid",
            description: "",
            label: "Agent Id",
            type: "nvarchar",
          },
          {
            name: "azureQueueName",
            description: "",
            label: "Azure Queue Name",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Live Communications",
        fields: [
          {
            name: "g4c_batchsize",
            description: "",
            label: "Batch Size",
            type: "int",
          },
          {
            name: "g4c_defaultduration",
            description: "",
            label: "Default Duration (days)",
            type: "int",
          },
          {
            name: "g4c_fromname",
            description: "",
            label: "From Name",
            type: "nvarchar",
          },
          {
            name: "g4c_fromaddress",
            description: "",
            label: "From Address",
            type: "email",
            validate: (email) => {
              const isValid = email ? isValidEmail(email) : false;
              return isValid
                ? ""
                : "Please enter a valid email address";
            },
          },
          {
            name: "g4c_toname",
            description: "",
            label: "To Name",
            type: "nvarchar",
          },
          {
            name: "g4c_expirypage",
            description: "",
            label: "Expiry Page",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Include Marketing List in all communications",
        fields: [
          {
            name: "g4c_includecontactmarketinglist",
            description: "",
            label: "Contact",
            lookup: lookups.list.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Seed Lists",
        fields: [
          {
            name: "g4ca_flowtestlistcontact",
            description:
              "When flows are configured in Test mode, the trigger actions will only apply to contacts in this list",
            label: "Contacts",
            lookup: lookups.list.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Activation",
        fields: [
          {
            name: "",
            className: "col-12",
            component: ActivationCode,
            type: "component",
          },
          {
            name: "g4c_customerid",
            description: "",
            displayIfFromState: (values) => values.g4c_customerid,
            disabled: true,
            label: "Customer Id",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
