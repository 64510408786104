import {
  getSeatBackgroundColour,
  getSeatLabel,
  getLeftPosition,
  getTopPosition,
} from "./Helpers";

function Seat({
  rowIndex,
  seatClasses,
  seatData,
  seatIndex,
  selectSeat,
}) {
  return (
    <div
      key={`${rowIndex}-${seatIndex}`}
      className="seat position-absolute"
      onClick={(event) => {
        selectSeat(event, rowIndex, seatIndex);
      }}
      style={{
        top: getTopPosition(rowIndex, seatIndex, seatData),
        left: getLeftPosition(rowIndex, seatIndex, seatData),
        backgroundColor: getSeatBackgroundColour(
          rowIndex,
          seatClasses,
          seatData,
          seatIndex
        ),
      }}
      title={getSeatLabel(rowIndex, seatIndex, seatData)}
    ></div>
  );
}

export default Seat;
