import React, { useEffect, useState } from "react";

const createPages = (currentPage, totalPages, maxPageButtons) => {
  if (maxPageButtons < 10) maxPageButtons = 10;
  let pages = [1];
  if (totalPages <= maxPageButtons) {
    for (let i = 2; i < totalPages; i++) {
      pages.push(i);
    }
  } else {
    let middleButtons = maxPageButtons - 2;
    let start = currentPage - Math.floor((middleButtons - 1) / 2);
    let end = currentPage + Math.floor((middleButtons - 1) / 2);
    if (start < 2) {
      end += 2 - start;
      start = 2;
    }
    if (end > totalPages - 1) {
      start -= end - totalPages + 1;
      end = totalPages - 1;
    }
    if (maxPageButtons % 2 === 0) {
      if (start > 2) start -= 1;
      else end += 1;
    }
    if (start > 2) pages.push("...");
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    if (end < totalPages - 1) pages.push("...");
  }
  if (totalPages > 1) {
    pages.push(totalPages);
  }
  return pages;
};

const Pagination = ({
  currentPage,
  maxPageButtons = 10,
  records,
  recordsPerPage = 10,
  setCurrentPage,
  setRecords,
  totalPages,
  useRows = false,
}) => {
  const [pagedRecords, setPagedRecords] = useState([]);

  if (!totalPages) {
    totalPages = records
      ? Math.ceil(records.length / recordsPerPage)
      : 0;
  }

  // update current records
  useEffect(() => {
    if (records && records.length > 0) {
      const indexOfLastRecord = currentPage * recordsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      if (useRows) {
        //We are using the rows value in the fields to determine what data to display
        //via using the indexOfFirstRecord as the first row record and indexOfLastRecord as the last row record
        let filteredData = records.filter(
          (r) =>
            r.Fields.row &&
            r.Fields.row > indexOfFirstRecord &&
            r.Fields.row <= indexOfLastRecord
        );
        setPagedRecords(filteredData);
      } else {
        //We are taking the range of data starting at the first index and ending at the last index
        //to determine what data to display
        setPagedRecords(
          records.slice(indexOfFirstRecord, indexOfLastRecord)
        );
      }
    } else {
      setPagedRecords([]);
    }
  }, [
    currentPage,
    records,
    recordsPerPage,
    setPagedRecords,
    useRows,
  ]);

  // notify the parent component that the records have updated
  useEffect(() => {
    if (setRecords) {
      setRecords(pagedRecords);
    }
  }, [pagedRecords, setRecords]);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber !== currentPage) setCurrentPage(pageNumber);
  };

  // Create a array of pages to show
  const pages = createPages(currentPage, totalPages, maxPageButtons);

  return (
    <>
      {totalPages < 2 ? (
        <></>
      ) : (
        <nav className="m-0">
          <ul className="pagination mb-0">
            {pages.map((page, i) => (
              <li
                key={i}
                className={`page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                {typeof page === "number" ? (
                  <span
                    className="page-link cursor-pointer"
                    onClick={() => paginate(page)}
                  >
                    {page}
                  </span>
                ) : (
                  <span className="page-link">{page}</span>
                )}
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  );
};

export default Pagination;
