export const PaymentBatchItemsFormStages = [
  {
    title: "Payment Batch Items",
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          fields: [
            {
              name: "g4b_bookingpaymentidName",
              type: "nvarchar",
            },
            {
              name: "g4b_paymentmandateidName",
              type: "nvarchar",
            },
            {
              name: "g4m_membershipaccountidName",
              type: "nvarchar",
            },
            {
              name: "g4b_amount",
              type: "decimal",
            },
          ],
          filterByStatus: false,
        },
      },
    ],
  },
];
