import classNames from "classnames";
import { getFilterComponent } from "./Helpers";

function Filters({
  allowExpandFilters,
  allRecords,
  displayIcons,
  filters,
  setFilters,
  state,
  subFormStages,
  tableFilters,
  values,
}) {
  return (
    <>
      {tableFilters.map((filter, i) => {
        return (
          <div
            key={i}
            className={classNames(
              tableFilters.length > 1 || !allowExpandFilters
                ? "col-md-2"
                : "col-md-12"
            )}
          >
            {getFilterComponent(
              allRecords,
              true,
              filter,
              filters,
              setFilters,
              state,
              subFormStages,
              values,
              displayIcons
            )}
          </div>
        );
      })}
    </>
  );
}

export default Filters;
