function HeaderTableCell({ children, properties }) {
  const {
    headerCellPadding,
    headerFontFamily,
    headerFontSize,
    headerFontWeight,
    headerTextColour,
  } = properties;

  return (
    <td
      style={{
        color: headerTextColour,
        padding: headerCellPadding,
        fontFamily: headerFontFamily,
        fontSize: headerFontSize,
        fontWeight: headerFontWeight,
      }}
    >
      {children}
    </td>
  );
}

function Header({ properties }) {
  const { headerBackgroundColour, quantity, price, product } =
    properties;

  return (
    <tr
      style={{
        backgroundColor: headerBackgroundColour,
      }}
    >
      <HeaderTableCell properties={properties}>
        {product}
      </HeaderTableCell>
      <HeaderTableCell properties={properties}>
        {quantity}
      </HeaderTableCell>
      <HeaderTableCell properties={properties}>
        {price}
      </HeaderTableCell>
    </tr>
  );
}

export default Header;
