import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { ProductCategoryFormStages } from "./productCategory";

export const ProductCategoriesFormStages = [
  {
    title: "Product Categories",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_sequence",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_code",
              className: "col-short-name",
              description: "",
              label: "Code",
            },
            {
              name: "g4b_description",
              className: "col-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_code"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/product-category",
          lookup: lookups.g4b_analysiscategory.all,
          subFormStages: ProductCategoryFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
