import lookups from "./lookups";
import { GroupFormStages } from "./group";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const GroupsFormStages = [
  {
    title: "Groups",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4_code",
              className: "col-short-name",
            },
            {
              name: "g4_sequence",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4_type",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4_code"],
              type: "text",
            },
            {
              name: "g4_type",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/group",
          lookup: lookups.g4_group.widget,
          subFormStages: GroupFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
