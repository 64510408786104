import { CollapsibleSection } from "../../elements/_Elements";

function Bookings({ DefaultAttribution }) {
  const {
    FormatedAverageBookingValue,
    FormatedTotalRevenue,
    Products,
    Promotions,
    TotalBookings,
  } = DefaultAttribution;

  return (
    <div className="border p-3">
      <CollapsibleSection id={"bookings"} title={"Bookings"}>
        <div className="row">
          <div className="col-12 col-xl-6">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col">Performance</th>
                  <th scope="col" className="col-number">
                    Total
                  </th>
                  <th scope="col" className="col-number">
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Revenue</th>
                  <td>{FormatedTotalRevenue}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <th scope="row">Total</th>
                  <td>{TotalBookings}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <th scope="row">Average Booking Value</th>
                  <td>{FormatedAverageBookingValue}</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
          {Products && Products.length > 0 && (
            <div className="col-12 col-xl-6">
              <table className="table">
                <thead>
                  <tr className="table-info">
                    <th scope="col">Products</th>
                    <th scope="col" className="col-number">
                      Total
                    </th>
                    <th scope="col" className="col-number">
                      Percentage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Products.map((product, index) => {
                    const { FormattedRevenue, Percentage, Product } =
                      product;
                    return (
                      <tr key={index}>
                        <th scope="row">{Product}</th>
                        <td>{FormattedRevenue}</td>
                        <td>{parseFloat(Percentage).toFixed(2)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {Promotions && Promotions.length > 0 && (
            <div className="col-12 col-xl-6">
              <table className="table">
                <thead>
                  <tr className="table-info">
                    <th scope="col">Promotion</th>
                    <th scope="col" className="col-number">
                      Total
                    </th>
                    <th scope="col" className="col-number">
                      Percentage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Promotions.map((promotion, index) => {
                    const { Percentage, Promotion, Total } =
                      promotion;
                    return (
                      <tr key={index}>
                        <th scope="row">{Promotion}</th>
                        <td>{Total}</td>
                        <td>{parseFloat(Percentage).toFixed(2)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </CollapsibleSection>
    </div>
  );
}

export default Bookings;
