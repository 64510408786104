import { PaymentStatus, PaymentType } from "../js/enums";
import lookups from "./lookups";
import ApproveBookingPayment from "../components/form/booking/ApproveBookingPayment";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const BookingPaymentFormStages = [
  {
    title: "General",
    id: "general",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "approveBookingPayment",
            className: "col-12",
            component: ApproveBookingPayment,
            displayComponentOnOverview: true,
            displayIfFromState: (state) =>
              state.g4b_paymenttype &&
              state.g4b_paymenttype === PaymentType.Invoice &&
              state.g4b_paymentstatus &&
              state.g4b_paymentstatus === PaymentStatus.Submitted,
            type: "component",
          },
          {
            name: "g4b_bookingid",
            type: "nvarchar",
            hidden: true,
          },
          {
            name: "g4b_paymentmethodid",
            type: "nvarchar",
            hidden: true,
          },
          {
            name: "g4b_paymentmethodidname",
            className: "col-md-4",
            description: "",
            label: "Payment Method",
            type: "nvarchar",
          },
          {
            name: "g4b_paymentdate",
            className: "col-md-4",
            description: "",
            label: "Payment Date",
            type: "datetime",
          },
          {
            name: "g4b_paymentstatus",
            className: "col-md-4",
            description: "",
            enum: PaymentStatus,
            label: "Payment Status",
            type: "picklist",
          },
          {
            name: "g4b_terminalidname",
            className: "col-md-4",
            description: "",
            label: "Terminal",
            type: "nvarchar",
          },
          {
            name: "g4b_bookingoperatoridname",
            className: "col-md-4",
            description: "",
            label: "Booking Operator",
            type: "nvarchar",
          },
          {
            name: "g4b_paymenttokenidname",
            className: "col-md-4",
            description: "",
            label: "Payment Token",
            type: "nvarchar",
          },
          {
            name: "g4b_providerreference",
            className: "col-md-4",
            description: "",
            label: "Provider Reference",
            type: "nvarchar",
          },
          {
            name: "g4b_paymentservice",
            className: "col-md-4",
            description: "",
            label: "Payment Service",
            type: "nvarchar",
          },
          {
            name: "g4b_servicereference",
            className: "col-md-4",
            description: "",
            label: "Service Reference",
            type: "nvarchar",
          },
          {
            name: "g4b_bookingpaymentreference",
            className: "col-md-4",
            description: "",
            label: "Payment Reference",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Payment Amounts",
    id: "payment-amounts",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_paymentamount",
            className: "col-md-4",
            description: "",
            label: "Payment Amount",
            type: "money",
          },
          {
            name: "g4b_chargeamount",
            className: "col-md-4",
            description: "",
            label: "Charge Amount",
            type: "money",
          },
          {
            name: "g4b_chargetotalamount",
            className: "col-md-4",
            description: "",
            label: "Charge Total Amount",
            type: "money",
          },
          {
            name: "g4m_membershipamount",
            className: "col-md-4",
            description: "",
            label: "Membership Amount",
            type: "money",
          },
          {
            name: "g4b_productamount",
            className: "col-md-4",
            description: "",
            label: "Product Amount",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Card",
    id: "card",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      state.g4b_paymenttype &&
      state.g4b_paymenttype === PaymentType.Card,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_cardtype",
            className: "col-md-4",
            description: "",
            label: "Card Type",
            type: "nvarchar",
          },
          {
            name: "g4b_cardauthorisationcode",
            className: "col-md-4",
            description: "",
            label: "Card Authorisation Code",
            type: "nvarchar",
          },
          {
            name: "g4b_cardnumber",
            className: "col-md-4",
            description: "",
            label: "Card Number",
            type: "nvarchar",
          },
          {
            name: "g4b_cardstart",
            className: "col-md-4",
            description: "",
            label: "Card Start",
            type: "nvarchar",
          },
          {
            name: "g4b_cardexpiry",
            className: "col-md-4",
            description: "",
            label: "Card Expiry",
            type: "nvarchar",
          },
          {
            name: "g4b_cardholdername",
            className: "col-md-4",
            description: "",
            label: "Card Holder Name",
            type: "nvarchar",
          },
          {
            name: "g4b_cardpaymentorderref",
            className: "col-md-4",
            description: "",
            label: "Card Payment Order Ref",
            type: "nvarchar",
          },
          {
            name: "g4b_cardissuenumber",
            className: "col-md-4",
            description: "",
            label: "Card Issue No.",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Cash Handling",
    id: "cash-handling",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      state.g4b_paymenttype &&
      state.g4b_paymenttype === PaymentType.Cash,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_tenderedamountdisplay",
            className: "col-md-4",
            description: "",
            label: "Tendered Amount",
            type: "nvarchar",
          },
          {
            name: "g4b_tenderedamountcurrencyidname",
            className: "col-md-4",
            description: "",
            label: "Tendered Amount Currency",
            type: "nvarchar",
          },
          {
            name: "g4b_tenderedamountexchangerate",
            className: "col-md-4",
            description: "",
            label: "Tendered Amount Exchange Rate",
            type: "decimal",
          },
          {
            name: "g4b_changeamountdisplay",
            className: "col-md-4",
            description: "",
            label: "Change Amount",
            type: "nvarchar",
          },
          {
            name: "g4b_changeamountcurrencyidname",
            className: "col-md-4",
            description: "",
            label: "Change Amount Currency",
            type: "nvarchar",
          },
          {
            name: "g4b_changeamountexchangerate",
            className: "col-md-4",
            description: "",
            label: "Change Amount Exchange Rate",
            type: "decimal",
          },
        ],
      },
    ],
  },
  {
    title: "Discount",
    id: "discount",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      state.g4b_paymenttype &&
      state.g4b_paymenttype === PaymentType.Discount,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_discountcode",
            className: "col-md-4",
            description: "",
            label: "Discount Code",
            type: "nvarchar",
          },
          {
            name: "g4b_discountreason",
            className: "col-md-4",
            description: "",
            label: "Discount Reason",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Invoice",
    id: "invoice",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      state.g4b_paymenttype &&
      state.g4b_paymenttype === PaymentType.Invoice,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_invoicenumber",
            className: "col-md-4",
            description: "",
            label: "Invoice Number",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Voucher",
    id: "voucher",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      state.g4b_paymenttype &&
      state.g4b_paymenttype === PaymentType.Voucher,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_vouchernumber",
            className: "col-md-4",
            description: "",
            label: "Voucher Number",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Journal Payments",
    id: "journal-payments",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          fields: [
            {
              name: "g4b_value",
              label: "Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_productbaseidname",
              label: "Product",
              className: "col-short-name",
            },
            {
              name: "g4b_varianttypeidname",
              label: "Variant",
              className: "col-short-name",
            },
            {
              name: "bundleproductname",
              label: "Bundle Product",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_journalpayment",
          lookup: lookups.g4b_journalpayment.all,
          useSubForm: false,
        },
      },
    ],
  },
];
