import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalReducer } from "../../../GlobalContext";
import {
  Alert,
  BadgeList,
  Button,
  Loading,
  Modal,
} from "../../elements/_Elements";
import {
  AlertStyle,
  BackgroundStyle,
  ButtonStyle,
  LoadingState,
} from "../../../js/enums";
import { WarningIcon } from "../../Icons";
import { GetEntityRecordById } from "../../../js/service";
import CommunicationTable from "../communication/CommunicationTable";
import { disableLinkClicks } from "../../../js/utility";

function CommunicationTrigger({ communication }) {
  const globalDispatch = useGlobalReducer();

  const [communicationData, setCommunicationData] = useState({
    data: null,
    loading: LoadingState.NotLoaded,
    showModal: false,
  });

  const { data, loading, showModal } = communicationData;

  const fetchCommunicationData = async () => {
    setCommunicationData({
      ...communicationData,
      loading: LoadingState.Loading,
      showModal: true,
    });

    let layoutData = null;
    try {
      const [serviceResponse] = await Promise.all([
        GetEntityRecordById(
          globalDispatch,
          "g4c_communication",
          communication.Value
        ),
      ]);

      if (
        serviceResponse &&
        serviceResponse.data &&
        serviceResponse.data.Fields
      ) {
        const {
          globalBackgroundColour,
          globalContentBackgroundColour,
          globalContentPadding,
          globalLinkColour,
          globalPadding,
          rows,
        } = serviceResponse.data.Fields;

        setCommunicationData({
          data: {
            globalBackgroundColour: globalBackgroundColour,
            globalContentBackgroundColour:
              globalContentBackgroundColour,
            globalContentPadding: globalContentPadding,
            globalLinkColour: globalLinkColour,
            globalPadding: globalPadding,
            rows: rows,
          },
          loading: LoadingState.Loaded,
          showModal: true,
        });
      }
    } catch (error) {
      console.error(error);
    }

    return layoutData;
  };

  return (
    <>
      {communication.Value ? (
        <p className="m-0">
          {communication.Valid !== 1 && (
            <FontAwesomeIcon
              className="me-2 text-warning warning-orange"
              icon={WarningIcon}
            />
          )}
          Send
          <Button
            className="px-1 py-0"
            style={ButtonStyle.Link}
            onClick={() => fetchCommunicationData()}
            text={communication.Name}
          />
          {communication.ComTagsList.length > 0 && <>to:</>}
        </p>
      ) : (
        <Alert
          className="m-0"
          style={AlertStyle.Danger}
          text="No communication selected"
        />
      )}
      {communication.ComTagsList.length > 0 && (
        <BadgeList
          backgroundStyle={BackgroundStyle.Info}
          className="mb-3"
          items={communication.ComTagsList.map((x) => ({ text: x }))}
        />
      )}
      {showModal && (
        <Modal
          title={"Preview"}
          modalCloseButtonClick={() => {
            setCommunicationData({
              ...communicationData,
              showModal: false,
            });
          }}
          className="modal modal-xl modal-dialog-scrollable"
        >
          <div className="modal-body">
            {loading !== LoadingState.Loaded ? (
              <Loading />
            ) : (
              <>
                <div className="d-flex align-items-center mb-3">
                  <p className="mb-0 me-auto">
                    <em>
                      Please note that all links are disabled for the
                      preview
                    </em>
                  </p>
                  <a
                    href={`/communication?id=${communication.Value}`}
                    className="btn btn-secondary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open in new tab
                  </a>
                </div>
                <div onClick={(event) => disableLinkClicks(event)}>
                  <CommunicationTable
                    allowEdit={false}
                    backgroundColour={data.globalBackgroundColour}
                    contentBackgroundColour={
                      data.globalContentBackgroundColour
                    }
                    contentPadding={data.globalContentPadding}
                    linkColour={data.globalLinkColour}
                    padding={data.globalPadding}
                    rows={data.rows}
                  />
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default CommunicationTrigger;
