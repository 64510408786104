import { useDraggable } from "@dnd-kit/core";
import { IconCard } from "./_Elements";

function DraggableIconCard({
  className = "mb-3",
  icon,
  iconClassName,
  id,
  title,
}) {
  const { attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: id,
    });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <IconCard {...{ icon, iconClassName }}>{title}</IconCard>
    </div>
  );
}

export default DraggableIconCard;
