import { MandateStatus } from "../js/enums";
import lookups from "./lookups";

export const PaymentMandateFormStages = [
  {
    title: "Payment Mandate",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_mandatestatus",
            className: "col-md-4",
            type: "picklist",
            label: "Mandate Status",
            enum: MandateStatus,
          },
          {
            name: "g4b_externalreference",
            className: "col-md-4",
            type: "nvarchar",
            label: "External Reference",
            disabled: true,
          },
          {
            name: "g4b_reference",
            className: "col-md-4",
            type: "nvarchar",
            label: "Reference",
            description:
              "Auto generated reference for the Payment Mandate",
            disabled: true,
            autoFillValue: () => {
              return "";
            },
          },
          {
            name: "g4b_cmsserviceresponsecode",
            className: "col-md-4",
            type: "int",
            label: "CMS Service Response Code",
            disabled: true,
          },
          {
            name: "g4b_startdate",
            className: "col-date",
            type: "datetime",
            label: "Start Date",
          },
        ],
      },
    ],
  },
  {
    title: "Banking Details",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_accountname",
            className: "col-md-4",
            type: "nvarchar",
            label: "Account Name",
          },

          {
            name: "g4b_sortcode",
            className: "col-md-4",
            type: "nvarchar",
            label: "Sortcode",
          },
          {
            name: "g4b_accountnumber",
            className: "col-md-4",
            type: "nvarchar",
            label: "Account Number",
          },
          {
            name: "g4b_bankname",
            className: "col-md-4",
            type: "nvarchar",
            label: "Bank Name",
          },
          {
            name: "g4b_paymentmethodid",
            className: "col-md-4",
            type: "lookup",
            label: "Payment Method",
            lookup: lookups.g4b_paymentmethod.finance,
          },
          {
            name: "g4b_isacafaccount",
            className: "col-md-3",
            type: "bit",
            label: "Is a CAF Account",
          },
        ],
      },
    ],
  },
  {
    title: "Are These Used For Anything",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_numberofpayments",
            className: "col-md-4",
            type: "int",
            label: "Number of Payments",
            description:
              "Number of recurring payments to be made, or leave blank for on-going payments.",
          },
          {
            name: "g4b_collectbalance",
            className: "col-md-3",
            type: "bit",
            label: "Collect Balance",
            description:
              "Whether to collect the outstanding credit account balance with each payment",
          },
          {
            name: "g4b_collectdepositwithfirstpayment",
            className: "col-md-3",
            type: "bit",
            label: "Collect Deposit With First Payment",
          },
        ],
      },
    ],
  },
];
