import { Alert, Button, Modal } from "../../elements/_Elements";
import { AlertStyle, ButtonStyle } from "../../../js/enums";
import { getErrorsForFlow } from "./Helpers";

function CommunicationFlowConfirmPublishModal({
  onSubmit,
  setConfirmationModal,
  setFieldValue,
  state,
}) {
  const { flow_data } = state;
  const valid = !flow_data.some((x) => x.Valid !== 1);

  return (
    <Modal
      title={"Publish Flow Confirmation"}
      modalCloseButtonClick={() => {
        setConfirmationModal(null);
      }}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body">
        {valid ? (
          <>
            <p>Do you want to publish this flow?</p>
            <p>This action will attempt to publish the flow.</p>
          </>
        ) : (
          <>
            <p>
              The following communication problems have been found.
              You can not start this flow until these problems are
              fixed.
            </p>
            {getErrorsForFlow(state).map((error, index) => (
              <Alert
                key={index}
                style={AlertStyle.Danger}
                text={error}
              />
            ))}
          </>
        )}
      </div>
      <div className="modal-footer">
        {valid && (
          <Button
            text={"Publish"}
            style={ButtonStyle.Primary}
            onClick={() => {
              setFieldValue("action", "saveAndPublish");
              setConfirmationModal(null);
              onSubmit();
            }}
          />
        )}
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => {
            setConfirmationModal(null);
          }}
        />
      </div>
    </Modal>
  );
}

export default CommunicationFlowConfirmPublishModal;
