const ColumnsOptions = Object.freeze({
  "1 Column": 1,
  "2 Columns": 2,
});

const ColumnSplits = Object.freeze({
  "50/50": 50,
  "30/70": 30,
  "70/30": 70,
});

const DesignMode = Object.freeze({
  Controls: 1,
  Rows: 2,
});

const DragType = Object.freeze({
  NewRow: 1,
  MoveRow: 2,
  NewControl: 3,
  MoveControl: 4,
});

const LinkType = Object.freeze({
  "Web Address": "0",
  "Email Address": "1",
  Predefined: "2",
  Attribute: "3",
});

const PredefinedLink = Object.freeze({
  "View Email In Browser": "actions/redirect.aspx?url=../home.html",
  "Temporary Access":
    "/actions/portal.aspx?type=temporaryaccess&email=$$attribute:emailaddress1-$&token=$$attribute:g4_accesstoken-$",
  "Update Details":
    "/actions/portal.aspx?type=update&email=$$attribute:emailaddress1-$",
  Unsubscribe: "/actions/portal.aspx?type=unsubscribe",
  "Pay By Link":
    "actions/redirect.aspx?url=$$attribute:regarding_g4b_paymenturl-$",
});

const Widths = Object.freeze({
  "Fit To Text": "auto",
  "Full Width": "100%",
});

export {
  ColumnsOptions,
  ColumnSplits,
  DesignMode,
  DragType,
  LinkType,
  PredefinedLink,
  Widths,
};
