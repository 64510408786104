import { useDroppable } from "@dnd-kit/core";
import classNames from "classnames";
import Layer from "./Layer";
import "./Layout.scss";

function Layout({
  activeId,
  allowEdit,
  layoutIndex,
  page,
  setFieldValue,
  snapIncrement = 1,
  values,
  zoomFactor = 1,
}) {
  const { setNodeRef } = useDroppable({
    id: "print-transform-layout",
  });

  const {
    BackgroundColour,
    BackgroundImage,
    BottomOffset,
    Height,
    Layers,
    LeftOffset,
    RightOffset,
    TopOffset,
    Width,
  } = page;

  return (
    <div
      className={classNames(
        "print-transform-layout",
        allowEdit ? "allow-edit" : ""
      )}
      ref={setNodeRef}
      style={{
        background: BackgroundImage
          ? "url(data:image/png;base64," +
            BackgroundImage +
            ") no-repeat center center"
          : "",
        backgroundColor: BackgroundColour,
        height: `${Height * zoomFactor}mm`,
        width: `${Width * zoomFactor}mm`,
      }}
    >
      <div
        style={{
          borderTopWidth: `${TopOffset * zoomFactor}mm`,
          borderRightWidth: `${RightOffset * zoomFactor}mm`,
          borderBottomWidth: `${BottomOffset * zoomFactor}mm`,
          borderLeftWidth: `${LeftOffset * zoomFactor}mm`,
        }}
      >
        {Layers.map((layer, layerIndex) => {
          return (
            <Layer
              key={layerIndex}
              {...{
                activeId,
                allowEdit,
                layer,
                layerIndex,
                layoutIndex,
                page,
                setFieldValue,
                snapIncrement,
                values,
                zoomFactor,
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Layout;
