import { VoucherFormStages } from "./voucher";
import lookups from "./lookups";
import { CouponVoucherStatus } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const VouchersFormStages = [
  {
    title: "Vouchers",
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          useSubmitButton: true,
          fields: [
            {
              name: "g4b_referencenumber",
              className: "col-short-name",
            },
            {
              name: "g4b_voucherstatus",
              className: "col-short-name",
              type: "statusbadge",
              enum: CouponVoucherStatus,
            },
            {
              name: "g4b_value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_customername",
              className: "col-name",
            },
            {
              name: "g4b_purchasedate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_referencenumber",
              type: "text",
            },
            {
              name: "g4b_voucherstatus",
              defaultValue: [CouponVoucherStatus.Available],
              enum: CouponVoucherStatus,
              label: "Voucher Status",
              type: "select",
            },
            {
              name: "g4b_customername",
              type: "text",
            },
            {
              name: "g4b_purchasedate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              type: "datetime",
            },
          ],
          linkForNewRecord: "/voucher",
          lookup: lookups.g4b_voucher.all,
          subFormStages: VoucherFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
