const ButtonControlDefaultValues = [
  {
    Name: "text",
    Value: "Button",
  },
  {
    Name: "textColour",
    Value: "#222",
  },
  {
    Name: "fontSize",
    Value: "14px",
  },
  {
    Name: "fontWeight",
    Value: "normal",
  },
  {
    Name: "backgroundColour",
    Value: "",
  },
  {
    Name: "buttonColour",
    Value: "#ddd",
  },
  {
    Name: "padding",
    Value: "5px 30px",
  },
  {
    Name: "margin",
    Value: "5px 0",
  },
  {
    Name: "borderRadius",
    Value: "0",
  },
  {
    Name: "borderType",
    Value: "none",
  },
  {
    Name: "borderThickness",
    Value: "",
  },
  {
    Name: "borderColour",
    Value: "",
  },
  {
    Name: "textAlign",
    Value: "center",
  },
  {
    Name: "align",
    Value: "left",
  },
  {
    Name: "width",
    Value: "auto",
  },
  {
    Name: "showOnMobile",
    Value: true,
  },
  {
    Name: "linkType",
    Value: null,
  },
  {
    Name: "selectedLinkAttribute",
    Value: "",
  },
  {
    Name: "link",
    Value: "",
  },
  {
    Name: "emailAddress",
    Value: "",
  },
  {
    Name: "emailSubject",
    Value: "",
  },
  {
    Name: "emailContent",
    Value: "",
  },
  {
    Name: "predefinedLinks",
    Value: "",
  },
  {
    Name: "fontFamily",
    Value: "Arial, Helvetica, sans-serif",
  },
];

export default ButtonControlDefaultValues;
