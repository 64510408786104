import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { TelegramQueueAction, TelegramQueueStage } from "../js/enums";
import { administratorRoles } from "../js/roles";
import { checkIfSellFixturesAndSeriesSettingOn } from "../js/utility";
import lookups from "./lookups";
import { TelegramFormStages } from "./telegram";

export const TelegramQueueFormStages = [
  {
    title: "Telegram Queue",
    editOnOverview: true,
    recordEditRoles: administratorRoles,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_stage",
            className: "col-md-4",
            description: "",
            enum: TelegramQueueStage,
            label: "Stage",
            type: "picklist",
          },
        ],
      },
    ],
  },
  {
    title: "Information",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-4",
            description: "",
            label: "Name",
            isNameField: true,
            type: "nvarchar",
          },
          {
            name: "g4b_sequencenumber",
            className: "col-md-4",
            description: "",
            label: "Sequence Number",
            type: "int",
          },
          {
            name: "g4b_type",
            className: "col-md-4",
            description: "",
            label: "Type",
            type: "nvarchar",
          },
          {
            name: "g4b_action",
            className: "col-md-4",
            description: "",
            enum: TelegramQueueAction,
            label: "Action",
            type: "picklist",
          },
          {
            name: "g4b_voidreason",
            className: "col-md-4",
            description: "",
            label: "Void Reason",
            type: "nvarchar",
          },
          {
            name: "g4b_accesscontrolprovideridname",
            className: "col-md-4",
            description: "",
            label: "Access Control Provider",
            type: "nvarchar",
          },
          {
            name: "g4b_utid",
            description: "",
            label: "UTID",
            type: "int",
          },
          {
            name: "g4m_membershipnumber",
            description: "",
            label: "Membership Number",
            type: "nvarchar",
          },
          {
            name: "g4m_membershipaccesstokenidname",
            description: "",
            label: "Membership Access Token",
            type: "nvarchar",
          },
          {
            name: "g4b_fixtureidname",
            className: "col-md-4",
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Fixture",
            type: "nvarchar",
          },
          {
            name: "g4b_seriesidname",
            className: "col-md-4",
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Series",
            type: "nvarchar",
          },
          {
            name: "g4b_xml",
            className: "col-12",
            label: "XML",
            textAreaHeight: "400px",
            type: "textarea",
          },
        ],
      },
    ],
  },
  {
    title: "Telegrams",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4b_sequencenumber",
              className: "col-number",
              label: "Sequence Number",
              type: "string",
            },
            {
              name: "g4b_sendtime",
              className: "col-date",
              label: "Send Time",
              type: "datetime",
            },
            {
              name: "g4b_receivetime",
              className: "col-date",
              label: "Receive Time",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_sendtime",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_receivetime",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_telegram",
          lookup: lookups.g4b_telegram.all,
          subFormStages: TelegramFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
