import classNames from "classnames";
import PageState from "../components/PageState";
import SideBar from "../components/sidebar/SideBar";
import Header from "../components/header/Header";
import Notification from "../components/notification/Notification";
import { useHasScrollbar } from "../js/utility";
import "./style.scss";

function DefaultLayout({ children, renderSidebar = true }) {
  const hasScrollbar = useHasScrollbar();

  return (
    <>
      <PageState />
      <Header />
      <main>
        {renderSidebar && <SideBar />}
        <div
          id="content"
          className={classNames(
            hasScrollbar ? "has-scrollbar" : "has-no-scrollbar"
          )}
        >
          {children}
        </div>
        <Notification />
      </main>
    </>
  );
}

export default DefaultLayout;
