import classNames from "classnames";
import { PriceType } from "../../../js/enums";

function VariantPriceListItem({
  disabled,
  errors,
  handleOffPeakPriceChange,
  handlePeakPriceChange,
  usePeakPrice,
  variantPriceListId,
  variantPriceListItem,
  variantTypeId,
}) {
  const updateOffPeakValue = (event) => {
    handleOffPeakPriceChange(event.target.value);
  };

  const updatePeakValue = (event) => {
    handlePeakPriceChange(event.target.value);
  };

  const offPeakPrice = variantPriceListItem
    ? variantPriceListItem.OffPeakPrice
    : "";

  const peakPrice = variantPriceListItem
    ? variantPriceListItem.PeakPrice
    : "";

  const hasOffPeakPriceError =
    errors &&
    errors.find(
      (productVariant) =>
        productVariant.PriceType === PriceType.OffPeakPrice &&
        productVariant.VariantTypeId === variantTypeId &&
        productVariant.VariantPriceListId === variantPriceListId
    );

  const hasPeakPriceError =
    errors &&
    errors.find(
      (productVariant) =>
        productVariant.PriceType === PriceType.PeakPrice &&
        productVariant.VariantTypeId === variantTypeId &&
        productVariant.VariantPriceListId === variantPriceListId
    );

  return (
    <>
      <div className="input-group currency-input">
        <div className="input-group-text">£</div>
        <input
          className={classNames(
            "form-control",
            hasOffPeakPriceError ? "is-invalid" : ""
          )}
          disabled={disabled}
          onChange={updateOffPeakValue}
          type="text"
          value={offPeakPrice}
        />
      </div>
      {usePeakPrice && (
        <div className="input-group currency-input ms-2">
          <div className="input-group-text">£</div>
          <input
            className={classNames(
              "form-control",
              hasPeakPriceError ? "is-invalid" : ""
            )}
            disabled={disabled}
            onChange={updatePeakValue}
            type="text"
            value={peakPrice}
          />
        </div>
      )}
    </>
  );
}

export default VariantPriceListItem;
