import ControlElements from "./ControlElements";
import RowElements from "./RowElements";
import Options from "./Options";

const GlobalFormStages = [
  {
    title: "Controls",
    displayIfFromValues: (values) => !values.previewComm,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "newControl",
            className: "col-12",
            component: ControlElements,
            type: "component",
          },
        ],
        wrapInCard: false,
      },
    ],
  },
  {
    title: "Rows",
    displayIfFromValues: (values) => !values.previewComm,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "newRow",
            className: "col-12",
            component: RowElements,
            type: "component",
          },
        ],
        wrapInCard: false,
      },
    ],
  },
  {
    title: "Style",
    displayIfFromValues: (values) => !values.previewComm,
    sections: [
      {
        name: "Background",
        fields: [
          {
            name: "globalBackgroundColour",
            className: "col-12",
            description: "",
            label: "Background Colour",
            type: "colourpicker",
          },
          {
            name: "globalPadding",
            className: "col-12",
            description: "",
            label: "Background Padding",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Content",
        fields: [
          {
            name: "globalContentBackgroundColour",
            className: "col-12",
            description: "",
            label: "Content Background Colour",
            type: "colourpicker",
          },
          {
            name: "globalContentPadding",
            className: "col-12",
            description: "",
            label: "Content Padding",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Links",
        fields: [
          {
            name: "globalLinkColour",
            className: "col-12",
            description: "",
            label: "Link Colour",
            type: "colourpicker",
          },
        ],
      },
    ],
  },
  {
    title: "Options",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "options",
            className: "col-12",
            component: Options,
            type: "component",
          },
        ],
      },
    ],
  },
];

export default GlobalFormStages;
