import {
  formatCurrency,
  getOrdinalSuffix,
} from "../../../js/utility";
import { BackgroundStyle, BookingStatus } from "../../../js/enums";
import { BadgeList } from "../../elements/_Elements";

function Summary({ state }) {
  const {
    g4b_bookingoperatoridname,
    g4b_bookingnumber,
    g4b_bookingstatus,
    g4b_channelidname,
    g4b_comment,
    g4b_discountvalue,
    g4b_extreferencenumber,
    g4b_referralsource,
    g4b_terminalidname,
    g4b_ticketprinted,
    g4b_totalprice,
    g4m_membershipamount,
  } = state;

  const bookingStatus = Object.keys(BookingStatus).find(
    (key) => BookingStatus[key] === g4b_bookingstatus
  );

  const badgeItems = [
    {
      backgroundStyle:
        String(g4b_bookingstatus) === String(BookingStatus.Active)
          ? BackgroundStyle.Success
          : String(g4b_bookingstatus) ===
            String(BookingStatus.Reserved)
          ? BackgroundStyle.Warning
          : BackgroundStyle.Danger,
      text: String(bookingStatus),
    },
    { text: `Channel: ${g4b_channelidname}` },
    { text: `Terminal: ${g4b_terminalidname}` },
    g4b_bookingoperatoridname
      ? { text: `Operator: ${g4b_bookingoperatoridname}` }
      : null,
    {
      backgroundStyle: g4b_ticketprinted
        ? BackgroundStyle.Success
        : BackgroundStyle.Info,
      text: g4b_ticketprinted
        ? "Tickets printed"
        : "Tickets not printed",
    },
  ];

  return (
    <div className="row">
      <div className="col-md-8">
        <BadgeList className="mb-3" items={badgeItems} />
        {g4b_extreferencenumber && (
          <p>
            External reference
            <strong className="ms-1">{g4b_extreferencenumber}</strong>
          </p>
        )}
        {g4b_bookingnumber && (
          <p>
            This is the purchaser's
            <span className="ms-1 me-1">
              {getOrdinalSuffix(g4b_bookingnumber)}
            </span>
            booking
          </p>
        )}
        {g4b_referralsource && (
          <p>
            The purchaser was referred from
            <strong className="ms-1">{g4b_referralsource}</strong>
          </p>
        )}
        {g4b_comment && (
          <p>
            <strong>Comment: </strong>
            {g4b_comment}
          </p>
        )}
      </div>
      <div className="col-md-4">
        <table className="table">
          <tbody>
            {g4b_totalprice > 0 && (
              <tr>
                <th scope="row">Product Total</th>
                <td className="text-end">
                  {formatCurrency(g4b_totalprice)}
                </td>
              </tr>
            )}
            {g4m_membershipamount > 0 && (
              <tr>
                <th scope="row">Membership Total</th>
                <td className="text-end">
                  {formatCurrency(g4m_membershipamount)}
                </td>
              </tr>
            )}
            <tr>
              <th scope="row">Total</th>
              <td className="text-end">
                {formatCurrency(
                  g4b_totalprice + g4m_membershipamount
                )}
              </td>
            </tr>
            {g4b_discountvalue > 0 && (
              <tr>
                <th scope="row">Discount</th>
                <td className="text-end">
                  {formatCurrency(g4b_discountvalue)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Summary;
