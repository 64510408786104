import { GroupFormStages } from "./group";
import lookups from "./lookups";
import {
  handleFilteredPicklistChange,
  isNonEmptyString,
} from "../components/form/Helpers";
import { getLookupData } from "../js/utility";

export const AdditionalProductFormStages = [
  {
    title: "Additional Product",
    sections: [
      {
        name: "Source",
        fields: [
          {
            name: "g4b_name",
            hidden: true,
            isNameField: true,
          },
          {
            name: "g4b_additionalprotoproductid",
            hidden: true,
            label: "Target Product Variant",
          },
          {
            name: "g4b_analysiscategoryid",
            description: "",
            disabledIfFromValues: (values) =>
              isNonEmptyString(values.g4b_productbaseid) ||
              isNonEmptyString(values.g4b_protoproductid),
            label: "Category",
            lookup: lookups.g4b_analysiscategory.all,
            type: "lookup",
          },
          {
            name: "g4b_productbaseid",
            description: "",
            disabledIfFromValues: (values) =>
              isNonEmptyString(values.g4b_analysiscategoryid) ||
              isNonEmptyString(values.g4b_protoproductid),
            label: "Product",
            lookup: lookups.g4b_productbase.all,
            type: "lookup",
          },
          {
            name: "g4b_protoproductid",
            description: "",
            disabledIfFromValues: (values) =>
              isNonEmptyString(values.g4b_analysiscategoryid) ||
              isNonEmptyString(values.g4b_productbaseid),
            label: "Product Variant",
            lookup: lookups.g4b_protoproduct.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Target",
        fields: [
          {
            name: "g4b_additionalproductid",
            description: "",
            handleChange: (
              event,
              handleChange,
              fieldName,
              values
            ) => {
              handleChange({
                event,
                target: {
                  name: "g4b_additionalvarianttypeid",
                  value: "",
                },
              });
              handleFilteredPicklistChange(
                event,
                handleChange,
                fieldName,
                values
              );
            },
            label: "Product",
            lookup: lookups.g4b_productbase.all,
            mapOptions: (options, state) => {
              //Filter out any products where there are no variants set
              const productVariants = getLookupData(
                lookups.g4b_protoproduct.all.name,
                state
              );

              return options.filter((o, i) => {
                return productVariants.some(
                  (x) => x.ProductId === o.Key
                );
              });
            },
            required: true,
            type: "lookup",
          },
          {
            name: "g4b_additionalvarianttypeid",
            description: "",
            disabledIfFromValues: (values) =>
              !isNonEmptyString(values.g4b_additionalproductid),
            label: "Variant Type",
            lookup: lookups.g4b_varianttype.all,
            mapOptions: (options, state, values) => {
              // check a product has been selected
              if (!isNonEmptyString(values.g4b_additionalproductid)) {
                return [];
              }

              // find product variants for the selected product
              const productVariants = getLookupData(
                lookups.g4b_protoproduct.all.name,
                state
              ).filter(
                (x) => x.ProductId === values.g4b_additionalproductid
              );
              if (productVariants.length === 0) {
                return [];
              }

              // find the variant types for the product variants
              const variantTypeIds = productVariants.map(
                (x) => x.VariantTypeId
              );

              // filter the options based on the available product variants
              return options.filter((x) =>
                variantTypeIds.includes(x.Key)
              );
            },
            showErrorsBeforeTouched: true,
            type: "lookup",
            validate: (value, values, state) => {
              if (!isNonEmptyString(values.g4b_additionalproductid)) {
                // don't show error message if the product is not selected
                return "";
              }

              // find product variants for the selected product
              const productVariants = getLookupData(
                lookups.g4b_protoproduct.all.name,
                state
              ).filter(
                (x) => x.ProductId === values.g4b_additionalproductid
              );
              if (productVariants.length === 0) {
                return "There are no variants for the selected product. Please choose another product or create product variants for this product.";
              }

              return !value ? "Required" : "";
            },
          },
        ],
      },
      {
        name: "Image",
        fields: [
          {
            name: "primaryImage",
            className: "col-12",
            description: "",
            type: "image",
          },
        ],
      },
      {
        name: "Groups",
        fields: [
          {
            allowAdd: true,
            className: "col-12 mb-3",
            name: "pagegroup",
            description: "",
            inline: true,
            label: "Web Page Groups",
            lookup: lookups.g4_group.page,
            subFormStages: GroupFormStages,
            type: "checklist",
          },
          {
            allowAdd: true,
            className: "col-12 mb-3",
            name: "productgroup",
            description: "",
            inline: true,
            label: "Web Product Groups",
            lookup: lookups.g4_group.product,
            subFormStages: GroupFormStages,
            type: "checklist",
          },
        ],
      },
    ],
  },
];
