export const PromotionExclusionDateFormStages = [
  {
    title: "Promotion Exclusion Date",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_bankholiday",
            className: "col-4",
            default: false,
            description: "",
            label: "Bank Holiday",
            type: "bit",
          },
          {
            name: "g4b_excludedate",
            className: "col-date",
            dateFormat: "dd/MM/yyyy",
            label: "Exclude Date",
            type: "datetime",
          },
        ],
      },
    ],
  },
];
