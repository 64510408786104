import { PastYearsSelectHeader } from "../components/elements/_Elements";
import FixtureSeriesTable from "../components/form/fixtureSeries/FixtureSeriesTable";
import { BookingStatus } from "../js/enums";
import { isSameOrLaterDate } from "../js/utility";
import { BookingFormStages } from "./booking";
import lookups from "./lookups";
import { VenueFormStages } from "./venue";
import { VenueConfigurationFormStages } from "./venueConfiguration";

export const SerieFormStages = [
  {
    title: "General",
    id: "general",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "g4b_description",
            className: "col-12",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Image",
    id: "image",
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "primaryImage",
            className: "col-12",
            description: "",
            type: "image",
          },
        ],
      },
    ],
  },
  {
    title: "Date and Times",
    id: "date-and-times",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_seriesstartdate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            label: "Start",
            required: true,
            showTimeSelect: true,
            type: "datetime",
          },
          {
            name: "g4b_seriesenddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            label: "End",
            required: true,
            showTimeSelect: true,
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_seriesstartdate
                ? isSameOrLaterDate(date, values.g4b_seriesstartdate)
                : true;
              return isValid ? "" : "Select a later date and time";
            },
          },
        ],
      },
    ],
  },
  {
    title: "Configuration",
    id: "configuration",
    sections: [
      {
        name: "",
        fields: [
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_venueid",
            description: "",
            label: "Venue",
            lookup: lookups.g4b_venue.all,
            required: true,
            subFormStages: VenueFormStages,
            type: "lookup",
          },
          {
            name: "g4b_venueconfigurationid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Venue Configuration",
            lookup: lookups.g4b_venueconfiguration.all,
            required: true,
            subFormStages: VenueConfigurationFormStages,
            type: "lookup",
          },
          {
            name: "g4b_purchaserticketlimit",
            description:
              "The max amount of tickets for the fixture that can be purchased in a by a purchaser. If set to 0 or a blank value the system will allow unlimited amount of tickets.",
            label: "Purchaser Ticket Limit",
            type: "int",
          },
          {
            name: "g4b_selectseats",
            description:
              "Whether users can select seats for the fixture",
            label: "Select Seats",
            type: "bit",
          },
          {
            className: "col-12 mb-3",
            name: "g4b_fixture",
            description:
              "These are the fixtures which the series will belong to",
            inline: true,
            label: "Fixtures",
            lookup: lookups.g4b_fixture.all,
            overviewRender: () => {
              return <></>;
            },
            type: "checklist",
          },
          {
            className: "col-12",
            name: "Fixture table",
            component: FixtureSeriesTable,
            displayComponentOnOverview: true,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "Printing",
    id: "printing",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_printtransform",
            description:
              "The print transform that is used when printing for this series. If not set then it will default to one of the print transforms set in the terminal",
            label: "Print transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
    ],
  },
  {
    title: "Bookings",
    id: "bookings",
    displayIfFromState: (state) => state.id,
    displayEditButtonFromState: () => false,
    //Only setting this to true to allow the loading of the channel lookup to trigger. Nothing in this table should actually be editable, just readable
    editOnOverview: true,
    renderStage: false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          submitSearchOnLoad: true,
          useSubmitButton: true,
          fields: [
            {
              name: "g4b_bookingreference",
              className: "col-name",
            },
            {
              name: "g4b_bookingstatus",
              className: "col-name",
              type: "statusbadge",
              enum: BookingStatus,
            },
            {
              name: "g4b_channelidname",
              className: "col-short-name",
            },
            {
              name: "bookingtotalprice",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_discountvalue",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_bookingreference",
              type: "text",
            },
            {
              name: "g4b_bookingstatus",
              defaultValue: [BookingStatus.Active],
              enum: BookingStatus,
              type: "select",
            },
            {
              name: "g4b_channelid",
              label: "Channel",
              lookup: lookups.g4b_channel.all,
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          filterDescription:
            "When searching by booking reference, all other filters will be ignored.",
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/booking",
          subFormStages: BookingFormStages,
          lookup: lookups.g4b_booking.series,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Seat Allocations",
    id: "seat-allocations",
    displayIfFromState: (state) => state.id,
    displayEditButtonFromState: () => false,
    renderStage: false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          allowStatusEdit: false,
          hideNameColumn: true,
          submitSearchOnLoad: true,
          useSubmitButton: true,
          overviewRender: () => {
            return (
              <p>
                Seat allocations are not applicable for this series
              </p>
            );
          },
          overviewRenderCondition: (state) => {
            return !state.g4b_selectseats;
          },
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking",
              className: "col-short-name",
            },
            {
              name: "g4b_row",
              label: "Row",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_seat",
              label: "Seat",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_protoproductidname",
              label: "Product Variant",
              className: "col-short-name",
            },
            {
              name: "g4b_fixtureidname",
              label: "Fixture",
              className: "col-short-name",
            },
            {
              name: "g4b_blockidname",
              label: "Block",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_row",
              label: "Row",
              type: "int",
            },
            {
              name: "g4b_seat",
              label: "Seat",
              type: "int",
            },
            {
              name: "g4b_blockid",
              label: "Block",
              customLookup: (state) => {
                //Get the block select list based on the blocks that are linked to the venue configuration blocks
                //that are linked to the venue configuration thats linked to the series
                let venueConfigBlocks =
                  state &&
                  state.relatedEntities &&
                  state.relatedEntities.length > 0
                    ? state.relatedEntities.find(
                        (r) =>
                          r.entityName ===
                          "g4b_venueconfigurationblock"
                      )
                    : null;
                if (
                  venueConfigBlocks &&
                  venueConfigBlocks.entities &&
                  venueConfigBlocks.entities.length > 0
                ) {
                  let uniqueBlockIdsArray = (
                    allAssociatedBlocks,
                    track = new Set()
                  ) =>
                    allAssociatedBlocks.filter(({ Key }) => {
                      return track.has(Key) ? false : track.add(Key);
                    });
                  let allAssociatedBlocks =
                    venueConfigBlocks.entities.map((v) => ({
                      Value: v.Fields.blockname,
                      Key: v.Fields.g4b_blockid,
                    }));
                  return uniqueBlockIdsArray(allAssociatedBlocks);
                } else {
                  return [];
                }
              },
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          lookup: lookups.g4b_seatallocation.series,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
];
