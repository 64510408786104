import { ProductGroupType } from "../js/enums";

export const PromotionProductGroupFormStages = [
  {
    title: "Promotion Product Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_grouptype",
            className: "col-4",
            label: "Group Type",
            type: "picklist",
            enum: ProductGroupType,
          },
          {
            name: "g4b_order",
            className: "col-4",
            label: "Order",
            type: "int",
          },
        ],
      },
    ],
  },
];
