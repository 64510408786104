const ImageControlDefaultValues = [
  {
    Name: "src",
    Value: "",
  },
  {
    Name: "width",
    Value: "",
  },
  {
    Name: "height",
    Value: "",
  },
  {
    Name: "filename",
    Value: "",
  },
  {
    Name: "filesize",
    Value: "",
  },
  {
    Name: "altText",
    Value: "",
  },
  {
    Name: "backgroundColour",
    Value: null,
  },
  {
    Name: "padding",
    Value: "5px",
  },
  {
    Name: "imageAlign",
    Value: "center",
  },
  {
    Name: "showOnMobile",
    Value: true,
  },
  {
    Name: "linkType",
    Value: null,
  },
  {
    Name: "selectedLinkAttribute",
    Value: "",
  },
  {
    Name: "link",
    Value: "",
  },
  {
    Name: "emailAddress",
    Value: "",
  },
  {
    Name: "emailSubject",
    Value: "",
  },
  {
    Name: "emailContent",
    Value: "",
  },
  {
    Name: "predefinedLinks",
    Value: "",
  },
];

export default ImageControlDefaultValues;
