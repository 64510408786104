import SelectAll from "../components/elements/SelectAll";
import { useGlobalReducer, useGlobalState } from "../GlobalContext";
import { GlobalDispatchMethods } from "../js/enums";
import { getNavigationItems } from "../js/navigationItems";

function useSitemapStages() {
  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();

  const { sitemap } = globalState;

  return [
    {
      title: "Sitemap",
      handleSubmit: function (_, __, submitValues) {
        globalDispatch({
          type: GlobalDispatchMethods.SetSitemap,
          sitemap: submitValues,
        });
      },
      sections: [
        {
          name: "",
          fields: [
            {
              name: "select_all",
              className: "col-12",
              component: SelectAll,
              displayComponentOnOverview: true,
              type: "component",
            },
          ],
        },
        ...getNavigationItems().map((navigationItem) => {
          return {
            name: navigationItem.text,
            description: navigationItem.description,
            fields: navigationItem.links.map((link) => {
              return {
                name: link.to,
                default:
                  sitemap && sitemap.hasOwnProperty(link.to)
                    ? sitemap[link.to]
                    : false,
                label: link.text,
                type: "bit",
              };
            }),
          };
        }),
      ],
    },
  ];
}

export default useSitemapStages;
