const AccountAddressFields = [
  {
    Key: "account/address1_line1",
    Value: "Address Line 1",
  },
  {
    Key: "account/address1_line2",
    Value: "Address Line 2",
  },
  {
    Key: "account/address1_line3",
    Value: "Address Line 3",
  },
  {
    Key: "account/address1_city",
    Value: "City",
  },
  {
    Key: "account/address1_county",
    Value: "County",
  },
  {
    Key: "account/address1_country",
    Value: "Country",
  },
  {
    Key: "account/address1_postalcode",
    Value: "Postal Code",
  },
];

const CardFields = [
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/allowprintathome",
    Value: "Allow Print At Home",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaname",
    Value: "Area Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaturnstileinformation",
    Value: "Area Turnstile Information",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaturnstileinformationalternative1",
    Value: "Area Turnstile Information Alternative",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/barcode",
    Value: "Barcode",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/blockname",
    Value: "Block Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/blocksponsormessage",
    Value: "Block Sponsor Message",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/bundleitemname",
    Value: "Bundle Item Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/customerreference",
    Value: "Customer Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/endtime",
    Value: "End Time",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/externalcontactreference",
    Value: "External Contact Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/fixturename",
    Value: "Fixture Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/price",
    Value: "Price",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productname",
    Value: "Product Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productuserfirstname",
    Value: "Product User First Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productuserlastname",
    Value: "Product User Last Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productusertitle",
    Value: "Product User Title",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productvariantname",
    Value: "Product Variant Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchasercustomerreference",
    Value: "Purchaser Customer Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchaserfirstname",
    Value: "Purchaser First Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchaserlastname",
    Value: "Purchaser Last Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/row",
    Value: "Row",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/seat",
    Value: "Seat",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/seriesname",
    Value: "Series Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/starttime",
    Value: "Start Time",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/ticketprinted",
    Value: "Ticket Printed",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/uniqueticketid",
    Value: "Unique Ticket Id",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/varianttypename",
    Value: "Variant Type Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/venueconfigname",
    Value: "Venue Config Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/venuename",
    Value: "Venue Name",
  },
];

const CommonFields = [
  {
    Key: "account/name",
    Value: "Account Name",
  },
  {
    Key: "account/accountnumber",
    Value: "Account Number",
  },
  {
    Key: "g4b_bookingreference",
    Value: "Booking Reference",
  },
  {
    Key: "formattedaddresses/paymentaddress",
    Value: "Billing Address",
  },
  {
    Key: "g4b_contactidname",
    Value: "Contact Name",
  },
  {
    Key: "formattedaddresses/deliveryaddress",
    Value: "Delivery Address",
  },
];

const ContactAddressFields = [
  {
    Key: "contact/address1_line1",
    Value: "Address Line 1",
  },
  {
    Key: "contact/address1_city",
    Value: "City",
  },
  {
    Key: "contact/address1_county",
    Value: "County",
  },
  {
    Key: "contact/address1_country",
    Value: "Country",
  },
  {
    Key: "contact/g4_customerreference",
    Value: "Customer Reference",
  },
  {
    Key: "contact/firstname",
    Value: "First Name",
  },
  {
    Key: "contact/lastname",
    Value: "Last Name",
  },
  {
    Key: "contact/address1_postalcode",
    Value: "Postal Code",
  },
];

const CouponFields = [
  {
    Key: "coupons/coupon[number($position)]/g4b_couponcode",
    Value: "Coupon Code",
  },
  {
    Key: "coupons/coupon[number($position)]/g4b_couponstatus",
    Value: "Coupon Status",
  },
  {
    Key: "coupons/coupon[number($position)]/g4b_coupontypeid",
    Value: "Coupon Type ID",
  },
  {
    Key: "coupons/coupon[number($position)]/g4b_coupontypeidname",
    Value: "Coupon Type",
  },
  {
    Key: "coupons/coupon[number($position)]/g4b_contactid",
    Value: "Contact ID",
  },
  {
    Key: "coupons/coupon[number($position)]/g4b_customername",
    Value: "Customer Name",
  },
  {
    Key: "coupons/coupon[number($position)]/notvaliduntildate",
    Value: "Not Valid Until Date",
  },
  {
    Key: "bookingproducts/bookingproduct[g4b_bookingproductid=/root/booking/coupons/coupon[number($position)]/g4b_purchasebookingproductid]/ProductName",
    Value: "Product Name",
  },
  {
    Key: "bookingproducts/bookingproduct[g4b_bookingproductid=/root/booking/coupons/coupon[number($position)]/g4b_purchasebookingproductid]/g4b_protoproductidname",
    Value: "Product Variant Name",
  },
  {
    Key: "coupons/coupon[number($position)]/g4b_purchasebookingproductid",
    Value: "Purchase Booking Product ID",
  },
  {
    Key: "coupons/coupon[number($position)]/g4b_purchasedate",
    Value: "Purchase Date",
  },
  {
    Key: "coupons/coupon[number($position)]/redeembydate",
    Value: "Redeem By Date",
  },
  {
    Key: "coupons/coupon[number($position)]/usebydate",
    Value: "Use By Date",
  },
  {
    Key: "bookingproducts/bookingproduct[g4b_bookingproductid=/root/booking/coupons/coupon[number($position)]/g4b_purchasebookingproductid]/g4b_varianttypeidname",
    Value: "Variant Type Name",
  },
];

const MembershipFields = [
  {
    Key: "membershiptypes/membershiptype[number($position)]/g4b_barcode",
    Value: "Barcode",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/birthdate",
    Value: "Member Birthdate",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/firstname",
    Value: "Member First Name",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/lastname",
    Value: "Member Last Name",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/title",
    Value: "Member Title",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/membershipaccesstokenname",
    Value: "Membership Access Token",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/g4m_enddate",
    Value: "Membership End Date",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/membershiplife",
    Value: "Membership Life",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/membershipname",
    Value: "Membership Name",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/g4m_membershipnumber",
    Value: "Membership Number",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/membershippaymentplanname",
    Value: "Membership Payment Plan Name",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/membershipperiod",
    Value: "Membership Period",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/g4m_renewaldate",
    Value: "Membership Renewal Date",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/productname",
    Value: "Product Name",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/productdesc",
    Value: "Product Description",
  },
  {
    Key: "membershiptypes/membershiptype[number($position)]/varianttypename",
    Value: "Variant Type Name",
  },
];

const ReceiptFields = [
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/allowprintathome",
    Value: "Allow Print At Home",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaname",
    Value: "Area Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaturnstileinformation",
    Value: "Area Turnstile Information",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaturnstileinformationalternative1",
    Value: "Area Turnstile Information Alternative",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/barcode",
    Value: "Barcode",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/blockname",
    Value: "Block Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/blocksponsormessage",
    Value: "Block Sponsor Message",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/bundleitemname",
    Value: "Bundle Item Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/customerreference",
    Value: "Customer Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/endtime",
    Value: "End Time",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/externalcontactreference",
    Value: "External Contact Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/fixturename",
    Value: "Fixture Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/price",
    Value: "Price",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productname",
    Value: "Product Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productuserfirstname",
    Value: "Product User First Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productuserlastname",
    Value: "Product User Last Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productusertitle",
    Value: "Product User Title",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productvariantname",
    Value: "Product Variant Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchasercustomerreference",
    Value: "Purchaser Customer Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchaserfirstname",
    Value: "Purchaser First Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchaserlastname",
    Value: "Purchaser Last Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/row",
    Value: "Row",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/seat",
    Value: "Seat",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/seriesname",
    Value: "Series Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/starttime",
    Value: "Start Time",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/ticketprinted",
    Value: "Ticket Printed",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/uniqueticketid",
    Value: "Unique Ticket Id",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/varianttypename",
    Value: "Variant Type Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/venueconfigname",
    Value: "Venue Config Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/venuename",
    Value: "Venue Name",
  },
];

const TicketFields = [
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/allowprintathome",
    Value: "Allow Print At Home",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaname",
    Value: "Area Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaturnstileinformation",
    Value: "Area Turnstile Information",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/areaturnstileinformationalternative1",
    Value: "Area Turnstile Information Alternative",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/barcode",
    Value: "Barcode",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/blockname",
    Value: "Block Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/blocksponsormessage",
    Value: "Block Sponsor Message",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/bundleitemname",
    Value: "Bundle Item Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/customerreference",
    Value: "Customer Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/endtime",
    Value: "End Time",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/externalcontactreference",
    Value: "External Contact Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/fixturename",
    Value: "Fixture Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/price",
    Value: "Price",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productname",
    Value: "Product Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productuserfirstname",
    Value: "Product User First Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productuserlastname",
    Value: "Product User Last Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productusertitle",
    Value: "Product User Title",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/productvariantname",
    Value: "Product Variant Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchasercustomerreference",
    Value: "Purchaser Customer Reference",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchaserfirstname",
    Value: "Purchaser First Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/purchaserlastname",
    Value: "Purchaser Last Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/row",
    Value: "Row",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/seat",
    Value: "Seat",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/seriesname",
    Value: "Series Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/starttime",
    Value: "Start Time",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/ticketprinted",
    Value: "Ticket Printed",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/uniqueticketid",
    Value: "Unique Ticket Id",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/varianttypename",
    Value: "Variant Type Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/venueconfigname",
    Value: "Venue Config Name",
  },
  {
    Key: "stadiumtickets/stadiumticket[number($position)]/venuename",
    Value: "Venue Name",
  },
];

const VoucherFields = [
  {
    Key: "vouchers/voucher[number($position)]/g4b_bookingproductid",
    Value: "Booking Product ID",
  },
  {
    Key: "vouchers/voucher[number($position)]/g4b_customername",
    Value: "Customer Name",
  },
  {
    Key: "vouchers/voucher[number($position)]/g4b_expirydate",
    Value: "Expiry Date",
  },
  {
    Key: "bookingproducts/bookingproduct[g4b_bookingproductid=/root/booking/vouchers/voucher[number($position)]/g4b_bookingproductid]/ProductName",
    Value: "Product Name",
  },
  {
    Key: "bookingproducts/bookingproduct[g4b_bookingproductid=/root/booking/vouchers/voucher[number($position)]/g4b_bookingproductid]/g4b_protoproductidname",
    Value: "Product Variant Name",
  },
  {
    Key: "vouchers/voucher[number($position)]/g4b_referencenumber",
    Value: "Reference Number",
  },
  {
    Key: "vouchers/voucher[number($position)]/g4b_value",
    Value: "Value",
  },
  {
    Key: "bookingproducts/bookingproduct[g4b_bookingproductid=/root/booking/vouchers/voucher[number($position)]/g4b_bookingproductid]/g4b_varianttypeidname",
    Value: "Variant Type Name",
  },
  {
    Key: "vouchers/voucher[number($position)]/g4b_voucherstatus",
    Value: "Voucher Status",
  },
];

export {
  AccountAddressFields,
  CardFields,
  CommonFields,
  ContactAddressFields,
  CouponFields,
  MembershipFields,
  ReceiptFields,
  TicketFields,
  VoucherFields,
};
