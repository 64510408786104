import { GroupType } from "../js/enums";

export const GroupFormStages = [
  {
    title: "Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4_type",
            label: "Type",
            type: "picklist",
            enum: GroupType,
            autoFillValue: (
              _,
              __,
              ___,
              parentFormEntityName,
              parentFormFieldLookup
            ) => {
              if (parentFormEntityName === "Products") {
                return GroupType.Widget;
              } else if (
                parentFormEntityName === "Additional Products" &&
                parentFormFieldLookup
              ) {
                if (parentFormFieldLookup.searchFilter === "1") {
                  return GroupType.Page;
                } else if (
                  parentFormFieldLookup.searchFilter === "21"
                ) {
                  return GroupType.Product;
                }
              }
            },
          },
          {
            name: "g4_code",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "g4_description",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            name: "g4_sequence",
            description: "",
            label: "Sequence",
            type: "int",
          },
        ],
      },
    ],
  },
];
