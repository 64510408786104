import { useCallback, useEffect, useState } from "react";
import SidebarForm from "../SidebarForm";
import { Button, Tabs } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";
import { useFormReducer } from "../FormContext";
import {
  cancelFieldChanges,
  getFieldDisplayValue,
  getStagesForField,
} from "./Helpers";
import { displayElement, getFieldValue } from "../Helpers";
import { useGlobalState } from "../../../GlobalContext";

function EditField({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  state,
  touched,
  values,
}) {
  const stages = getStagesForField(values);
  const dispatch = useFormReducer();
  const globalState = useGlobalState();

  const [activeTab, setActiveTab] = useState(stages[0].title);
  const [mandatoryFieldsNotFilledIn, setMandatoryFieldsNotFilledIn] =
    useState(true);

  const checkIfAllMandatoryFieldsFilledIn = useCallback(
    (updatedField, updatedValue) => {
      var mandatoryFieldNotFilledIn = false;
      stages.forEach((stage) => {
        stage.sections
          .filter(
            (section) =>
              section.fields &&
              displayElement(section, state, values, globalState)
          )
          .forEach((section) => {
            section.fields
              .filter((field) =>
                displayElement(field, state, values, globalState)
              )
              .forEach((field) => {
                //Check if the field is mandatory and if a value has not been set on it.
                //Have a check for the field type being int just in case its got a 0 value since
                //a 0 value will read as false in the if check
                var fieldValue = getFieldValue(field, values);
                var validValue =
                  field.type === "int" ? fieldValue >= 0 : fieldValue;
                if (
                  !mandatoryFieldNotFilledIn &&
                  field.mandatory &&
                  !validValue
                ) {
                  //It could be the case that the field value hasn't been set in the values when it
                  //gets to this point despite the user just setting it so also have a check whether
                  //the given updatedField match the currently viewed field and whether there is not a value set
                  var validUpdatedValue =
                    field.type === "int"
                      ? updatedValue && updatedValue >= 0
                      : updatedValue;
                  mandatoryFieldNotFilledIn =
                    field.name !== updatedField ||
                    (field.name === updatedField &&
                      !validUpdatedValue);
                }
              });
          });
      });
      setMandatoryFieldsNotFilledIn(mandatoryFieldNotFilledIn);
    },
    [globalState, stages, state, values]
  );

  useEffect(() => {
    checkIfAllMandatoryFieldsFilledIn();
  }, [checkIfAllMandatoryFieldsFilledIn]);

  return (
    <>
      <div className="sidebar-header d-flex justify-content-end mb-3">
        <Button
          text="Cancel"
          onClick={() => cancelFieldChanges(dispatch, values)}
          style={ButtonStyle.Link}
        />
      </div>
      <Tabs
        activeTab={activeTab}
        onClick={(tab) => setActiveTab(tab.title)}
        tabs={stages}
      />
      <div className="sidebar-content position-relative flex-fill my-3">
        <div className="position-absolute top-0 end-0 bottom-0 start-0 p-2 overflow-y-scroll">
          <SidebarForm
            {...{
              activeTab,
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
              state,
              touched,
            }}
            handleChange={(event) => {
              const { name, value } = event.target;

              const isImageUpload =
                name === "Value.Value" &&
                value &&
                value.hasOwnProperty("Base64String");

              const updateValue = isImageUpload
                ? value.Base64String
                : value;

              if (isImageUpload) {
                handleChange({
                  target: {
                    name: event.target.name,
                    value: updateValue,
                  },
                });
              } else {
                handleChange(event);
              }

              if (name === "Value.Value") {
                handleChange({
                  target: {
                    name: "Value.DisplayValue",
                    value: updateValue,
                  },
                });
              }

              // clear values when is static changed
              if (name === "Value.IsStatic") {
                handleChange({
                  target: {
                    name: "Value.Value",
                    value: "",
                  },
                });
                handleChange({
                  target: {
                    name: "Value.DisplayValue",
                    value: "",
                  },
                });
              }
              checkIfAllMandatoryFieldsFilledIn(
                event.target.name,
                updateValue
              );
            }}
            handleCheckListChange={(event) => {
              handleChange(event);
            }}
            handleFilteredPicklistChange={(
              event,
              _,
              name,
              values
            ) => {
              const { g4b_printerjobtypeidname } = state;
              const value = event ? event.value : "";
              if (name === "Value.Value") {
                const { Value } = values;
                setFieldValue("Value", {
                  ...Value,
                  DisplayValue: getFieldDisplayValue(
                    value,
                    g4b_printerjobtypeidname
                  ),
                  Value: value,
                });
              } else {
                setFieldValue(name, value);
              }
              checkIfAllMandatoryFieldsFilledIn(name, value);
            }}
            stages={stages}
            useTabs={true}
            values={values}
          />
        </div>
      </div>
      <div className="sidebar-content d-flex justify-content-end">
        <Button
          text="Apply and close"
          style={ButtonStyle.Primary}
          type="submit"
          disabled={mandatoryFieldsNotFilledIn}
        />
      </div>
    </>
  );
}

export default EditField;
