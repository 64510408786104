import lookups from "./lookups";
import { VariantTypeCategoryFormStages } from "./variantTypeCategory";

export const VariantTypeFormStages = [
  {
    title: "Variant Type",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_varianttypecategoryid",
            allowAdd: true,
            allowEdit: true,
            autoFillValue: (_, __, parentFormValues) => {
              return parentFormValues &&
                parentFormValues.g4b_varianttypecategory
                ? parentFormValues.g4b_varianttypecategory
                : "";
            },
            description:
              "The category that this variant type belongs to",
            label: "Category",
            lookup: lookups.g4b_varianttypecategory.all,
            subFormStages: VariantTypeCategoryFormStages,
            type: "lookup",
          },
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_sequence",
            className: "col-md-6",
            description: "",
            label: "Sequence",
            type: "int",
          },
          {
            name: "g4b_people",
            className: "col-md-6",
            description: "",
            label: "People",
            required: true,
            type: "int",
          },
          {
            name: "g4b_description",
            className: "col-12",
            description: "",
            label: "Descripton",
            type: "nvarchar",
          },
          {
            name: "g4b_collectgiftaid",
            className: "col-md-6",
            default: true,
            description:
              "If enabled, Gift Aid can be collected for the Variant Type",
            label: "Collect Gift Aid",
            type: "bit",
          },
        ],
      },
    ],
  },
];
