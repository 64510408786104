export const WidgetGroupFormStages = [
  {
    title: "Widget Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-8",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "g4b_sequence",
            description: "",
            label: "Sequence",
            type: "int",
          },
          {
            name: "g4b_description",
            className: "col-12",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
