import {
  Align,
  BackgroundColour,
  Padding,
} from "../../../CommonProperties";
import { LinkProperties } from "../CommonProperties";

const ImageControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "src",
            description: "",
            label: "Src",
            mapValue: (value) => {
              return value
                ? `data:${value.MimeType};base64,${value.Base64String}`
                : "";
            },
            type: "image",
          },
          {
            name: "altText",
            description: "",
            label: "Alternative Text",
            type: "nvarchar",
          },
          ...LinkProperties(""),
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "",
        fields: [BackgroundColour(""), Padding("")],
      },
    ],
  },
  {
    title: "Settings",
    sections: [
      {
        name: "",
        fields: [
          Align("image"),
          {
            name: "showOnMobile",
            description: "",
            label: "Show On Mobile",
            type: "bit",
          },
        ],
      },
    ],
  },
];

export default ImageControlFormStages;
