import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { setupButtons } from "./Helpers";
import AddAttribute from "./AddAttribute";
import AddLink from "./AddLink";
import SourceCode from "./SourceCode";
import { PredefinedLink } from "../../form/communication/enums";
import { FontFamilies, FontSizes } from "../../form/enums";
import { LoadingState } from "../../../js/enums";
import Loading from "../Loading";

function RichTextEditor({ name, setFieldValue, state, value }) {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(LoadingState.NotLoaded);

  const [editorState, setEditorState] = useState({
    addAttribute: false,
    addLink: false,
    viewSourceCode: false,
  });

  const { addAttribute, addLink, viewSourceCode } = editorState;

  return (
    <>
      {viewSourceCode && (
        <SourceCode
          {...{ editorRef, editorState, setEditorState, value }}
        />
      )}
      {addLink && (
        <AddLink
          {...{
            editorRef,
            editorState,
            setEditorState,
            state,
          }}
        />
      )}
      {addAttribute && (
        <AddAttribute
          {...{
            editorRef,
            editorState,
            setEditorState,
            state,
          }}
        />
      )}
      {loading !== LoadingState.Loaded && <Loading />}
      <Editor
        onInit={(_, editor) => {
          editorRef.current = editor;
          setLoading(LoadingState.Loaded);
        }}
        init={{
          block_formats:
            "Paragraph=p; Quote=blockquote; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6;",
          branding: false,
          content_style:
            ".crmattribute { background-color: yellow; }",
          font_family_formats: Object.entries(FontFamilies)
            .map(([key, value]) => `${key}=${value}`)
            .join("; "),
          font_size_formats: Object.values(FontSizes).join(" "),
          height: 600,
          license_key: "gpl",
          link_list: Object.entries(PredefinedLink).map(
            ([title, value]) => ({
              title: title,
              value: value,
            })
          ),
          link_title: false,
          menubar: false,
          plugins: "lists link",
          promotion: false,
          setup: (editor) => {
            setupButtons(editor, editorState, setEditorState);
          },
          toolbar:
            "undo redo sourceCodeButton blocks | outdent indent alignleft aligncenter alignright alignjustify | fontsize fontfamily | bold italic underline strikethrough forecolor backcolor | bullist numlist linkButton unlink attributeButton",

          toolbar_mode: "wrap",
        }}
        value={value}
        onEditorChange={(newContent) =>
          setFieldValue(name, newContent)
        }
      />
    </>
  );
}

export default RichTextEditor;
