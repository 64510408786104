import { useState } from "react";
import { hideNavigationButtons } from "../Helpers";
import { Button, Footer } from "../../elements/_Elements";
import { AudienceType, ButtonStyle } from "../../../js/enums";
import CommunicationConfirmPublishModal from "./CommunicationConfirmPublishModal";
import CommunicationNoFinalCountMessageModal from "./CommunicationNoFinalCountMessageModal";
import { useGlobalState } from "../../../GlobalContext";
import RecordCopy from "../../elements/RecordCopy";
import { setCommCopyAttributes } from "./Helpers";

function CommunicationNavigationButtons({
  backText,
  disabled = false,
  entityName,
  errors,
  isBusy = false,
  onBack,
  onSubmit,
  saveAndCloseSubmitButtonClickEvent,
  saveAndCloseSubmitButtonText = "Save and Close",
  setConfirmationModal,
  setFieldValue,
  showSaveAndCloseButton,
  showSaveButton,
  stage,
  stages,
  state,
  submitButtonClickEvent,
  submitText = "Save",
  values,
}) {
  const globalState = useGlobalState();

  const [displayCommCopyModal, setDisplayCommCopyModal] =
    useState(false);
  const handleSaveButton = (action, submitButtonEvent) => {
    let confirmationModalComponent = null;

    const { g4c_audiencetype } = state;

    //Check if the selected comm audience has a final count thats more
    //than 0 otherwise publishing or testing will throw an error in the services
    if (
      (action === "saveAndPublish" || action === "saveAndTest") &&
      String(g4c_audiencetype) !== String(AudienceType.Flow) &&
      (!values.finalCount || values.finalCount === 0)
    ) {
      confirmationModalComponent = {
        displayModal: true,
        modalComponent: CommunicationNoFinalCountMessageModal,
      };
    } else if (action === "saveAndPublish") {
      confirmationModalComponent = {
        displayModal: true,
        modalComponent: CommunicationConfirmPublishModal,
      };
    }

    if (confirmationModalComponent) {
      if (errors && Object.keys(errors).length > 0) {
        setConfirmationModal(null);
      } else {
        setConfirmationModal(confirmationModalComponent);
      }
    } else {
      submitButtonEvent();
      onSubmit();
    }
  };
  return (!onBack && !showSaveButton && !showSaveAndCloseButton) ||
    hideNavigationButtons(globalState, stage, stages, state) ? (
    <></>
  ) : (
    <>
      {displayCommCopyModal && (
        <RecordCopy
          entityName={entityName}
          setCustomAttributes={setCommCopyAttributes}
          setDisplayRecordCopyModal={setDisplayCommCopyModal}
          stages={stages}
          state={state}
          values={values}
        />
      )}
      <Footer {...{ backText, disabled, isBusy, onBack }}>
        {showSaveButton && (
          <>
            {state.statuscode !== 4 && (
              <Button
                style={ButtonStyle.Primary}
                text={submitText}
                disabled={disabled || isBusy}
                showSpinner={isBusy}
                onClick={() =>
                  handleSaveButton("save", submitButtonClickEvent)
                }
              />
            )}
            {state.id && (
              <Button
                className="ms-3"
                style={ButtonStyle.Primary}
                text={"Copy Communication"}
                disabled={disabled || isBusy || displayCommCopyModal}
                onClick={() => setDisplayCommCopyModal(true)}
              />
            )}
            {state.statuscode !== 4 && (
              <>
                <Button
                  className="ms-3"
                  style={ButtonStyle.Primary}
                  text={"Save and Test"}
                  disabled={disabled || isBusy}
                  showSpinner={isBusy}
                  onClick={() =>
                    handleSaveButton("saveAndTest", () =>
                      setFieldValue("action", "saveAndTest")
                    )
                  }
                />
                <Button
                  className="ms-3"
                  style={ButtonStyle.Primary}
                  text={"Save and Publish"}
                  disabled={disabled || isBusy}
                  showSpinner={isBusy}
                  onClick={() =>
                    handleSaveButton("saveAndPublish", () =>
                      setFieldValue("action", "saveAndPublish")
                    )
                  }
                />
              </>
            )}
          </>
        )}
        {showSaveAndCloseButton && state.statuscode !== 4 && (
          <Button
            className="ms-3"
            type={"submit"}
            style={ButtonStyle.Primary}
            text={saveAndCloseSubmitButtonText}
            disabled={disabled || isBusy}
            showSpinner={isBusy}
            onClick={() =>
              handleSaveButton(
                "saveAndClose",
                saveAndCloseSubmitButtonClickEvent
              )
            }
          />
        )}
      </Footer>
    </>
  );
}

export default CommunicationNavigationButtons;
