import lookups from "./lookups";
import { BookingFormStages } from "./booking";
import { CustomerAddressFormStages } from "./customerAddress";
import { ContactAuditFormStages } from "./contactAudit";
import {
  BookingStatus,
  CouponVoucherStatus,
  Gender,
  MandateStatus,
} from "../js/enums";
import { PaymentMandateFormStages } from "./paymentMandate";
import { MembershipAccountFormStages } from "./membershipAccount";
import { MembershipFormStages } from "./membership";
import { CouponFormStages } from "./coupon";
import { VoucherFormStages } from "./voucher";
import {
  FutureYearsSelectHeader,
  PastYearsSelectHeader,
} from "../components/elements/DateSelectHeader";
import { administratorRoles } from "../js/roles";
import { DeclarationFormStages } from "./declaration";

export const ContactFormStages = [
  {
    title: "Personal Details",
    id: "personal-details",
    recordEditRoles: administratorRoles,
    statusEditAllowedRoles: administratorRoles,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "usesoundex",
            hidden: true,
            type: "nvarchar",
            label: "Use Sounds Like",
          },
          {
            name: "firstname",
            className: "col-md-4",
            description: "",
            label: "First Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "middlename",
            className: "col-md-4",
            description: "",
            label: "Middle Name",
            required: false,
            type: "nvarchar",
          },
          {
            name: "lastname",
            className: "col-md-4",
            description: "",
            label: "Last Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "emailaddress1",
            className: "col-md-4",
            description: "",
            label: "Email Address",
            required: false,
            type: "email",
          },
          {
            name: "gendercode",
            className: "col-md-4",
            description: "",
            label: "Gender",
            required: false,
            type: "picklist",
            enum: Gender,
          },
          {
            name: "telephone1",
            className: "col-md-4",
            description: "",
            label: "Business Phone",
            required: false,
            type: "nvarchar",
          },
          {
            name: "telephone2",
            className: "col-md-4",
            description: "",
            label: "Home Phone",
            required: false,
            type: "nvarchar",
          },
          {
            name: "mobilephone",
            className: "col-md-4",
            description: "",
            label: "Mobile Phone",
            required: false,
            type: "nvarchar",
          },
          {
            name: "jobtitle",
            className: "col-md-4",
            description: "",
            label: "Job Title",
            required: false,
            type: "nvarchar",
          },
          {
            name: "birthdate",
            className: "col-md-4",
            description: "",
            label: "Birthdate",
            required: false,
            dateFormat: "dd/MM/yyyy",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Authentication",
    id: "authentication",
    displayIfFromState: (values) =>
      values.contactid && values.haswebaccount,
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "haswebaccount",
            hidden: true,
            label: "Has Web Account",
            type: "bit",
          },
          {
            name: "g4b_username",
            className: "col-md-4",
            description: "",
            disabled: true,
            label: "Username",
            required: false,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Marketing Preferences",
    id: "marketing-preferences",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "donotemail",
            className: "col-md-3",
            description: "",
            label: "Send Emails",
            required: false,
            type: "bit",
          },
          {
            name: "donotbulkemail",
            className: "col-md-3",
            description: "",
            label: "Send Bulk Emails",
            required: false,
            type: "bit",
          },
          {
            name: "donotpostalmail",
            className: "col-md-3",
            description: "",
            label: "Send Mail",
            required: false,
            type: "bit",
          },
          {
            name: "donotbulkpostalmail",
            className: "col-md-3",
            description: "",
            label: "Send Bulk Mail",
            required: false,
            type: "bit",
          },
          {
            name: "donotphone",
            className: "col-md-3",
            description: "",
            label: "Allow Phone Calls",
            required: false,
            type: "bit",
          },
          {
            name: "g4_donotphone3rdparty",
            className: "col-md-3",
            description: "",
            label: "Allow Third Party Phone Calls",
            required: false,
            type: "bit",
          },
          {
            name: "g4_donotbulkemail3rdparty",
            className: "col-md-3",
            description: "",
            label: "Send Third Party Bulk Emails",
            required: false,
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Addresses",
    id: "addresses",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowAddRoles: administratorRoles,
          allowEdit: true,
          fields: [
            {
              name: "addressnumber",
              className: "col-number",
              type: "string",
            },
            {
              name: "line1",
              className: "col-name",
              type: "string",
            },
            {
              name: "line2",
              className: "col-name",
              type: "string",
            },
            {
              name: "line3",
              className: "col-name",
              type: "string",
            },
            {
              name: "city",
              className: "col-name",
              type: "string",
            },
            {
              name: "county",
              className: "col-name",
              type: "string",
            },
            {
              name: "country",
              className: "col-name",
              type: "string",
            },
            {
              name: "postalcode",
              className: "col-name",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "addressnumber",
          initialOrderedByAsc: true,
          linkForNewRecord: "/customer-address",
          linkedEntityId: "parentid",
          loadStateRelatedEntityName: "customeraddress",
          lookup: lookups.customeraddress.all,
          subFormStages: CustomerAddressFormStages,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Booking History",
    id: "booking-history",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking Reference",
              className: "col-primary",
              isNameField: true,
            },
            {
              name: "g4b_bookingstatus",
              label: "Booking Status",
              className: "col-name",
              type: "statusbadge",
              enum: BookingStatus,
            },
            {
              name: "g4b_channelidname",
              label: "Channel",
              className: "col-short-name",
            },
            {
              name: "g4b_totalprice",
              label: "Total Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_discountvalue",
              label: "Discount Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4b_bookingreference"],
              type: "text",
            },
            {
              name: "g4b_bookingstatus",
              defaultValue: [BookingStatus.Active],
              enum: BookingStatus,
              label: "Booking Status",
              type: "select",
            },
            {
              name: "g4b_channelidname",
              label: "Channel",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/booking",
          loadStateRelatedEntityName: "g4b_booking",
          lookup: lookups.g4b_booking.all,
          subFormStages: BookingFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Communication Activities",
    id: "communication-activities",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "scheduledstart",
              label: "Scheduled Start",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4c_communicationname",
              label: "Communication",
              className: "col-name",
            },
            {
              name: "g4ca_trigger",
              label: "Trigger",
              className: "col-name",
            },
            {
              name: "g4ca_canceltrigger",
              label: "Cancel Trigger",
              className: "col-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4c_communicationname"],
              type: "text",
            },
            {
              name: "scheduledstart",
              renderCustomHeader: FutureYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "scheduledstart",
          initialOrderedByAsc: true,
          loadStateRelatedEntityName: "g4c_communicationactivity",
          lookup: lookups.g4c_communicationactivity.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Communication Audits",
    id: "communication-audits",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "g4c_name",
              label: "Type",
              className: "col-name",
            },
            {
              name: "g4c_auditdate",
              label: "Audit Date",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4c_communicationidname",
              label: "Communication",
              className: "col-name",
            },
            {
              name: "g4c_page",
              label: "Page",
              className: "col-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: [
                "g4c_name",
                "g4c_communicationidname",
              ],
              type: "text",
            },
            {
              name: "g4c_auditdate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4c_auditdate",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4c_communicationaudit",
          lookup: lookups.g4c_communicationaudit.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Marketing List",
    id: "marketing-list",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "listname",
              label: "Name",
              className: "col-name",
            },
            {
              name: "g4c_issubscription",
              label: "Is Subscription?",
              className: "col-bool",
            },
            {
              name: "createdon",
              label: "Date Added",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "listname",
          initialOrderedByAsc: true,
          loadStateRelatedEntityName: "listmember",
          lookup: lookups.listmember.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Membership",
    id: "membership",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "Accounts",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4m_name",
              className: "col-name",
              type: "string",
            },
            {
              name: "g4m_membershipaccountnumber",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4m_accountbalance",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_accountbalance_date",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/membership-account",
          loadStateRelatedEntityName: "g4m_membershipaccount",
          lookup: lookups.g4m_membershipaccount.all,
          subFormStages: MembershipAccountFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
      {
        name: "Memberships",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4m_membershipnumber",
              className: "col-name",
              type: "string",
            },
            {
              name: "g4m_memberidname",
              className: "col-name",
              label: "Member Name",
            },
            {
              name: "g4m_owneridname",
              className: "col-name",
              label: "Owner",
            },
            {
              name: "g4m_productidname",
              className: "col-name",
              label: "Product",
            },
            {
              name: "g4m_varianttypeidname",
              className: "col-name",
              label: "Variant Type",
            },
            {
              name: "g4m_startdate",
              className: "col-date",
              type: "datetime",
              dateFormat: "dd/MM/yyyy",
            },
            {
              name: "g4m_cancellationdate",
              className: "col-date",
              type: "datetime",
              dateFormat: "dd/MM/yyyy",
            },
            {
              name: "g4m_enddate",
              className: "col-date",
              type: "datetime",
              dateFormat: "dd/MM/yyyy",
            },
            {
              name: "g4m_renewaldate",
              className: "col-date",
              type: "datetime",
              dateFormat: "dd/MM/yyyy",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4m_startdate",
              type: "datetime",
            },
            {
              name: "g4m_cancellationdate",
              type: "datetime",
            },
            {
              name: "g4m_enddate",
              type: "datetime",
            },
            {
              name: "g4m_renewaldate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4m_membership",
          lookup: lookups.g4m_membership.all,
          subFormStages: MembershipFormStages,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Payment Mandates",
    id: "payment-mandates",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_reference",
              className: "col-short-name",
              label: "Reference",
            },
            {
              name: "g4b_mandatestatus",
              label: "Mandate Status",
              className: "col-name",
              type: "statusbadge",
              enum: MandateStatus,
            },
            {
              name: "g4b_externalreference",
              className: "col-short-name",
              label: "External Reference",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4b_reference"],
              type: "text",
            },
            {
              name: "g4b_mandatestatus",
              defaultValue: [MandateStatus.Active],
              enum: MandateStatus,
              label: "Mandate Status",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4b_paymentmandate",
          lookup: lookups.g4b_paymentmandate.all,
          subFormStages: PaymentMandateFormStages,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Gift Aid Declaration",
    id: "gift-aid-declaration",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4d_charityidname",
              label: "Charity",
              className: "col-short-name",
            },
            {
              name: "g4d_declarationstartdate",
              className: "col-date",
              label: "Start Date",
              type: "datetime",
            },
            {
              name: "g4d_declarationenddate",
              className: "col-date",
              label: "End Date",
              type: "datetime",
            },
            {
              name: "g4d_cancelleddate",
              className: "col-date",
              label: "Cancelled Date",
              type: "datetime",
            },
            {
              name: "g4d_currentdonation",
              label: "Is Current Donation?",
              className: "col-bool",
            },
            {
              name: "g4d_historicaldonation",
              label: "Is Historical Donation?",
              className: "col-bool",
            },
            {
              name: "g4d_futuredonation",
              label: "Is Future Donation?",
              className: "col-bool",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4d_declarationstartdate",
              type: "datetime",
            },
            {
              name: "g4d_declarationenddate",
              type: "datetime",
            },
            {
              name: "g4d_cancelleddate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4d_declarationstartdate",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4d_declaration",
          lookup: lookups.g4d_declaration.all,
          subFormStages: DeclarationFormStages,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Coupons",
    id: "coupons",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_couponcode",
              label: "Coupon Code",
              className: "col-short-name",
              type: "string",
            },
            {
              name: "g4b_coupontypeidname",
              label: "Coupon Type",
              className: "col-short-name",
            },
            {
              name: "g4b_couponstatus",
              label: "Coupon Status",
              className: "col-name",
              type: "statusbadge",
              enum: CouponVoucherStatus,
            },
            {
              name: "g4b_purchasedate",
              className: "col-date",
              label: "Purchase Date",
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              className: "col-date",
              label: "Expiry Date",
              type: "datetime",
            },
            {
              name: "g4b_expirydatewithgraceperiod",
              className: "col-date",
              label: "Expiry Date With Grace Period",
              type: "datetime",
            },
            {
              name: "g4b_bookingidname",
              className: "col-short-name",
              label: "Booking Reference",
              type: "string",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4b_couponcode"],
              type: "text",
            },
            {
              name: "g4b_coupontypeidname",
              label: "Coupon Type",
              type: "select",
            },
            {
              name: "g4b_couponstatus",
              defaultValue: [CouponVoucherStatus.Available],
              enum: CouponVoucherStatus,
              label: "Coupon Status",
              type: "select",
            },
            {
              name: "g4b_purchasedate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4b_coupon",
          lookup: lookups.g4b_coupon.all,
          subFormStages: CouponFormStages,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Vouchers",
    id: "vouchers",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_referencenumber",
              label: "Reference Number",
              className: "col-short-name",
            },
            {
              name: "g4b_voucherstatus",
              label: "Voucher Status",
              className: "col-name",
              type: "statusbadge",
              enum: CouponVoucherStatus,
            },
            {
              name: "g4b_value",
              label: "Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_purchasedate",
              className: "col-date",
              label: "Purchase Date",
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              className: "col-date",
              label: "Expiry Date",
              type: "datetime",
            },
            {
              name: "g4b_bookingidname",
              className: "col-short-name",
              label: "Booking Reference",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4b_referencenumber"],
              type: "text",
            },
            {
              name: "g4b_voucherstatus",
              defaultValue: [CouponVoucherStatus.Available],
              enum: CouponVoucherStatus,
              label: "Voucher Status",
              type: "select",
            },
            {
              name: "g4b_purchasedate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4b_expirydate",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4b_voucher",
          lookup: lookups.g4b_voucher.all,
          subFormStages: VoucherFormStages,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Contact Audits",
    id: "contact-audits",
    displayIfFromState: (values) => values.contactid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4gdpr_contactaudit",
          lookup: lookups.g4gdpr_contactaudit.all,
          subFormStages: ContactAuditFormStages,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
];
