import { PromotionExclusionDateFormStages } from "./promotionExclusionDate";
import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const PromotionExclusionDatesFormStages = [
  {
    title: "Promotion Exclusion Dates",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_bankholiday",
              className: "col-bool",
            },
            {
              name: "g4b_excludedate",
              className: "col-date",
              dateFormat: "DD/MM/yyyy",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "g4b_excludedate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/promotion-exclusion-date",
          lookup: lookups.g4b_promotionexclusiondate.all,
          subFormStages: PromotionExclusionDateFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
