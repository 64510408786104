import Field from "./Field";

function Layer({
  activeId,
  allowEdit,
  layer,
  layerIndex,
  layoutIndex,
  page,
  setFieldValue,
  snapIncrement,
  values,
  zoomFactor,
}) {
  return (
    <>
      {layer.Fields &&
        layer.Fields.length > 0 &&
        layer.Fields.map((field, fieldIndex) => {
          return (
            <Field
              key={fieldIndex}
              {...{
                activeId,
                allowEdit,
                field,
                fieldIndex,
                layerIndex,
                layoutIndex,
                page,
                setFieldValue,
                snapIncrement,
                values,
                zoomFactor,
              }}
            />
          );
        })}
    </>
  );
}

export default Layer;
