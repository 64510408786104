import { useState } from "react";
import { Button, Modal } from "./_Elements";
import { ButtonStyle } from "../../js/enums";
import { Update } from "../../js/service";
import {
  handleRedirect,
} from "../form/Helpers";
import { useGlobalReducer } from "../../GlobalContext";
import { useFormReducer } from "../form/FormContext";

function UpdateCustomAttributesAction({
  entityName,
  customAttributes,
  setDisplayUpdateCustomAttributesResetModal,
  state,
  successMessage,
  confirmationText
}) {
  const [isUpdatingCustomAttributesStatus, setIsUpdatingCustomAttributesStatus] = useState(false);

  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();
  const handleHasErrorStatusChange = () => {
    const setErrorStatus = async () => {
      setIsUpdatingCustomAttributesStatus(true);
      try {
        const [serviceResponse] = await Promise.all([
          Update(globalDispatch,
            successMessage, 
            { Id: state[entityName+'id'],
            Attributes:customAttributes,
          },
          entityName
        ),
        ]);
        if (serviceResponse && serviceResponse.status === 200) {
          handleRedirect(null, dispatch, state.id, "refreshPage");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsUpdatingCustomAttributesStatus(false);
      }
    };
    setErrorStatus();
  };
  return (
    <>
      <Modal
        title={"Confirmation"
        }
        modalCloseButtonClick={() => {
          setDisplayUpdateCustomAttributesResetModal(false);
        }}
        className="modal modal-dialog-scrollable"
      >
        <div className="modal-body">
          <p>{confirmationText}</p>
        </div>
        <div className="modal-footer">
          <Button
            text={"Confirm"}
            style={ButtonStyle.Primary}
            onClick={() => handleHasErrorStatusChange()}
            showSpinner={isUpdatingCustomAttributesStatus}
            disabled={
              isUpdatingCustomAttributesStatus
            }
          />
          <Button
            text={"Cancel"}
            style={ButtonStyle.Info}
            onClick={() => setDisplayUpdateCustomAttributesResetModal(false)}
            showSpinner={isUpdatingCustomAttributesStatus}
            disabled={
              isUpdatingCustomAttributesStatus
            }
          />
        </div>
      </Modal>
    </>
  );
}

export default UpdateCustomAttributesAction;
