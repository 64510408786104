import { DonationBatchLineStatus } from "../js/enums";

export const DonationBatchLineFormStages = [
  {
    title: "Donation Batch Line",
    id: "donation-batch-line",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4d_name",
            className: "col-12",
            description: "",
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4d_donationbatchheaderidname",
            className: "col-md-6",
            description: "",
            label: "Donation Batch Header",
            type: "nvarchar",
          },
          {
            name: "g4d_donationidname",
            className: "col-md-6",
            description: "",
            label: "Donation",
            type: "nvarchar",
          },
          {
            name: "g4d_giftaidvalue",
            className: "col-md-6",
            description: "",
            label: "Gift Aid Value",
            type: "money",
          },
          {
            name: "g4d_donationvalue",
            className: "col-md-6",
            description: "",
            label: "Donation Value",
            type: "money",
          },
          {
            name: "g4d_batchlinestatus",
            className: "col-md-4",
            label: "Batch Line Status",
            type: "picklist",
            enum: DonationBatchLineStatus,
          },
          {
            name: "g4d_membershipnumber",
            className: "col-md-4",
            description: "",
            label: "Membership Number",
            type: "nvarchar",
          },
          {
            name: "g4d_paymentdate",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Payment Date",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Customer Details",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4d_salutation",
            className: "col-md-4",
            description: "",
            label: "Salutation",
            type: "nvarchar",
          },
          {
            name: "g4d_firstname",
            className: "col-md-4",
            description: "",
            label: "First Name",
            type: "nvarchar",
          },
          {
            name: "g4d_lastname",
            className: "col-md-4",
            description: "",
            label: "Last Name",
            type: "nvarchar",
          },
          {
            name: "g4d_postcode",
            className: "col-md-4",
            description: "",
            label: "Postcode",
            type: "nvarchar",
          },
          {
            name: "g4d_addressline1",
            className: "col-md-4",
            description: "",
            label: "Address Line 1",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
