import { Fragment } from "react";
import "./HorizontalRule.scss";

function HorizontalRule({ width, zoomFactor }) {
  const increments = Array.from(
    { length: width },
    (_, index) => index
  );

  return (
    <div className="horizontal-rule">
      <svg
        width={`${width * zoomFactor}mm`}
        viewBox={`0 0 ${width * zoomFactor}mm 10mm`}
      >
        {increments.map((increment) => {
          const lineHeight =
            increment % 10 === 0 ? 10 : increment % 5 === 0 ? 5 : 3;

          return (
            <Fragment key={increment}>
              {lineHeight === 10 && (
                <text x={`${increment * zoomFactor + 1}mm`} y="10mm">
                  {increment}
                </text>
              )}
              <line
                x1={`${increment * zoomFactor}mm`}
                y1="0mm"
                x2={`${increment * zoomFactor}mm`}
                y2={`${lineHeight}mm`}
              ></line>
            </Fragment>
          );
        })}
      </svg>
    </div>
  );
}

export default HorizontalRule;
