import { GlobalDispatchMethods } from "./js/enums";

const globalReducer = (state, action) => {
  switch (action.type) {
    case GlobalDispatchMethods.AddNotification:
      return addNotification(state, action);
    case GlobalDispatchMethods.RemoveNotification:
      return removeNotification(state, action);
    case GlobalDispatchMethods.ResetInitialState:
      return resetInitialState(state);
    case GlobalDispatchMethods.SetInitialState:
      return setInitialState(state, action);
    case GlobalDispatchMethods.SetSitemap:
      return setSitemap(state, action);
    default:
      return state;
  }
};

const addNotification = (state, action) => {
  const notificationId = state.notificationId + 1;
  action.notification.id = notificationId;

  setTimeout(() => {
    action.dispatch({
      type: GlobalDispatchMethods.RemoveNotification,
      id: notificationId,
    });
  }, 5000);

  return {
    ...state,
    notifications: [...state.notifications, action.notification],
    notificationId: notificationId,
  };
};

const removeNotification = (state, action) => {
  const updatedNotifications = state.notifications.filter(
    (notification) => notification.id !== action.id
  );

  return {
    ...state,
    notifications: updatedNotifications,
  };
};

const resetInitialState = (state) => {
  return {
    ...state,
    initialStateBound: false,
  };
};

const setInitialState = (state, action) => {
  const { config, sitemap, user } = action.initialState;
  return {
    ...state,
    config: config,
    initialStateBound: true,
    sitemap: sitemap,
    user: user,
  };
};

const setSitemap = (state, action) => {
  return {
    ...state,
    sitemap: action.sitemap,
  };
};

export { globalReducer };
