import { getLinkForNewRecord } from "./Helpers";

function AddRecordButton({ filters, parentId, table }) {
  return (
    <a
      href={getLinkForNewRecord(table, parentId, filters)}
      className="btn btn-primary"
    >
      New
    </a>
  );
}

export default AddRecordButton;
