import { AnswerOptionCategoryFormStages } from "./answerOptionCategory";
import lookups from "./lookups";

export const AnswerOptionFormStages = [
  {
    title: "Answer Option",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4_answeroptioncategoryid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Answer Option Category",
            lookup: lookups.g4_answeroptioncategory.all,
            required: true,
            subFormStages: AnswerOptionCategoryFormStages,
            type: "lookup",
          },
          {
            name: "g4_freetext",
            default: false,
            description: "",
            label: "Free text",
            type: "bit",
          },
        ],
      },
    ],
  },
];
