import {
  convertPxToMm,
  getFieldLeftPosition,
  getFieldTopPosition,
} from "./Helpers";
import "./DragIndicators.scss";

function DragIndicators({
  active,
  dragDelta,
  page,
  snapIncrement,
  zoomFactor,
}) {
  // get the left and top position, adding 10 for the rulers
  const { Height, Left, Top, Width } = active;
  const left =
    getFieldLeftPosition(
      Width,
      page,
      snapIncrement * zoomFactor,
      Left * zoomFactor + convertPxToMm(dragDelta.x),
      zoomFactor
    ) +
    page.LeftOffset * zoomFactor +
    10;
  const top =
    getFieldTopPosition(
      Height,
      page,
      snapIncrement * zoomFactor,
      Top * zoomFactor + convertPxToMm(dragDelta.y),
      zoomFactor
    ) +
    page.TopOffset * zoomFactor +
    10;
  const zoomedWidth = Width * zoomFactor;
  const zoomedHeight = Height * zoomFactor;

  return (
    <div className="drag-indicators">
      <div
        className="drag-shadow drag-shadow-horizontal"
        style={{
          left: `${left}mm`,
          width: `${zoomedWidth}mm`,
        }}
      ></div>
      <div
        className="drag-shadow drag-shadow-vertical"
        style={{
          top: `${top}mm`,
          height: `${zoomedHeight}mm`,
        }}
      ></div>
      <div
        className="drag-grid-line drag-grid-line-left"
        style={{
          left: `${left}mm`,
        }}
      ></div>
      <div
        className="drag-grid-line drag-grid-line-right"
        style={{
          left: `${left + zoomedWidth - 2}mm`,
        }}
      ></div>
      <div
        className="drag-grid-line drag-grid-line-top"
        style={{
          top: `${top}mm`,
        }}
      ></div>
      <div
        className="drag-grid-line drag-grid-line-bottom"
        style={{
          top: `${top + zoomedHeight - 2}mm`,
        }}
      ></div>
    </div>
  );
}

export default DragIndicators;
