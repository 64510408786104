import classNames from "classnames";
import { getLinkHref } from "../../Helpers";

function ImageTextControl({ properties }) {
  const {
    align,
    altText,
    backgroundColour,
    borderColour,
    borderThickness,
    borderType,
    buttonAlign,
    buttonBorderColour,
    buttonBorderThickness,
    buttonBorderType,
    buttonColour,
    buttonFontFamily,
    buttonFontSize,
    buttonFontWeight,
    buttonLink,
    buttonMargin,
    buttonPadding,
    buttonRadius,
    buttonText,
    buttonTextAlign,
    buttonTextColour,
    buttonWidth,
    fontFamily,
    fontSize,
    fontWeight,
    height,
    imageAlign,
    lineHeight,
    margin,
    padding,
    showButton,
    showOnMobile,
    src,
    stackColumns,
    text,
    textAlign,
    textColour,
    textPosition,
    verticalAlign,
    width,
  } = properties;

  const href = getLinkHref(properties, "");

  return (
    <table
      className={classNames(
        "full-width zero-margin",
        showOnMobile ? "" : "hide-for-small"
      )}
    >
      <tbody>
        <tr>
          <td style={{ padding: margin }}>
            <table
              className="full-width zero-margin"
              dir={textPosition}
              style={{
                backgroundColor: backgroundColour,
                borderColor: borderColour,
                borderStyle: borderType,
                borderWidth: borderThickness,
              }}
            >
              <tbody>
                <tr>
                  <td
                    className={classNames(
                      stackColumns ? "mobile-full" : "half-width"
                    )}
                    valign={verticalAlign}
                    style={{ verticalAlign: verticalAlign }}
                  >
                    <table
                      align="left"
                      className="zero-margin full-width"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              lineHeight: lineHeight,
                              padding: padding,
                            }}
                          >
                            {!src ? (
                              <p className="text-center">No image</p>
                            ) : (
                              <div style={{ textAlign: imageAlign }}>
                                {href ? (
                                  <a href={href}>
                                    <img
                                      align={align}
                                      alt={altText}
                                      className={imageAlign}
                                      height={height}
                                      src={src}
                                      width={width}
                                      style={{ width: `${width}px` }}
                                    />
                                  </a>
                                ) : (
                                  <img
                                    alt={altText}
                                    className={imageAlign}
                                    height={height}
                                    src={src}
                                    width={width}
                                    style={{ width: `${width}px` }}
                                  />
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td
                    className={classNames(
                      stackColumns ? "mobile-full" : "half-width"
                    )}
                    valign={verticalAlign}
                    style={{ verticalAlign: verticalAlign }}
                  >
                    <table className="zero-margin full-width">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              color: textColour,
                              fontFamily: fontFamily,
                              fontSize: fontSize,
                              fontWeight: fontWeight,
                              lineHeight: lineHeight,
                              padding: padding,
                              textAlign: textAlign,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: text,
                            }}
                          ></td>
                        </tr>
                        {showButton && (
                          <tr>
                            <td>
                              <table
                                width="100%"
                                border="0"
                                cellSpacing="0"
                                cellPadding="0"
                                style={{
                                  backgroundColor: backgroundColour,
                                  margin: "0",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        padding: buttonMargin,
                                        textAlign: buttonAlign,
                                      }}
                                    >
                                      <table
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{
                                          borderCollapse: "separate",
                                          display:
                                            buttonWidth === "auto"
                                              ? "inline-block"
                                              : "table",
                                          margin: "0",
                                          width: buttonWidth,
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              dir="ltr"
                                              style={{
                                                backgroundColor:
                                                  buttonColour,
                                                borderColor:
                                                  buttonBorderColour,
                                                borderRadius:
                                                  buttonRadius,
                                                borderStyle:
                                                  buttonBorderType,
                                                borderWidth:
                                                  buttonBorderThickness,
                                                MozBorderRadius:
                                                  buttonRadius,
                                                padding:
                                                  buttonPadding,
                                                textAlign:
                                                  buttonTextAlign,
                                                WebkitBorderRadius:
                                                  buttonRadius,
                                              }}
                                            >
                                              <a
                                                href={buttonLink}
                                                rel="noreferrer"
                                                target="_blank"
                                                style={{
                                                  color:
                                                    buttonTextColour,
                                                  fontFamily:
                                                    buttonFontFamily,
                                                  fontSize:
                                                    buttonFontSize,
                                                  fontWeight:
                                                    buttonFontWeight,
                                                }}
                                              >
                                                {buttonText}
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ImageTextControl;
