import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import "./IconCard.scss";

function IconCard({
  children,
  className,
  containerClass,
  icon,
  iconClassName,
  onClick,
  onClickIcon,
}) {
  return (
    <div className={classNames("card", "rounded", className)}>
      <div className={classNames("d-flex", containerClass)}>
        {icon && (
          <div className={classNames("card-icon", iconClassName)}>
            <FontAwesomeIcon
              icon={icon}
              onClick={onClickIcon && onClickIcon}
              size="xl"
            />
          </div>
        )}
        <div className="p-3 flex-grow-1" onClick={onClick}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default IconCard;
