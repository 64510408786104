import classNames from "classnames";
import FormLabel from "./FormLabel";
import { getInputType } from "../form/Helpers";
import "./Input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipComponent from "./TooltipComponent";

function Input({
  actions,
  actionsClass = "d-flex justify-content-end",
  checked,
  className,
  description,
  disabled = false,
  errors,
  inputIcon,
  label,
  mandatory = false,
  name,
  onBlur,
  onChange,
  placeholder,
  showErrorsBeforeTouched = false,
  tooltipDescription,
  touched,
  type,
  value,
}) {
  const inputType = getInputType(type);
  return (
    <>
      {label && (
        <div className="d-flex">
          {inputType !== "checkbox" && (
            <FormLabel
              className="form-label me-auto"
              label={label}
              mandatory={mandatory}
              name={name}
            />
          )}
          {actions && <div className={actionsClass}>{actions}</div>}
        </div>
      )}
      <div
        className={classNames(
          className,
          inputType === "checkbox" ? "form-check" : "",
          type === "money"
            ? "input-group currency-input"
            : inputIcon
            ? "input-group search-input"
            : ""
        )}
      >
        {type === "money" && (
          <div className="input-group-text">£</div>
        )}
        {inputIcon && (
          <div className="input-group-text">
            <FontAwesomeIcon icon={inputIcon} />
          </div>
        )}
        <input
          type={inputType}
          className={classNames(
            inputType === "checkbox"
              ? "form-check-input"
              : "form-control",
            (touched || showErrorsBeforeTouched) && errors
              ? "is-invalid"
              : ""
          )}
          id={name}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          checked={checked}
        />
        {inputType === "checkbox" && label && (
          <FormLabel
            label={label}
            mandatory={mandatory}
            name={name}
            className={classNames(
              "form-check-label",
              value ? "fw-bold" : ""
            )}
          />
        )}
        {description && (
          <div className="form-text">
            {description}
            {tooltipDescription ? (
              <TooltipComponent
                name={name}
                tooltipDescription={tooltipDescription}
              />
            ) : (
              ""
            )}
          </div>
        )}
        {errors && (touched || showErrorsBeforeTouched) && (
          <div className="invalid-feedback">{errors}</div>
        )}
      </div>
    </>
  );
}

Input.defaultProps = {
  placeholder: "",
  type: "text",
};

export default Input;
