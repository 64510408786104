const BarcodeFormatType = Object.freeze({
  Code128: 1,
  Code128A: 2,
  Code128B: 3,
  Code128C: 4,
  EAN8: 5,
  EAN13: 6,
  Interleaved20f5: 7,
  Code39: 8,
  Codabar: 9,
});

const DragType = Object.freeze({
  NewField: 1,
  MoveField: 2,
});

const FieldType = Object.freeze({
  Text: 1,
  Image: 2,
  LinearBarcode: 3,
  TwoDimensionalBarcode: 4,
  List: 5,
  Date: 6,
});

const LayoutType = Object.freeze({
  Receipt: "Receipt",
  Ticket: "Ticket",
});

const PageType = Object.freeze({
  Front: 0,
  Back: 1,
});

const TextFormatType = Object.freeze({
  Percent: 1,
  Duration: 2,
  Currency: 3,
});

const TwoDimensionalBarcodeFormatType = Object.freeze({
  QRCode: 17,
  Datamatrix: 18,
  Aztec: 19,
  PDF417: 20,
});

const ZoomScale = Object.freeze({
  75: "75%",
  100: "100%",
  125: "125%",
  150: "150%",
  200: "200%",
});

export {
  BarcodeFormatType,
  DragType,
  FieldType,
  LayoutType,
  PageType,
  TextFormatType,
  TwoDimensionalBarcodeFormatType,
  ZoomScale,
};
