import { Input } from "../elements/_Elements";
import { tableHandleFilterChange } from "./Helpers";

function InactiveFilter({ filters, setFilters, table }) {
  return (
    <div
      key={
        table && table.filters && table.filters.length
          ? table.filters.length + 1
          : 0
      }
      className="col-md-2"
    >
      <Input
        checked={
          filters.some((f) => f.Key === "getInactive")
            ? filters.filter((f) => f.Key === "getInactive")[0].Value
            : false
        }
        label={"Inactive"}
        name={"getInactive"}
        onChange={(event) =>
          tableHandleFilterChange(
            { name: "getInactive", type: "bit" },
            event,
            filters,
            setFilters
          )
        }
        type={"bit"}
        value={
          filters.some((f) => f.Key === "getInactive")
            ? filters.filter((f) => f.Key === "getInactive")[0].Value
            : ""
        }
      />
    </div>
  );
}

export default InactiveFilter;
