import { useEffect, useState } from "react";
import { useGlobalReducer } from "../../../GlobalContext";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { DesignSection, Loading } from "../../elements/_Elements";
import RulesAndCount from "./RulesAndCount";
import Sidebar from "./Sidebar";
import { handleDragEnd } from "./Helpers";
import { GetCommunicationAudienceRules } from "../../../js/service";
import { LoadingState } from "../../../js/enums";

function Designer({
  errors,
  handleBlur,
  handleChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  setFieldValue,
  state,
  values,
}) {
  const globalDispatch = useGlobalReducer();

  const [formState, setFormState] = useState({
    ActiveId: null,
    Loading: LoadingState.NotLoaded,
    RulesData: null,
  });

  useEffect(() => {
    const fetchAudienceRules = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetCommunicationAudienceRules(globalDispatch),
        ]);

        if (serviceResponse && serviceResponse.data) {
          setFormState({
            ...formState,
            Loading: LoadingState.Loaded,
            RulesData: serviceResponse.data,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (formState.Loading !== LoadingState.Loaded) {
      fetchAudienceRules();
    }
  }, [formState, globalDispatch]);

  const onDragStart = (event) => {
    setFormState({
      ...formState,
      ActiveId: event.active.id,
    });
  };

  const onDragEnd = (event) => {
    setFormState({
      ...formState,
      ActiveId: null,
    });
    handleDragEnd(event, setFieldValue, values);
  };

  return (
    <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <DesignSection
        className={formState.ActiveId ? "is-dragging" : ""}
      >
        <DesignSection.Content>
          {formState.Loading !== LoadingState.Loaded ? (
            <Loading />
          ) : (
            <RulesAndCount
              {...{ errors, setFieldValue, values }}
              activeId={formState.ActiveId}
              rulesData={formState.RulesData}
            />
          )}
        </DesignSection.Content>
        <DesignSection.Sidebar>
          <Sidebar
            {...{
              handleBlur,
              handleChange,
              handleFilteredPicklistChange,
              handlePicklistChange,
              setFieldValue,
              state,
              values,
            }}
            rulesData={formState.RulesData}
          />
        </DesignSection.Sidebar>
      </DesignSection>
      <DragOverlay>
        {formState.ActiveId ? <div id="drag-overlay" /> : null}
      </DragOverlay>
    </DndContext>
  );
}

export default Designer;
