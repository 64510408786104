const DividerControlDefaultValues = [
  {
    Name: "backgroundColour",
    Value: "#fff",
  },
  {
    Name: "borderType",
    Value: "solid",
  },
  {
    Name: "borderThickness",
    Value: "1px",
  },
  {
    Name: "borderColour",
    Value: "#ddd",
  },
  {
    Name: "padding",
    Value: "5px 0",
  },
  {
    Name: "margin",
    Value: "5px 0",
  },
  {
    Name: "showOnMobile",
    Value: true,
  },
];

export default DividerControlDefaultValues;
