import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDown, ChevronUp } from "../Icons";

function CollapsibleSection({ children, id, title }) {
  const [expanded, setExpanded] = useState(true);
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h5 className="text-uppercase">{title}</h5>
        </div>
        <div>
          <button
            className="btn btn-info"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${id}-collapse-component`}
            aria-expanded="true"
            aria-controls="collapse-component"
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon
              icon={expanded ? ChevronUp : ChevronDown}
            />
          </button>
        </div>
      </div>
      <div className="show" id={`${id}-collapse-component`}>
        {children}
      </div>
    </>
  );
}

export default CollapsibleSection;
