import { isSameOrLaterDate } from "../js/utility";
import lookups from "./lookups";

export const MembershipPaymentPlanScheduleFormStages = [
  {
    title: "",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4m_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4m_membershippaymentplanid",
            description: "",
            label: "Membership payment plan",
            lookup: lookups.g4m_membershippaymentplan.all,
            filterLookupOptions: (
              lookupOptions,
              _,
              parentFormState
            ) => {
              return lookupOptions.filter((x) =>
                parentFormState.g4m_membershippaymentplanid.includes(
                  x.Key
                )
              );
            },
            required: true,
            type: "lookup",
          },
          {
            name: "g4m_startdate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Start date",
            type: "datetime",
          },
          {
            name: "g4m_enddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            filterDate: (date, values) => {
              return values.g4m_startdate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4m_startdate)
                  )
                : true;
            },
            label: "End date",
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4m_startdate
                ? isSameOrLaterDate(date, values.g4m_startdate)
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4m_joiningfee",
            className: "col-md-6",
            description: "",
            label: "Joining fee",
            type: "money",
          },
          {
            name: "g4m_periodfee",
            className: "col-md-6",
            description: "",
            label: "Period fee",
            type: "money",
          },
        ],
      },
    ],
  },
];
