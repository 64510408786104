import React, { useEffect, useState } from "react";

function SelectAll({ setFieldValue, values }) {
  const [isSelectAll, setIsSelectAll] = useState(false);

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    Object.keys(values).forEach((key) => {
      setFieldValue(key, checked);
    });
  };

  useEffect(() => {
    setIsSelectAll(!Object.keys(values).some((key) => !values[key]));
  }, [values]);

  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="selectAll"
        checked={isSelectAll}
        onChange={handleSelectAll}
      />
      <h5>Select All</h5>
    </div>
  );
}

export default SelectAll;
