import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeft } from "../Icons";
import {
  Button,
  JumpToContent,
  ProgressTracker,
  SetStatusModal,
} from "./_Elements";
import {
  ButtonStyle,
  LoadingState,
  RecordStatus,
} from "../../js/enums";
import "./FormPageHeader.scss";
import { SetRecordState } from "../../js/service";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../GlobalContext";
import { checkForValidRoles, handleRedirect } from "../form/Helpers";
import classNames from "classnames";

function FormPageHeader({
  allowShowInformation,
  dispatch,
  entityName,
  filteredStages,
  header,
  loading,
  onBack,
  scrollToElement,
  setLoading,
  setShowInformation,
  showInformation,
  stages,
  state,
}) {
  const [
    showConfirmStatusChangeModal,
    setShowConfirmStatusChangeModal,
  ] = useState(false);
  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();

  //If there are no stages with a statusEditAllowedRoles value set then allow for
  //any role to edit the status. If it does have a value then check if the logged in
  //user has a valid role which will allow them to edit the record status
  let stageWithStatusEditAllowRoles =
    stages &&
    stages.length > 0 &&
    stages.some((s) => s.statusEditAllowedRoles)
      ? stages.find((s) => s.statusEditAllowedRoles)
      : null;

  const statusEditAllowed =
    !stageWithStatusEditAllowRoles ||
    (stageWithStatusEditAllowRoles &&
      checkForValidRoles(
        stageWithStatusEditAllowRoles.statusEditAllowedRoles,
        globalState
      ));

  const handleRecordStatusChange = () => {
    const setStatus = async () => {
      setLoading(LoadingState.Loading);
      try {
        const [serviceResponse] = await Promise.all([
          SetRecordState(globalDispatch, {
            Ids: [state.id],
            EntityName: entityName,
            StateCode: state.statecode === 0 ? 1 : 0,
          }),
        ]);
        if (serviceResponse && serviceResponse.status === 200) {
          handleRedirect(null, dispatch, state.id, "refreshPage");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };
    setStatus();
  };

  const displayJumpTo =
    stages && stages.length > 0 && stages.some((s) => s.id);
  return (
    <>
      {showConfirmStatusChangeModal && (
        <SetStatusModal
          handleRecordStatusChange={handleRecordStatusChange}
          recordStatusToShow={
            state.statecode === 0
              ? RecordStatus.Active
              : RecordStatus.Inactive
          }
          setShowConfirmStatusChangeModal={
            setShowConfirmStatusChangeModal
          }
          singleRecord={true}
          state={state}
        />
      )}
      <div id="form-page-header" className="sticky-top px-3">
        {state &&
          !state.id &&
          filteredStages &&
          filteredStages.length > 1 && (
            <ProgressTracker {...{ filteredStages, state }} />
          )}
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {state && header && !showInformation && onBack && (
              <Button
                className="btn-sm me-3"
                onClick={onBack}
                style={ButtonStyle.Info}
                text={<FontAwesomeIcon icon={ArrowLeft} />}
              />
            )}
            {header && (
              <h2 className="fw-bold text-dark me-3">{header}</h2>
            )}
            {state &&
              state.id &&
              (state.statecode === 0 || state.statecode === 1) && (
                <>
                  <div className="dropdown">
                    <button
                      className={classNames(
                        "btn",
                        state.statecode === 0
                          ? "btn-success"
                          : "btn-warning",
                        statusEditAllowed ? "dropdown-toggle" : ""
                      )}
                      type="button"
                      id="statusDropDown"
                      data-bs-toggle={
                        statusEditAllowed ? "dropdown" : ""
                      }
                      aria-expanded="false"
                      disabled={loading !== LoadingState.Loaded}
                    >
                      {state.statecode === 0 ? "Active" : "Inactive"}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="statusDropDown"
                    >
                      <li
                        key={"toggleStatus"}
                        onClick={() =>
                          setShowConfirmStatusChangeModal(true)
                        }
                      >
                        <span className="dropdown-item cursor-pointer">
                          {state.statecode === 0
                            ? "Deactivate"
                            : "Activate"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </>
              )}
          </div>
          <div className="d-flex align-items-end">
            <div className="me-2">
              {allowShowInformation && showInformation ? (
                <Button
                  onClick={() => setShowInformation(false)}
                  text="Content"
                />
              ) : (
                <>
                  {displayJumpTo && (
                    <JumpToContent {...{ scrollToElement, stages }} />
                  )}
                </>
              )}
            </div>
            {allowShowInformation && !showInformation && (
              <Button
                onClick={() => setShowInformation(true)}
                style={ButtonStyle.OutlinePrimary}
                text="Info"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPageHeader;
