import { useEffect, useState } from "react";
import classNames from "classnames";
import { useGlobalReducer } from "../../../GlobalContext";
import { LoadingState, PrinterJobType } from "../../../js/enums";
import {
  GetPrintTransformLayouts,
  Update,
} from "../../../js/service";
import { SaveIcon, ReceiptIcon, TicketIcon } from "../../Icons";
import { ButtonStyle } from "../../../js/enums";
import {
  Button,
  IconCard,
  Input,
  Loading,
  Modal,
} from "../../elements/_Elements";
import { LayoutType } from "./Enums";
import StandardReceipt from "./StandardReceipt";
import StandardTicket from "./StandardTicket";
import Layout from "./Layout";

function SavedLayouts({
  handleChange,
  name,
  setFieldValue,
  state,
  value,
}) {
  const globalDispatch = useGlobalReducer();
  const newLayout = Object.values(LayoutType);
  const [layoutLoading, setLayoutLoading] = useState(
    LoadingState.Loaded
  );

  const [previewLayoutData, setPreviewLayoutData] = useState(null);

  const [layoutToDelete, setLayoutToDelete] = useState(null);
  const [savedLayoutsLookup, setSavedLayoutsLookup] = useState([]);
  const [layoutToRename, setLayoutToRename] = useState(null);
  const [newLayoutName, setNewLayoutName] = useState("");

  const { g4b_printerjobtypeidname } = state;

  useEffect(() => {
    const fetchLayoutsData = async () => {
      try {
        setLayoutLoading(LoadingState.Loading);
        const [serviceResponse] = await Promise.all([
          GetPrintTransformLayouts(globalDispatch),
        ]);

        if (serviceResponse && serviceResponse.data) {
          const filteredSavedLayouts = serviceResponse.data.filter(
            (item) =>
              item.PrinterJobTypeName ===
                state.g4b_printerjobtypeidname &&
              item.LayoutName !== "" &&
              item.State === 0
          );
          setSavedLayoutsLookup(filteredSavedLayouts);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLayoutLoading(LoadingState.Loaded);
      }
    };
    fetchLayoutsData();
  }, [globalDispatch, state.g4b_printerjobtypeidname]);

  const renameLayout = async () => {
    setLayoutLoading(LoadingState.Loading);
    selectLayout(null);
    try {
      const [renameResponse] = await Promise.all([
        Update(
          globalDispatch,
          `${layoutToRename.LayoutName} successfully renamed to ${newLayoutName}`,
          {
            Id: layoutToRename.PrintTransformId,
            Name: layoutToRename.Name,
            Attributes: {
              g4pd_LayoutName: newLayoutName,
            },
          },
          "g4b_printtransform"
        ),
      ]);

      if (renameResponse && renameResponse.data) {
        setLayoutLoading(LoadingState.Loading);
        await resetSavedLayouts();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLayoutToRename(null);
      setLayoutLoading(LoadingState.Loaded);
    }
  };

  const deleteLayout = async () => {
    setLayoutLoading(LoadingState.Loading);
    selectLayout(null);
    try {
      const [deactivateResponse] = await Promise.all([
        Update(
          globalDispatch,
          `${layoutToDelete.LayoutName} successfully deleted`,
          {
            Id: layoutToDelete.PrintTransformId,
            Name: layoutToDelete.Name,
            Attributes: {
              statecode: 1,
              statuscode: 2,
            },
          },
          "g4b_printtransform"
        ),
      ]);

      if (deactivateResponse && deactivateResponse.data) {
        setLayoutLoading(LoadingState.Loading);
        await resetSavedLayouts();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLayoutToDelete(null);
      setLayoutLoading(LoadingState.Loaded);
    }
  };

  const resetSavedLayouts = async () => {
    const [serviceResponse] = await Promise.all([
      GetPrintTransformLayouts(globalDispatch),
    ]);

    //Reset the value of the pint transform saved layout stored in the session storage
    if (serviceResponse && serviceResponse.data) {
      const filteredSavedLayouts = serviceResponse.data.filter(
        (item) =>
          item.PrinterJobTypeName ===
            state.g4b_printerjobtypeidname &&
          item.LayoutName !== "" &&
          item.State === 0
      );

      setSavedLayoutsLookup(filteredSavedLayouts);
    }
  };

  const getLayout = (layout) => {
    return new Promise(function (resolve) {
      if (layout === LayoutType.Receipt) {
        resolve(StandardReceipt());
      } else if (layout === LayoutType.Ticket) {
        resolve(StandardTicket());
      } else {
        resolve(layout);
      }
    });
  };

  const selectLayout = (layout) => {
    // clear the value so that the buttons are disabled while the layout is loaded
    handleChange({
      target: {
        name: name,
        value: "",
      },
    });
    if (layout) {
      getLayout(layout).then(function (layoutData) {
        if (g4b_printerjobtypeidname === PrinterJobType.Card) {
          // cards should have a front and back
          setFieldValue("front", layoutData.Front);
          if (layoutData.Back) {
            setFieldValue("back", layoutData.Back);
          } else {
            setFieldValue("back", layoutData.Front);
          }
        } else {
          // all other job types should not have a back
          setFieldValue("front", layoutData.Front);
          setFieldValue("back", null);
        }
        handleChange({
          target: {
            name: name,
            value: layout,
          },
        });
      });
    }
  };

  const selectPreview = (layout) => {
    getLayout(layout).then(function (layoutData) {
      setPreviewLayoutData(layoutData);
    });
  };

  return layoutLoading === LoadingState.Loading ? (
    <Loading />
  ) : (
    <>
      {layoutToRename && (
        <Modal
          title={"Rename Saved Layout"}
          modalCloseButtonClick={() => {
            setLayoutToRename(null);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <small>
              {"Please enter the new name for your layout."}
            </small>
            <Input
              label={"New Name:"}
              mandatory={true}
              name={"NewLayoutName"}
              onChange={(event) =>
                setNewLayoutName(event.target.value)
              }
              value={newLayoutName}
            />
          </div>
          <div className="modal-footer">
            <Button
              text={"Save"}
              style={ButtonStyle.Primary}
              onClick={() => renameLayout()}
              disabled={!newLayoutName || newLayoutName.trim() === ""}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => setLayoutToRename(null)}
            />
          </div>
        </Modal>
      )}
      {layoutToDelete && (
        <Modal
          title={"Delete Saved Layout"}
          modalCloseButtonClick={() => {
            setLayoutToDelete(null);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <small>{`Are you sure you want to delete the ${layoutToDelete.LayoutName} saved layout?`}</small>
          </div>
          <div className="modal-footer">
            <Button
              text={"Delete"}
              style={ButtonStyle.Primary}
              onClick={() => deleteLayout()}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => setLayoutToDelete(null)}
            />
          </div>
        </Modal>
      )}
      {previewLayoutData && (
        <Modal
          title={previewLayoutData.LayoutName}
          modalCloseButtonClick={() => {
            setPreviewLayoutData(null);
          }}
          className="modal modal-dialog-scrollable modal-fullscreen"
        >
          <div id="saved-layout-preview" className="modal-body">
            {previewLayoutData && previewLayoutData.Front && (
              <>
                {previewLayoutData.Back && <p>Front</p>}
                <Layout
                  layoutIndex={0}
                  allowEdit={false}
                  page={previewLayoutData.Front}
                  zoomFactor={1}
                />
              </>
            )}
            {previewLayoutData && previewLayoutData.Back && (
              <>
                <p className="mt-3">Back</p>
                <Layout
                  layoutIndex={0}
                  allowEdit={false}
                  page={previewLayoutData.Back}
                  zoomFactor={1}
                />
              </>
            )}
          </div>
        </Modal>
      )}
      {savedLayoutsLookup && savedLayoutsLookup.length > 0 && (
        <>
          <h5>Saved Layouts</h5>
          <div className="row mb-3">
            {savedLayoutsLookup.map((layout, index) => {
              const isSelected =
                value &&
                value.PrintTransformId === layout.PrintTransformId;
              return (
                <div key={index} className="col-md-4 mb-3">
                  <IconCard
                    containerClass={classNames(
                      "border",
                      "rounded",
                      "cursor-pointer",
                      isSelected ? "border-primary" : ""
                    )}
                    icon={SaveIcon}
                    iconClassName={
                      isSelected ? "bg-primary" : "bg-info"
                    }
                    onClick={() => selectLayout(layout)}
                  >
                    {layout.LayoutName}
                  </IconCard>
                  <div className="d-flex justify-content-between mb-3">
                    <Button
                      className={"mt-2"}
                      text={"Preview"}
                      style={ButtonStyle.OutlineSecondary}
                      onClick={() => {
                        selectPreview(layout);
                      }}
                    />
                    <Button
                      className={"mt-2 ms-2 me-2 me-auto"}
                      text={"Rename"}
                      style={ButtonStyle.OutlineSecondary}
                      onClick={() => {
                        setNewLayoutName(layout.LayoutName);
                        setLayoutToRename(layout);
                      }}
                    />
                    <Button
                      className={"mt-2 ms-2"}
                      text={"Delete"}
                      style={ButtonStyle.Danger}
                      onClick={() => setLayoutToDelete(layout)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <h5>Standard Layouts</h5>
      <div className="row">
        {newLayout.map((layout, index) => {
          const isSelected = value && value === layout;
          return (
            <div className="col-md-4" key={index}>
              <IconCard
                containerClass={classNames(
                  "border",
                  "rounded",
                  "cursor-pointer",
                  isSelected ? "border-primary" : ""
                )}
                icon={
                  layout === LayoutType.Ticket
                    ? TicketIcon
                    : ReceiptIcon
                }
                iconClassName={classNames(
                  isSelected ? "bg-primary" : "bg-info"
                )}
                onClick={() => selectLayout(layout)}
              >
                {layout}
              </IconCard>
              <div className="d-flex justify-content-between mb-3">
                <Button
                  className={"mt-2"}
                  text={"Preview"}
                  style={ButtonStyle.OutlineSecondary}
                  onClick={() => {
                    selectPreview(layout);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default SavedLayouts;
