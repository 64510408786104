import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { AdditionalProductFormStages } from "./additionalProduct";
import lookups from "./lookups";

export const AdditionalProductsFormStages = [
  {
    title: "Additional Products",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_analysiscategoryid",
              className: "col-name",
            },
            {
              name: "g4b_productbaseid",
              className: "col-name",
            },
            {
              name: "g4b_protoproductid",
              className: "col-name",
            },
            {
              name: "g4b_additionalprotoproductid",
              className: "col-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_analysiscategoryid",
              type: "select",
            },
            {
              name: "g4b_productbaseid",
              type: "select",
            },
            {
              name: "g4b_protoproductid",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/additional-product",
          lookup: lookups.g4b_additionalproduct.all,
          subFormStages: AdditionalProductFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
