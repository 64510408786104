import classNames from "classnames";

function Tabs({ activeTab, onClick, tabs }) {
  return (
    <ul className="nav nav-pills">
      {tabs.map((tab, tabIndex) => {
        return (
          <li key={tabIndex} className="nav-item">
            <span
              className={classNames(
                "nav-link cursor-pointer",
                activeTab === tab.title ? "active" : ""
              )}
              onClick={() => onClick(tab)}
            >
              {tab.title}
            </span>
          </li>
        );
      })}
    </ul>
  );
}

export default Tabs;
