import PropTypes from "prop-types";
import classNames from "classnames";
import { ButtonStyle } from "../../js/enums";
import "./Button.scss";

function Button({
  className,
  disabled,
  isBlock = false,
  onClick,
  showSpinner = false,
  style = ButtonStyle.Info,
  text,
  type = "button",
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames(
        "btn",
        style,
        isBlock ? "btn-block" : "",
        className
      )}
      disabled={disabled}
    >
      {showSpinner && (
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {text}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isBlock: PropTypes.bool,
  onClick: PropTypes.func,
  showSpinner: PropTypes.bool,
  style: PropTypes.string,
  text: PropTypes.node,
  type: PropTypes.string,
};

export default Button;
