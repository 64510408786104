import { getDateString } from "../../Helpers";

function VoucherTableCell({ children, className, properties }) {
  const {
    voucherFontFamily,
    voucherFontSize,
    voucherFontWeight,
    voucherPadding,
    voucherTextColour,
  } = properties;

  return (
    <td
      className={className}
      style={{
        color: voucherTextColour,
        fontFamily: voucherFontFamily,
        fontSize: voucherFontSize,
        fontWeight: voucherFontWeight,
        padding: voucherPadding,
      }}
    >
      {children}
    </td>
  );
}

function ActualVouchers({ properties }) {
  const {
    localeCode,
    longDateFormat,
    voucherBackgroundColour,
    voucherBorderColour,
    voucherBorderThickness,
    voucherBorderType,
    voucherRedeemLink,
    voucherMargin,
    voucherTermsLink,
  } = properties;

  return (
    <table
      className="full-width zero-margin hide-on-designer"
      cellSpacing="0"
    >
      <tbody
        data-xsl-wrap="xsl:for-each"
        data-xsl-attributes="select='Vouchers/Voucher'"
      >
        <tr>
          <td style={{ padding: voucherMargin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: voucherBorderColour,
                borderStyle: voucherBorderType,
                borderWidth: voucherBorderThickness,
              }}
            >
              <tbody>
                <tr
                  style={{ backgroundColor: voucherBackgroundColour }}
                >
                  <VoucherTableCell properties={properties}>
                    <p style={{ fontSize: "20px" }}>
                      <strong
                        dangerouslySetInnerHTML={{
                          __html:
                            '<xsl:value-of select="VoucherName" />',
                        }}
                      />
                    </p>
                  </VoucherTableCell>
                </tr>
                <tr
                  style={{ backgroundColor: voucherBackgroundColour }}
                >
                  <td>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <VoucherTableCell properties={properties}>
                            <p
                              data-xsl-wrap="xsl:if"
                              data-xsl-attributes='test="VoucherValue"'
                            >
                              <strong>Value: </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '<xsl:value-of select="g4:FormatCurrency(VoucherValue, ../../CurrencyCode)" />',
                                }}
                              />
                            </p>
                            <p>
                              <strong>Code: </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '<xsl:value-of select="VoucherCode" />',
                                }}
                              />
                            </p>
                            <p
                              data-xsl-wrap="xsl:if"
                              data-xsl-attributes='test="ExpiryDate"'
                            >
                              <strong>Expiry Date: </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `<xsl:value-of select="g4:TranslateDate(ExpiryDate, '${longDateFormat}', '${localeCode}')" />`,
                                }}
                              />
                            </p>
                          </VoucherTableCell>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="inner-content-only"
                      dangerouslySetInnerHTML={{
                        __html:
                          "<!--[if mso]></td><td><![endif]--></td>",
                      }}
                    ></div>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <VoucherTableCell properties={properties}>
                            <div
                              className="inner-content-only"
                              dangerouslySetInnerHTML={{
                                __html: `<xsl:variable name="voucherCodeUrl" select="concat('https://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128{HtmlEncode:&Dpi=300&}Data=', VoucherCode)"></xsl:variable>`,
                              }}
                            ></div>
                            <p
                              style={{ textAlign: "center" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<xsl:img src="{$voucherCodeUrl}" style="max-width: 100%; width: 268px;" width="268"></xsl:img>',
                              }}
                            ></p>
                          </VoucherTableCell>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {(voucherRedeemLink || voucherTermsLink) && (
                  <tr
                    style={{
                      backgroundColor: voucherBackgroundColour,
                    }}
                  >
                    <VoucherTableCell properties={properties}>
                      {voucherRedeemLink && (
                        <p style={{ float: "left" }}>
                          <a href={voucherRedeemLink}>
                            Redeem Online
                          </a>
                        </p>
                      )}
                      {voucherTermsLink && (
                        <p style={{ float: "right" }}>
                          <a href={voucherTermsLink}>
                            View Terms & Conditions
                          </a>
                        </p>
                      )}
                    </VoucherTableCell>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function SampleVouchers({ properties }) {
  const {
    voucherBackgroundColour,
    voucherBorderColour,
    voucherBorderThickness,
    voucherBorderType,
    voucherRedeemLink,
    voucherMargin,
    voucherTermsLink,
  } = properties;

  return (
    <table
      className="full-width zero-margin remove-item"
      cellSpacing="0"
    >
      <tbody>
        <tr>
          <td style={{ padding: voucherMargin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: voucherBorderColour,
                borderStyle: voucherBorderType,
                borderWidth: voucherBorderThickness,
              }}
            >
              <tbody>
                <tr
                  style={{ backgroundColor: voucherBackgroundColour }}
                >
                  <VoucherTableCell properties={properties}>
                    <p style={{ fontSize: "20px" }}>
                      <strong>Voucher Product Name</strong>
                    </p>
                  </VoucherTableCell>
                </tr>
                <tr
                  style={{ backgroundColor: voucherBackgroundColour }}
                >
                  <td>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <VoucherTableCell properties={properties}>
                            <p>
                              <strong>Value: </strong>
                              <span>£20.00</span>
                            </p>
                            <p>
                              <strong>Code: </strong>
                              <span>SJ20N-F3UD2-301U5</span>
                            </p>
                            <p>
                              <strong>Expiry Date: </strong>
                              <span>
                                {getDateString(
                                  0,
                                  1,
                                  "dddd D MMMM YYYY"
                                )}
                              </span>
                            </p>
                          </VoucherTableCell>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <VoucherTableCell properties={properties}>
                            <p style={{ textAlign: "center" }}>
                              <img
                                src="http://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128&Dpi=300&Data=8PP0D-CC96A-00082"
                                alt="8PP0D-CC96A-0008"
                                style={{ maxWidth: "100%" }}
                                width="268"
                              />
                            </p>
                          </VoucherTableCell>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {(voucherRedeemLink || voucherTermsLink) && (
                  <tr
                    style={{
                      backgroundColor: voucherBackgroundColour,
                    }}
                  >
                    <VoucherTableCell properties={properties}>
                      {voucherRedeemLink && (
                        <p style={{ float: "left" }}>
                          <a href={voucherRedeemLink}>
                            Redeem Online
                          </a>
                        </p>
                      )}
                      {voucherTermsLink && (
                        <p style={{ float: "right" }}>
                          <a href={voucherTermsLink}>
                            View Terms & Conditions
                          </a>
                        </p>
                      )}
                    </VoucherTableCell>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function Vouchers({ properties }) {
  return (
    <table
      className="full-width zero-margin"
      cellSpacing="0"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='Vouchers/Voucher'"
    >
      <tbody>
        <tr>
          <td>
            <ActualVouchers properties={properties} />
            <SampleVouchers properties={properties} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Vouchers;
