import ReactDOM from "react-dom";
import { withFormik } from "formik";
import { Button, Modal } from "../_Elements";
import { ButtonStyle } from "../../../js/enums";
import SidebarForm from "../../form/SidebarForm";
import AddLinkFormStages from "./AddLinkFormStages";
import {
  LinkType,
  PredefinedLink,
} from "../../form/communication/enums";
import { getHref } from "./Helpers";

function AddLink({
  editorState,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  setEditorState,
  setFieldValue,
  state,
  touched,
  values,
}) {
  const { addLink } = editorState;

  const modalRoot = document.getElementById("modal-root");

  return addLink && modalRoot ? (
    ReactDOM.createPortal(
      <form onSubmit={handleSubmit}>
        <Modal
          title="Link"
          modalCloseButtonClick={() =>
            setEditorState({
              ...editorState,
              addLink: false,
            })
          }
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <SidebarForm
              {...{
                errors,
                handleBlur,
                handleChange,
                state,
                touched,
                values,
              }}
              handleFilteredPicklistChange={(event, _, name) => {
                const value = event ? event.value : "";
                setFieldValue(name, value);
              }}
              stages={AddLinkFormStages}
            />
          </div>
          <div className="modal-footer">
            <Button
              style={ButtonStyle.Primary}
              text={"Confirm"}
              type="submit"
            />
          </div>
        </Modal>
      </form>,
      modalRoot
    )
  ) : (
    <></>
  );
}

const AddLinkWithFormik = withFormik({
  enableReinitialize: true,

  mapPropsToValues: ({ editorRef }) => {
    const editor = editorRef.current;

    const selectedNode = editor.selection.getNode();

    let attribute = "";
    let emailAddress = "";
    let emailContent = "";
    let emailSubject = "";
    let linkType = LinkType["Web Address"];
    let openInNewTab = false;
    let predefinedLink = "";
    let text = "";
    let url = "";

    // Check if the selected node is an anchor tag
    if (selectedNode && selectedNode.nodeName === "A") {
      // determine the link type and properties
      openInNewTab = selectedNode.target === "_blank";
      text = selectedNode.innerHTML;
      url = selectedNode.getAttribute("href");

      // check if using a predefined link
      const matchedPredefinedLink = Object.entries(
        PredefinedLink
      ).find(([_, value]) => value === url);
      if (matchedPredefinedLink) {
        linkType = LinkType.Predefined;
        predefinedLink = PredefinedLink[matchedPredefinedLink[0]];
      }

      // check if using email
      const emailMatch = url.match(/^mailto:([^?]*)/);
      if (emailMatch) {
        linkType = LinkType["Email Address"];
        emailAddress = emailMatch[1];
        const queryString = url.split("?")[1];
        const params = new URLSearchParams(queryString);
        emailSubject = params.get("subject");
        emailContent = params.get("body");
      }

      // check if using an attribute
      const attributeMatch = url.match(
        /^actions\/redirect\.aspx\?url=\$\$attribute:([^-$]*)-\$/
      );
      if (attributeMatch) {
        linkType = LinkType.Attribute;
        attribute = attributeMatch[1];
      }
    } else {
      text = editorRef.current.selection.getContent({
        format: "text",
      });
    }

    return {
      attribute: attribute,
      emailAddress: emailAddress,
      emailContent: emailContent,
      emailSubject: emailSubject,
      linkType: linkType,
      openInNewTab: openInNewTab,
      predefinedLink: predefinedLink,
      text: text,
      url: url,
    };
  },

  validate: (values) => {
    const errors = {};

    const {
      attribute,
      emailAddress,
      linkType,
      predefinedLink,
      text,
      url,
    } = values;

    if (!linkType) {
      errors["linkType"] = "Required";
    }

    if (linkType === LinkType["Web Address"]) {
      if (!url) {
        errors["url"] = "Required";
      }
    }

    if (linkType === LinkType["Email Address"]) {
      if (!emailAddress) {
        errors["emailAddress"] = "Required";
      }
    }

    if (linkType === LinkType.Predefined) {
      if (!predefinedLink) {
        errors["predefinedLink"] = "Required";
      }
    }

    if (linkType === LinkType.Attribute) {
      if (!attribute) {
        errors["attribute"] = "Required";
      }
    }

    if (!text) {
      errors["text"] = "Required";
    }

    return errors;
  },

  handleSubmit: async (values, { props }) => {
    const {
      attribute,
      emailAddress,
      emailContent,
      emailSubject,
      linkType,
      openInNewTab,
      predefinedLink,
      text,
      url,
    } = values;
    const { editorRef, editorState, setEditorState } = props;

    const href = getHref(
      attribute,
      emailAddress,
      emailContent,
      emailSubject,
      linkType,
      predefinedLink,
      url
    );

    const targetAttr = openInNewTab ? ' target="_blank"' : "";
    const linkHtml = `<a href="${href}"${targetAttr}>${text}</a>`;

    const editor = editorRef.current;
    const selectedNode = editor.selection.getNode();
    const selectedContent = editor.selection.getContent();

    if (selectedNode && selectedNode.nodeName === "A") {
      // Replace the existing anchor tag
      editor.dom.remove(selectedNode);
      editor.insertContent(linkHtml);
    } else {
      if (selectedContent) {
        // Replace the selected content with the new link
        editor.selection.setContent(linkHtml);
      } else {
        // Insert the link at the current cursor position
        editor.insertContent(linkHtml);
      }
    }

    setEditorState({
      ...editorState,
      addLink: false,
    });
  },
})(AddLink);

export default AddLinkWithFormik;
