import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { MembershipAccountFormStages } from "./membershipAccount";

export const MembershipAccountsFormStages = [
  {
    title: "Memberships Accounts",
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          useSubmitButton: true,
          fields: [
            {
              name: "g4m_name",
              className: "col-name",
              type: "string",
            },
            {
              name: "g4m_membershipaccountnumber",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4m_accountbalance",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_accountbalance_date",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4m_name",
              type: "text",
            },
            {
              name: "g4m_membershipaccountnumber",
              type: "text",
            },
            {
              name: "g4m_accountbalance_date",
              label: "Account Balance Last Updated On",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/membership-account",
          subFormStages: MembershipAccountFormStages,
          lookup: lookups.g4m_membershipaccount.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
