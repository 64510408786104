import classNames from "classnames";
import { BackgroundStyle } from "../../js/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CrossIcon } from "../Icons";
import "./Badge.scss";

function Badge({
  backgroundStyle = BackgroundStyle.Info,
  className,
  item,
  onDelete,
}) {
  const { text } = item;
  return (
    <span
      className={classNames(
        "badge",
        onDelete ? "cursor-pointer" : "",
        backgroundStyle,
        className
      )}
      onClick={() => (onDelete ? onDelete(item) : null)}
    >
      {text}
      {onDelete && (
        <FontAwesomeIcon className="ms-2" icon={CrossIcon} />
      )}
    </span>
  );
}

export default Badge;
