import {
  Aligns,
  FontFamilies,
  FontSizes,
  FontWeights,
  LineTypes,
  VerticalAligns,
} from "../../../enums";

const MembershipRenewalControlDefaultValues = [
  {
    Name: "showFooter",
    Value: true,
  },
  {
    Name: "showDirectDebitReference",
    Value: true,
  },
  {
    Name: "membership",
    Value: "Membership",
  },
  {
    Name: "price",
    Value: "Price",
  },
  {
    Name: "totalToBeDebited",
    Value: "Total to be debited",
  },
  {
    Name: "directDebitReferenceNumber",
    Value: "Direct Debit reference number:",
  },
  {
    Name: "backgroundColour",
    Value: "",
  },
  {
    Name: "margin",
    Value: "15px 5px",
  },
  {
    Name: "padding",
    Value: "0px",
  },
  {
    Name: "tableMargin",
    Value: "0px",
  },
  {
    Name: "tableMargin",
    Value: "0px",
  },
  {
    Name: "borderType",
    Value: String(LineTypes.None),
  },
  {
    Name: "borderThickness",
    Value: "0px",
  },
  {
    Name: "borderColour",
    Value: "",
  },
  {
    Name: "textColour",
    Value: "#333333",
  },
  {
    Name: "fontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "fontSize",
    Value: String(FontSizes["14px"]),
  },
  {
    Name: "fontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "textAlign",
    Value: String(Aligns.Center),
  },
  {
    Name: "headerBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "headerTextColour",
    Value: "#333333",
  },
  {
    Name: "headerFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "headerFontSize",
    Value: String(FontSizes["14px"]),
  },
  {
    Name: "headerFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "headerCellPadding",
    Value: "5px 5px",
  },
  {
    Name: "headerVerticalAlign",
    Value: String(VerticalAligns.Middle),
  },
  {
    Name: "rowBackgroundColour",
    Value: "#ffffff",
  },
  {
    Name: "rowCellPadding",
    Value: "5px 5px",
  },
  {
    Name: "rowLineType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "rowLineThickness",
    Value: "1px",
  },
  {
    Name: "rowLineColour",
    Value: "#333333",
  },
  {
    Name: "rowTextColour",
    Value: "#333333",
  },
  {
    Name: "rowFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "rowFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "rowFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "memberDetailsTextColour",
    Value: "#333333",
  },
  {
    Name: "memberDetailsFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "memberDetailsFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "memberDetailsFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "footerBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "footerTextColour",
    Value: "#333333",
  },
  {
    Name: "footerFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "footerFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "footerFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "footerCellPadding",
    Value: "5px 5px",
  },
  {
    Name: "footerVerticalAlign",
    Value: String(VerticalAligns.Middle),
  },
];

export default MembershipRenewalControlDefaultValues;
