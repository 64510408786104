import { useEffect, useState } from "react";
import classNames from "classnames";
import { Pagination } from "./_Elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTableColumnValue } from "../form/Helpers";

function RecordsTable({
  allowEdit,
  loadRemainingRecords,
  records,
  recordsPerPage,
  recordsTableColumns,
  removeColumnIcon,
  removeRecord,
  totalRecords,
  startPage,
}) {
  const [currentPage, setCurrentPage] = useState(
    startPage ? startPage : 1
  );
  const [currentRecords, setCurrentRecords] = useState([]);

  //In the event that we loaded up the first x amount of records but have paging for the
  //remaining records for an entity, we use this useEffect to trigger the loading of the
  //remaining records
  useEffect(() => {
    if (
      loadRemainingRecords &&
      currentPage > Math.ceil(records.length / recordsPerPage)
    ) {
      loadRemainingRecords(currentPage);
    }
  }, [currentPage, loadRemainingRecords, records, recordsPerPage]);

  return (
    <>
      <div className="list-view">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="table-info">
                {recordsTableColumns.map((r, i) => (
                  <th
                    key={i}
                    scope="col"
                    className={classNames(r.className)}
                  >
                    {r.headingName}
                  </th>
                ))}
                {allowEdit && (
                  <th
                    scope="col"
                    className={classNames("col-sequence")}
                  />
                )}
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((record, i) => {
                return (
                  <tr key={i}>
                    {recordsTableColumns.map((c, j) => (
                      <td key={j}>
                        {getTableColumnValue(c, record)}
                      </td>
                    ))}
                    {allowEdit && (
                      <td
                        className="text-center"
                        onClick={() => {
                          removeRecord(record);
                        }}
                      >
                        <FontAwesomeIcon
                          className="cursor-pointer"
                          icon={removeColumnIcon}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="d-flex justify-content-end py-3">
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          records={records}
          recordsPerPage={recordsPerPage}
          setRecords={setCurrentRecords}
          totalPages={
            totalRecords
              ? Math.ceil(totalRecords / recordsPerPage)
              : null
          }
        />
      </div>
    </>
  );
}

export default RecordsTable;
