import classNames from "classnames";
import { Alert, IconCard } from "../../elements/_Elements";
import { AlertStyle } from "../../../js/enums";
import {
  getCaptionForAudienceRule,
  getIconForAudienceRule,
} from "../communicationAudience/Helpers";

function ReadOnlyRulesAndCount({ state }) {
  const { final_count, rule_data } = state;

  return (
    <>
      {rule_data ? (
        rule_data.map((rule, index) => {
          const { icon, iconClassName } = getIconForAudienceRule(
            rule.Type
          );
          const isMissingRule =
            state &&
            state.rule_data &&
            state.rule_data[index].IsMissing;

          return (
            <IconCard
              key={index}
              className={
                isMissingRule ? "border border-danger mb-3" : "mb-3"
              }
              icon={icon}
              iconClassName={classNames(
                "rounded-start",
                "text-white",
                isMissingRule ? "bg-danger" : iconClassName
              )}
            >
              <span>
                {getCaptionForAudienceRule(index + 1, rule)}
              </span>
            </IconCard>
          );
        })
      ) : (
        <></>
      )}
      <Alert
        className="text-center m-0"
        style={AlertStyle.Info}
        text={`Final count: ${final_count}`}
      />
    </>
  );
}

export default ReadOnlyRulesAndCount;
