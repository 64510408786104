import { Fragment } from "react";
import RowLabel from "./RowLabel";
import Seat from "./Seat";
import { getBlockDimensions } from "./Helpers";

function Block({
  seatClasses,
  seatData,
  selectSeat,
  setFieldValue,
  setSeatData,
}) {
  const { rows, seats } = seatData;
  const { height, width } = getBlockDimensions(rows, seats);

  return (
    <div
      className="position-relative"
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      {Array(rows)
        .fill()
        .map((_, rowIndex) => {
          return (
            <Fragment key={rowIndex}>
              {Array(seats)
                .fill()
                .map((_, seatIndex) => {
                  return (
                    <Seat
                      key={`${rowIndex}-${seatIndex}`}
                      {...{
                        rowIndex,
                        seatClasses,
                        seatData,
                        seatIndex,
                        selectSeat,
                      }}
                    />
                  );
                })}
              <RowLabel
                {...{
                  rowIndex,
                  seatData,
                  setFieldValue,
                  setSeatData,
                }}
              />
            </Fragment>
          );
        })}
    </div>
  );
}

export default Block;
