import { isSameOrLaterDate } from "../js/utility";
import lookups from "./lookups";
import { CouponCategoryFormStages } from "./couponCategory";
import { CouponProductFormStages } from "./couponProduct";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const CouponTypeFormStages = [
  {
    title: "Coupon Type",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            className: "col-md-6",
            description: "",
            label: "Third Party Code",
            type: "nvarchar",
          },
          {
            name: "g4b_notvaliduntildate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description:
              "The coupon cannot be redeemed before this date. If a value for this is set then this value will be used over the value set for Not Valid by Days",
            label: "Not Valid Until Date",
            type: "datetime",
          },
          {
            name: "g4b_notvaliduntildays",
            className: "col-md-6",
            description:
              "The coupon cannot be redeemed until this number of days after the sale.",
            label: "Not Valid by Days",
            type: "int",
          },
          {
            name: "g4b_redeembydate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description:
              "The date the coupon must be redeemed by. If a value for this is set then this value will be used over the value set for Redeem by Days",
            filterDate: (date, values) => {
              return values.g4b_notvaliduntildate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_notvaliduntildate)
                  )
                : true;
            },
            label: "Redeem by Date",
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_notvaliduntildate
                ? isSameOrLaterDate(
                    date,
                    values.g4b_notvaliduntildate
                  )
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4b_redeembydays",
            className: "col-md-6",
            description:
              "The number of days after sale in which the coupon must be redeemed.",
            label: "Redeem by Days",
            type: "int",
          },
          {
            name: "g4b_usebydate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description:
              "The coupon will only be valid for sessions prior to this date. If a value for this is set then this value will be used over the value set for Use by Days",
            filterDate: (date, values) => {
              return (
                (values.g4b_notvaliduntildate
                  ? isSameOrLaterDate(
                      date,
                      new Date(values.g4b_notvaliduntildate)
                    )
                  : true) &&
                (values.g4b_redeembydate
                  ? isSameOrLaterDate(
                      date,
                      new Date(values.g4b_redeembydate)
                    )
                  : true)
              );
            },
            label: "Use by Date",
            type: "datetime",
            validate: (date, values) => {
              const isValid =
                (values.g4b_notvaliduntildate
                  ? isSameOrLaterDate(
                      date,
                      values.g4b_notvaliduntildate
                    )
                  : true) &&
                (values.g4b_redeembydate
                  ? isSameOrLaterDate(date, values.g4b_redeembydate)
                  : true);
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4b_usebydays",
            className: "col-md-6",
            description:
              "The coupon will only be valid for sessions prior to this number of days after the sale date.",
            label: "Use by Days",
            type: "int",
          },
          {
            name: "g4b_collectcoupon",
            className: "col-md-6",
            default: false,
            description:
              "If Yes, the Epos operator will be prompted to collect the coupon from the customer when the booking is made or when tickets are collected.",
            label: "Collect Coupon",
            type: "bit",
          },
          {
            name: "g4b_preexisting",
            className: "col-md-6",
            default: false,
            description: "",
            label: "Code 1 Pre-Existing",
            type: "bit",
          },
          {
            name: "g4b_quantity",
            className: "col-md-6",
            description: "",
            label: "Quantity",
            type: "int",
          },
          {
            name: "g4b_code1regex",
            className: "col-md-6",
            description: "",
            label: "Code Regex 1",
            type: "nvarchar",
          },
          {
            name: "g4b_description",
            className: "col-12",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            allowAdd: true,
            allowEdit: true,
            className: "col-12",
            name: "g4b_couponcategoryid",
            description: "",
            label: "Coupon Category",
            lookup: lookups.g4b_couponcategory.all,
            subFormStages: CouponCategoryFormStages,
            type: "lookup",
          },
        ],
      },
      {
        name: "Coupon Products",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_additionalcost",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4b_coupontypeid",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/coupon-product",
          linkedEntityId: "g4b_coupontypeid",
          loadStateRelatedEntityName: "g4b_couponproduct",
          lookup: lookups.g4b_couponproduct.all,
          subFormStages: CouponProductFormStages,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
];
