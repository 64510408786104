import {
  handleFilteredPicklistChange,
  isNonEmptyString,
} from "../Helpers";
import Parameters from "./Parameters";

const RuleFormStages = [
  {
    title: "",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "showAdvancedRules",
            description: "",
            label: "Show Advanced Rules",
            type: "bit",
          },
          {
            name: "category",
            description: "",
            handleChange: (
              event,
              handleChange,
              fieldName,
              values
            ) => {
              handleChange({
                event,
                target: {
                  name: "ruleId",
                  value: "",
                },
              });

              handleFilteredPicklistChange(
                event,
                handleChange,
                fieldName,
                values
              );
            },
            label: "Category",
            mandatory: true,
            mapOptions: (_, state, values) => {
              const rules = values.rules.filter(
                (r) =>
                  r.Entity === state.g4c_entitytype &&
                  (values.showAdvancedRules ||
                    r.Class.toLowerCase() !== "advanced")
              );

              const categories = [
                ...new Set(rules.map((x) => x.Category)),
              ].sort((a, b) => a.localeCompare(b));

              return categories.map((x) => ({
                Key: x,
                Value: x,
              }));
            },
            type: "lookup",
          },
          {
            name: "ruleId",
            description: "",
            disabledIfFromValues: (values) =>
              !isNonEmptyString(values.category),
            handleChange: (
              event,
              handleChange,
              fieldName,
              values
            ) => {
              handleFilteredPicklistChange(
                event,
                handleChange,
                fieldName,
                values
              );
            },
            label: "Rule",
            mandatory: true,
            mapOptions: (_, state, values) => {
              const { category, rules, showAdvancedRules } = values;
              // check a category has been selected
              if (!category) {
                return [];
              }

              // filter the rules for the category
              const categoryRules = rules.filter(
                (r) =>
                  r.Category === category &&
                  r.Entity === state.g4c_entitytype &&
                  (showAdvancedRules ||
                    r.Class.toLowerCase() !== "advanced")
              );
              return categoryRules.map((x) => ({
                Key: x.Id,
                Value: x.Caption,
              }));
            },
            type: "lookup",
          },
          {
            name: "parameters",
            displayIfFromValues: (values) =>
              isNonEmptyString(values.category) &&
              isNonEmptyString(values.ruleId),
            component: Parameters,
            type: "component",
          },
        ],
      },
    ],
  },
];

export default RuleFormStages;
