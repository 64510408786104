import { useEffect, useState } from "react";
import { GlobalDispatchMethods } from "../../../js/enums";
import { GetEntityRecordById } from "../../../js/service";
import { MagnifyIcon, MinusIcon, PlusIcon } from "../../Icons";
import { DispatchMethods } from "../enums";
import { DragType } from "./Enums";
import moment from "moment";
import lookups from "../../../forms/lookups";

const applyRuleChanges = (dispatch, rulesData, state, values) => {
  const { editRule, ruleId, rule_data } = values;
  const { index } = editRule;

  // check a rule has been selected
  if (!ruleId) {
    console.log("No rule selected");
    return;
  }

  // match to the rule
  const ruleData = rulesData.find((r) => r.Id === ruleId);
  if (!ruleData) {
    throw new Error("Invalid rule");
  }

  // check that all parameters have a value
  const parameters = ruleData.Parameters;
  const invalidParameters = parameters.filter(
    (parameter) => !values.parameters[parameter.Name]
  );
  if (invalidParameters.length > 0) {
    console.log("Parameter without a value");
    return;
  }

  // update the rule
  const rule = rule_data[index];
  rule.Id = ruleData.Id;
  rule.Caption = ruleData.Caption;

  const re = new RegExp("(.*?)\\{\\d:\\[(.*?)\\]\\}", "g");
  let parameterIndex = 0;
  parameters.forEach((parameter) => {
    switch (parameter.DataType.toLowerCase()) {
      case "datetime":
        const date = moment(values.parameters[parameter.Name]);
        parameter.Value = date.format("yyyy-MM-DDTHH:mm:ss");
        break;
      default:
        parameter.Value = values.parameters[parameter.Name];
        break;
    }

    switch (parameter.DataType.toLowerCase()) {
      case "entity":
        const lookup = getLookupNameForEntity(parameter.EntityName);
        const lookupOptions = state.lookupOptions.find(
          (x) => x.name === lookup.name
        );
        const lookupValue = lookupOptions.data.find(
          (x) => x.Key === parameter.Value
        );
        parameter.Caption = lookupValue.Value;
        break;
      default:
        const matches = rule.Caption.match(re);
        parameter.Caption = matches
          ? matches[parameterIndex].replace(re, "$1")
          : "";
        break;
    }
    parameterIndex++;
  });
  rule.Parameters = parameters;

  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      rule_data: rule_data,
      editRule: "",
    },
  });
};

const cancelRuleChanges = (dispatch, values) => {
  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      editRule: "",
    },
  });
};

const deleteRule = (globalDispatch, index, setFieldValue, values) => {
  // prevent if a rule is being edited
  if (values.editRule) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot delete while a rule is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  setFieldValue(
    "rule_data",
    values.rule_data.filter((_, i) => i !== index)
  );
};

const getCaptionForAudienceRule = (index, rule) => {
  const { Caption, Parameters, Type } = rule;
  let captionType = Type === "Evaluate" ? "KEEP" : Type.toUpperCase();
  const summary = [];

  if (Parameters.length > 0) {
    const re = new RegExp("\\{\\d:\\[(.*?)\\]\\}", "g");
    const captionArray = [];
    let lastIndex = 0;
    let index = 0;

    Caption.replace(re, (match, p1, offset) => {
      captionArray.push(Caption.substring(lastIndex, offset));
      const ruleParameter = Parameters[index];

      if (ruleParameter.DataType.toLowerCase() === "entity") {
        captionArray.push(ruleParameter.Caption);
      } else {
        captionArray.push(ruleParameter.Value);
      }

      lastIndex = offset + match.length;
      index++;
    });

    captionArray.push(Caption.substring(lastIndex));
    summary.push(captionArray.join(""));
  } else {
    summary.push(Caption);
  }
  let caption = `${index}. ${captionType} ${summary.join("")}`;
  return rule.IsMissing ? caption + " : ---MISSING RULE---" : caption;
};

const getIconForAudienceRule = (type) => {
  let icon, iconClassName;

  switch (type) {
    case "Evaluate":
      icon = MagnifyIcon;
      iconClassName = "bg-warning";
      break;
    case "Remove":
      icon = MinusIcon;
      iconClassName = "bg-danger";
      break;
    default:
      icon = PlusIcon;
      iconClassName = "bg-success";
  }

  return { icon, iconClassName };
};

const getLookupNameForEntity = (entityName) => {
  switch (entityName) {
    case "g4b_analysiscategory":
      return lookups.g4b_analysiscategory.all;
    case "g4b_channel":
      return lookups.g4b_channel.all;
    case "g4b_fixture":
      return lookups.g4b_fixture.all;
    case "g4b_productbase":
      return lookups.g4b_productbase.all;
    case "g4b_protoproduct":
      return lookups.g4b_protoproduct.all;
    case "g4b_series":
      return lookups.g4b_series.all;
    case "g4c_communication":
      return lookups.g4c_communication.all;
    case "list":
      return lookups.list.all;
    default:
      throw new Error("Unsupported entity");
  }
};

const handleDragEnd = (event, setFieldValue, values) => {
  const { active, over } = event;

  if (!active || !over) {
    return;
  }

  const dragType = getDragType(active.id);
  if (dragType === null) {
    return;
  }

  // deep copy the rules to avoid changing the original value
  let ruleData = [];
  const rulesCopy = JSON.parse(JSON.stringify(values.rule_data));

  switch (dragType) {
    case DragType.NewRule:
      ruleData = handleNewRule(rulesCopy, active.id, over.id);
      break;
    case DragType.MoveRule:
      ruleData = handleMoveRule(rulesCopy, active.id, over.id);
      break;
    default:
      break;
  }

  if (ruleData === null || ruleData.length === 0) {
    return;
  }

  // update the rules value
  setFieldValue("rule_data", ruleData);
};

const getDragType = (activeId) => {
  return activeId.indexOf("moverule") === 0
    ? DragType.MoveRule
    : DragType.NewRule;
};

const handleNewRule = (result, activeId, overId) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  if (overIdentifiers.length !== 2) {
    return null;
  }

  const index = parseInt(overIdentifiers[1]);

  const newRule = {
    Caption: "",
    Category: "",
    Class: "",
    Entity: "",
    Id: "",
    Name: "",
    Parameters: [],
    RunAt: "crm",
    Type:
      activeId === "add-rule"
        ? "Add"
        : activeId === "keep-rule"
        ? "Evaluate"
        : "Remove",
  };

  if (!result) {
    result = [newRule];
  } else {
    // add the new rule
    result.splice(index, 0, newRule);
  }

  return result;
};

const handleMoveRule = (result, activeId, overId) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  if (overIdentifiers.length !== 2) {
    return null;
  }

  const index = parseInt(overIdentifiers[1]);

  // rule being moved
  const activeIdentifiers = activeId.split("-");
  const ruleIndex = parseInt(activeIdentifiers[1]);

  // remember the rule being removed
  const rule = result[ruleIndex];

  // remove the rule
  result.splice(ruleIndex, 1);

  // add the rule back in
  const newRuleIndex = ruleIndex < index ? index - 1 : index;
  result.splice(newRuleIndex, 0, rule);

  return result;
};

const setEditRule = (
  dispatch,
  globalDispatch,
  index,
  rule,
  rulesData,
  values
) => {
  // prevent if a rule is being edited
  if (values.editRule) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot edit while a rule is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  if (!rule) {
    return;
  }

  // match to the rule data
  const ruleData = rulesData.find((r) => r.Id === rule.Id);

  // find all possible parameter names
  const parameterNames = [
    ...new Set(
      rulesData.flatMap((rule) =>
        rule.Parameters.map((param) => param.Name)
      )
    ),
  ];

  // create an object of parameters and map the current values
  const parameters = {};
  parameterNames.forEach((name) => {
    const parameter = rule.Parameters.find((p) => p.Name === name);
    if (parameter) {
      if (parameter.DataType.toLowerCase() === "datetime") {
        parameters[name] = moment(parameter.Value).toDate();
      } else {
        parameters[name] = parameter.Value;
      }
    } else {
      parameters[name] = "";
    }
  });

  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      category: ruleData ? ruleData.Category : "",
      editRule: {
        index: index,
      },
      parameters: parameters,
      ruleId: rule.Id,
      showAdvancedRules: ruleData
        ? ruleData.Class.toLowerCase() === "advanced"
        : "",
    },
  });
};

const useCommunicationAudience = (
  globalDispatch,
  setFieldValue,
  values
) => {
  const hasRules =
    values.rule_data != null && values.final_count != null;

  const [loading, setLoading] = useState(!hasRules);
  const [formData, setFormData] = useState(
    hasRules
      ? {
          rules: values.rule_data,
          finalCount: values.final_count,
        }
      : null
  );

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const [serviceResponse] = await Promise.all([
          GetEntityRecordById(
            globalDispatch,
            "g4c_communicationaudience",
            id
          ),
        ]);
        let rulesData = [];
        let finalCount = null;

        if (
          serviceResponse &&
          serviceResponse.data &&
          serviceResponse.data.Fields
        ) {
          rulesData = serviceResponse.data.Fields["rule_data"]
            ? serviceResponse.data.Fields["rule_data"]
            : [];
          finalCount = serviceResponse.data.Fields["final_count"]
            ? serviceResponse.data.Fields["final_count"]
            : null;
        }
        setFormData({
          rules: rulesData,
          finalCount: finalCount,
        });
        setFieldValue("finalCount", finalCount);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (values.g4c_audienceid) {
      setLoading(true);
      fetchData(values.g4c_audienceid);
    }
  }, [globalDispatch, setFieldValue, values.g4c_audienceid]);

  return { loading, formData };
};

export {
  applyRuleChanges,
  cancelRuleChanges,
  deleteRule,
  getCaptionForAudienceRule,
  getIconForAudienceRule,
  getLookupNameForEntity,
  handleDragEnd,
  setEditRule,
  useCommunicationAudience,
};
