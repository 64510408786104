import AddRecordButton from "./AddRecordButton";
import EditStatusButton from "./EditStatusButton";

function FormTableSubmitActions({
  filters,
  isSubmitting,
  parentId,
  recordsSelected,
  recordStatusToShow,
  setShowConfirmStatusChangeModal,
  statusEditAllowed,
  table,
  toggleAllowAdd,
}) {
  return (
    <div className="d-flex justify-content-end border-top mt-3 pt-3">
      {statusEditAllowed && (
        <EditStatusButton
          {...{
            isSubmitting,
            recordsSelected,
            recordStatusToShow,
            setShowConfirmStatusChangeModal,
          }}
        />
      )}
      {toggleAllowAdd && (
        <AddRecordButton {...{ filters, parentId, table }} />
      )}
    </div>
  );
}

export default FormTableSubmitActions;
