import classNames from "classnames";

function DividerControl({ properties }) {
  const {
    backgroundColour,
    borderColour,
    borderThickness,
    borderType,
    margin,
    padding,
    showOnMobile,
  } = properties;

  return (
    <table
      className={classNames(
        "full-width zero-margin",
        showOnMobile ? "" : "hide-for-small"
      )}
    >
      <tbody>
        <tr>
          <td style={{ padding: margin }}>
            <table
              border="0"
              cellSpacing="0"
              cellPadding="0"
              style={{ width: "100%", margin: "0", padding: "0" }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      margin: "0",
                      backgroundColor: backgroundColour,
                      padding: padding,
                    }}
                  >
                    <p
                      style={{
                        clear: "both",
                        lineHeight: "0px",
                        height: "0px",
                        fontSize: "0px",
                        margin: "0",
                        padding: "0",
                        borderBottomStyle: borderType,
                        borderBottomWidth: borderThickness,
                        borderBottomColor: borderColour,
                      }}
                    >
                      &nbsp;
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default DividerControl;
