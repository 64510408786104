function Donation({ properties }) {
  const {
    giftAidBackgroundColour,
    giftAidBorderColour,
    giftAidBorderThickness,
    giftAidBorderType,
    giftAidFontFamily,
    giftAidFontSize,
    giftAidFontWeight,
    giftAidHeader,
    giftAidMargin,
    giftAidPadding,
    giftAidTextColour,
  } = properties;

  return (
    <div
      style={{ display: "block" }}
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='Donation'"
    >
      <table
        className="full-width zero-margin"
        cellSpacing="0"
        data-xsl-wrap="xsl:if"
        data-xsl-attributes="test=&quot;format-number(Donation/Value, '#,##0.00', 'Money') {HtmlEncode:>} 0&quot;"
      >
        <tbody>
          <tr>
            <td style={{ padding: giftAidMargin }}>
              <table
                className="full-width zero-margin"
                cellSpacing="0"
                style={{
                  borderColor: giftAidBorderColour,
                  borderStyle: giftAidBorderType,
                  borderWidth: giftAidBorderThickness,
                }}
              >
                <tbody>
                  <tr
                    style={{
                      backgroundColor: giftAidBackgroundColour,
                    }}
                  >
                    <td
                      style={{
                        color: giftAidTextColour,
                        fontFamily: giftAidFontFamily,
                        fontSize: giftAidFontSize,
                        fontWeight: giftAidFontWeight,
                        padding: giftAidPadding,
                      }}
                    >
                      <p style={{ fontSize: "20px" }}>
                        {giftAidHeader}
                      </p>
                      <p>
                        This is a written record of your written,
                        verbal or electronic Gift Aid declaration, in
                        which you stated the{" "}
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="Donation/Charity" />',
                          }}
                        ></span>
                        <span className="remove-item">Charity</span>{" "}
                        could treat the value of your donation,{" "}
                        <span
                          className="hide-on-designer"
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="g4:FormatCurrency(Donation/Value, CurrencyCode)" />',
                          }}
                        ></span>
                        <span className="remove-item">£50.00</span>,
                        paid by you today, on behalf of you and/or
                        your family group, as a donation for Gift Aid
                        purposes.
                      </p>
                      <p>
                        By agreeing to Gift Aid you are confirming
                        that you are a UK taxpayer and if you pay less
                        Income Tax and/or Capital Gains Tax than the
                        amount of Gift Aid claimed on all your
                        donations in this tax year (6 April to 5
                        April) it is your responsibility to pay any
                        difference. Please notify us if you want to
                        cancel this declaration; change your name or
                        home address or no longer pay sufficient tax
                        on your income and/or capital gains. If you
                        pay Income Tax at the higher or additional
                        rate and want to receive the additional tax
                        relief due to you, you must include all your
                        Gift Aid donations on your Self-Assessment tax
                        return or ask HM Revenue and Customs to adjust
                        your tax code.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Donation;
