import { Fragment, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useWindowResize } from "../../../js/utility";
import {
  ButtonStyle,
  GridBreakpoints,
  ProductType,
} from "../../../js/enums";
import lookups from "../../../forms/lookups";
import {
  Button,
  Card,
  CloseButton,
  Input,
  Loading,
  Modal,
  PaginationArrows,
  PastYearsSelectHeader,
  SelectWithFiltering,
} from "../../elements/_Elements";
import {
  addToSessionStorage,
  getFromSessionStorage,
  getLookupByName,
  usePeakPricing,
} from "../Helpers";
import VariantPriceListItem from "./VariantPriceListItem";
import FormTable from "../FormTable";
import { MembershipPaymentPlanFormStages } from "../../../forms/membershipPaymentPlan";
import { GetAllEntityDetailedInfo } from "../../../js/service";
import { useGlobalReducer } from "../../../GlobalContext";
import "./ProductVariants.scss";
import {
  handlePeakPriceChange,
  handleProductVariantChange,
  handleOffPeakPriceChange,
  handleVariantPriceListChange,
} from "./Helpers";

function ProductVariants({
  dispatch,
  disabled,
  errors,
  handleChange,
  name,
  setSubForm,
  state,
  value,
  values,
}) {
  const globalDispatch = useGlobalReducer();

  const [
    loadingMembershipPaymentPlans,
    setLoadingMembershipPaymentPlans,
  ] = useState(true);
  const [membershipPaymentPlans, setMembershipPaymentPlans] =
    useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const productType = Number(state.g4b_type);
  const usePeakPrice = usePeakPricing(productType);

  const variantPriceListIds = useMemo(() => {
    return value
      ? Array.from(
          new Set(
            value.flatMap((productVariant) =>
              productVariant.PriceListItems.flatMap(
                (obj) => obj.VariantPriceListId
              )
            )
          )
        )
      : [];
  }, [value]);

  const variantTypesLookup = getLookupByName(
    lookups.g4b_varianttype.all,
    state.lookupOptions
  );

  const taxCodesLookup = getLookupByName(
    lookups.g4b_taxcode.all,
    state.lookupOptions
  );

  const variantPriceListsLookup = getLookupByName(
    lookups.g4b_variantpricelist.all,
    state.lookupOptions
  );

  const marketingListsLookup = getLookupByName(
    lookups.list.all,
    state.lookupOptions
  );

  const variantPriceLists = useMemo(() => {
    return variantPriceListsLookup
      ? variantPriceListsLookup.data.filter((d) =>
          variantPriceListIds.some((v) => v === d.Key)
        )
      : [];
  }, [variantPriceListsLookup, variantPriceListIds]);

  useEffect(() => {
    setProductVariants(
      values && values.productVariants && variantTypesLookup
        ? values.productVariants.map((productVariant) => {
            const variantType = variantTypesLookup.data.find(
              (type) => type.Key === productVariant.VariantTypeId
            );
            return {
              ...productVariant,
              VariantTypeName: variantType ? variantType.Value : "",
            };
          })
        : []
    );
  }, [values, variantTypesLookup]);

  // prefetch membership payment plans for membership product types
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetAllEntityDetailedInfo(
            globalDispatch,
            "g4m_membershippaymentplan",
            false
          ),
        ]);
        if (serviceResponse && serviceResponse.data) {
          // Order the returned records by name
          const serviceData = serviceResponse.data.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
          addToSessionStorage(
            "g4m_membershippaymentplan-Active",
            serviceData
          );
          setMembershipPaymentPlans(serviceData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMembershipPaymentPlans(false);
      }
    };

    if (productType === ProductType.Membership) {
      const data = getFromSessionStorage("g4m_membershippaymentplan");
      if (!data) {
        fetchData();
      } else {
        setMembershipPaymentPlans(data);
        setLoadingMembershipPaymentPlans(false);
      }
    } else {
      setLoadingMembershipPaymentPlans(false);
    }
  }, [globalDispatch, productType]);

  const availableVariantPriceLists = variantPriceListsLookup
    ? variantPriceListsLookup.data.filter(
        (d) => !variantPriceListIds.some((v) => v === d.Key)
      )
    : [];

  const [priceListIndex, setPriceListIndex] = useState(0);
  const [expandedVariantTypes, setExpandedVariantTypes] = useState(
    []
  );
  const [variantTypeToRemove, setVariantTypeToRemove] =
    useState(null);

  // show 4 pricelists for larger devices and 1 for smaller
  const windowSize = useWindowResize();
  const priceListsToShow =
    windowSize.width >= GridBreakpoints.md ? 2 : 1;

  const toggleVariantType = (variantTypeId) => {
    const updatedVariants = expandedVariantTypes.includes(
      variantTypeId
    )
      ? expandedVariantTypes.filter((id) => id !== variantTypeId)
      : [...expandedVariantTypes, variantTypeId];

    // update the expanded variant types
    setExpandedVariantTypes(updatedVariants);
  };

  const handleVariantTypeRemove = (
    productVariantId,
    variantTypeId
  ) => {
    // Check whether to show a warning
    // For memberships, check for associated payment plans, else the selected pricing detail has at least one peak or off-peak price set
    const showWarning =
      productType === ProductType.Membership
        ? membershipPaymentPlans.some(
            (x) =>
              x.Fields &&
              x.Fields["g4m_productvariantid"] &&
              x.Fields["g4m_productvariantid"].Id === productVariantId
          )
        : value.some(
            (productVariant) =>
              productVariant.VariantTypeId === variantTypeId &&
              productVariant.PriceListItems.some(
                (priceListItem) =>
                  priceListItem.OffPeakPrice ||
                  priceListItem.PeakPrice
              )
          );
    //If so then display a confirm alert box to prompt the user to remove the pricing
    if (showWarning) {
      setVariantTypeToRemove(variantTypeId);
    } else {
      removeVariantType(variantTypeId);
    }
  };

  const removeVariantType = (variantTypeId) => {
    setVariantTypeToRemove(null);
    handleChange({
      target: {
        name: name,
        value: value.filter(
          (productVariant) =>
            productVariant.VariantTypeId !== variantTypeId
        ),
      },
    });
  };

  useEffect(() => {
    if (values.varianttype) {
      // add the new variant type
      let newValue = [...productVariants];
      newValue.push({
        Id: null,
        Code: "",
        MarketingListId: null,
        NotForSale: false,
        PriceListItems: variantPriceLists.map((variantPriceList) => {
          return {
            PeakPrice: "",
            OffPeakPrice: "",
            VariantPriceListId: variantPriceList.Key,
            VariantTypeId: values.varianttype,
          };
        }),
        TaxCodeId: null,
        VariantTypeId: values.varianttype,
      });

      handleChange({
        target: {
          name: name,
          value: newValue,
        },
      });

      // clear the variant type value
      handleChange({
        target: {
          name: "varianttype",
          value: "",
        },
      });
    }
  }, [
    handleChange,
    name,
    productVariants,
    values.varianttype,
    variantPriceLists,
  ]);

  // Wait for lookups to load. Memberships load membership payment plans instead of variant price lists
  if (
    !taxCodesLookup ||
    (productType === ProductType.Membership
      ? loadingMembershipPaymentPlans
      : !variantPriceListsLookup)
  ) {
    return <Loading />;
  }

  return (
    <>
      {variantTypeToRemove && (
        <Modal
          title={"Are you sure you want to remove this variant?"}
          modalCloseButtonClick={() => {
            setVariantTypeToRemove(null);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            {productType === ProductType.Membership ? (
              <small>
                There are membership payment plans currently set for
                this product variant
              </small>
            ) : (
              <small>
                There are prices currently set for this product
                variant
              </small>
            )}
          </div>
          <div className="modal-footer">
            <Button
              text={"Confirm"}
              style={ButtonStyle.Primary}
              onClick={() => {
                removeVariantType(variantTypeToRemove);
              }}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => {
                setVariantTypeToRemove(null);
              }}
            />
          </div>
        </Modal>
      )}
      <div className="row pb-2">
        <div className="col-3 col-md-2 d-flex align-items-center justify-content-end">
          <PaginationArrows
            index={priceListIndex}
            recordsToShow={priceListsToShow}
            setIndex={setPriceListIndex}
            totalRecords={variantPriceLists.length}
          />
        </div>
        {variantPriceLists
          .slice(priceListIndex, priceListIndex + priceListsToShow)
          .map((priceList, index) => {
            return (
              <Fragment key={index}>
                {priceList ? (
                  <div className="col-6 col-md-4 d-flex align-items-center justify-content-center text-center">
                    {priceList.Value}
                  </div>
                ) : (
                  <></>
                )}
              </Fragment>
            );
          })}
        <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
          {productVariants &&
            productVariants.length > 0 &&
            productType !== ProductType.Membership && (
              <SelectWithFiltering
                disabled={disabled}
                name="priceList"
                onChange={(e) =>
                  handleVariantPriceListChange(
                    e.value,
                    productVariants,
                    handleChange
                  )
                }
                options={availableVariantPriceLists}
                placeholder="Please select"
              />
            )}
        </div>
      </div>
      {productVariants &&
        productVariants
          .sort((a, b) =>
            a.VariantTypeName.localeCompare(b.VariantTypeName)
          )
          .map((productVariant) => {
            const { VariantTypeId, VariantTypeName } = productVariant;
            const hasMembershipPaymentPlans =
              productType === ProductType.Membership
                ? membershipPaymentPlans.some(
                    (x) =>
                      x.Fields &&
                      x.Fields["g4m_productvariantid"] &&
                      x.Fields["g4m_productvariantid"].Id ===
                        productVariant.Id
                  )
                : false;
            return (
              <Fragment key={VariantTypeId}>
                <div className="row align-items-center py-2 border-top border-bottom border-light">
                  <div
                    className={classNames(
                      "col-3 col-md-2 product-variant-type-name",
                      expandedVariantTypes.includes(VariantTypeId)
                        ? "active"
                        : ""
                    )}
                    onClick={() => toggleVariantType(VariantTypeId)}
                  >
                    {VariantTypeName}
                  </div>
                  {productType === ProductType.Membership ? (
                    <div className="col-6 col-md-8">
                      {!hasMembershipPaymentPlans && (
                        <strong>No payment plans set</strong>
                      )}
                    </div>
                  ) : (
                    variantPriceLists
                      .slice(
                        priceListIndex,
                        priceListIndex + priceListsToShow
                      )
                      .map((variantPriceList, index) => {
                        const variantPriceListId =
                          variantPriceList.Key;
                        const variantPriceListItem = variantPriceList
                          ? productVariant.PriceListItems.find(
                              (item) =>
                                item.VariantTypeId ===
                                  VariantTypeId &&
                                item.VariantPriceListId ===
                                  variantPriceListId
                            )
                          : null;

                        return (
                          <div
                            key={index}
                            className="col-6 col-md-4 d-flex align-items-center justify-content-center"
                          >
                            {variantPriceList && (
                              <VariantPriceListItem
                                disabled={disabled}
                                errors={errors}
                                handleOffPeakPriceChange={(
                                  offPeakPrice
                                ) =>
                                  handleOffPeakPriceChange(
                                    VariantTypeId,
                                    variantPriceListId,
                                    offPeakPrice,
                                    productVariants,
                                    usePeakPrice,
                                    handleChange
                                  )
                                }
                                handlePeakPriceChange={(peakPrice) =>
                                  handlePeakPriceChange(
                                    VariantTypeId,
                                    variantPriceListId,
                                    peakPrice,
                                    productVariants,
                                    handleChange
                                  )
                                }
                                usePeakPrice={usePeakPrice}
                                variantPriceListId={
                                  variantPriceListId
                                }
                                variantPriceListItem={
                                  variantPriceListItem
                                }
                                variantTypeId={VariantTypeId}
                              />
                            )}
                          </div>
                        );
                      })
                  )}
                  <div className="col-3 col-md-2 ms-auto">
                    <CloseButton
                      className="float-end"
                      disabled={disabled}
                      label="Close"
                      onClick={() => {
                        handleVariantTypeRemove(
                          productVariant.Id,
                          VariantTypeId
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className={classNames(
                    "row py-3 product-variant collapse",
                    expandedVariantTypes.includes(VariantTypeId)
                      ? "show"
                      : ""
                  )}
                >
                  <div className="col-md-3">
                    <Input
                      disabled={disabled}
                      label="Code"
                      name={VariantTypeId + "code"}
                      onChange={(event) =>
                        handleProductVariantChange(
                          "code",
                          event.target.value,
                          VariantTypeId,
                          productVariants,
                          handleChange
                        )
                      }
                      placeholder=""
                      type="text"
                      value={productVariant.Code}
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectWithFiltering
                      disabled={disabled}
                      label="Marketing List"
                      name={VariantTypeId + "marketingListId"}
                      onChange={(event) =>
                        handleProductVariantChange(
                          "marketingListId",
                          event ? event.value : null,
                          VariantTypeId,
                          productVariants,
                          handleChange
                        )
                      }
                      options={marketingListsLookup.data}
                      placeholder="Please select"
                      value={
                        productVariant.MarketingListId
                          ? productVariant.MarketingListId
                          : ""
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectWithFiltering
                      disabled={disabled}
                      label="Tax code"
                      name={VariantTypeId + "taxCodeId"}
                      options={taxCodesLookup.data}
                      placeholder="Please select"
                      onChange={(event) =>
                        handleProductVariantChange(
                          "taxCodeId",
                          event ? event.value : null,
                          VariantTypeId,
                          productVariants,
                          handleChange
                        )
                      }
                      value={
                        productVariant.TaxCodeId
                          ? productVariant.TaxCodeId
                          : ""
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      disabled={disabled}
                      name={VariantTypeId + "notForSale"}
                      label="Not for sale"
                      onChange={(event) =>
                        handleProductVariantChange(
                          "notForSale",
                          event.target.checked,
                          VariantTypeId,
                          productVariants,
                          handleChange
                        )
                      }
                      value={productVariant.NotForSale}
                      checked={productVariant.NotForSale}
                      type="bit"
                    />
                  </div>
                  {expandedVariantTypes.includes(VariantTypeId) &&
                    productType === ProductType.Membership && (
                      <>
                        {!productVariant.Id && (
                          <Fragment key={VariantTypeId}>
                            <div className="my-3">
                              <strong>
                                Please save this record to view
                                membership payment plans.
                              </strong>
                            </div>
                          </Fragment>
                        )}
                        {productVariant.Id && (
                          <div className="col-12">
                            <Card className="my-3">
                              <FormTable
                                {...{
                                  dispatch,
                                  setSubForm,
                                  state,
                                  values,
                                }}
                                disabled={disabled}
                                initialFilters={[
                                  {
                                    Key: "g4m_productvariantid",
                                    Value: productVariant.Id,
                                  },
                                ]}
                                parentId={productVariant.Id}
                                section={{
                                  table: {
                                    allowAdd: true,
                                    allowEdit: true,
                                    allowStatusEdit: true,
                                    fields: [
                                      {
                                        name: "g4m_membershiplife",
                                        className: "col-name",
                                      },
                                      {
                                        name: "g4m_membershipperiod",
                                        className: "col-name",
                                      },
                                      {
                                        name: "g4m_contractualperiod",
                                        className: "col-name",
                                      },
                                      {
                                        name: "g4m_sequence",
                                        className: "col-number",
                                        type: "string",
                                      },
                                      {
                                        name: "createdon",
                                        className: "col-date",
                                        type: "datetime",
                                      },
                                      {
                                        name: "modifiedon",
                                        className: "col-date",
                                        type: "datetime",
                                      },
                                    ],
                                    filterByStatus: true,
                                    filters: [
                                      {
                                        name: "createdon",
                                        renderCustomHeader:
                                          PastYearsSelectHeader,
                                        type: "datetime",
                                      },
                                      {
                                        name: "modifiedon",
                                        renderCustomHeader:
                                          PastYearsSelectHeader,
                                        type: "datetime",
                                      },
                                    ],
                                    linkedEntityId:
                                      "g4m_productvariantid",
                                    lookup:
                                      lookups
                                        .g4m_membershippaymentplan
                                        .all,
                                    subFormStages:
                                      MembershipPaymentPlanFormStages,
                                    useAlphabetPagination: false,
                                    useSubForm: true,
                                  },
                                }}
                              />
                            </Card>
                          </div>
                        )}
                      </>
                    )}
                </div>
              </Fragment>
            );
          })}
    </>
  );
}

export default ProductVariants;
