const StandardTicket = () => {
  return {
    LayoutName: "Ticket",
    Front: {
      Name: "front",
      Width: 200,
      Height: 100,
      LeftOffset: 0,
      TopOffset: 0,
      RightOffset: 0,
      BottomOffset: 0,
      BackgroundImage: null,
      DesignOnly: null,
      FontSize: 4.0,
      FontName: "Helvetica",
      ForegroundColour: "rgb(0,0,0)",
      BackgroundColour: "rgb(255,255,255)",
      InvertColours: false,
      Layers: [{ Position: 1, Fields: [] }],
    },
  };
};

export default StandardTicket;
