import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import SVGLayout from "../components/form/venue/venueConfiguration/SVGLayout";
import { AccessControlProviderFormStages } from "./accessControlProvider";
import lookups from "./lookups";
import { VenueFormStages } from "./venue";

export const VenueConfigurationFormStages = [
  {
    title: "General",
    id: "general",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_venueid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Venue",
            lookup: lookups.g4b_venue.all,
            required: true,
            subFormStages: VenueFormStages,
            type: "lookup",
          },
          {
            name: "g4b_accesscontrolproviderid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Primary access control provider",
            lookup: lookups.g4b_accesscontrolprovider.all,
            subFormStages: AccessControlProviderFormStages,
            type: "lookup",
          },
          {
            name: "g4b_allowprintathome",
            description: "",
            label: "Allow Print at Home",
            type: "bit",
          },
          {
            name: "g4b_printticketsforvenue",
            description: "",
            label: "Print Tickets for Venue",
            type: "bit",
          },
          {
            name: "primaryImage",
            className: "col-12",
            description: "",
            type: "image",
          },
        ],
      },
      {
        name: "Venue Configuration Blocks",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_bookableresourceidname",
              label: "Bookable Resource",
              className: "col-short-name",
            },
            {
              name: "g4b_blockidname",
              label: "Block",
              className: "col-short-name",
            },
            {
              name: "g4b_seatclassidname",
              label: "Seat Class",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: [
                "Name",
                "g4b_bookableresourceidname",
                "g4b_blockidname",
                "g4b_seatclassidname",
              ],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_venueconfigurationblock",
          lookup: lookups.g4b_venueconfigurationblock.all,
          linkForNewRecord: "/venue-configuration-block",
          linkedEntityId: "g4b_venueconfigurationid",
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
      {
        name: "SVG Layout",
        fields: [
          {
            name: "svg_layout",
            className: "col-12",
            component: SVGLayout,
            displayIfFromState: (state) => state.id,
            displayComponentOnOverview: true,
            type: "component",
          },
        ],
      },
    ],
  },
];
