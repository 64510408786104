import classNames from "classnames";
import { getSeatClassOptions } from "./Helpers";

function SeatClasses({
  seatClasses,
  selectedSeatClass,
  setSelectedSeatClass,
}) {
  return (
    <div className="card p-3">
      {getSeatClassOptions(seatClasses).map(
        (seatClass, seatClassIndex) => {
          const { Color, Id, Name } = seatClass;

          return (
            <div
              key={seatClassIndex}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <label className="form-label">{Name}</label>
              <div
                className={classNames(
                  "seat",
                  "m-0",
                  "border",
                  "border-3",
                  selectedSeatClass === Id ? "border-primary" : ""
                )}
                style={{ backgroundColor: Color }}
                onClick={() => setSelectedSeatClass(Id)}
              ></div>
            </div>
          );
        }
      )}
    </div>
  );
}

export default SeatClasses;
