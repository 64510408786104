import lookups from "./lookups";
import {
  DonationBatchHeaderStatus,
  DonationBatchLineStatus,
  DonationStatus,
} from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { DonationBatchLineFormStages } from "./donationBatchLine";

export const DonationBatchHeaderFormStages = [
  {
    title: "Donation Batch Header",
    id: "donation-batch-header",
    editOnOverview: true,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4d_name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4d_batchstatus",
            className: "col-md-4",
            label: "Batch Status",
            default: DonationBatchHeaderStatus.Pending,
            disabled: true,
            displayIfFromState: (state) => state.id,
            type: "picklist",
            enum: DonationBatchHeaderStatus,
          },
          {
            name: "g4d_batchrecordcount",
            className: "col-md-4",
            description: "",
            disabled: true,
            displayIfFromState: (state) => state.id,
            label: "Batch Status",
            type: "nvarchar",
          },
          {
            name: "g4d_batchreference",
            className: "col-md-4",
            description: "",
            disabled: true,
            displayIfFromState: (state) => state.id,
            label: "Batch Reference",
            type: "int",
          },
        ],
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4d_batchlinestatus",
              className: "col-short-name",
              label: "Batch Line Status",
              type: "statusbadge",
              enum: DonationBatchLineStatus,
            },
            {
              name: "g4d_firstname",
              className: "col-short-name",
              label: "First Name",
              type: "string",
            },
            {
              name: "g4d_lastname",
              className: "col-short-name",
              label: "Last Name",
              type: "string",
            },
            {
              name: "g4d_addressline1",
              className: "col-short-name",
              label: "Address Line",
              type: "string",
            },
            {
              name: "g4d_postcode",
              className: "col-short-name",
              label: "Address Line",
              type: "string",
            },
            {
              name: "g4d_donationvalue",
              className: "col-short-name",
              label: "Donation value",
              type: "money",
            },
            {
              name: "g4d_giftaidvalue",
              className: "col-short-name",
              label: "Gift Aid Value",
              type: "money",
            },
            {
              name: "g4d_addressline1",
              className: "col-short-name",
              label: "Donation Status",
              type: "statusbadge",
              enum: DonationStatus,
            },
            {
              name: "g4d_paymentdate",
              className: "col-date",
              label: "Payment Date",
              type: "datetime",
            },
            {
              name: "g4b_channelidname",
              className: "col-short-name",
              label: "Channel",
              type: "string",
            },
            {
              name: "g4d_donationidname",
              className: "col-short-name",
              label: "Donation",
              type: "string",
            },
            {
              name: "g4d_membershipnumber",
              className: "col-short-name",
              label: "Membership Number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4d_paymentdate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4d_paymentdate",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4d_donationbatchline",
          lookup: lookups.g4d_donationbatchline.all,
          subFormStages: DonationBatchLineFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
