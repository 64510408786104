import React, { useEffect, useState } from "react";
import {
  Button,
  FormCheckList,
  Input,
  Loading,
  Modal,
  SelectWithFiltering,
} from "../../elements/_Elements";
import { ButtonStyle, LoadingState } from "../../../js/enums";
import { Create, GetAllEntityBasicInfo } from "../../../js/service";
import {
  getAttributesForSave,
  getLookupInState,
  handleRedirect,
} from "../Helpers";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { getEntityNameAttribute } from "../../../js/utility";
import lookups from "../../../forms/lookups";

function ProductCopy({
  entityName,
  setDisplayRecordCopyModal,
  stages,
  state,
  values,
}) {
  const [isCopying, setIsCopying] = useState(false);
  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [categoryData, setCategoryData] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const [groupData, setGroupData] = useState(null);
  const [widgetGroupData, setWidgetGroupData] = useState(null);
  const [productGroupData, setProductGroupData] = useState(null);

  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(
    state.g4b_categoryid
  );
  const [selectedChannels, setSelectedChannels] = useState(
    state.g4b_channel || []
  );
  const [selectedGroups, setSelectedGroups] = useState(
    state.g4_group || []
  );
  const [selectedWidgetGroups, setSelectedWidgetGroups] = useState(
    state.g4b_widgetgroup || []
  );
  const [selectedProductGroups, setSelectedProductGroups] = useState(
    state.g4b_productgroup || []
  );

  const [newProductRecordName, setNewProductRecordName] =
    useState("");
  const [newProductRecordCode, setNewProductRecordCode] =
    useState("");
  const [
    newProductRecordDescription,
    setNewProductRecordDescription,
  ] = useState("");
  const [newProductRecordSequence, setNewProductRecordSequence] =
    useState("");
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryLookupOptions = getLookupInState(
          state,
          lookups.g4b_analysiscategory.all
        );
        const channelLookupOptions = getLookupInState(
          state,
          lookups.g4b_channel.all
        );
        const groupLookupOptions = getLookupInState(
          state,
          lookups.g4_group.widget
        );
        const widgetGroupLookupOptions = getLookupInState(
          state,
          lookups.g4b_widgetgroup.all
        );
        const productGroupLookupOptions = getLookupInState(
          state,
          lookups.g4b_productgroup.all
        );
        const [
          categoryResponse,
          channelResponse,
          groupResponse,
          widgetGroupResponse,
          productGroupResponse,
        ] = await Promise.all([
          categoryLookupOptions &&
          categoryLookupOptions.data &&
          categoryLookupOptions.data.length > 0
            ? categoryLookupOptions
            : GetAllEntityBasicInfo(
                globalDispatch,
                "g4b_analysiscategory",
                null
              ),
          channelLookupOptions &&
          channelLookupOptions.data &&
          channelLookupOptions.data.length > 0
            ? channelLookupOptions
            : GetAllEntityBasicInfo(
                globalDispatch,
                "g4b_channel",
                null
              ),
          groupLookupOptions &&
          groupLookupOptions.data &&
          groupLookupOptions.data.length > 0
            ? groupLookupOptions
            : GetAllEntityBasicInfo(globalDispatch, "g4_group", "22"),
          widgetGroupLookupOptions &&
          widgetGroupLookupOptions.data &&
          widgetGroupLookupOptions.data.length > 0
            ? widgetGroupLookupOptions
            : GetAllEntityBasicInfo(
                globalDispatch,
                "g4b_widgetgroup",
                null
              ),
          productGroupLookupOptions &&
          productGroupLookupOptions.data &&
          productGroupLookupOptions.data.length > 0
            ? productGroupLookupOptions
            : GetAllEntityBasicInfo(
                globalDispatch,
                "g4b_productgroup",
                null
              ),
        ]);
        setCategoryData(
          categoryResponse && categoryResponse.data
            ? categoryResponse.data
            : []
        );
        setChannelData(
          channelResponse && channelResponse.data
            ? channelResponse.data
            : []
        );
        setGroupData(
          groupResponse && groupResponse.data
            ? groupResponse.data
            : []
        );
        setWidgetGroupData(
          widgetGroupResponse && widgetGroupResponse.data
            ? widgetGroupResponse.data
            : []
        );
        setProductGroupData(
          productGroupResponse && productGroupResponse.data
            ? productGroupResponse.data
            : []
        );

        setLoading(LoadingState.Loaded);
        setInitialLoad(false);
      } catch (error) {
        console.error(error);
      }
    };

    if (initialLoad) {
      fetchData();
    }
  }, [globalDispatch, initialLoad, state]);

  const copyProductRecord = async () => {
    let response = null;
    setIsCopying(true);
    try {
      const successMessage = `${newProductRecordName} successfully created`;
      let attributes = getAttributesForSave(null, stages, {
        ...state,
        ...values,
      });
      let nameAttribute = getEntityNameAttribute(entityName);
      attributes[nameAttribute] = newProductRecordName;
      attributes["g4b_code"] = newProductRecordCode;
      attributes["g4b_description"] = newProductRecordDescription;
      attributes["g4b_sequence"] = newProductRecordSequence;
      attributes["g4b_categoryid"] = selectedCategory;
      attributes["g4b_channel"] = selectedChannels;
      attributes["g4_group"] = selectedGroups;
      attributes["g4b_widgetgroup"] = selectedWidgetGroups;
      attributes["g4b_productgroup"] = selectedProductGroups;
      attributes["g4b_reportname"] =
        newProductRecordName.toUpperCase();
      attributes["g4b_zone"] = [];
      attributes["productVariants"] = [];
      attributes["primaryImage"] = null;
      const [serviceResponse] = await Promise.all([
        Create(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: newProductRecordName,
            Attributes: attributes,
          },
          entityName
        ),
      ]);
      response = serviceResponse.data;
    } catch (error) {
      console.error(error);
    } finally {
      setIsCopying(false);
      if (response && response.Id) {
        // result is the id and we redirect to the page for it
        handleRedirect(null, dispatch, response.Id, "copyRecord");
      } else {
        console.error("Invalid response returned.");
      }
    }
  };

  return (
    <>
      <Modal
        title={"Copy Product Record"}
        modalCloseButtonClick={() => setDisplayRecordCopyModal(null)}
        className="modal modal-dialog-scrollable modal-fullscreen"
      >
        {loading !== LoadingState.Loaded ? (
          <Loading />
        ) : (
          <>
            <div className="modal-body">
              <small>
                {"Enter values for the new product record"}
              </small>
              <Input
                className={"mb-3"}
                label={"Name"}
                mandatory={true}
                name={"NewProductRecordName"}
                onChange={(event) =>
                  setNewProductRecordName(event.target.value)
                }
                value={newProductRecordName}
              />
              <div className="row mb-3">
                <div className="col-md-6">
                  <Input
                    label={"Code"}
                    name={"NewProductRecordCode"}
                    onChange={(event) =>
                      setNewProductRecordCode(event.target.value)
                    }
                    value={newProductRecordCode}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label={"Sequence"}
                    type={"int"}
                    name={"NewProductRecordSequence"}
                    onChange={(event) =>
                      setNewProductRecordSequence(event.target.value)
                    }
                    value={newProductRecordSequence}
                  />
                </div>
              </div>
              <Input
                className={"mb-3"}
                label={"Description"}
                name={"NewProductRecordDescription"}
                onChange={(event) =>
                  setNewProductRecordDescription(event.target.value)
                }
                value={newProductRecordDescription}
              />
              {categoryData && categoryData.length > 0 && (
                <SelectWithFiltering
                  className={"mb-3"}
                  label={"Category"}
                  mandatory={true}
                  name={"NewProductRecordCategory"}
                  options={categoryData}
                  onChange={(event) =>
                    setSelectedCategory(event.value)
                  }
                  value={selectedCategory}
                />
              )}
              {channelData && channelData.length > 0 && (
                <FormCheckList
                  className={"mb-3"}
                  label={"Sales channels"}
                  name={"NewProductRecordChannels"}
                  inline={true}
                  onChange={(event) =>
                    setSelectedChannels(
                      event.target.checked
                        ? [...selectedChannels, event.target.value]
                        : selectedChannels.filter(
                            (id) => id !== event.target.value
                          )
                    )
                  }
                  options={channelData}
                  value={selectedChannels}
                />
              )}
              {groupData && groupData.length > 0 && (
                <FormCheckList
                  className={"mb-3"}
                  label={"Groups"}
                  name={"NewProductRecordGroups"}
                  inline={true}
                  onChange={(event) =>
                    setSelectedGroups(
                      event.target.checked
                        ? [...selectedGroups, event.target.value]
                        : selectedGroups.filter(
                            (id) => id !== event.target.value
                          )
                    )
                  }
                  options={groupData}
                  value={selectedGroups}
                />
              )}
              {widgetGroupData && widgetGroupData.length > 0 && (
                <FormCheckList
                  className={"mb-3"}
                  label={"Widget Groups"}
                  name={"NewProductRecordWidgetGroups"}
                  inline={true}
                  onChange={(event) =>
                    setSelectedWidgetGroups(
                      event.target.checked
                        ? [
                            ...selectedWidgetGroups,
                            event.target.value,
                          ]
                        : selectedWidgetGroups.filter(
                            (id) => id !== event.target.value
                          )
                    )
                  }
                  options={widgetGroupData}
                  value={selectedWidgetGroups}
                />
              )}
              {productGroupData && productGroupData.length > 0 && (
                <FormCheckList
                  className={"mb-3"}
                  label={"Product Groups"}
                  name={"NewProductRecordProductGroups"}
                  inline={true}
                  onChange={(event) =>
                    setSelectedProductGroups(
                      event.target.checked
                        ? [
                            ...selectedProductGroups,
                            event.target.value,
                          ]
                        : selectedProductGroups.filter(
                            (id) => id !== event.target.value
                          )
                    )
                  }
                  options={productGroupData}
                  value={selectedProductGroups}
                />
              )}
            </div>
            <div className="modal-footer">
              <Button
                text={"Confirm"}
                style={ButtonStyle.Primary}
                onClick={() => copyProductRecord()}
                showSpinner={isCopying}
                disabled={
                  !newProductRecordName ||
                  newProductRecordName.trim() === "" ||
                  isCopying
                }
              />
              <Button
                text={"Cancel"}
                style={ButtonStyle.Info}
                onClick={() => setDisplayRecordCopyModal(null)}
                showSpinner={isCopying}
                disabled={isCopying}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default ProductCopy;
