import { useState } from "react";
import { useGlobalReducer } from "../../../GlobalContext";
import { FormField, Loading } from "../../elements/_Elements";
import { useFormReducer } from "../FormContext";
import {
  getFieldLabel,
  getLookupFieldOptions,
  useLookupOptions,
} from "../Helpers";
import { LoadingState } from "../../../js/enums";
import { getLookupNameForEntity } from "./Helpers";

function Parameters({
  handleBlur,
  handleChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  setFieldValue,
  state,
  values,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [loading, setLoading] = useState(LoadingState.NotLoaded);

  const { parameters, ruleId, rules } = values;
  const rule = ruleId ? rules.find((r) => r.Id === ruleId) : null;
  const entityParameters =
    rule && rule.Parameters
      ? rule.Parameters.filter(
          (p) => p.DataType.toLowerCase() === "entity"
        )
      : [];
  const entityNames = [
    ...new Set(entityParameters.map((p) => p.EntityName)),
  ];

  const lookupOptions = [];
  entityNames.forEach((name) => {
    lookupOptions.push(getLookupNameForEntity(name));
  });

  useLookupOptions(
    dispatch,
    globalDispatch,
    loading,
    lookupOptions,
    setLoading,
    state
  );

  if (!rule || !rule.Parameters || rule.Parameters.length === 0) {
    return <></>;
  }

  if (loading !== LoadingState.Loaded) {
    return <Loading />;
  }

  return (
    <div className="row g-3">
      {rule.Parameters.map((parameter, index) => {
        const { DataType, EntityName, Name } = parameter;

        const name = `parameters.${Name}`;

        var re = new RegExp("(.*?)\\{\\d:\\[(.*?)\\]\\}", "g");
        var matches = rule.Caption.match(re);
        var label =
          matches == null
            ? ""
            : matches[index].replace(re, "$1").trim();

        let lookup = "";
        let type = "nvarchar";
        switch (DataType) {
          case "DateTime":
            type = "datetime";
            break;
          case "int":
            type = "int";
            break;
          case "Entity":
            lookup = getLookupNameForEntity(EntityName);
            type = "lookup";
            break;
          case "String":
          default:
            type = "nvarchar";
            break;
        }

        const field = {
          label: label,
          lookup: lookup,
          name: name,
          type: type,
        };

        const options = getLookupFieldOptions(
          field,
          state,
          values,
          null
        );
        const value = parameters[Name];

        return (
          <div className="col-12" key={index}>
            <FormField
              {...{
                field,
                handleBlur,
                handleChange,
                handleFilteredPicklistChange,
                handlePicklistChange,
                options,
                setFieldValue,
                state,
                value,
                values,
              }}
              handleDateChange={(date) => {
                setFieldValue(name, date);
              }}
              handleFilteredPicklistChange={(event) => {
                const value = event ? event.value : "";
                setFieldValue(name, value);
              }}
              label={getFieldLabel(field, state, values)}
              mandatory={true}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Parameters;
