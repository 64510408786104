import { ButtonStyle, RecordStatus } from "../../js/enums";
import { Button } from "../elements/_Elements";

function EditStatusButton({
  isSubmitting,
  recordsSelected,
  recordStatusToShow,
  setShowConfirmStatusChangeModal,
}) {
  return (
    <Button
      className="me-3"
      disabled={recordsSelected.length === 0 || isSubmitting}
      type={"button"}
      style={
        String(recordStatusToShow) === String(RecordStatus.Inactive)
          ? ButtonStyle.Primary
          : ButtonStyle.Warning
      }
      text={
        String(recordStatusToShow) === String(RecordStatus.Inactive)
          ? "Activate"
          : "Deactivate"
      }
      onClick={() => setShowConfirmStatusChangeModal(true)}
      showSpinner={isSubmitting}
    />
  );
}

export default EditStatusButton;
