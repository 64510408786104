import { getDateString } from "../../Helpers";

function CouponTableCell({ children, className, properties }) {
  const {
    couponFontFamily,
    couponFontSize,
    couponFontWeight,
    couponPadding,
    couponTextColour,
  } = properties;

  return (
    <td
      className={className}
      style={{
        color: couponTextColour,
        fontFamily: couponFontFamily,
        fontSize: couponFontSize,
        fontWeight: couponFontWeight,
        padding: couponPadding,
      }}
    >
      {children}
    </td>
  );
}

function ActualCoupons({ properties }) {
  const {
    couponBackgroundColour,
    couponBorderColour,
    couponBorderThickness,
    couponBorderType,
    couponCode,
    couponMargin,
    couponRedeemBy,
    couponRedeemFrom,
    couponRedeemLink,
    couponTermsLink,
    couponUseBy,
    localeCode,
    longDateFormat,
  } = properties;

  return (
    <table
      className="full-width zero-margin hide-on-designer"
      cellSpacing="0"
    >
      <tbody
        data-xsl-wrap="xsl:for-each"
        data-xsl-attributes="select='Coupons/Coupon'"
      >
        <tr>
          <td style={{ padding: couponMargin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: couponBorderColour,
                borderStyle: couponBorderType,
                borderWidth: couponBorderThickness,
              }}
            >
              <tbody>
                <tr
                  style={{ backgroundColor: couponBackgroundColour }}
                >
                  <CouponTableCell properties={properties}>
                    <p style={{ fontSize: "20px" }}>
                      <strong
                        dangerouslySetInnerHTML={{
                          __html:
                            '<xsl:value-of select="CouponName" />',
                        }}
                      ></strong>
                    </p>
                  </CouponTableCell>
                </tr>
                <tr
                  style={{ backgroundColor: couponBackgroundColour }}
                >
                  <td>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <CouponTableCell properties={properties}>
                            <p
                              data-xsl-wrap="xsl:if"
                              data-xsl-attributes='test="CouponCode"'
                            >
                              <strong>{couponCode} </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '<xsl:value-of select="CouponCode" />',
                                }}
                              />
                            </p>
                            <p
                              data-xsl-wrap="xsl:if"
                              data-xsl-attributes='test="RedeemFromDate"'
                            >
                              <strong>{couponRedeemFrom} </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `<xsl:value-of select="g4:TranslateDate(RedeemFromDate, '${longDateFormat}', '${localeCode}')" />`,
                                }}
                              />
                            </p>
                            <p
                              data-xsl-wrap="xsl:if"
                              data-xsl-attributes='test="RedeemByDate"'
                            >
                              <strong>{couponRedeemBy} </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `<xsl:value-of select="g4:TranslateDate(RedeemByDate, '${longDateFormat}', '${localeCode}')" />`,
                                }}
                              />
                            </p>
                            <p
                              data-xsl-wrap="xsl:if"
                              data-xsl-attributes='test="UseByDate"'
                            >
                              <strong>{couponUseBy} </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `<xsl:value-of select="g4:TranslateDate(UseByDate, '${longDateFormat}', '${localeCode}')" />`,
                                }}
                              />
                            </p>
                          </CouponTableCell>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="inner-content-only"
                      dangerouslySetInnerHTML={{
                        __html:
                          "<!--[if mso]></td><td><![endif]--></td>",
                      }}
                    ></div>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <CouponTableCell properties={properties}>
                            <div
                              className="inner-content-only"
                              dangerouslySetInnerHTML={{
                                __html: `<xsl:variable name="couponCodeUrl" select="concat('https://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128{HtmlEncode:&Dpi=300&}Data=', CouponCode)"></xsl:variable>`,
                              }}
                            ></div>
                            <p
                              style={{ textAlign: "center" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<xsl:img src="{$couponCodeUrl}" style="max-width: 100%; width: 268px;" width="268"></xsl:img>',
                              }}
                            ></p>
                          </CouponTableCell>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {(couponRedeemLink || couponTermsLink) && (
                  <tr
                    style={{
                      backgroundColor: couponBackgroundColour,
                    }}
                  >
                    <CouponTableCell properties={properties}>
                      {couponRedeemLink && (
                        <p style={{ float: "left" }}>
                          <a href={couponRedeemLink}>Redeem Online</a>
                        </p>
                      )}
                      {couponTermsLink && (
                        <p style={{ float: "right" }}>
                          <a href={couponTermsLink}>
                            View Terms & Conditions
                          </a>
                        </p>
                      )}
                    </CouponTableCell>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function SampleCoupons({ properties }) {
  const {
    couponBackgroundColour,
    couponBorderColour,
    couponBorderThickness,
    couponBorderType,
    couponCode,
    couponMargin,
    couponRedeemBy,
    couponRedeemFrom,
    couponRedeemLink,
    couponTermsLink,
    couponUseBy,
  } = properties;

  return (
    <table
      className="full-width zero-margin remove-item"
      cellSpacing="0"
    >
      <tbody>
        <tr>
          <td style={{ padding: couponMargin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: couponBorderColour,
                borderStyle: couponBorderType,
                borderWidth: couponBorderThickness,
              }}
            >
              <tbody>
                <tr
                  style={{ backgroundColor: couponBackgroundColour }}
                >
                  <CouponTableCell properties={properties}>
                    <p style={{ fontSize: "20px" }}>
                      <strong>Coupon Type</strong>
                    </p>
                  </CouponTableCell>
                </tr>
                <tr
                  style={{ backgroundColor: couponBackgroundColour }}
                >
                  <td>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <CouponTableCell properties={properties}>
                            <p>
                              <strong>{couponCode} </strong>
                              SJ20N-F3UD2-301U5
                            </p>
                            <p>
                              <strong>{couponRedeemFrom} </strong>
                              {getDateString(
                                0,
                                0,
                                "dddd D MMMM YYYY"
                              )}
                            </p>
                            <p>
                              <strong>{couponRedeemBy} </strong>
                              {getDateString(
                                0,
                                2,
                                "dddd D MMMM YYYY"
                              )}
                            </p>
                            <p>
                              <strong>{couponUseBy} </strong>
                              {getDateString(
                                0,
                                6,
                                "dddd D MMMM YYYY"
                              )}
                            </p>
                          </CouponTableCell>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="inner-content-only"
                      dangerouslySetInnerHTML={{
                        __html:
                          "<!--[if mso]></td><td><![endif]--></td>",
                      }}
                    ></div>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <CouponTableCell properties={properties}>
                            <p style={{ textAlign: "center" }}>
                              <img
                                src="https://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128&Dpi=300&Data=SJ20N-F3UD2-301U5"
                                alt="SJ20N-F3UD2-301U5"
                                style={{ maxWidth: "100%" }}
                                width="268"
                              />
                            </p>
                          </CouponTableCell>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {(couponRedeemLink || couponTermsLink) && (
                  <tr
                    style={{
                      backgroundColor: couponBackgroundColour,
                    }}
                  >
                    <CouponTableCell properties={properties}>
                      {couponRedeemLink && (
                        <p style={{ float: "left" }}>
                          <a href={couponRedeemLink}>Redeem Online</a>
                        </p>
                      )}
                      {couponTermsLink && (
                        <p style={{ float: "right" }}>
                          <a href={couponTermsLink}>
                            View Terms & Conditions
                          </a>
                        </p>
                      )}
                    </CouponTableCell>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function Coupons({ properties }) {
  return (
    <table
      className="full-width zero-margin"
      cellSpacing="0"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='Coupons/Coupon'"
    >
      <tbody>
        <tr>
          <td>
            <ActualCoupons properties={properties} />
            <SampleCoupons properties={properties} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Coupons;
