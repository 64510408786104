import React, { useEffect, useState } from "react";
import { loadSeatData, noSeatGuid } from "./Helpers";
import { GetAllEntityDetailedInfo } from "../../../js/service";
import { useGlobalReducer } from "../../../GlobalContext";
import { LoadingState } from "../../../js/enums";
import { Loading } from "../../elements/_Elements";
import Block from "./Block";
import SeatClasses from "./SeatClasses";
import "./BlockDesigner.scss";

function BlockDesigner({ setFieldValue, values }) {
  const globalDispatch = useGlobalReducer();

  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [prevColumnSelected, setPrevColumnSelected] = useState(-1);
  const [prevRowSelected, setPrevRowSelected] = useState(-1);
  const [seatData, setSeatData] = useState("");
  const [seatClasses, setSeatClasses] = useState("");
  const [selectedSeatClass, setSelectedSeatClass] =
    useState(noSeatGuid);

  loadSeatData(setFieldValue, seatData, setSeatData, values);

  useEffect(() => {
    const getSeatClasses = async () => {
      const [serviceResponse] = await Promise.all([
        GetAllEntityDetailedInfo(
          globalDispatch,
          "g4b_seatclass",
          false
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        setSeatClasses(serviceResponse.data || []);
      }
      setLoading(LoadingState.Loaded);
    };
    if (!seatClasses) {
      getSeatClasses();
    }
  }, [globalDispatch, seatClasses]);

  const selectSeat = (event, row, column) => {
    const { shiftKey } = event;

    //Need a check for === 0 if row or column were 0,
    //otherwise then it would see it as false in the statement
    if ((row || row === 0) && (column || column === 0)) {
      let updatedSeatData = seatData;

      var seatClassIndexes =
        updatedSeatData && updatedSeatData.classIndexes
          ? updatedSeatData.classIndexes
          : [noSeatGuid];

      //Get the index number of the selected seat class from
      //seatClassIndexes
      let matchedClassIndex =
        seatClassIndexes.indexOf(selectedSeatClass);

      //If there is no matching class from seatClassIndexes,
      //then we must add the selectedSeatClass Guid to the
      //updatedSeatData.classIndexes array
      if (matchedClassIndex === -1) {
        updatedSeatData.classIndexes = [
          ...seatClassIndexes,
          selectedSeatClass,
        ];
        matchedClassIndex = updatedSeatData.classIndexes.length - 1;
      }

      //If the shift key was pressed when the click happened and we previously
      //clicked on a seat then we can set the seat colour of every seat that falls
      //within the range formed between the prev seat and the current seat
      if (
        shiftKey &&
        prevRowSelected >= 0 &&
        prevColumnSelected >= 0
      ) {
        let upperRow = prevRowSelected <= row ? row : prevRowSelected;
        let lowerRow = prevRowSelected > row ? row : prevRowSelected;
        let upperColumn =
          prevColumnSelected <= column ? column : prevColumnSelected;
        let lowerColumn =
          prevColumnSelected > column ? column : prevColumnSelected;
        for (var r = lowerRow; r <= upperRow; r++) {
          for (var c = lowerColumn; c <= upperColumn; c++) {
            updatedSeatData.classes[r][c] = matchedClassIndex;
          }
        }
      }
      //Otherwise just update the class index value for the specified row and column to the
      //selected seat class index
      else {
        updatedSeatData.classes[row][column] = matchedClassIndex;
      }

      //And then update the current state and the values
      setSeatData(updatedSeatData);
      setFieldValue("g4b_seatdata", JSON.stringify(updatedSeatData));

      //Finally set the previous row and column selected which can then be used for
      //when we select via the shift key for multi setting
      setPrevRowSelected(row);
      setPrevColumnSelected(column);
    }
  };

  return loading !== LoadingState.Loaded ? (
    <Loading />
  ) : seatData ? (
    <>
      <div className="row">
        <div className="col-10">
          <div className="border border-radius d-flex justify-content-center overflow-auto">
            <Block
              {...{
                seatClasses,
                seatData,
                selectSeat,
                setFieldValue,
                setSeatData,
              }}
            />
          </div>
        </div>
        <div className="col-2">
          <SeatClasses
            {...{
              seatClasses,
              selectedSeatClass,
              setSelectedSeatClass,
            }}
          />
        </div>
      </div>
    </>
  ) : (
    <p>No Seat Data Found</p>
  );
}

export default BlockDesigner;
