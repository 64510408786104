import { useState } from "react";
import { Button } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";
import ExportModal from "./ExportModal";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { DispatchMethods } from "../enums";

function Export({ state }) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [showModal, setShowModal] = useState(false);

  const onFileExported = (newFile) => {
    // add the new file to the list of related entities
    const updatedRelatedEntities = [...state.relatedEntities];
    let updatedAnnotationdEntity = updatedRelatedEntities.find(
      (r) => r.entityName === "annotation"
    );
    updatedAnnotationdEntity.entities.unshift({
      Id: newFile.Id,
      Name: newFile.Subject,
      Fields: {
        annotationid: newFile.Id,
        createdon: newFile.CreatedOn,
        filesize: newFile.FileSize,
        mimetype: newFile.MimeType,
        modifiedon: newFile.ModifiedOn,
        subject: newFile.Subject,
      },
    });

    // set the new file to be highlighted
    const highlightRelatedEntities = [
      {
        entityName: "annotation",
        id: newFile.Id,
      },
    ];

    dispatch({
      type: DispatchMethods.SetRelatedEntities,
      relatedEntities: updatedRelatedEntities,
      highlightRelatedEntities: highlightRelatedEntities,
    });

    // close the modal
    setShowModal(false);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => setShowModal(true)}
          style={ButtonStyle.Secondary}
          text="Export"
        />
      </div>
      {showModal && (
        <ExportModal
          {...{ globalDispatch, onFileExported, setShowModal, state }}
        />
      )}
    </>
  );
}

export default Export;
