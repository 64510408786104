import classNames from "classnames";
import {
  getStageNames,
  getStagesToRender,
  selectStage,
} from "../form/Helpers";
import { useFormReducer } from "../form/FormContext";
import "./progressTracker.scss";

const ProgressTracker = ({ filteredStages, state }) => {
  const dispatch = useFormReducer();

  const currentStage = state.stage;
  const stageNames = getStageNames(filteredStages);
  const stagesToRender = getStagesToRender(filteredStages);
  const stages = [
    ...stagesToRender.keys(),
    stagesToRender.length,
  ].map((stage) => stage + 1);

  return (
    <div className={classNames("progress-tracker")}>
      {stages.map((stage, index) => (
        <div key={index}>
          <div
            className={classNames(
              "stage",
              stage === currentStage ? "current" : "",
              stage < currentStage ? "done" : ""
            )}
            onClick={() => selectStage(dispatch, stage, state)}
          >
            <div className="stage-number-wrapper">
              <span className="stage-number">{index + 1}</span>
            </div>
            <span
              className={classNames(
                "stage-name",
                stages.length > 5
                  ? "stage-name-width-thin"
                  : "stage-name-width-normal"
              )}
            >
              {stageNames[stage - 1]}
            </span>
          </div>
          {index !== stages.length - 1 && (
            <div
              className={classNames(
                "stage-divider",
                stage < currentStage ? "done" : "",
                stages.length > 5
                  ? "stage-divider-width-thin"
                  : "stage-divider-width-normal"
              )}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressTracker;
