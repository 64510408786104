import { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalState } from "../../GlobalContext";
import { AnglesLeft, AnglesRight } from "../Icons";
import Navigation from "./Navigation";
import { navigationItems } from "../../js/navigationItems";
import { getCurrentPage } from "./Helpers";
import "./style.scss";

function SideBar() {
  const globalState = useGlobalState();

  const { initialStateBound, sitemap } = globalState;
  const showNavigation = localStorage.getItem("showNavigation");
  const page = getCurrentPage(navigationItems);

  const [display, setDisplay] = useState(page.display);
  const [subDisplay, setSubDisplay] = useState(page.subDisplay);
  const [showNav, setShowNav] = useState(
    showNavigation === "false" ? false : true
  );

  const toggleDisplay = (itemId) => {
    setDisplay(itemId === display ? "" : itemId);
  };

  const toggleSubDisplay = (itemId) => {
    setSubDisplay(itemId === subDisplay ? "" : itemId);
  };

  const toggleShowNav = () => {
    localStorage.setItem("showNavigation", !showNav);
    setShowNav(!showNav);
  };

  return (
    <nav
      id="sidebar"
      className={classNames(
        "navbar d-md-block",
        showNav ? "sidebar-expanded" : ""
      )}
    >
      {showNav && (
        <>
          <div className="d-flex justify-content-center">
            <img
              src={
                process.env.PUBLIC_URL +
                "/base_logo_transparent_background.svg"
              }
              alt="VisiT"
              width="100"
              height="60"
            />
          </div>
          {initialStateBound && (
            <Navigation
              {...{
                display,
                navigationItems,
                sitemap,
                subDisplay,
                toggleDisplay,
                toggleSubDisplay,
              }}
            />
          )}
        </>
      )}
      <div id="sidebar-collapse" onClick={toggleShowNav}>
        {showNav && <FontAwesomeIcon icon={AnglesLeft} />}
        {!showNav && <FontAwesomeIcon icon={AnglesRight} />}
      </div>
    </nav>
  );
}

export default SideBar;
