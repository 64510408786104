import {
  BackgroundColour,
  BorderColour,
  BorderThickness,
  BorderType,
  Colour,
  FontFamily,
  FontSize,
  FontWeight,
  LineThickness,
  LineType,
  Margin,
  Padding,
  TextAlign,
  TextColour,
  VerticalAlign,
} from "../../../CommonProperties";

const BookingControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "Options",
        fields: [
          {
            name: "showBookingSummary",
            description: "",
            label: "Show Booking Summary",
            type: "bit",
          },
          {
            name: "showPromotionCriteria",
            description: "",
            label: "Show Promotion Criteria",
            type: "bit",
          },
          {
            name: "showPaymentMandate",
            description: "",
            label: "Show Payment Mandate",
            type: "bit",
          },
          {
            name: "showDirectDebit",
            description: "",
            label: "Show Schedule",
            type: "bit",
          },
          {
            name: "showVouchers",
            description: "",
            label: "Show Vouchers",
            type: "bit",
          },
          {
            name: "showCoupons",
            description: "",
            label: "Show Coupons",
            type: "bit",
          },
          {
            name: "showGiftAid",
            description: "",
            label: "Show Gift Aid",
            type: "bit",
          },
          {
            name: "showVehicleRegistrationNumbers",
            description: "",
            label: "Show Vehicle Registration Numbers",
            type: "bit",
          },
          {
            name: "showTax",
            description: "",
            label: "Show Tax",
            type: "bit",
          },
        ],
      },
      {
        name: "Locale",
        fields: [
          {
            name: "localeCode",
            description: "",
            label: "Locale Code",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Date Format",
        fields: [
          {
            name: "shortDateFormat",
            description: "",
            label: "Short Date Format",
            type: "nvarchar",
          },
          {
            name: "longDateFormat",
            description: "",
            label: "Long Date Format",
            type: "nvarchar",
          },
          {
            name: "timeFormat",
            description: "",
            label: "Time Format",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Labels",
        fields: [
          {
            name: "product",
            description: "",
            label: "Product",
            type: "nvarchar",
          },
          {
            name: "quantity",
            description: "",
            label: "Quantity",
            type: "nvarchar",
          },
          {
            name: "price",
            description: "",
            label: "Price",
            type: "nvarchar",
          },
          {
            name: "area",
            description: "",
            label: "Area",
            type: "nvarchar",
          },
          {
            name: "block",
            description: "",
            label: "Block",
            type: "nvarchar",
          },
          {
            name: "seat",
            description: "",
            label: "Seat",
            type: "nvarchar",
          },
          {
            name: "beneficiary",
            description: "",
            label: "Beneficiary",
            type: "nvarchar",
          },
          {
            name: "totalPrice",
            description: "",
            label: "Total Price",
            type: "nvarchar",
          },
          {
            name: "totalCharges",
            description: "",
            label: "Total Charges",
            type: "nvarchar",
          },
          {
            name: "totalDiscount",
            description: "",
            label: "Total Discount",
            type: "nvarchar",
          },
          {
            name: "subTotal",
            description: "",
            label: "Sub-Total",
            type: "nvarchar",
          },
          {
            name: "totalPaid",
            description: "",
            label: "Total Paid",
            type: "nvarchar",
          },
          {
            name: "totalDue",
            description: "",
            label: "Total Due",
            type: "nvarchar",
          },
          {
            name: "membershipNumber",
            description: "",
            label: "Membership Number",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Payment Mandate",
        displayIfFromValues: (values) => {
          return values.showPaymentMandate;
        },
        fields: [
          {
            name: "bankName",
            description: "",
            label: "Bank Name",
            type: "nvarchar",
          },
          {
            name: "accountName",
            description: "",
            label: "Account Name",
            type: "nvarchar",
          },
          {
            name: "accountNumber",
            description: "",
            label: "Account Number",
            type: "nvarchar",
          },
          {
            name: "sortCode",
            description: "",
            label: "Sort Code",
            type: "nvarchar",
          },
          {
            name: "serviceNumber",
            description: "",
            label: "Service Number",
            type: "nvarchar",
          },
          {
            name: "referenceNumber",
            description: "",
            label: "Reference Number",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Vouchers",
        displayIfFromValues: (values) => {
          return values.showVouchers;
        },
        fields: [
          {
            name: "voucherRedeemLink",
            description: "",
            label: "Redeem Link",
            type: "nvarchar",
          },
          {
            name: "voucherTermsLink",
            description: "",
            label: "Terms Link",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Coupons",
        displayIfFromValues: (values) => {
          return values.showCoupons;
        },
        fields: [
          {
            name: "couponCode",
            description: "",
            label: "Code Text",
            type: "nvarchar",
          },
          {
            name: "couponRedeemFrom",
            description: "",
            label: "Redeem From Text",
            type: "nvarchar",
          },
          {
            name: "couponRedeemBy",
            description: "",
            label: "Redeem By Text",
            type: "nvarchar",
          },
          {
            name: "couponUseBy",
            description: "",
            label: "Use By Text",
            type: "nvarchar",
          },
          {
            name: "couponRedeemLink",
            description: "",
            label: "Redeem Link",
            type: "nvarchar",
          },
          {
            name: "couponTermsLink",
            description: "",
            label: "Terms Link",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Gift Aid",
        displayIfFromValues: (values) => {
          return values.showGiftAid;
        },
        fields: [
          {
            name: "giftAidHeader",
            description: "",
            label: "Header",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Vehicle Registration Numbers",
        displayIfFromValues: (values) => {
          return values.showVehicleRegistrationNumbers;
        },
        fields: [
          {
            name: "vehicleRegistrationNumber",
            description: "",
            label: "Registration Number Text",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Content",
        fields: [
          BackgroundColour(""),
          Margin(""),
          Padding(""),
          BorderType(""),
          BorderThickness(""),
          BorderColour(""),
        ],
      },
      {
        name: "Header",
        fields: [
          BackgroundColour("header"),
          TextColour("header"),
          Padding("headerCell", "Cell Padding"),
          FontFamily("header"),
          FontSize("header"),
          FontWeight("header"),
        ],
      },
      {
        name: "Rows",
        fields: [
          BackgroundColour("row"),
          TextColour("row"),
          Padding("rowCell", "Cell Padding"),
          FontFamily("row"),
          FontSize("row"),
          FontWeight("row"),
          VerticalAlign("row"),
          LineType("row"),
          LineThickness("row"),
          Colour("rowLine", "Line Colour"),
        ],
      },
      {
        name: "Footer",
        fields: [
          BackgroundColour("footer"),
          TextColour("footer"),
          Padding("footerCell", "Cell Padding"),
          FontFamily("footer"),
          FontSize("footer"),
          FontWeight("footer"),
        ],
      },
      {
        name: "Promotion Criteria",
        displayIfFromValues: (values) => {
          return values.showPromotionCriteria;
        },
        fields: [
          BackgroundColour("promotionCriteria"),
          TextColour("promotionCriteria"),
          TextAlign("promotionCriteria"),
          Margin("promotionCriteria"),
          Padding("promotionCriteria"),
          FontFamily("promotionCriteria"),
          FontSize("promotionCriteria"),
          FontWeight("promotionCriteria"),
          BorderType("promotionCriteria"),
          BorderThickness("promotionCriteria"),
          BorderColour("promotionCriteria"),
        ],
      },
      {
        name: "Payment Mandate",
        displayIfFromValues: (values) => {
          return values.showPaymentMandate;
        },
        fields: [
          BackgroundColour("paymentMandate"),
          TextColour("paymentMandate"),
          Margin("paymentMandate"),
          Padding("paymentMandate"),
          FontFamily("paymentMandate"),
          FontSize("paymentMandate"),
          FontWeight("paymentMandate"),
          BorderType("paymentMandate"),
          BorderThickness("paymentMandate"),
          BorderColour("paymentMandate"),
        ],
      },
      {
        name: "Schedule",
        displayIfFromValues: (values) => {
          return values.showDirectDebit;
        },
        fields: [Margin("directDebit")],
      },
      {
        name: "Vouchers",
        displayIfFromValues: (values) => {
          return values.showVouchers;
        },
        fields: [
          BackgroundColour("voucher"),
          TextColour("voucher"),
          FontFamily("voucher"),
          FontSize("voucher"),
          FontWeight("voucher"),
          Margin("voucher"),
          Padding("voucher"),
          BorderType("voucher"),
          BorderThickness("voucher"),
          BorderColour("voucher"),
        ],
      },
      {
        name: "Coupons",
        displayIfFromValues: (values) => {
          return values.showCoupons;
        },
        fields: [
          BackgroundColour("coupon"),
          TextColour("coupon"),
          FontFamily("coupon"),
          FontSize("coupon"),
          FontWeight("coupon"),
          Margin("coupon"),
          Padding("coupon"),
          BorderType("coupon"),
          BorderThickness("coupon"),
          BorderColour("coupon"),
        ],
      },
      {
        name: "Gift Aid",
        displayIfFromValues: (values) => {
          return values.showGiftAid;
        },
        fields: [
          BackgroundColour("giftAid"),
          TextColour("giftAid"),
          Margin("giftAid"),
          Padding("giftAid"),
          FontFamily("giftAid"),
          FontSize("giftAid"),
          FontWeight("giftAid"),
          BorderType("giftAid"),
          BorderThickness("giftAid"),
          BorderColour("giftAid"),
        ],
      },
      {
        name: "Vehicle Registration Numbers",
        displayIfFromValues: (values) => {
          return values.showVehicleRegistrationNumbers;
        },
        fields: [
          BackgroundColour("vehicleRegistrationNumber"),
          TextColour("vehicleRegistrationNumber"),
          FontFamily("vehicleRegistrationNumber"),
          FontSize("vehicleRegistrationNumber"),
          FontWeight("vehicleRegistrationNumber"),
          Margin("vehicleRegistrationNumber"),
          Padding("vehicleRegistrationNumber"),
          BorderType("vehicleRegistrationNumber"),
          BorderThickness("vehicleRegistrationNumber"),
          BorderColour("vehicleRegistrationNumber"),
        ],
      },
    ],
  },
];

export default BookingControlFormStages;
