import { ButtonStyle } from "../../../js/enums";
import { Button } from "../../elements/_Elements";
import { useFormReducer } from "../FormContext";
import { DispatchMethods } from "../enums";

function NewCommunication({ values }) {
  const dispatch = useFormReducer();

  const addNewCommunication = () => {
    const { numberOfCommunications } = values;
    const index = numberOfCommunications + 1;

    dispatch({
      type: DispatchMethods.SetReloadValues,
      reloadValues: {
        ...values,
        numberOfCommunications: index,
        [`communication${index}`]: null,
        [`tags${index}`]: [],
      },
    });
  };

  return (
    <div className="row g-3">
      <div className="col-12">
        <Button
          className="w-100"
          onClick={addNewCommunication}
          style={ButtonStyle.Secondary}
          text="Add communication variant"
          type="button"
        />
      </div>
    </div>
  );
}

export default NewCommunication;
