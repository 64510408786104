function VehicleRegistrationNumberTableCell({
  children,
  className,
  properties,
}) {
  const {
    vehicleRegistrationNumberFontFamily,
    vehicleRegistrationNumberFontSize,
    vehicleRegistrationNumberFontWeight,
    vehicleRegistrationNumberPadding,
    vehicleRegistrationNumberTextColour,
  } = properties;

  return (
    <td
      className={className}
      style={{
        color: vehicleRegistrationNumberTextColour,
        fontFamily: vehicleRegistrationNumberFontFamily,
        fontSize: vehicleRegistrationNumberFontSize,
        fontWeight: vehicleRegistrationNumberFontWeight,
        padding: vehicleRegistrationNumberPadding,
      }}
    >
      {children}
    </td>
  );
}

function ActualVehicleRegistrationNumbers({ properties }) {
  const {
    vehicleRegistrationNumber,
    vehicleRegistrationNumberBackgroundColour,
    vehicleRegistrationNumberBorderColour,
    vehicleRegistrationNumberBorderThickness,
    vehicleRegistrationNumberBorderType,
    vehicleRegistrationNumberMargin,
  } = properties;

  return (
    <table
      className="full-width zero-margin hide-on-designer"
      cellSpacing="0"
    >
      <tbody
        data-xsl-wrap="xsl:for-each"
        data-xsl-attributes="select='VehicleRegistrationNumbers/VehicleRegistrationNumber'"
      >
        <tr>
          <td style={{ padding: vehicleRegistrationNumberMargin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: vehicleRegistrationNumberBorderColour,
                borderStyle: vehicleRegistrationNumberBorderType,
                borderWidth: vehicleRegistrationNumberBorderThickness,
              }}
            >
              <tbody>
                <tr
                  style={{
                    backgroundColor:
                      vehicleRegistrationNumberBackgroundColour,
                  }}
                >
                  <VehicleRegistrationNumberTableCell
                    properties={properties}
                  >
                    <p
                      data-xsl-wrap="xsl:if"
                      data-xsl-attributes='test="Number"'
                    >
                      <strong>{vehicleRegistrationNumber} </strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: '<xsl:value-of select="Number" />',
                        }}
                      />
                    </p>
                  </VehicleRegistrationNumberTableCell>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function SampleVehicleRegistrationNumbers({ properties }) {
  const {
    vehicleRegistrationNumber,
    vehicleRegistrationNumberBackgroundColour,
    vehicleRegistrationNumberBorderColour,
    vehicleRegistrationNumberBorderThickness,
    vehicleRegistrationNumberBorderType,
    vehicleRegistrationNumberMargin,
  } = properties;

  return (
    <table
      className="full-width zero-margin remove-item"
      cellSpacing="0"
    >
      <tbody>
        <tr>
          <td style={{ padding: vehicleRegistrationNumberMargin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: vehicleRegistrationNumberBorderColour,
                borderStyle: vehicleRegistrationNumberBorderType,
                borderWidth: vehicleRegistrationNumberBorderThickness,
              }}
            >
              <tbody>
                <tr
                  style={{
                    backgroundColor:
                      vehicleRegistrationNumberBackgroundColour,
                  }}
                >
                  <VehicleRegistrationNumberTableCell
                    properties={properties}
                  >
                    <p>
                      <strong>{vehicleRegistrationNumber} </strong>
                      CU57ABC
                    </p>
                  </VehicleRegistrationNumberTableCell>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function VehicleRegistrationNumbers({ properties }) {
  return (
    <table
      className="full-width zero-margin"
      cellSpacing="0"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='VehicleRegistrationNumbers/VehicleRegistrationNumber'"
    >
      <tbody>
        <tr>
          <td>
            <ActualVehicleRegistrationNumbers
              properties={properties}
            />
            <SampleVehicleRegistrationNumbers
              properties={properties}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default VehicleRegistrationNumbers;
