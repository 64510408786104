import { checkIfSellFixturesAndSeriesSettingOn } from "../js/utility";
import lookups from "./lookups";
import { SeatClassFormStages } from "./seatClass";
import { BookableResourceCategoryFormStages } from "./bookableResourceCategory";
import { VenueFormStages } from "./venue";

export const BookableResourceFormStages = [
  {
    title: "Bookable Resource",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_capacity",
            label: "Capacity",
            type: "int",
          },
          {
            name: "g4b_venueid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Venue",
            lookup: lookups.g4b_venue.all,
            subFormStages: VenueFormStages,
            type: "lookup",
          },
          {
            name: "g4b_seatclassid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Seat Class",
            lookup: lookups.g4b_seatclass.all,
            subFormStages: SeatClassFormStages,
            type: "lookup",
          },
          {
            name: "g4b_categoryid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Category",
            lookup: lookups.g4b_bookableresourcecategory.all,
            subFormStages: BookableResourceCategoryFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
