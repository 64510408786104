import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { ChannelPriceListFormStages } from "./channelPriceList";
import lookups from "./lookups";

export const SalesPlanFormStages = [
  {
    title: "Sales Plan",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            { name: "g4b_channelid", className: "col-short-name" },
            { name: "g4b_variantpricelistid", className: "col-name" },
            { name: "g4b_marketinglistid", className: "col-name" },
            {
              name: "g4b_onsaledate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_onsaledaysbefore",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_onsalehoursbefore",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_offsaledate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_activitystartdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_activityenddate",
              className: "col-date",
              type: "datetime",
            },
            { name: "g4b_discountpercent", className: "col-number" },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_channelid",
              type: "select",
            },
            {
              name: "g4b_variantpricelistid",
              type: "select",
            },
            {
              name: "g4b_marketinglistid",
              type: "select",
            },
            {
              name: "g4b_onsaledate",
              type: "datetime",
            },
            {
              name: "g4b_offsaledate",
              type: "datetime",
            },
            {
              name: "g4b_activitystartdate",
              type: "datetime",
            },
            {
              name: "g4b_activityenddate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/channel-price-list",
          lookup: lookups.g4b_channelpricelist.all,
          subFormStages: ChannelPriceListFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
