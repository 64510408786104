import { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useGlobalReducer } from "../../GlobalContext";
import { convertDayFormatForMoment } from "../../js/utility";
import { LoadingState } from "../../js/enums";
import { GetEntityAudits } from "../../js/service";
import Card from "./Card";
import EntityAuditModal from "./EntityAuditModal";
import Loading from "./Loading";

function EntityInformation({ label, state }) {
  const globalDispatch = useGlobalReducer();

  const [auditState, setAuditState] = useState({
    loading: LoadingState.NotLoaded,
    audits: [],
  });
  const [loggingId, setLoggingId] = useState("");

  const { audits, loading } = auditState;

  const { createdon, id } = state;

  useEffect(() => {
    const getEntityAudits = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetEntityAudits(globalDispatch, label, id),
        ]);
        if (serviceResponse && serviceResponse.data) {
          setAuditState({
            loading: LoadingState.Loaded,
            audits: serviceResponse.data,
          });
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    if (loading !== LoadingState.Loaded) {
      getEntityAudits();
    }
  }, [globalDispatch, id, label, loading]);

  return (
    <>
      <EntityAuditModal
        {...{ audits, label, loggingId, setLoggingId }}
      />
      <div className="row">
        <div className="col-12 col-md-3 order-1 order-md-2">
          <Card>
            <p className="fw-bold m-0">Id</p>
            <p>{id}</p>
            <p className="fw-bold m-0">Created On</p>
            <p>
              {moment(createdon).format(
                convertDayFormatForMoment("dd/MM/yyyy HH:mm:ss")
              )}
            </p>
          </Card>
        </div>
        <div className="col-12 col-md-9 order-2 order-md-1">
          {loading !== LoadingState.Loaded ? (
            <Loading />
          ) : (
            <Card>
              {audits.length > 0 ? (
                audits.map((audit, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(index > 0 ? "mt-4" : "")}
                    >
                      <strong
                        className="link-primary cursor-pointer"
                        onClick={() => setLoggingId(audit.LoggingId)}
                      >
                        {audit.Type === 0
                          ? "Created by "
                          : "Updated by "}
                        {audit.EmailAddress}
                      </strong>
                      <p className="m-0">
                        {moment(audit.CreatedOn).format(
                          convertDayFormatForMoment(
                            "dd/MM/yyyy HH:mm:ss"
                          )
                        )}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p>No audits found</p>
              )}
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default EntityInformation;
