import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import BlockDesigner from "../components/form/block/BlockDesigner";
import { SeatNumberDirection, ZigZagStart } from "../js/enums";
import { AreaFormStages } from "./area";
import lookups from "./lookups";
import { VenueFormStages } from "./venue";

export const BlockFormStages = [
  {
    title: "Block",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "g4b_venueid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Venue",
            lookup: lookups.g4b_venue.all,
            required: true,
            subFormStages: VenueFormStages,
            type: "lookup",
          },
          {
            name: "g4b_areaid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Area",
            lookup: lookups.g4b_area.all,
            subFormStages: AreaFormStages,
            type: "lookup",
          },
          {
            name: "g4b_svg",
            className: "col-12 col-md-8",
            description: "",
            label: "SVG",
            type: "nvarchar",
          },
          {
            name: "g4b_seated",
            description: "",
            label: "Seated",
            type: "bit",
          },
        ],
      },
      {
        name: "Capacity",
        fields: [
          {
            name: "g4b_unseatedcapacity",
            description: "",
            displayIfFromValues: (values) => !values.g4b_seated,
            label: "Unseated Capacity",
            type: "int",
          },
        ],
      },
      {
        name: "Seat Layout",
        fields: [
          {
            name: "g4b_rows",
            description: "",
            default: 10,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Rows",
            type: "int",
          },
          {
            name: "g4b_seats",
            description: "",
            default: 10,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Seats",
            type: "int",
          },
          {
            name: "g4b_radius",
            description:
              "Defines the block curvature. Enter a value of 10,000 or more for a straight line, or a smaller value for a curved layout.",
            default: 10000,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Radius",
            type: "int",
          },
          {
            name: "g4b_seatnumberdirection",
            description: "",
            default: SeatNumberDirection["Left to Right"],
            displayIfFromValues: (values) => values.g4b_seated,
            enum: SeatNumberDirection,
            label: "Seat Number Direction",
            type: "picklist",
          },
          {
            name: "g4b_firstseat",
            description: "",
            default: 1,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "First Seat",
            type: "int",
          },
          {
            name: "g4b_ignoregaps",
            description:
              "If yes, seat numbers are allocated ignoring gaps (no seat) i.e. seat numbers either side of a gap will be sequential.",
            default: false,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Ignore Gaps",
            type: "bit",
          },
          {
            name: "g4b_zigzagstart",
            description: "",
            default: ZigZagStart["Bottom Left"],
            displayIfFromValues: (values) =>
              values.g4b_seated &&
              values.g4b_seatnumberdirection &&
              values.g4b_seatnumberdirection ===
                SeatNumberDirection["Zig Zag"],
            enum: ZigZagStart,
            label: "Zig Zag Start",
            type: "picklist",
          },
          {
            name: "g4b_seatdata",
            className: "col-12",
            component: BlockDesigner,
            displayComponentOnOverview: true,
            displayIfFromValues: (values) => values.g4b_seated,
            type: "component",
          },
        ],
      },
      {
        name: "Block Seats",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          displayIfFromValues: (values) => values.g4b_seated,
          fields: [
            {
              name: "g4b_row",
              label: "Row",
              className: "col-number",
            },
            {
              name: "g4b_seat",
              label: "Seat",
              className: "col-number",
            },
            {
              name: "g4b_seatclassname",
              label: "Seat Class",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              label: "Created On",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              label: "Modified On",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_seatclassname"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/block-seat",
          linkedEntityId: "g4b_block",
          loadStateRelatedEntityName: "g4b_blockseat",
          lookup: lookups.g4b_blockseat.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
      {
        name: "Block Seat Classes",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          displayIfFromValues: (values) => values.g4b_seated,
          fields: [
            {
              name: "g4b_seatclassidname",
              label: "Seat class",
              className: "col-short-name",
            },
            {
              name: "g4b_count",
              label: "Count",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_seatclassidname"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/block-seat-class",
          linkedEntityId: "g4b_blockid",
          loadStateRelatedEntityName: "g4b_blockseatclass",
          lookup: lookups.g4b_blockseat.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
