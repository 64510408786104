import { getDateString } from "../../Helpers";

function BookingProductTableCell({
  children,
  className,
  properties,
  textAlign,
  whiteSpace,
}) {
  const {
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowFontWeight,
    rowLineColour,
    rowLineThickness,
    rowLineType,
    rowTextColour,
    rowVerticalAlign,
  } = properties;

  return (
    <td
      className={className}
      style={{
        borderBottomColor: rowLineColour,
        borderBottomStyle: rowLineType,
        borderBottomWidth: rowLineThickness,
        color: rowTextColour,
        fontFamily: rowFontFamily,
        fontSize: rowFontSize,
        fontWeight: rowFontWeight,
        padding: rowCellPadding,
        textAlign: textAlign,
        verticalAlign: rowVerticalAlign,
        whiteSpace: whiteSpace,
      }}
    >
      {children}
    </td>
  );
}

function FixtureTableCell({
  children,
  className,
  properties,
  whiteSpace,
}) {
  const {
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowFontWeight,
    rowTextColour,
  } = properties;

  return (
    <td
      className={className}
      style={{
        color: rowTextColour,
        fontFamily: rowFontFamily,
        fontSize: rowFontSize,
        fontWeight: rowFontWeight,
        padding: rowCellPadding,
        whiteSpace: whiteSpace,
      }}
    >
      {children}
    </td>
  );
}

function Fixture() {
  return (
    <div
      data-xsl-wrap="xsl:if"
      data-xsl-attributes='test="Fixture"'
      dangerouslySetInnerHTML={{
        __html: '<xsl:value-of select="Fixture" />',
      }}
    ></div>
  );
}

function ActivityTime({ properties }) {
  const { localeCode, longDateFormat, shortDateFormat, timeFormat } =
    properties;

  return (
    <div
      data-xsl-wrap="xsl:if"
      data-xsl-attributes='test="ActivityTime"'
      style={{ display: "block" }}
    >
      <div>
        <span
          className="hide-for-small"
          dangerouslySetInnerHTML={{
            __html: `<xsl:value-of select="g4:TranslateDate(ActivityTime, '${longDateFormat}', '${localeCode}')" />`,
          }}
        ></span>
        <span
          className="show-for-small"
          dangerouslySetInnerHTML={{
            __html: `<xsl:value-of select="ms:format-date(ActivityTime, '${shortDateFormat}')" />`,
          }}
        ></span>
        <span
          data-xsl-wrap="xsl:if"
          data-xsl-attributes='test="Type != 16"'
          dangerouslySetInnerHTML={{
            __html: `<xsl:value-of select="concat(' - ', ms:format-time(ActivityTime, '${timeFormat}'))" />`,
          }}
        ></span>
      </div>
    </div>
  );
}

function Seats({ properties }) {
  const {
    area,
    beneficiary,
    block,
    price,
    rowBackgroundColour,
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowFontWeight,
    rowTextColour,
    seat,
  } = properties;

  return (
    <div style={{ display: "block" }}>
      <div className="hide-for-small">
        <div
          className="inner-content-only"
          data-xsl-wrap="xsl:choose"
          data-xsl-attributes=""
        >
          <div
            className="inner-content-only"
            data-xsl-wrap="xsl:when"
            data-xsl-attributes='test="../Beneficiaries/Beneficiary"'
          >
            <table
              style={{
                margin: "0",
                marginLeft: "10px",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <tbody>
                <tr style={{ backgroundColor: rowBackgroundColour }}>
                  <td
                    className="three"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{area}</strong>
                  </td>
                  <td
                    className="two"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{block}</strong>
                  </td>
                  <td
                    className="two"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{seat}</strong>
                  </td>
                  <td
                    className="three"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{beneficiary}</strong>
                  </td>
                  <td
                    className="two"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{price}</strong>
                  </td>
                </tr>
                <tr
                  data-xsl-wrap="xsl:for-each"
                  data-xsl-attributes="select='Seats/Seat[Seated=1]'"
                  style={{ backgroundColor: rowBackgroundColour }}
                >
                  <td
                    className="three"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: '<xsl:value-of select="Area" />',
                      }}
                    ></p>
                  </td>
                  <td
                    className="two"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: '<xsl:value-of select="Block" />',
                      }}
                    ></p>
                  </td>
                  <td
                    className="two"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p
                      data-xsl-wrap="xsl:if"
                      data-xsl-attributes='test="Seated=1"'
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="concat(Row, ' ', Seat)" />`,
                      }}
                    ></p>
                    <p
                      data-xsl-wrap="xsl:if"
                      data-xsl-attributes='test="Seated!=1"'
                    >
                      Unseated
                    </p>
                  </td>
                  <td
                    className="three"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<xsl:value-of select="../../Beneficiaries/Beneficiary[Id = current()/Id]/Name" />`,
                        }}
                      ></span>
                      <span
                        className="inner-content-only"
                        data-xsl-wrap="xsl:if"
                        data-xsl-attributes='test="../../Beneficiaries/Beneficiary[Id = current()/Id]/Entitlement"'
                      >
                        {" "}
                        (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `<xsl:value-of select="../../Beneficiaries/Beneficiary[Id = current()/Id]/Entitlement" />`,
                          }}
                        ></span>
                        )
                      </span>
                    </p>
                  </td>
                  <td
                    className="two"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p
                      data-xsl-wrap="xsl:if"
                      data-xsl-attributes='test="../../Beneficiaries/Beneficiary[Id = current()/Id]/Price"'
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<xsl:value-of select="g4:FormatCurrency(../../Beneficiaries/Beneficiary[Id = current()/Id]/Price, ../../../../CurrencyCode)" />`,
                        }}
                      ></span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="inner-content-only"
            data-xsl-wrap="xsl:otherwise"
            data-xsl-attributes=""
          >
            <table
              style={{
                margin: "0",
                marginLeft: "10px",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <tbody>
                <tr style={{ backgroundColor: rowBackgroundColour }}>
                  <td
                    className="four"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{area}</strong>
                  </td>
                  <td
                    className="four"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{block}</strong>
                  </td>
                  <td
                    className="four"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      padding: rowCellPadding,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{seat}</strong>
                  </td>
                </tr>
                <tr
                  data-xsl-wrap="xsl:for-each"
                  data-xsl-attributes="select='Seats/Seat[Seated=1]'"
                  style={{ backgroundColor: rowBackgroundColour }}
                >
                  <td
                    className="four"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: '<xsl:value-of select="Area" />',
                      }}
                    ></p>
                  </td>
                  <td
                    className="four"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: '<xsl:value-of select="Block" />',
                      }}
                    ></p>
                  </td>
                  <td
                    className="four"
                    style={{
                      color: rowTextColour,
                      fontFamily: rowFontFamily,
                      fontSize: rowFontSize,
                      fontWeight: rowFontWeight,
                      padding: rowCellPadding,
                    }}
                  >
                    <p
                      data-xsl-wrap="xsl:if"
                      data-xsl-attributes='test="Seated=1"'
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="concat(Row, ' ', Seat)" />`,
                      }}
                    ></p>
                    <p
                      data-xsl-wrap="xsl:if"
                      data-xsl-attributes='test="Seated!=1"'
                    >
                      Unseated
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="show-for-small" style={{ msoHide: "all" }}>
        <table
          style={{
            margin: "0",
            marginLeft: "10px",
            marginBottom: "15px",
            msoHide: "all",
            width: "100%",
          }}
        >
          <tbody>
            <tr
              data-xsl-wrap="xsl:for-each"
              data-xsl-attributes="select='Seats/Seat[Seated=1]'"
              style={{
                backgroundColor: rowBackgroundColour,
                msoHide: "all",
              }}
            >
              <td
                className="four"
                style={{
                  color: rowTextColour,
                  fontFamily: rowFontFamily,
                  fontSize: rowFontSize,
                  fontWeight: rowFontWeight,
                  msoHide: "all",
                  padding: rowCellPadding,
                }}
              >
                <p style={{ msoHide: "all" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<xsl:value-of select="concat(Area, ', ', Block)" />`,
                    }}
                  ></span>
                  <span
                    data-xsl-wrap="xsl:if"
                    data-xsl-attributes='test="Seated=1"'
                    dangerouslySetInnerHTML={{
                      __html: `<xsl:value-of select="concat(', ', Row, ' ', Seat)" />`,
                    }}
                  ></span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function Beneficiaries({ properties }) {
  const {
    membershipNumber,
    rowBackgroundColour,
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowTextColour,
  } = properties;

  return (
    <div
      className="inner-content-only"
      data-xsl-wrap="xsl:choose"
      data-xsl-attributes=""
    >
      <div
        className="inner-content-only"
        data-xsl-wrap="xsl:when"
        data-xsl-attributes='test="Seats/Seat[Seated=1]"'
      >
        <Seats properties={properties} />
      </div>
      <div
        className="inner-content-only"
        data-xsl-wrap="xsl:otherwise"
        data-xsl-attributes=""
      >
        <div
          className="inner-content-only"
          data-xsl-wrap="xsl:if"
          data-xsl-attributes='test="Beneficiaries/Beneficiary"'
        >
          <table
            cellPadding="0"
            cellSpacing="0"
            className="full-width zero-margin"
          >
            <tbody>
              <tr
                data-xsl-wrap="xsl:for-each"
                data-xsl-attributes="select='Beneficiaries/Beneficiary'"
                style={{ backgroundColor: rowBackgroundColour }}
              >
                <td
                  className="four"
                  style={{
                    color: rowTextColour,
                    fontFamily: rowFontFamily,
                    fontSize: rowFontSize,
                    fontStyle: "italic",
                    padding: rowCellPadding,
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<xsl:value-of select="Name" />',
                    }}
                  ></div>
                </td>
                <td
                  style={{
                    color: rowTextColour,
                    fontFamily: rowFontFamily,
                    fontSize: rowFontSize,
                    fontStyle: "italic",
                    padding: rowCellPadding,
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:choose"
                    data-xsl-attributes=""
                  >
                    <div
                      className="inner-content-only"
                      data-xsl-wrap="xsl:when"
                      data-xsl-attributes='test="MembershipNumber"'
                    >
                      <span>{membershipNumber} </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            '<xsl:value-of select="MembershipNumber" />',
                        }}
                      />
                    </div>
                    <div
                      className="inner-content-only"
                      data-xsl-wrap="xsl:otherwise"
                      data-xsl-attributes=""
                    >
                      <div
                        className="inner-content-only"
                        data-xsl-wrap="xsl:if"
                        data-xsl-attributes='test="Entitlement"'
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:value-of select="Entitlement" />',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className="four"
                  data-xsl-wrap="xsl:if"
                  data-xsl-attributes='test="Price"'
                  style={{
                    color: rowTextColour,
                    fontFamily: rowFontFamily,
                    fontSize: rowFontSize,
                    fontStyle: "italic",
                    padding: rowCellPadding,
                    textAlign: "right",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        '<xsl:value-of select="g4:FormatCurrency(Price, ../../../../CurrencyCode)" />',
                    }}
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function PaymentSchedule({ properties }) {
  const {
    localeCode,
    rowBackgroundColour,
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowTextColour,
  } = properties;

  return (
    <div
      className="inner-content-only"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes='test="PaymentSchedule"'
    >
      <table cellSpacing="0" className="full-width zero-margin">
        <tbody>
          <tr style={{ backgroundColor: rowBackgroundColour }}>
            <td
              className="twelve"
              style={{
                color: rowTextColour,
                fontFamily: rowFontFamily,
                fontSize: rowFontSize,
                fontStyle: "italic",
                padding: rowCellPadding,
                whiteSpace: "nowrap",
              }}
            >
              <div>
                <span>Membership cost is </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<xsl:value-of select="g4:FormatCurrency(PaymentSchedule/Amount, ../../CurrencyCode)" />`,
                  }}
                ></span>
                <span> per </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<xsl:choose>
                        <xsl:when test="PaymentSchedule/Period = 0">day</xsl:when>
                        <xsl:when test="PaymentSchedule/Period = 1">week</xsl:when>
                        <xsl:when test="PaymentSchedule/Period = 2">4 weeks</xsl:when>
                        <xsl:when test="PaymentSchedule/Period = 3">month</xsl:when>
                        <xsl:when test="PaymentSchedule/Period = 4">year</xsl:when>
                      </xsl:choose>`,
                  }}
                ></span>
                <span
                  className="inner-content-only"
                  data-xsl-wrap="xsl:if"
                  data-xsl-attributes='test="PaymentSchedule/MembershipLife != 5 and PaymentSchedule/EndDate"'
                  dangerouslySetInnerHTML={{
                    __html: `<xsl:text> until </xsl:text><xsl:value-of select="g4:TranslateDate(PaymentSchedule/EndDate, 'MMMM yyyy', '${localeCode}')" />`,
                  }}
                ></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function ProductBundleItems({ properties }) {
  const {
    localeCode,
    longDateFormat,
    rowBackgroundColour,
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowTextColour,
    timeFormat,
  } = properties;

  return (
    <div
      className="inner-content-only"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes='test="ProductBundleItem"'
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        className="full-width zero-margin"
      >
        <tbody>
          <tr
            data-xsl-wrap="xsl:if"
            data-xsl-attributes='test="g4b_displayprices=1"'
            style={{ backgroundColor: rowBackgroundColour }}
          >
            <td
              style={{
                color: rowTextColour,
                fontFamily: rowFontFamily,
                fontSize: rowFontSize,
                fontStyle: "italic",
                padding: rowCellPadding,
              }}
            >
              <div className="margin-left">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<xsl:value-of select="g4b_name" />`,
                  }}
                ></span>
                <div
                  className="inner-content-only"
                  data-xsl-wrap="xsl:choose"
                  data-xsl-attributes=""
                >
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="g4b_startdatetime and g4b_enddatetime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(g4b_startdatetime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    <span> - </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(g4b_enddatetime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="g4b_startdatetime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(g4b_startdatetime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                </div>
              </div>
            </td>
            <td
              style={{
                color: rowTextColour,
                fontFamily: rowFontFamily,
                fontSize: rowFontSize,
                fontStyle: "italic",
                padding: rowCellPadding,
                textAlign: "right",
                width: "60px",
                whiteSpace: "nowrap",
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: `<xsl:value-of select="g4:FormatCurrency(g4b_price, ../../../CurrencyCode)" />`,
                }}
              ></span>
            </td>
          </tr>
          <tr
            data-xsl-wrap="xsl:if"
            data-xsl-attributes='test="g4b_displayprices!=1"'
            style={{ backgroundColor: rowBackgroundColour }}
          >
            <td
              style={{
                color: rowTextColour,
                fontFamily: rowFontFamily,
                fontSize: rowFontSize,
                fontStyle: "italic",
                padding: rowCellPadding,
              }}
            >
              <div className="margin-left">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<xsl:value-of select="g4b_name" />`,
                  }}
                ></span>
                <div
                  className="inner-content-only"
                  data-xsl-wrap="xsl:choose"
                  data-xsl-attributes=""
                >
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="g4b_startdatetime and g4b_enddatetime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(g4b_startdatetime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    <span> - </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(g4b_enddatetime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="g4b_startdatetime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(g4b_startdatetime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:otherwise"
                    data-xsl-attributes=""
                  ></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Lessons({ properties }) {
  const {
    localeCode,
    longDateFormat,
    rowBackgroundColour,
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowTextColour,
    shortDateFormat,
    timeFormat,
  } = properties;

  return (
    <div
      className="inner-content-only"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes='test="Lessons"'
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        className="full-width zero-margin"
      >
        <tbody>
          <tr
            data-xsl-wrap="xsl:for-each"
            data-xsl-attributes="select='Lessons/Lesson'"
            style={{ backgroundColor: rowBackgroundColour }}
          >
            <td
              className="twelve"
              style={{
                color: rowTextColour,
                fontFamily: rowFontFamily,
                fontSize: rowFontSize,
                fontStyle: "italic",
                padding: rowCellPadding,
                whiteSpace: "nowrap",
              }}
            >
              <div className="hide-for-small">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      '<xsl:value-of select="G4b_lessonnumberName" />',
                  }}
                ></span>
                <div
                  className="inner-content-only"
                  data-xsl-wrap="xsl:choose"
                  data-xsl-attributes=""
                >
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="G4B_StartTime and G4B_EndTime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(G4B_StartTime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    <span> - </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(G4B_EndTime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="G4B_StartTime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(G4B_StartTime, concat(concat('${longDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                </div>
              </div>
              <div className="show-for-small">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      '<xsl:value-of select="G4b_lessonnumber" />',
                  }}
                ></span>
                <div
                  className="inner-content-only"
                  data-xsl-wrap="xsl:choose"
                  data-xsl-attributes=""
                >
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="G4B_StartTime and G4B_EndTime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(G4B_StartTime, concat(concat('${shortDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    <span> - </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(G4B_EndTime, concat(concat('${shortDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                  <div
                    className="inner-content-only"
                    data-xsl-wrap="xsl:when"
                    data-xsl-attributes='test="G4B_StartTime"'
                  >
                    (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<xsl:value-of select="g4:TranslateDate(G4B_StartTime, concat(concat('${shortDateFormat}', ' '), '${timeFormat}'), '${localeCode}')" />`,
                      }}
                    />
                    )
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function ActualBookingProducts({ properties }) {
  const { rowBackgroundColour } = properties;

  return (
    <tr
      className="hide-on-designer"
      data-xsl-wrap="xsl:for-each"
      data-xsl-attributes="select='Products/Product'"
      style={{
        backgroundColor: rowBackgroundColour,
      }}
    >
      <BookingProductTableCell
        className="eight"
        properties={properties}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: '<xsl:value-of select="Product" />',
          }}
        ></div>
        <Fixture properties={properties} />
        <ActivityTime properties={properties} />
        <Beneficiaries properties={properties} />
        <PaymentSchedule properties={properties} />
        <ProductBundleItems properties={properties} />
        <Lessons properties={properties} />
      </BookingProductTableCell>
      <BookingProductTableCell
        className="two"
        properties={properties}
        textAlign="right"
        whiteSpace="nowrap"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: '<xsl:value-of select="Quantity" />',
          }}
        ></div>
      </BookingProductTableCell>
      <BookingProductTableCell
        className="two"
        properties={properties}
        textAlign="right"
        whiteSpace="nowrap"
      >
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<xsl:value-of select="g4:FormatCurrency(Price, ../../CurrencyCode)" />',
          }}
        ></div>
      </BookingProductTableCell>
    </tr>
  );
}

function SampleBookingProducts({ properties }) {
  const {
    area,
    beneficiary,
    block,
    rowBackgroundColour,
    price,
    seat,
  } = properties;

  return (
    <>
      <tr
        className="remove-item"
        style={{
          backgroundColor: rowBackgroundColour,
        }}
      >
        <BookingProductTableCell
          className="eight"
          properties={properties}
        >
          <div>Product Purchased 1</div>
        </BookingProductTableCell>
        <BookingProductTableCell
          className="two"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <div>1</div>
        </BookingProductTableCell>
        <BookingProductTableCell
          className="two"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <div>£100.00</div>
        </BookingProductTableCell>
      </tr>
      <tr
        className="remove-item"
        style={{
          backgroundColor: rowBackgroundColour,
        }}
      >
        <BookingProductTableCell
          className="eight"
          properties={properties}
        >
          <div>Product Purchased 2</div>
          <div>Fixture 1</div>
          <div style={{ display: "block" }}>
            <p className="hide-for-small">
              {getDateString(0, 1, "dddd D MMMM YYYY")} - 16:00
            </p>
            <p className="show-for-small">
              {getDateString(0, 1, "DD/MM/YYYY")} - 16:00
            </p>
          </div>
          <div style={{ display: "block" }}>
            <div className="hide-for-small">
              <table
                style={{
                  margin: "0",
                  marginLeft: "10px",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr
                    style={{ backgroundColor: rowBackgroundColour }}
                  >
                    <FixtureTableCell
                      className="three"
                      properties={properties}
                      whiteSpace="nowrap"
                    >
                      <strong>{area}</strong>
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                      whiteSpace="nowrap"
                    >
                      <strong>{block}</strong>
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                      whiteSpace="nowrap"
                    >
                      <strong>{seat}</strong>
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="three"
                      properties={properties}
                      whiteSpace="nowrap"
                    >
                      <strong>{beneficiary}</strong>
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                      whiteSpace="nowrap"
                    >
                      <strong>{price}</strong>
                    </FixtureTableCell>
                  </tr>
                  <tr
                    style={{ backgroundColor: rowBackgroundColour }}
                  >
                    <FixtureTableCell
                      className="three"
                      properties={properties}
                    >
                      North Stand
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                    >
                      401
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                    >
                      D 5
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="three"
                      properties={properties}
                    >
                      David Smith (Season Ticket Holder)
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                    >
                      £25.00
                    </FixtureTableCell>
                  </tr>
                  <tr
                    style={{ backgroundColor: rowBackgroundColour }}
                  >
                    <FixtureTableCell
                      className="three"
                      properties={properties}
                    >
                      North Stand
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                    >
                      401
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                    >
                      D 6
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="three"
                      properties={properties}
                    >
                      John Wilkes
                    </FixtureTableCell>
                    <FixtureTableCell
                      className="two"
                      properties={properties}
                    >
                      £50.00
                    </FixtureTableCell>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="show-for-small">
              <table
                style={{
                  margin: "0",
                  marginLeft: "10px",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr
                    style={{ backgroundColor: rowBackgroundColour }}
                  >
                    <FixtureTableCell
                      className="four"
                      properties={properties}
                    >
                      North Stand, 401, D 5
                    </FixtureTableCell>
                  </tr>
                  <tr
                    style={{ backgroundColor: rowBackgroundColour }}
                  >
                    <FixtureTableCell
                      className="four"
                      properties={properties}
                    >
                      North Stand, 401, D 6
                    </FixtureTableCell>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </BookingProductTableCell>
        <BookingProductTableCell
          className="two"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <div>2</div>
        </BookingProductTableCell>
        <BookingProductTableCell
          className="two"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <div>£75.00</div>
        </BookingProductTableCell>
      </tr>
      <tr
        className="remove-item"
        style={{
          backgroundColor: rowBackgroundColour,
        }}
      >
        <BookingProductTableCell
          className="eight"
          properties={properties}
        >
          <div>Product Purchased 3</div>
        </BookingProductTableCell>
        <BookingProductTableCell
          className="two"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <div>1</div>
        </BookingProductTableCell>
        <BookingProductTableCell
          className="two"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <div>£50.00</div>
        </BookingProductTableCell>
      </tr>
    </>
  );
}

function BookingProducts({ properties }) {
  return (
    <>
      <ActualBookingProducts properties={properties} />
      <SampleBookingProducts properties={properties} />
    </>
  );
}

export default BookingProducts;
