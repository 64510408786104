import { LanguageFormStages } from "./language";
import lookups from "./lookups";
import { AnswerGroupOptionFormStages } from "./answerGroupOption";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const AnswerGroupFormStages = [
  {
    title: "Answer Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4_languageid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Language",
            lookup: lookups.g4_language.all,
            required: true,
            subFormStages: LanguageFormStages,
            type: "lookup",
          },
        ],
      },
      {
        name: "Answer Group Options",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4_sequence",
              className: "col-sequence",
              type: "number",
            },
            {
              name: "g4_answeroptionid",
              className: "col-name",
              lookup: lookups.g4_answeroption.all,
              type: "lookup",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4_answergroupid",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/answer-group-option",
          linkedEntityId: "g4_answergroupid",
          lookup: lookups.g4_answergroupoption.all,
          subFormStages: AnswerGroupOptionFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
