import { ChannelQuestionFormStages } from "./channelQuestion";
import lookups from "./lookups";
import {
  StadiumOptions,
  StadiumOptionsDefaultTab,
} from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const ChannelFormStages = [
  {
    title: "Main fields",
    id: "main-fields",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_description",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "General Settings",
        fields: [
          {
            name: "g4b_allowpartialseries",
            default: false,
            description:
              "Allow Series to be booked where not all fixtures are available",
            label: "Allow Partial Series",
            type: "bit",
          },
          {
            name: "g4b_allowquickcancel",
            default: false,
            description:
              "Allow Booking Search Amend/Seat Search Amend to give the options to Amend (default) or Cancel Booking.",
            label: "Allow Quick Cancel",
            type: "bit",
          },
          {
            name: "g4b_allowsaletoaccount",
            default: false,
            description:
              "If true, the channel allows sales to accounts.",
            label: "Allow Sale to Account",
            type: "bit",
          },
          {
            name: "g4b_allowseriesseatstobechanged",
            default: false,
            description: "",
            label: "Allow Series Seats to be Changed",
            type: "bit",
          },
          {
            name: "g4b_assignpurchaserasfirstproductuser",
            default: false,
            description:
              "If set to yes the first product user in a booking will be populated with the details of the purchaser",
            label: "Assign purchaser as first product user",
            type: "bit",
          },
          {
            name: "g4b_crosssellproducts",
            default: false,
            description:
              "Should the system allow to offer cross sell products",
            label: "Cross Sell Products",
            type: "bit",
          },
          {
            name: "g4b_automarkticketsprinted",
            default: false,
            description:
              "If true, tickets will be marked as printed automatically",
            label: "Automatically Mark Tickets as Printed",
            type: "bit",
          },
          {
            name: "g4b_enableprintathome",
            default: false,
            description:
              "Determines whether the channel has a Print at Home option",
            label: "Enable Print at Home",
            type: "bit",
          },
          {
            name: "g4b_offeradditionalproducts",
            default: false,
            description:
              "Should the system offer additional products.",
            label: "Offer Additional Products",
            type: "bit",
          },
          {
            name: "g4b_productuserbookingsearch",
            default: false,
            description: "",
            label: "Product User Booking Search",
            type: "bit",
          },
          {
            name: "g4b_recalculatepriceonamend",
            default: false,
            description:
              "If true, for reserved bookings, the booking price will be recalculated on amend",
            label: "Recalculate Price On Amend",
            type: "bit",
          },
          {
            name: "g4b_selectdeliveryaddress",
            default: false,
            description: "Choose the delivery address for each order",
            label: "Select Delivery Address",
            type: "bit",
          },
          {
            name: "g4b_selectseats",
            default: false,
            description:
              "Whether users on this channel can select seats for seated events (rather than being auto allocated a seat).",
            label: "Select Seats",
            type: "bit",
          },
          {
            name: "g4b_showbeneficiaryentitleduser",
            default: false,
            description:
              "On the beneficiary window show entitled users as well as the beneficiary (end user).",
            label: "Show Beneficiary Entitled User",
            type: "bit",
          },
          {
            name: "g4b_validatepurchaserticketlimit",
            default: true,
            description:
              "If true, purchasers are prevented from exceeding the fixture or series ticket limit.",
            label: "Validate Purchaser Ticket Limit",
            type: "bit",
          },
        ],
      },
      {
        name: "Availability",
        fields: [
          {
            name: "g4b_minutesinthepast",
            description:
              "The available calendar starts several minutes before booking time, this indicates the count of the minutes",
            label: "Minutes in the Past",
            type: "int",
          },
          {
            name: "g4b_monthsinthefuture",
            description:
              "The available calendar ends serveral months after the booking time, this indicates the count of the months",
            label: "Months in the Future",
            type: "int",
          },
        ],
      },
      {
        name: "Printing",
        fields: [
          {
            name: "g4b_firstpagetransform",
            description: "",
            label: "First Page Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
          {
            name: "g4b_lastpagetransform",
            description: "",
            label: "Last Page Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Donation",
        fields: [
          {
            name: "g4b_donationproductid",
            description:
              "Unique identifier for Bookable Product associated with Channel.",
            label: "Donation Product",
            lookup: lookups.g4b_productbase.donation,
            type: "lookup",
          },
          {
            name: "g4b_donationpercent",
            description: "",
            label: "Donation Percent",
            type: "decimal",
          },
        ],
      },
      {
        name: "Reporting",
        fields: [
          {
            name: "g4b_reportname",
            description: "The name to appear on reports",
            label: "Report Name",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Epos",
    id: "epos",
    sections: [
      {
        name: "Settings",
        fields: [
          {
            name: "g4b_anonymousbooking",
            default: false,
            description:
              "If true, the channel allows bookings to be made without selecting a contact as the purchaser, but instead must enter a contact name.",
            label: "Anonymous booking",
            type: "bit",
          },
          {
            name: "g4b_callcentre",
            default: true,
            description: "True if this is a call centre channel",
            label: "Call Centre",
            type: "bit",
          },
          {
            name: "g4b_hidepromobutton",
            default: false,
            description:
              "Should the promotion button be hidden on the shopping cart panel",
            label: "Hide Promo Button",
            type: "bit",
          },
          {
            name: "g4b_membershipnumberreadonly",
            default: true,
            description: "",
            label: "Membership Number Read Only",
            type: "bit",
          },
          {
            name: "g4b_printreceipt",
            default: true,
            description:
              'Default value of the "Print Receipt" checkbox on the payment screen',
            label: "Print Receipt",
            type: "bit",
          },
          {
            name: "g4b_requestproductuserinfoafterpayment",
            default: false,
            description:
              "Request to enter product user details again after payment, usually when need to take a photo",
            label: "Request Product User Info After Payment",
            type: "bit",
          },
          {
            name: "g4b_requiresemailorphone",
            default: false,
            description:
              "When adding a purchase provider, setting this to Yes will require operators to specify either an email address or phone number supplied on creation",
            label: "Requires Email Or Phone",
            type: "bit",
          },
          {
            name: "g4b_contactrequiredfields",
            description:
              "Required fields for contact creation. The fields must be comma (,) separated.",
            label: "Contact Required Fields",
            type: "nvarchar",
          },
          {
            name: "g4b_productuserrequiredfields",
            description:
              "Required fields for product user creation. The fields must be comma (,) separated.",
            label: "Product User Required Fields",
            type: "nvarchar",
          },
          {
            name: "g4b_expandallproductuserslimit",
            description:
              "The number of product users in a booking allowed before the UI will minimise them all. (-1 will never minimise the product users)",
            label: "Expand All Product Users Limit",
            type: "int",
          },
        ],
      },
      {
        name: "Tabs",
        fields: [
          {
            name: "g4b_displayfinancetab",
            default: false,
            description:
              "Display a Finance tab in the Contact Editor to show finance agreements (payment mandates) for the contact",
            label: "Display Finance Tab",
            type: "bit",
          },
          {
            name: "g4b_displaymembershipstab",
            default: false,
            description: "",
            label: "Display Memberships Tab",
            type: "bit",
          },
          {
            name: "g4b_displayproductsearchtab",
            default: false,
            description: "",
            label: "Display Product Search Tab",
            type: "bit",
          },
          {
            name: "g4b_displayothertab",
            default: false,
            description: "",
            label: "Display Other Tab",
            type: "bit",
          },
        ],
      },
      {
        name: "Search",
        fields: [
          {
            name: "g4b_allowmembershipsearch",
            default: false,
            description: "Allow Membership Search",
            label: "Allow Membership Search",
            type: "bit",
          },
          {
            name: "g4b_externalreferencesearch",
            default: false,
            description: "",
            label: "External reference search",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Payment defaults",
    id: "payment-defaults",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_sessionturnsoncashhandling",
            default: false,
            description: "",
            label: "Session Turns on Cash Handling",
            type: "bit",
          },
          {
            name: "g4b_sendcustomerbookingconfirmation",
            default: false,
            description:
              'Set the default value of "Send Confirmation Email to Customer" check box on payment dialog',
            label: "Send Customer Booking Confirmation",
            type: "bit",
          },
          {
            name: "g4b_opentill",
            default: false,
            description:
              "Determines if a till will be opened after a payment has been made",
            label: "Open Till",
            type: "bit",
          },
          {
            name: "g4b_closebooking",
            default: false,
            description:
              "Whether to immediately close booking if fully paid",
            label: "Close Booking if paid",
            type: "bit",
          },
          {
            name: "g4b_refundauthorisationrequired",
            default: true,
            description:
              "When giving a refund should should the authorisation dialog be displayed",
            label: "Refund Authorisation Required",
            type: "bit",
          },
          {
            name: "g4b_validatedeliveryproduct",
            default: false,
            description:
              "If true, a valid delivery product must be selected before a booking can be completed.",
            label: "Validate Delivery Product",
            type: "bit",
          },
          {
            name: "g4b_autocompletevoucherpayment",
            default: false,
            description:
              "If true, vouchers will be automatically redeemed once applied to a booking.",
            label: "Auto Complete Voucher Payment",
            type: "bit",
          },
          {
            name: "g4b_tillpodlimitwarninginterval",
            description:
              "A warning message will be displayed every X transactions if the Pod limit is exceeded. If left empty or set to zero, then the default is a message will appear for every transaction.",
            label: "Till Pod Limit Warning Interval",
            type: "int",
          },
          {
            name: "g4b_tillpodlimit",
            default: "0",
            description:
              "The amount of cash a till draw can contain before a warning is displayed to inform the operator they must carry out a pod lift",
            label: "Till Pod Limit",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Contact fields",
    id: "contact-fields",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_contactbulkemaildefault",
            default: false,
            description:
              'Default value of "Allow bulk email" checkbox',
            label: "Allow Bulk Email",
            type: "bit",
          },
          {
            name: "g4b_collectreferredfromattribute",
            default: false,
            description:
              "If true, a dropdown containing the referred from options will be displayed on the contact forms",
            label: "Collect Referred From Attribute",
            type: "bit",
          },
          {
            name: "g4b_familycontactdetailsrequired",
            default: true,
            description:
              "Family beneficiary contact details required",
            label: "Contact details required",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Stadium options",
    id: "stadium-options",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_showstadiumoptions",
            default: false,
            description:
              "Should the application show the stadium options. This is used by the epos product panel. If yes then the series and fixture buttons are displayed by default",
            label: "Show Stadium Options",
            type: "bit",
          },
          {
            name: "g4b_stadiumoptionsview",
            description:
              "How should the stadium options home screen be displayed",
            label: "Stadium Options View",
            type: "picklist",
            enum: StadiumOptions,
          },
          {
            name: "g4b_stadiumoptionsdefaulttab",
            description: "",
            label: "Default Tab",
            type: "picklist",
            enum: StadiumOptionsDefaultTab,
          },
        ],
      },
    ],
  },
  {
    title: "Channel Questions",
    id: "channel-questions",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_sequence",
              className: "col-sequence",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4b_channelid",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/channel-question",
          linkedEntityId: "g4b_channelid",
          lookup: lookups.g4b_channelquestion.all,
          subFormStages: ChannelQuestionFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
