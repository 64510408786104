import { AnswerGroupFormStages } from "./answerGroup";
import lookups from "./lookups";

export const QuestionFormStages = [
  {
    title: "Question",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4_name",
            description: "",
            isNameField: true,
            label: "Question to ask",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4_mandatory",
            default: false,
            description: "",
            label: "Mandatory",
            type: "bit",
          },
          {
            name: "g4_answergroupid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Answer Group",
            lookup: lookups.g4_answergroup.all,
            required: true,
            subFormStages: AnswerGroupFormStages,
            type: "lookup",
          },
          {
            name: "g4b_widgetgroupid",
            description: "",
            label: "Widget Group",
            lookup: lookups.g4b_widgetgroup.all,
            type: "lookup",
          },
          {
            name: "g4_placeholdertext",
            description:
              "Set placeholder text for the answer dropdown",
            label: "Placeholder text",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
