export const TwoDimensionalBarcodeDefaultValues = {
  BackgroundColour: null,
  BarcodeFormat: 17,
  Capitalized: false,
  DateFormat: null,
  FontName: null,
  FontSize: 0,
  ForegroundColour: null,
  Height: 20,
  InvertColours: false,
  KeepAspectRatio: false,
  Left: 0,
  Orientation: 0,
  ShowHumanReadable: false,
  ShowTotalSeparator: false,
  TextWrap: false,
  Top: 0,
  Type: 4,
  UseOrdinalDateSuffix: false,
  Value: {
    DisplayValue: "Barcode",
    Format: 0,
    IsStatic: false,
    Value: "",
  },
  Width: 20,
};
