export const ImageDefaultValues = {
  BackgroundColour: null,
  BarcodeFormat: 0,
  Capitalized: false,
  DateFormat: null,
  FontName: null,
  FontSize: 0,
  ForegroundColour: null,
  Height: 20,
  InvertColours: false,
  KeepAspectRatio: false,
  Left: 0,
  Orientation: 0,
  ShowHumanReadable: false,
  ShowTotalSeparator: false,
  TextWrap: false,
  Top: 0,
  Type: 2,
  UseOrdinalDateSuffix: false,
  Value: {
    DisplayValue: "",
    Format: 0,
    IsStatic: true,
    Value: "",
  },
  Width: 20,
};
