import lookups from "./lookups";
import { ContactFormStages } from "./contact";
import { administratorRoles } from "../js/roles";

export const ContactsFormStages = [
  {
    title: "Contacts",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowAddRoles: administratorRoles,
          allowEdit: true,
          allowStatusEdit: true,
          statusEditAllowedRoles: administratorRoles,
          useSubmitButton: true,
          fields: [
            {
              name: "firstname",
              className: "col-name",
              type: "string",
            },
            {
              name: "lastname",
              className: "col-name",
              type: "string",
            },
            {
              name: "emailaddress1",
              className: "col-name",
              type: "string",
            },
            {
              name: "haswebaccount",
              className: "col-bool",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "firstname",
              type: "text",
            },
            {
              name: "lastname",
              type: "text",
            },
            {
              name: "usesoundex",
              type: "bit",
            },
            {
              name: "emailaddress1",
              description: "Must be an exact match",
              type: "text",
            },
          ],
          linkForNewRecord: "/contact",
          subFormStages: ContactFormStages,
          lookup: lookups.contact.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
