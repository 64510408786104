import { Button } from "./_Elements";
import { ButtonStyle } from "../../js/enums";

const AlphabetPagination = ({ currentLetter, onSelectLetter }) => {
  const letters = [
    "All",
    ...Array.from({ length: 26 }, (_, i) =>
      String.fromCharCode(65 + i)
    ),
  ];

  return (
    <div className="d-flex justify-content-center flex-wrap">
      {letters.map((letter, i) =>
        currentLetter === letter ||
        (letter === "All" && currentLetter === "") ? (
          <Button key={i} style={ButtonStyle.Primary} text={letter} />
        ) : (
          <Button
            key={i}
            className="text-decoration-none"
            style={ButtonStyle.Link}
            text={letter}
            onClick={() =>
              onSelectLetter(letter === "All" ? "" : letter)
            }
          />
        )
      )}
    </div>
  );
};
export default AlphabetPagination;
