const BookingsTableDetails = {
  Title: "Bookings",
  Columns: [
    {
      headingName: "Booking Status",
      className: "col-primary",
      fieldName: "BookingStatus",
      type: "string",
    },
    {
      headingName: "Total Price",
      className: "col-number",
      fieldName: "TotalPrice",
      type: "money",
    },
    {
      headingName: "Membership Price",
      className: "col-number",
      fieldName: "MembershipPrice",
      type: "money",
    },
    {
      headingName: "Record Count",
      className: "col-number",
      fieldName: "RecordCount",
      type: "string",
    },
  ],
};

const BookingsTotalErrorsTableDetails = {
  Title: "Bookings With Total Errors",
  Columns: [
    {
      headingName: "Booking Reference",
      className: "col-primary",
      fieldName: "BookingReference",
      type: "string",
    },
    {
      headingName: "Booking Status",
      className: "col-short-name",
      fieldName: "BookingStatus",
      type: "string",
    },
    {
      headingName: "Total Booking Price",
      className: "col-number",
      fieldName: "TotalBookingPrice",
      type: "money",
    },
    {
      headingName: "Total Booking Product Price",
      className: "col-number",
      fieldName: "TotalBookingProductPrice",
      type: "money",
    },
    {
      headingName: "Total Journal Entry",
      className: "col-number",
      fieldName: "TotalJournalEntry",
      type: "money",
    },
    {
      headingName: "Total Journal Payment",
      className: "col-number",
      fieldName: "TotalJournalPayment",
      type: "money",
    },
    {
      headingName: "Created On",
      className: "col-date",
      fieldName: "CreatedOn",
      type: "datetime",
    },
    {
      headingName: "Modified On",
      className: "col-date",
      fieldName: "ModifiedOn",
      type: "datetime",
    },
  ],
};

const JournalEntriesTableDetails = {
  Title: "Non membership bookings without Journal Entries",
  Columns: [
    {
      headingName: "Booking Reference",
      className: "col-primary",
      fieldName: "BookingReference",
      type: "string",
    },
    {
      headingName: "Booking Status",
      className: "col-short-name",
      fieldName: "BookingStatus",
      type: "string",
    },
    {
      headingName: "Total Price",
      className: "col-number",
      fieldName: "TotalPrice",
      type: "money",
    },
    {
      headingName: "Membership Amount",
      className: "col-number",
      fieldName: "MembershipAmount",
      type: "money",
    },
    {
      headingName: "Product Type",
      className: "col-short-name",
      fieldName: "ProductType",
      type: "string",
    },
    {
      headingName: "Product",
      className: "col-short-name",
      fieldName: "Product",
      type: "string",
    },
    {
      headingName: "Variant",
      className: "col-short-name",
      fieldName: "Variant",
      type: "string",
    },
    {
      headingName: "Number Of Products",
      className: "col-number",
      fieldName: "NumberOfProducts",
      type: "string",
    },
    {
      headingName: "Created On",
      className: "col-date",
      fieldName: "CreatedOn",
      type: "datetime",
    },
    {
      headingName: "Modified On",
      className: "col-date",
      fieldName: "ModifiedOn",
      type: "datetime",
    },
  ],
};

const JournalPaymentsTableDetails = {
  Title: "Non membership bookings without Journal Payments",
  Columns: [
    {
      headingName: "Booking Reference",
      className: "col-primary",
      fieldName: "BookingReference",
      type: "string",
    },
    {
      headingName: "Booking Status",
      className: "col-short-name",
      fieldName: "BookingStatus",
      type: "string",
    },
    {
      headingName: "Total Price",
      className: "col-number",
      fieldName: "TotalPrice",
      type: "money",
    },
    {
      headingName: "Membership Amount",
      className: "col-number",
      fieldName: "MembershipAmount",
      type: "money",
    },
    {
      headingName: "Product Type",
      className: "col-short-name",
      fieldName: "ProductType",
      type: "string",
    },
    {
      headingName: "Product",
      className: "col-short-name",
      fieldName: "Product",
      type: "string",
    },
    {
      headingName: "Variant",
      className: "col-short-name",
      fieldName: "Variant",
      type: "string",
    },
    {
      headingName: "Number Of Products",
      className: "col-number",
      fieldName: "NumberOfProducts",
      type: "string",
    },
    {
      headingName: "Has Missing Journal Entries",
      className: "col-bool",
      fieldName: "HasMissingJournalEntries",
      type: "boolean",
    },
    {
      headingName: "Payment Status",
      className: "col-number",
      fieldName: "PaymentStatus",
      type: "string",
    },
    {
      headingName: "Payment Amount",
      className: "col-number",
      fieldName: "PaymentAmount",
      type: "money",
    },
    {
      headingName: "Membership Payment Amount",
      className: "col-number",
      fieldName: "MembershipPaymentAmount",
      type: "money",
    },
    {
      headingName: "Created On",
      className: "col-date",
      fieldName: "CreatedOn",
      type: "datetime",
    },
    {
      headingName: "Modified On",
      className: "col-date",
      fieldName: "ModifiedOn",
      type: "datetime",
    },
  ],
};

const MembershipJournalEntriesTableDetails = {
  Title: "Membership bookings without Journal Entries",
  Columns: [
    {
      headingName: "Booking Reference",
      className: "col-primary",
      fieldName: "BookingReference",
      type: "string",
    },
    {
      headingName: "Booking Status",
      className: "col-short-name",
      fieldName: "BookingStatus",
      type: "string",
    },
    {
      headingName: "Total Price",
      className: "col-number",
      fieldName: "TotalPrice",
      type: "money",
    },
    {
      headingName: "Membership Amount",
      className: "col-number",
      fieldName: "MembershipAmount",
      type: "money",
    },
    {
      headingName: "Product Type",
      className: "col-short-name",
      fieldName: "ProductType",
      type: "string",
    },
    {
      headingName: "Product",
      className: "col-short-name",
      fieldName: "Product",
      type: "string",
    },
    {
      headingName: "Variant",
      className: "col-short-name",
      fieldName: "Variant",
      type: "string",
    },
    {
      headingName: "Number Of Products",
      className: "col-number",
      fieldName: "NumberOfProducts",
      type: "string",
    },
    {
      headingName: "Transaction Type",
      className: "col-short-name",
      fieldName: "TransactionType",
      type: "string",
    },
    {
      headingName: "Created On",
      className: "col-date",
      fieldName: "CreatedOn",
      type: "datetime",
    },
    {
      headingName: "Modified On",
      className: "col-date",
      fieldName: "ModifiedOn",
      type: "datetime",
    },
  ],
};

const MembershipJournalPaymentsTableDetails = {
  Title: "Membership bookings without Journal Payments",
  Columns: [
    {
      headingName: "Booking Reference",
      className: "col-primary",
      fieldName: "BookingReference",
      type: "string",
    },
    {
      headingName: "Booking Status",
      className: "col-short-name",
      fieldName: "BookingStatus",
      type: "string",
    },
    {
      headingName: "Total Price",
      className: "col-number",
      fieldName: "TotalPrice",
      type: "money",
    },
    {
      headingName: "Membership Amount",
      className: "col-number",
      fieldName: "MembershipAmount",
      type: "money",
    },
    {
      headingName: "Product Type",
      className: "col-short-name",
      fieldName: "ProductType",
      type: "string",
    },
    {
      headingName: "Product",
      className: "col-short-name",
      fieldName: "Product",
      type: "string",
    },
    {
      headingName: "Variant",
      className: "col-short-name",
      fieldName: "Variant",
      type: "string",
    },
    {
      headingName: "Number Of Products",
      className: "col-number",
      fieldName: "NumberOfProducts",
      type: "string",
    },
    {
      headingName: "Transaction Type",
      className: "col-short-name",
      fieldName: "TransactionType",
      type: "string",
    },
    {
      headingName: "Created On",
      className: "col-date",
      fieldName: "CreatedOn",
      type: "datetime",
    },
    {
      headingName: "Modified On",
      className: "col-date",
      fieldName: "ModifiedOn",
      type: "datetime",
    },
  ],
};

export {
  BookingsTableDetails,
  BookingsTotalErrorsTableDetails,
  JournalEntriesTableDetails,
  JournalPaymentsTableDetails,
  MembershipJournalEntriesTableDetails,
  MembershipJournalPaymentsTableDetails,
};
