import { useEffect, useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import DefaultLayout from "./layouts/DefaultLayout";
import { GetReport } from "./js/service";
import { useGlobalReducer, useGlobalState } from "./GlobalContext";
import { AlertStyle, LoadingState } from "./js/enums";
import { Alert, Loading } from "./components/elements/_Elements";
import { userHasCorrectRole } from "./js/roles";

export default function Reporting({ allowedRoles, reportType }) {
  const globalDispatch = useGlobalReducer();

  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [reportConfig, setReportConfig] = useState({
    reportId: undefined,
    embedToken: undefined,
    embedUrl: undefined,
  });

  const { reportId, embedToken, embedUrl } = reportConfig;

  useEffect(() => {
    const fetchReportConfig = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetReport(globalDispatch, reportType),
        ]);

        if (serviceResponse && serviceResponse.data) {
          setReportConfig({
            reportId: serviceResponse.data.reportId,
            embedToken: serviceResponse.data.embedToken,
            embedUrl: serviceResponse.data.embedUrl,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };

    if (!embedUrl) {
      fetchReportConfig();
    }
  }, [embedUrl, globalDispatch, reportType]);

  const globalState = useGlobalState();
  const { user } = globalState;

  const hasCorrectRole = userHasCorrectRole(user, allowedRoles);

  return (
    <DefaultLayout>
      {loading !== LoadingState.Loaded ? (
        <Loading />
      ) : !hasCorrectRole ? (
        <DefaultLayout>
          {user && (
            <Alert
              className="m-3"
              style={AlertStyle.Danger}
              text={"Invalid Role"}
            /> //Only show the alert if the user has been loaded
          )}
        </DefaultLayout>
      ) : (
        <>
          {embedUrl && embedToken && (
            <>
              {embedToken.error ? (
                <Alert text={embedToken.error} />
              ) : (
                <PowerBIEmbed
                  embedConfig={{
                    type: "report",
                    id: reportId,
                    embedUrl: embedUrl,
                    accessToken: embedToken.token,
                    tokenType: models.TokenType.Embed,
                  }}
                  cssClassName={"h-100"}
                />
              )}
            </>
          )}
        </>
      )}
    </DefaultLayout>
  );
}
