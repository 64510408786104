import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { BlockFormStages } from "./block";

export const BlocksFormStages = [
  {
    title: "Blocks",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_code",
              className: "col-short-name",
              type: "string",
            },
            {
              name: "g4b_venueid",
              className: "col-filter",
            },
            {
              name: "g4b_areaid",
              className: "col-filter",
            },
            {
              name: "g4b_seated",
              className: "col-bool",
            },
            {
              name: "g4b_unseatedcapacity",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_rows",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_seats",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_code"],
              type: "text",
            },
            {
              name: "g4b_venueid",
              type: "select",
            },
            {
              name: "g4b_areaid",
              className: "select",
            },
            {
              name: "g4b_seated",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/block",
          lookup: lookups.g4b_block.all,
          subFormStages: BlockFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
