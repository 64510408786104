import EditTrigger from "./EditTrigger";
import TriggerElements from "./TriggerElements";

function Sidebar({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  state,
  touched,
  values,
}) {
  return values.editTrigger ? (
    <EditTrigger
      {...{
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        state,
        touched,
        values,
      }}
    />
  ) : (
    <TriggerElements />
  );
}

export default Sidebar;
