import { OperatorFormStages } from "./operator";
import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const OperatorsFormStages = [
  {
    title: "Operators",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            { name: "g4b_operatorgroupid", className: "col-name" },
            { name: "g4b_pin", className: "col-short-name" },
            { name: "g4b_allowdiscounts", className: "col-bool" },
            { name: "g4b_allowrefunds", className: "col-bool" },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "g4b_operatorgroupid",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/operator",
          lookup: lookups.g4b_bookingoperator.all,
          subFormStages: OperatorFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
