import lookups from "./lookups";
import { CountryFormStages } from "./country";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const CountriesFormStages = [
  {
    title: "Countries",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4_sequence",
              className: "col-sequence",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4_sequence",
          initialOrderedByAsc: true,
          linkForNewRecord: "/country",
          lookup: lookups.g4_country.all,
          subFormStages: CountryFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
