import { TextFormatType } from "../../Enums";
import { getOptionsForPrintJobType } from "../../Helpers";

const TextFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "Name",
            description: "",
            label: "Name",
            mandatory: true,
            type: "nvarchar",
          },
          {
            name: "Value.IsStatic",
            description: "",
            label: "Static Text",
            type: "bit",
          },
          {
            name: "Value.Value",
            description: "",
            label: "Value",
            mandatory: true,
            mapOptions: (_, state, values) => {
              const { g4b_printerjobtypeidname } = state;
              return getOptionsForPrintJobType(
                g4b_printerjobtypeidname
              );
            },
            mapType: (values) =>
              values.Value.IsStatic ? "nvarchar" : "lookup",
          },
          {
            name: "Value.Format",
            description:
              "Select a format to apply percent, duration or currency formatting",
            displayIfFromValues: (values) => !values.Value.IsStatic,
            enum: TextFormatType,
            label: "Format",
            type: "picklist",
          },
        ],
      },
    ],
  },
  {
    title: "Position",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "Left",
            label: "Left",
            mandatory: true,
            type: "int",
          },
          { name: "Top", label: "Top", mandatory: true, type: "int" },
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Size",
        fields: [
          {
            name: "Width",
            label: "Width",
            mandatory: true,
            type: "int",
          },
          {
            name: "Height",
            label: "Height",
            mandatory: true,
            type: "int",
          },
        ],
      },
      {
        name: "Colours",
        fields: [
          {
            name: "BackgroundColour",
            colourPickerType: "RGB",
            label: "Background Colour",
            type: "colourpicker",
          },
          {
            name: "ForegroundColour",
            colourPickerType: "RGB",
            label: "Foreground Colour",
            mandatory: true,
            type: "colourpicker",
          },
          {
            name: "InvertColours",
            description: "",
            label: "Invert Colours",
            type: "bit",
          },
        ],
      },
      {
        name: "Font",
        fields: [
          {
            name: "FontName",
            description: "",
            label: "Font Name",
            mandatory: true,
            type: "nvarchar",
          },
          {
            name: "FontSize",
            description: "",
            label: "Font Size",
            mandatory: true,
            type: "nvarchar",
          },
          {
            name: "TextWrap",
            description: "",
            label: "Text Wrap",
            type: "bit",
          },
          {
            name: "Capitalize",
            description: "",
            label: "Capitalize",
            type: "bit",
          },
        ],
      },
    ],
  },
];

export default TextFormStages;
