export const SeatAllocationFormStages = [
  {
    title: "General",
    id: "general",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4b_row",
            description: "",
            displayIfFromState: (state) => state.g4b_seated,
            label: "Row",
            type: "int",
          },
          {
            name: "g4b_seat",
            description: "",
            displayIfFromState: (state) => state.g4b_seated,
            label: "Seat",
            type: "int",
          },
        ],
      },
    ],
  },
  {
    title: "Product Information",
    id: "product-information",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_bookingidname",
            description: "",
            label: "Booking",
            type: "nvarchar",
          },
          {
            name: "g4b_protoproductidname",
            description: "",
            label: "Product",
            type: "nvarchar",
          },
          {
            name: "g4b_fixtureidname",
            description: "",
            label: "Fixture",
            type: "nvarchar",
          },
          {
            name: "g4b_seriesidname",
            description: "",
            label: "Series",
            type: "nvarchar",
          },
          {
            name: "g4b_blockidname",
            description: "",
            label: "Block",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
