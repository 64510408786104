import lookups from "./lookups";
import { BookingStatus, PreferredContactMethod } from "../js/enums";
import { BookingFormStages } from "./booking";
import AddRemoveRecordsTable from "../components/elements/AddRemoveRecordsTable";
import { PastYearsSelectHeader } from "../components/elements/_Elements";
import { administratorRoles } from "../js/roles";

export const AccountFormStages = [
  {
    title: "Main Details",
    id: "main-details",
    recordEditRoles: administratorRoles,
    statusEditAllowedRoles: administratorRoles,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "name",
            className: "col-md-8",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_creditaccount",
            className: "col-md-3",
            description: "",
            label: "Credit Account",
            required: false,
            type: "bit",
          },
          {
            name: "g4b_commissionbyinvoice",
            className: "col-md-3",
            description: "",
            label: "Price Includes Commission",
            required: false,
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Marketing Preferences",
    id: "marketing-preferences",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "preferredcontactmethodcode",
            className: "col-md-4",
            description: "",
            enum: PreferredContactMethod,
            label: "Preferred Contact Method",
            required: false,
            type: "picklist",
          },
          {
            name: "donotemail",
            className: "col-md-4",
            description: "",
            label: "Send Emails",
            required: false,
            type: "bit",
          },
          {
            name: "donotbulkemail",
            className: "col-md-4",
            description: "",
            label: "Send Bulk Emails",
            required: false,
            type: "bit",
          },
          {
            name: "donotpostalmail",
            className: "col-md-4",
            description: "",
            label: "Send Mail",
            required: false,
            type: "bit",
          },
          {
            name: "donotbulkpostalmail",
            className: "col-md-4",
            description: "",
            label: "Send Bulk Mail",
            required: false,
            type: "bit",
          },
          {
            name: "donotphone",
            className: "col-md-4",
            description: "",
            label: "Allow Phone Calls",
            required: false,
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Booking History",
    id: "booking-history",
    displayIfFromState: (values) => values.accountid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking Reference",
              className: "col-primary",
              isNameField: true,
            },
            {
              name: "g4b_bookingstatus",
              label: "Booking Status",
              className: "col-name",
              type: "statusbadge",
              enum: BookingStatus,
            },
            {
              name: "g4b_channelidname",
              label: "Channel",
              className: "col-short-name",
            },
            {
              name: "g4b_totalprice",
              label: "Total Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_discountvalue",
              label: "Discount Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_contactidname",
              label: "Purchaser",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_bookingstatus",
              defaultValue: [BookingStatus.Active],
              enum: BookingStatus,
              label: "Booking Status",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4b_booking",
          lookup: lookups.g4b_booking.all,
          subFormStages: BookingFormStages,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Contacts",
    id: "contacts",
    displayIfFromState: (values) => values.accountid,
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "contactsTable",
            className: "col-12",
            allowAddRoles: administratorRoles,
            allowEdit: true,
            component: AddRemoveRecordsTable,
            existingRecordsEntitySearchName: "accountcontacts",
            existingRecordsEntitySearchFilter:
              "ignoreresultexceedcheck",
            existingRecordsSearchFilterArray: [
              {
                name: "fullname",
                description: "",
                label: "Name",
              },
              {
                name: "emailaddress1",
                description: "Must be an exact match",
                label: "Email Address",
              },
            ],
            lookup: lookups.contact.all,
            type: "component",
            recordsAddValue: "contactsToAdd",
            recordsRemoveValue: "contactsToRemove",
            recordsTableColumns: [
              {
                headingName: "Name",
                className: "col-primary",
                isNameField: true,
                type: "string",
                linkById: true,
                linkedEntityPath: "/contact",
                openInNewTab: true,
              },
              {
                headingName: "Email Address",
                className: "col-primary",
                fieldName: "emailaddress1",
                type: "string",
              },
            ],
            addRecordsEntitySearchName: "contact",
            addRecordsEntitySearchFilter: "filteroutaccount",
            addRecordsSearchFilterArray: [
              {
                name: "firstname",
                label: "First Name",
                type: "text",
              },
              {
                name: "lastname",
                label: "Last Name",
                type: "text",
              },
              {
                name: "usesoundex",
                label: "Use Sounds Like",
                type: "bit",
              },
              {
                name: "emailaddress1",
                description: "Must be an exact match",
                label: "Email Address",
                type: "text",
              },
            ],
            addRecordsTableColumns: [
              {
                headingName: "Name",
                className: "col-primary",
                isNameField: true,
                type: "string",
                linkById: true,
                linkedEntityPath: "/contact",
                openInNewTab: true,
              },
              {
                headingName: "Email Address",
                className: "col-primary",
                fieldName: "emailaddress1",
                type: "string",
              },
            ],
          },
          { name: "contactsToAdd", hidden: true },
          { name: "contactsToRemove", hidden: true },
        ],
      },
    ],
  },
];
