import { PromotionFormStages } from "./promotion";
import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const PromotionsFormStages = [
  {
    title: "Promotions",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_code",
              className: "col-short-name",
            },
            {
              name: "g4b_promotiontypeid",
              className: "col-name",
            },
            {
              name: "g4b_value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_percent",
              className: "col-number",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_promotiontypeid",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/promotion",
          lookup: lookups.g4b_promotion.all,
          subFormStages: PromotionFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
