import { config } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignJustify,
  faAnglesLeft,
  faAnglesRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsRotate,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowsUpDownLeftRight,
  faBarcode,
  faCalendar,
  faCartShopping,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircleInfo,
  faClone,
  faCode,
  faCreditCard,
  faDotCircle,
  faEnvelope,
  faExclamationCircle,
  faFilter,
  faImage,
  faLink,
  faHandPointer,
  faMagnifyingGlass,
  faMinus,
  faPen,
  faPlus,
  faQrcode,
  faRefresh,
  faReceipt,
  faSave,
  faSquare,
  faTicket,
  faTimes,
  faTrash,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false;

export {
  faAlignJustify as TextIcon,
  faAnglesLeft as AnglesLeft,
  faAnglesRight as AnglesRight,
  faArrowDown as ArrowDown,
  faArrowLeft as ArrowLeft,
  faArrowRight as ArrowRight,
  faArrowUp as ArrowUp,
  faArrowsRotate as RotateIcon,
  faArrowRotateLeft as UndoIcon,
  faArrowRotateRight as RedoIcon,
  faArrowsUpDownLeftRight as MoveIcon,
  faBarcode as BarcodeIcon,
  faCalendar as CalendarIcon,
  faCartShopping as CartIcon,
  faCheck as CheckIcon,
  faChevronDown as ChevronDown,
  faChevronUp as ChevronUp,
  faCircleInfo as CircleInfo,
  faClone as CloneIcon,
  faCode as CodeIcon,
  faCreditCard as CreditCardIcon,
  faDotCircle as DotCircleIcon,
  faEnvelope as EnvelopeIcon,
  faExclamationCircle as WarningIcon,
  faFilter as FilterIcon,
  faImage as ImageIcon,
  faHandPointer as HandIcon,
  faLink as LinkIcon,
  faMagnifyingGlass as MagnifyIcon,
  faMinus as MinusIcon,
  faTimes as TimesIcon,
  faTrash as DeleteIcon,
  faPen as EditIcon,
  faPlus as PlusIcon,
  faQrcode as QrcodeIcon,
  faRefresh as RefreshIcon,
  faReceipt as ReceiptIcon,
  faSave as SaveIcon,
  faSquare as SquareIcon,
  faTicket as TicketIcon,
  faUser as UserIcon,
  faXmark as CrossIcon,
};
