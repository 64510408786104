export const BookableResourceCategoryFormStages = [
    {
      title: "Category",
      sections: [
        {
          name: "",
          fields: [
            {
              name: "g4b_name",
              description: "",
              isNameField: true,
              label: "Name",
              type: "nvarchar",
            },
          ],
        },
      ],
    },
  ];
  