const ImageFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "Name",
            description: "",
            label: "Name",
            mandatory: true,
            type: "nvarchar",
          },
          {
            name: "Value.Value",
            description: "",
            displayValue: (value) =>
              `data:image/jpeg;base64,${value}`,
            label: "Value",
            mandatory: true,
            type: "image",
          },
        ],
      },
    ],
  },
  {
    title: "Position",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "Left",
            label: "Left",
            mandatory: true,
            type: "int",
          },
          { name: "Top", label: "Top", mandatory: true, type: "int" },
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Size",
        fields: [
          {
            name: "Width",
            label: "Width",
            mandatory: true,
            type: "int",
          },
          {
            name: "Height",
            label: "Height",
            mandatory: true,
            type: "int",
          },
        ],
      },
    ],
  },
];

export default ImageFormStages;
