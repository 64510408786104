import React, { useEffect, useState } from "react";
import { GetAllEntityDetailedInfo } from "../../../js/service";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../../GlobalContext";
import { LoadingState } from "../../../js/enums";
import { Loading } from "../../elements/_Elements";
import { filterStages, getStagesToRender } from "../Helpers";
import { displayFieldValue } from "../../../js/utility";

function FixtureSeriesTable({ entityName, stages, state }) {
  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [detailedFixtureSeriesInfo, setDetailedFixtureSeriesInfo] =
    useState(null);

  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();
  let filteredStages = filterStages(stages, state, globalState);
  const stagesToRender = getStagesToRender(filteredStages);

  const onOverview =
    state.stage >= stagesToRender.length + 1 &&
    stagesToRender.length > 1;

  const targetEntity =
    entityName === "g4b_fixture"
      ? "g4b_series"
      : entityName === "g4b_series"
      ? "g4b_fixture"
      : "";

  useEffect(() => {
    const getDetailedFixtureSeriesInfo = async () => {
      if (
        onOverview &&
        targetEntity &&
        ((state.g4b_series && state.g4b_series.length > 0) ||
          (state.g4b_fixture && state.g4b_fixture.length > 0))
      ) {
        const [serviceResponse] = await Promise.all([
          GetAllEntityDetailedInfo(
            globalDispatch,
            targetEntity,
            false
          ),
        ]);
        if (serviceResponse && serviceResponse.data) {
          //Filter the results to only have the info for the fixture or series that have been selected
          let filteredResponse =
            targetEntity === "g4b_series"
              ? serviceResponse.data.filter((s) =>
                  state.g4b_series.includes(s.Id)
                )
              : targetEntity === "g4b_fixture"
              ? serviceResponse.data.filter((f) =>
                  state.g4b_fixture.includes(f.Id)
                )
              : [];
          setDetailedFixtureSeriesInfo(filteredResponse || []);
        }
      } else {
        setDetailedFixtureSeriesInfo([]);
      }
      setLoading(LoadingState.Loaded);
    };
    if (!detailedFixtureSeriesInfo) {
      getDetailedFixtureSeriesInfo();
    }
  }, [
    detailedFixtureSeriesInfo,
    entityName,
    globalDispatch,
    onOverview,
    stagesToRender.length,
    state.g4b_fixture,
    state.g4b_series,
    state.stage,
    targetEntity,
  ]);

  return (
    <>
      {onOverview && (
        <label
          for="FixtureSeriesTable"
          className="form-label me-auto"
        >
          {targetEntity === "g4b_series" ? "Series" : "Fixtures"}
        </label>
      )}
      {onOverview && loading !== LoadingState.Loaded ? (
        <Loading />
      ) : detailedFixtureSeriesInfo &&
        detailedFixtureSeriesInfo.length > 0 ? (
        <div className="list-view mb-3">
          <div className="table-responsive">
            <table className="table table-hover mb-0">
              <thead>
                <tr className="table-info">
                  <th scope="col" className="col-primary">
                    {targetEntity === "g4b_series"
                      ? "Series Name"
                      : targetEntity === "g4b_fixture"
                      ? "Fixture Name"
                      : ""}
                  </th>
                  <th scope="col" className="col-short-name">
                    Code
                  </th>
                  <th scope="col" className="col-short-name">
                    Venue
                  </th>
                  <th scope="col" className="col-bool">
                    Seated?
                  </th>
                  <th scope="col" className="col-date">
                    Start Date
                  </th>
                  <th scope="col" className="col-date">
                    End Date
                  </th>
                  <th scope="col" className="col-date">
                    Purchaser Ticket Limit
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailedFixtureSeriesInfo.map((record, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">
                        <span>{record.Name ? record.Name : ""}</span>
                      </th>
                      <td>
                        <span>
                          {displayFieldValue(
                            record.Fields.g4b_code,
                            "string",
                            null,
                            record,
                            state
                          )}
                        </span>
                      </td>
                      <td>
                        <span>
                          {displayFieldValue(
                            record.Fields.g4b_venueid,
                            "picklist",
                            null,
                            record,
                            state
                          )}
                        </span>
                      </td>
                      <td>
                        <span>
                          {displayFieldValue(
                            record.Fields.g4b_selectseats,
                            "boolean",
                            null,
                            record,
                            state
                          )}
                        </span>
                      </td>
                      {targetEntity === "g4b_series" ? (
                        <>
                          <td>
                            <span>
                              {displayFieldValue(
                                record.Fields.g4b_seriesstartdate,
                                "datetime",
                                null,
                                record,
                                state
                              )}
                            </span>
                          </td>
                          <td>
                            <span>
                              {displayFieldValue(
                                record.Fields.g4b_seriesenddate,
                                "datetime",
                                null,
                                record,
                                state
                              )}
                            </span>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            <span>
                              {displayFieldValue(
                                record.Fields.g4b_startdatetime,
                                "datetime",
                                null,
                                record,
                                state
                              )}
                            </span>
                          </td>
                          <td>
                            <span>
                              {displayFieldValue(
                                record.Fields.g4b_enddatetime,
                                "datetime",
                                null,
                                record,
                                state
                              )}
                            </span>
                          </td>
                        </>
                      )}
                      <td>
                        <span>
                          {displayFieldValue(
                            record.Fields.g4b_purchaserticketlimit,
                            "string",
                            null,
                            record,
                            state
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : onOverview ? (
        <p>
          <strong>
            {`No ${
              targetEntity === "g4b_series" ? "Series" : "Fixtures"
            } set`}
          </strong>
        </p>
      ) : (
        <></>
      )}
    </>
  );
}

export default FixtureSeriesTable;
