import { useState } from "react";
import CloseButton from "./CloseButton";
import "./imageUpload.scss";

function FileUpload({ handleChange, mapValue, name, value, label }) {
  const [errorMessage, setErrorMessage] = useState("");

  const maxFileSize = 1024000;

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file.size > maxFileSize) {
      setErrorMessage("File can not be larger than 1MB");
      return;
    }

    setErrorMessage("");

    const reader = new FileReader();

    reader.onload = () => {
      const base64Result = reader.result;
      const base64Image = base64Result.split(",")[1];

      handleFileChange(
        event,
        file.name,
        base64Image,
        file.type,
        file.size
      );
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = (
    event,
    fileName,
    base64String,
    mimeType,
    fileSize
  ) => {
    const fileValue = base64String
      ? {
          Name: fileName,
          Base64String: base64String,
          MimeType: mimeType,
          FileSize: fileSize,
        }
      : null;

    handleChange({
      ...event,
      target: {
        name: name,
        value:
          mapValue && fileValue ? mapValue(fileValue) : fileValue,
      },
    });
  };

  return (
    <div className="row">
      <div className="col-md-6 position-relative">
        {value && (mapValue || value.Base64String) && (
          <>
            <div className="row">
              <label>
                <strong>{label}</strong>
              </label>
            </div>
            <div className="row">
              <label>{value.Name}</label>
            </div>
            <div className="position-absolute top-0 end-0 pt-2 pe-3">
              <CloseButton
                className="bg-white"
                onClick={(event) =>
                  handleFileChange(event, null, null, null, null)
                }
              />
            </div>
          </>
        )}
      </div>
      <div className="col-md-6 d-flex align-items-stretch">
        <div
          className="image-upload w-75 d-flex justify-content-center align-items-center"
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <p className="my-5">Drop your file here</p>
        </div>
      </div>
      {errorMessage && (
        <div className="col-md-9 offset-md-3">
          <div className="d-block invalid-feedback">
            {errorMessage}
          </div>
        </div>
      )}
    </div>
  );
}

export default FileUpload;
