import { useEffect, useState } from "react";
import classNames from "classnames";
import lookups from "../../../forms/lookups";
import { addToSessionStorage, getLookupByName } from "../Helpers";
import { SaveIcon, SquareIcon } from "../../Icons";
import {
  Button,
  IconCard,
  Input,
  Loading,
  Modal,
} from "../../elements/_Elements";
import CommunicationTable from "./CommunicationTable";
import New from "./New";
import { LoadingState } from "../../../js/enums";
import { ButtonStyle } from "../../../js/enums";
import { useGlobalReducer } from "../../../GlobalContext";
import {
  GetAllEntityBasicInfo,
  GetEntityRecordById,
  Update,
} from "../../../js/service";
import { disableLinkClicks } from "../../../js/utility";
import { getNameForTemplate } from "./Helpers";

function SavedLayouts({
  handleChange,
  name,
  setFieldValue,
  state,
  value,
}) {
  const globalDispatch = useGlobalReducer();

  const newLayout = { Key: "New", Value: "New" };

  const [layoutLoading, setLayoutLoading] = useState(
    LoadingState.Loaded
  );
  const [savedLayoutsLookup, setSavedLayoutsLookup] = useState(null);

  const [layouts, setLayouts] = useState([]);
  const [previewLayout, setPreviewLayout] = useState(null);
  const [previewLayoutData, setPreviewLayoutData] = useState(null);

  const [layoutToDelete, setLayoutToDelete] = useState(null);
  const [layoutToRename, setLayoutToRename] = useState(null);
  const [newLayoutName, setNewLayoutName] = useState("");

  useEffect(() => {
    setSavedLayoutsLookup(
      getLookupByName(
        lookups.g4c_communicationsavedlayout.all,
        state.lookupOptions
      )
    );
  }, [state.lookupOptions]);

  const fetchLayout = async (id) => {
    setLayoutLoading(LoadingState.Loading);

    let layoutData = null;
    try {
      const [serviceResponse] = await Promise.all([
        GetEntityRecordById(
          globalDispatch,
          "g4c_communicationsavedlayout",
          id
        ),
      ]);

      if (
        serviceResponse &&
        serviceResponse.data &&
        serviceResponse.data.Fields
      ) {
        layoutData = serviceResponse.data.Fields.layout;
        layoutData.Key = id;
        setLayouts([...layouts, layoutData]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLayoutLoading(LoadingState.Loaded);
    }

    return layoutData;
  };

  const getLayout = (layout) => {
    return new Promise(function (resolve) {
      if (layout.Key === "New") {
        resolve(New(state.g4c_listtype));
      } else {
        const layoutData = layouts.find((l) => l.Key === layout.Key);
        if (layoutData) {
          resolve(layoutData);
        } else {
          resolve(fetchLayout(layout.Key));
        }
      }
    });
  };

  const selectLayout = (layout) => {
    // clear the value so that the buttons are disabled while the layout is loaded
    handleChange({
      target: {
        name: name,
        value: "",
      },
    });

    if (layout) {
      getLayout(layout).then(function (layoutData) {
        const {
          BackgroundColour,
          ContentBackgroundColour,
          ContentPadding,
          LinkColour,
          Padding,
          Rows,
        } = layoutData;

        // ensure that all of the controls have names
        Rows.forEach((row) => {
          row.Columns.forEach((column) => {
            column.Controls.forEach((control) => {
              if (!control.Name) {
                control.Name = getNameForTemplate(control.Template);
              }
            });
          });
        });

        setFieldValue("globalBackgroundColour", BackgroundColour);
        setFieldValue(
          "globalContentBackgroundColour",
          ContentBackgroundColour
        );
        setFieldValue("globalContentPadding", ContentPadding);
        setFieldValue("globalLinkColour", LinkColour);
        setFieldValue("globalPadding", Padding);
        setFieldValue("rows", Rows);

        handleChange({
          target: {
            name: name,
            value: layout,
          },
        });
      });
    }
  };

  const selectPreview = (layout) => {
    getLayout(layout).then(function (layoutData) {
      setPreviewLayoutData(layoutData);
      setPreviewLayout(layout);
    });
  };

  const deleteLayout = async () => {
    setLayoutLoading(LoadingState.Loading);
    selectLayout(null);
    try {
      const [deactivateResponse] = await Promise.all([
        Update(
          globalDispatch,
          `${layoutToDelete.Value} successfully deleted`,
          {
            Id: layoutToDelete.Key,
            Name: layoutToDelete.Value,
            Attributes: { statecode: 1, statuscode: 2 },
          },
          "g4c_communicationsavedlayout"
        ),
      ]);

      if (deactivateResponse && deactivateResponse.data) {
        setLayoutLoading(LoadingState.Loading);
        await resetSavedLayouts();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLayoutToDelete(null);
      setLayoutLoading(LoadingState.Loaded);
    }
  };

  const renameLayout = async () => {
    setLayoutLoading(LoadingState.Loading);
    selectLayout(null);
    try {
      const [renameResponse] = await Promise.all([
        Update(
          globalDispatch,
          `${layoutToRename.Value} successfully renamed to ${newLayoutName}`,
          {
            Id: layoutToRename.Key,
            Name: newLayoutName,
            Attributes: { g4c_name: newLayoutName },
          },
          "g4c_communicationsavedlayout"
        ),
      ]);

      if (renameResponse && renameResponse.data) {
        setLayoutLoading(LoadingState.Loading);
        await resetSavedLayouts();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLayoutToRename(null);
      setLayoutLoading(LoadingState.Loaded);
    }
  };

  const resetSavedLayouts = async () => {
    const [serviceResponse] = await Promise.all([
      GetAllEntityBasicInfo(
        globalDispatch,
        "g4c_communicationsavedlayout",
        ""
      ),
    ]);

    //Reset the value of the communication saved layout stored in the session storage
    if (serviceResponse && serviceResponse.data) {
      sessionStorage.removeItem(
        lookups.g4c_communicationsavedlayout.all.name
      );

      let commSavedLayoutLookup = state.lookupOptions.find(
        (lookup) =>
          lookup.name ===
          lookups.g4c_communicationsavedlayout.all.name
      );
      if (commSavedLayoutLookup) {
        commSavedLayoutLookup.data = serviceResponse.data;
      }
      addToSessionStorage(
        lookups.g4c_communicationsavedlayout.all.name,
        commSavedLayoutLookup.data
      );
    }
  };

  const isDefaultSelected = value && value.Key === "New";

  return layoutLoading === LoadingState.Loading ? (
    <Loading />
  ) : (
    <>
      {layoutToRename && (
        <Modal
          title={"Rename Saved Layout"}
          modalCloseButtonClick={() => {
            setLayoutToRename(null);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <small>
              {"Please enter the new name for your layout."}
            </small>
            <Input
              label={"New Name:"}
              mandatory={true}
              name={"NewLayoutName"}
              onChange={(event) =>
                setNewLayoutName(event.target.value)
              }
              value={newLayoutName}
            />
          </div>
          <div className="modal-footer">
            <Button
              text={"Save"}
              style={ButtonStyle.Primary}
              onClick={() => renameLayout()}
              disabled={!newLayoutName || newLayoutName.trim() === ""}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => setLayoutToRename(null)}
            />
          </div>
        </Modal>
      )}
      {layoutToDelete && (
        <Modal
          title={"Delete Saved Layout"}
          modalCloseButtonClick={() => {
            setLayoutToDelete(null);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <small>{`Are you sure you want to delete the ${layoutToDelete.Value} saved layout?`}</small>
          </div>
          <div className="modal-footer">
            <Button
              text={"Delete"}
              style={ButtonStyle.Primary}
              onClick={() => deleteLayout()}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => setLayoutToDelete(null)}
            />
          </div>
        </Modal>
      )}
      {previewLayout && (
        <Modal
          title={previewLayout.Value}
          modalCloseButtonClick={() => {
            setPreviewLayout(null);
            setPreviewLayoutData(null);
          }}
          className="modal modal-lg modal-dialog-scrollable"
        >
          <div
            id="saved-layout-preview"
            className="modal-body"
            onClick={(event) => disableLinkClicks(event)}
          >
            <p>
              <em>
                Please note that all links are disabled for the
                preview
              </em>
            </p>
            <CommunicationTable
              allowEdit={false}
              backgroundColour={previewLayoutData.BackgroundColour}
              contentBackgroundColour={
                previewLayoutData.ContentBackgroundColour
              }
              contentPadding={previewLayoutData.ContentPadding}
              linkColour={previewLayoutData.LinkColour}
              padding={previewLayoutData.Padding}
              rows={previewLayoutData.Rows}
            />
          </div>
        </Modal>
      )}
      {savedLayoutsLookup && savedLayoutsLookup.data && (
        <>
          <h5>Saved Layouts</h5>
          <div className="row mb-3">
            {savedLayoutsLookup.data.map((layout, index) => {
              const isSelected = value && value.Key === layout.Key;

              return (
                <div key={index} className="col-md-4 mb-3">
                  <IconCard
                    containerClass={classNames(
                      "border",
                      "rounded",
                      "cursor-pointer",
                      isSelected ? "border-primary" : ""
                    )}
                    icon={SaveIcon}
                    iconClassName={
                      isSelected ? "bg-primary" : "bg-info"
                    }
                    onClick={() => selectLayout(layout)}
                  >
                    {layout.Value}
                  </IconCard>

                  <div className="d-flex justify-content-between mb-3">
                    <Button
                      className={"mt-2"}
                      text={"Preview layout"}
                      style={ButtonStyle.OutlineSecondary}
                      onClick={() => {
                        selectPreview(layout);
                      }}
                    />
                    <Button
                      className={"mt-2 ms-2 me-2 me-auto"}
                      text={"Rename layout"}
                      style={ButtonStyle.OutlineSecondary}
                      onClick={() => {
                        setNewLayoutName(layout.Value);
                        setLayoutToRename(layout);
                      }}
                    />
                    <Button
                      className={"mt-2 ms-2"}
                      text={"Delete layout"}
                      style={ButtonStyle.Danger}
                      onClick={() => setLayoutToDelete(layout)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <h5>Standard Layouts</h5>
      <div className="row">
        <div className="col-md-4">
          <IconCard
            containerClass={classNames(
              "border",
              "rounded",
              "cursor-pointer",
              isDefaultSelected ? "border-primary" : ""
            )}
            icon={SquareIcon}
            iconClassName={classNames(
              isDefaultSelected ? "bg-primary" : "bg-info"
            )}
            onClick={() => selectLayout(newLayout)}
          >
            New
          </IconCard>
          <div className="d-flex justify-content-between mb-3">
            <Button
              className={"mt-2"}
              text={"Preview layout"}
              style={ButtonStyle.OutlineSecondary}
              onClick={() => {
                selectPreview(newLayout);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SavedLayouts;
