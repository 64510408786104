import { getEditLink } from "../form/Helpers";

function ReadOnlyLabel({ field, label, state, value }) {
  if (!label && !value) {
    return <></>;
  }

  return (
    <>
      <p className="fw-bold m-0">{label}</p>
      {field &&
      field.linkedEntityId &&
      field.linkForNewRecord &&
      state &&
      state[field.linkedEntityId] &&
      state[field.name] ? (
        <p>
          {getEditLink(
            state[field.linkedEntityId],
            field.linkForNewRecord,
            state[field.name],
            null,
            true
          )}
        </p>
      ) : (
        <>
          {field.type === "textarea" ? (
            <>{value || ""}</>
          ) : (
            <p>{value || ""}</p>
          )}
        </>
      )}
    </>
  );
}

export default ReadOnlyLabel;
