import { PromotionTypeFormStages } from "./promotionType";
import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const PromotionTypesFormStages = [
  {
    title: "Promotion Types",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/promotion-type",
          lookup: lookups.g4b_promotiontype.all,
          subFormStages: PromotionTypeFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
