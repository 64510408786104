import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import {
  Alert,
  EditElement,
  IconCard,
} from "../../elements/_Elements";
import { AlertStyle } from "../../../js/enums";
import {
  cloneTrigger,
  deleteTrigger,
  getCanBeCancelledLabel,
  getCaption,
  getCaptionName,
  getIconForFlowTrigger,
  getIconForWhen,
  getWhenText,
  setEditTrigger,
} from "./Helpers";
import { ChevronDown, ChevronUp, EnvelopeIcon } from "../../Icons";
import CommunicationTrigger from "./CommunicationTrigger";

function Trigger({
  activeId,
  errors,
  flowControl,
  index,
  setFieldValue,
  values,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [showCommunications, setShowCommunications] = useState(true);

  const {
    CanBeCancelled,
    Communications,
    Interval,
    Timepart,
    Trigger,
    When,
  } = flowControl;

  const id = `movetrigger-${index}`;

  const { icon, iconClassName } = getIconForFlowTrigger(Trigger);
  const whenIcon = getIconForWhen(When);

  const { attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: id,
    });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const { editTrigger } = values;
  const isEditing = editTrigger && editTrigger.index === index;
  const hasError = errors && errors[`trigger-${index}`];

  return (
    <EditElement
      className="rounded"
      hasError={hasError}
      isActive={activeId === id}
      isEditing={isEditing}
      moveAttributes={attributes}
      moveListeners={listeners}
      onClone={() => {
        cloneTrigger(globalDispatch, index, setFieldValue, values);
      }}
      onDelete={() => {
        deleteTrigger(globalDispatch, index, setFieldValue, values);
      }}
      onEdit={() => {
        setEditTrigger(
          dispatch,
          globalDispatch,
          index,
          flowControl,
          values
        );
      }}
      setNodeRef={setNodeRef}
      showToolbar={!activeId}
      style={style}
      toolbarClassName="rounded-top"
    >
      <IconCard
        icon={icon}
        iconClassName={classNames(
          "rounded-start",
          "text-white",
          iconClassName
        )}
      >
        <h5 className="mb-3">{getCaptionName(Trigger)}</h5>
        <div className="d-flex align-items-center mb-3">
          <FontAwesomeIcon className="me-4" icon={whenIcon} />
          <div>
            <span>{getWhenText(Interval, Timepart, When)}</span>
            <strong>{getCaption(Trigger)}</strong>
          </div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <FontAwesomeIcon className="me-4" icon={EnvelopeIcon} />
          <div>
            {Communications.length === 1 ? (
              <CommunicationTrigger
                communication={Communications[0]}
              />
            ) : (
              <span
                onClick={() =>
                  setShowCommunications(!showCommunications)
                }
              >
                <FontAwesomeIcon
                  className="me-2 cursor-pointer"
                  icon={showCommunications ? ChevronUp : ChevronDown}
                />
                Send 1/{Communications.length} communication variants
              </span>
            )}
          </div>
        </div>
        {showCommunications &&
          Communications.length > 1 &&
          Communications.map((communication, index) => {
            return (
              <div key={index} className="ms-5 mb-3">
                {index > 0 && <p className="ms-2 my-2">Otherwise</p>}
                <CommunicationTrigger communication={communication} />
              </div>
            );
          })}
        {CanBeCancelled && (
          <Alert
            className="m-0"
            style={AlertStyle.Warning}
            text={getCanBeCancelledLabel(Trigger)}
          />
        )}
      </IconCard>
    </EditElement>
  );
}

export default Trigger;
