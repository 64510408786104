import { DateTimePickerRangesAll } from "../../js/enums";
import { BadgeList } from "../elements/_Elements";
import {
  getFieldLabelForFormTable,
  getFieldObject,
  getLookupFieldOptions,
  getOptionsFromRecords,
} from "./Helpers";
import moment from "moment";

function getBadgeItems(
  allRecords,
  filters,
  globalState,
  stages,
  state,
  values
) {
  let items = [];
  filters
    .filter((f) => f.Key && f.Value)
    .forEach((filter) => {
      const field = getFieldObject(filter.Key, stages, filter.Type);
      if (field) {
        const { type } = field;
        const label = getFieldLabelForFormTable(field, stages);
        switch (type) {
          case "picklist":
            filter &&
              filter.Value &&
              filter.Value.forEach((v) => {
                let enumList = field.displayAltEnumViaGlobalState
                  ? field.displayAltEnumViaGlobalState(globalState)
                  : field.enum
                  ? field.enum
                  : null;

                const value = enumList
                  ? String(
                      Object.keys(enumList).find(
                        (key) => String(enumList[key]) === String(v)
                      )
                    )
                  : "";
                items.push({
                  field: field,
                  text: `${label}: ${value.toString()}`,
                  value: v,
                });
              });
            break;
          case "checklist":
          case "lookup":
            const useLookup =
              field.lookup &&
              state.lookupOptions &&
              state.lookupOptions.some(
                (x) => x.name === field.lookup.name
              );
            const options = useLookup
              ? getLookupFieldOptions(field, state, values, null)
              : allRecords
              ? getOptionsFromRecords(allRecords, filter.Key)
              : [];

            //Need this check for the filter.Value type just in case that we are
            //using the parent ID in the filter in which case the filter.Value
            //is going to be a string and we don't want to display a badge for it
            if (
              options &&
              filter &&
              filter.Value &&
              typeof filter.Value === "object"
            ) {
              filter.Value.forEach((v) => {
                const option = options.find(
                  (o) => String(o.Key) === String(v)
                );
                if (option) {
                  items.push({
                    field: field,
                    text: `${label}: ${option.Value.toString()}`,
                    value: v,
                  });
                }
              });
            }
            break;
          case "datetime":
            var text = "";
            var dateDirection = filter.Key.endsWith("-From")
              ? "From"
              : filter.Key.endsWith("-To")
              ? "To"
              : "";

            var formattedDateValue = moment(filter.Value).format(
              "DD/MM/yyyy"
            );
            switch (filter.DateTimeRange) {
              case DateTimePickerRangesAll.Custom:
                text = `${label} ${dateDirection}: ${formattedDateValue}`;
                break;
              case DateTimePickerRangesAll["On or After"]:
                text = `${label}: On or After ${formattedDateValue}`;
                break;
              case DateTimePickerRangesAll["On or Before"]:
                text = `${label}: On or Before ${formattedDateValue}`;
                break;
              //For the remaining date time picker ranges it sets
              //the from and to values to the start and end of the selected day respectively.
              //So we don't want to show the filter badge for To since both will end up showing the same value
              case DateTimePickerRangesAll.On:
                if (dateDirection !== "To") {
                  text = `${label}: On ${formattedDateValue}`;
                }
                break;
              case DateTimePickerRangesAll.Yesterday:
                if (dateDirection !== "To") {
                  text = `${label}: Yesterday`;
                }
                break;
              case DateTimePickerRangesAll.Today:
                if (dateDirection !== "To") {
                  text = `${label}: Today`;
                }
                break;
              case DateTimePickerRangesAll.Tomorrow:
                if (dateDirection !== "To") {
                  text = `${label}: Tomorrow`;
                }
                break;
              case DateTimePickerRangesAll["Next 7 Days"]:
                if (dateDirection !== "To") {
                  text = `${label}: Next 7 Days`;
                }
                break;
              case DateTimePickerRangesAll["Last 7 Days"]:
                if (dateDirection !== "To") {
                  text = `${label}: Last 7 Days`;
                }
                break;
              case DateTimePickerRangesAll["Next Month"]:
                if (dateDirection !== "To") {
                  text = `${label}: Next Month`;
                }
                break;
              case DateTimePickerRangesAll["Last Month"]:
                if (dateDirection !== "To") {
                  text = `${label}: Last Month`;
                }
                break;
              case DateTimePickerRangesAll["This Month"]:
                if (dateDirection !== "To") {
                  text = `${label}: This Month`;
                }
                break;
              case DateTimePickerRangesAll["Next Year"]:
                if (dateDirection !== "To") {
                  text = `${label}: Next Year`;
                }
                break;
              case DateTimePickerRangesAll["Last Year"]:
                if (dateDirection !== "To") {
                  text = `${label}: Last Year`;
                }
                break;
              case DateTimePickerRangesAll["This Year"]:
                if (dateDirection !== "To") {
                  text = `${label}: This Year`;
                }
                break;
              default:
                break;
            }
            if (text) {
              items.push({
                field: field,
                text: text,
                value: filter.Value,
              });
            }
            break;
          default:
            break;
        }
      }
    });

  items.sort((a, b) => a.text.localeCompare(b.text));

  return items;
}

function onDeleteBadge(i, setFilters) {
  setFilters((prevFilters) => {
    return prevFilters.map((filter) => {
      // If the key matches the field name then update
      if (filter.Key === i.field.name) {
        // filter out the value
        return {
          ...filter,
          DateTimeRange: null,
          Value:
            filter.Type === "datetime"
              ? null
              : filter.Value.filter((v) => v !== i.value),
        };
      }

      //if the datetime filter has a named range of the following selected, then we must
      //also remove the value of the to filter for the datetime filter as that also got set
      //when initially selected but the filter badge for it wasn't displayed
      //We do this by matching the field name prefix on the filter key and the i.field.name
      //values after substringing out the -To and the -From parts respectively
      if (
        filter.Type === "datetime" &&
        [
          DateTimePickerRangesAll.On,
          DateTimePickerRangesAll.Yesterday,
          DateTimePickerRangesAll.Today,
          DateTimePickerRangesAll.Tomorrow,
          DateTimePickerRangesAll["Next 7 Days"],
          DateTimePickerRangesAll["Last 7 Days"],
          DateTimePickerRangesAll["Next Month"],
          DateTimePickerRangesAll["Last Month"],
          DateTimePickerRangesAll["This Month"],
          DateTimePickerRangesAll["Next Year"],
          DateTimePickerRangesAll["Last Year"],
          DateTimePickerRangesAll["This Year"],
        ].includes(filter.DateTimeRange) &&
        filter.Key.substring(0, filter.Key.length - 3) ===
          i.field.name.substring(0, i.field.name.length - 5)
      ) {
        return {
          ...filter,
          DateTimeRange: null,
          Value: null,
        };
      }
      return filter;
    });
  });
}

function AppliedFilters({
  allRecords,
  filters,
  globalState,
  setFilters,
  stages,
  state,
  values,
}) {
  const items = getBadgeItems(
    allRecords,
    filters,
    globalState,
    stages,
    state,
    values
  );

  return items.length > 0 ? (
    <BadgeList
      {...{ items }}
      className="mb-3"
      onDeleteBadge={(i) => onDeleteBadge(i, setFilters)}
    />
  ) : (
    <></>
  );
}

export default AppliedFilters;
