import { Input } from "../../elements/_Elements";
import { getLeftPosition, getTopPosition } from "./Helpers";

function RowLabel({
  rowIndex,
  seatData,
  setFieldValue,
  setSeatData,
}) {
  const updateRowLabel = (event, row) => {
    let updatedSeatData = seatData;
    updatedSeatData.rowLabels[row] = event.target.value;
    setSeatData(updatedSeatData);
    setFieldValue("g4b_seatdata", JSON.stringify(updatedSeatData));
  };

  return (
    <div
      className="row-label"
      style={{
        top: getTopPosition(rowIndex, seatData.seats - 1, seatData),
        left: getLeftPosition(
          seatData.rows - 1,
          seatData.seats,
          seatData
        ),
      }}
    >
      <Input
        onChange={(event) => updateRowLabel(event, rowIndex)}
        type={"text"}
        value={seatData.rowLabels[rowIndex]}
      />
    </div>
  );
}

export default RowLabel;
