import { useState } from "react";
import ReactDOM from "react-dom";
import { withFormik } from "formik";
import { Button, Loading, Modal } from "../../elements/_Elements";
import { ButtonStyle, LoadingState } from "../../../js/enums";
import SidebarForm from "../SidebarForm";
import ExportFormStages from "./ExportFormStages";
import { ExportType, MarketingListType } from "./Enums";
import { getLookupsForStages, useLookupOptions } from "../Helpers";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import {
  ExportCommunicationAudienceToFile,
  ExportCommunicationAudienceToMarketingList,
} from "../../../js/service";

function ExportModal({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  resetForm,
  setFieldValue,
  setShowModal,
  state,
  touched,
  values,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [loading, setLoading] = useState(LoadingState.NotLoaded);

  const modalRoot = document.getElementById("modal-root");

  const lookupOptions = getLookupsForStages(ExportFormStages, state);

  useLookupOptions(
    dispatch,
    globalDispatch,
    loading,
    lookupOptions,
    setLoading,
    state
  );

  return !modalRoot ? (
    <></>
  ) : (
    <>
      {ReactDOM.createPortal(
        <form onSubmit={handleSubmit}>
          <Modal
            title={"Export"}
            modalCloseButtonClick={() => {
              resetForm();
              setShowModal(false);
            }}
            className="modal modal-dialog-scrollable"
          >
            {loading !== LoadingState.Loaded ? (
              <div className="modal-body">
                <Loading />
              </div>
            ) : (
              <>
                <div className="modal-body">
                  <p>
                    <em>
                      The export will use the rules for the saved
                      audience. Please ensure you have saved before
                      exporting.
                    </em>
                  </p>
                  <SidebarForm
                    {...{
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      state,
                      touched,
                      values,
                    }}
                    handleCheckListChange={(event) => {
                      handleChange(event);
                    }}
                    handleFilteredPicklistChange={(
                      event,
                      _,
                      name
                    ) => {
                      const value = event ? event.value : "";
                      setFieldValue(name, value);
                    }}
                    handlePicklistChange={(event) => {
                      handleChange(event);
                    }}
                    stages={ExportFormStages}
                    useTabs={false}
                  />
                </div>
                <div className="modal-footer">
                  {values.listLink && (
                    <a
                      href={values.listLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Marketing List
                    </a>
                  )}
                  <Button
                    showSpinner={isSubmitting}
                    style={ButtonStyle.Primary}
                    text="Export"
                    type="submit"
                  />
                </div>
              </>
            )}
          </Modal>
        </form>,
        modalRoot
      )}
    </>
  );
}

const ExportModalWithFormik = withFormik({
  mapPropsToValues: () => {
    return {
      exportFields: [],
      exportType: "",
      fileType: "",
      listId: "",
      listLink: "",
      listName: "",
      listType: "",
      replaceExistingMembers: false,
    };
  },

  validate: (values) => {
    const errors = {};

    if (values.exportType === "") {
      errors["exportType"] = "Required";
    }

    if (values.exportType === ExportType.File) {
      if (values.fileType === "") {
        errors["fileType"] = "Required";
      }

      if (values.exportFields.length === 0) {
        errors["exportFields"] = "Required";
      }
    }

    if (values.exportType === ExportType["Marketing List"]) {
      if (values.listType === "") {
        errors["listType"] = "Required";
      }

      if (
        values.listType === MarketingListType.New &&
        values.listName === ""
      ) {
        errors["listName"] = "Required";
      }

      if (
        values.listType === MarketingListType.Existing &&
        values.listId === ""
      ) {
        errors["listId"] = "Required";
      }
    }

    return errors;
  },

  handleSubmit: async (
    values,
    { props, setFieldValue, setSubmitting }
  ) => {
    setSubmitting(true);

    const { globalDispatch, state } = props;
    const { id } = state;
    const {
      exportFields,
      exportType,
      fileType,
      listId,
      listName,
      replaceExistingMembers,
    } = values;

    if (exportType === ExportType.File) {
      const result = await ExportCommunicationAudienceToFile(
        globalDispatch,
        "Export File Created",
        {
          Id: id,
          DataType: fileType,
          Fields: exportFields,
        }
      );

      if (result && result.data) {
        props.onFileExported(result.data);
      }
    } else {
      const result = await ExportCommunicationAudienceToMarketingList(
        globalDispatch,
        "Marketing List Updated",
        {
          Id: id,
          ListId: listId,
          ReplaceExistingMembers: replaceExistingMembers,
          ListName: listName,
        }
      );

      if (result && result.data) {
        setFieldValue(
          "listLink",
          `/marketing-list?id=${result.data}&closeRedirect=%2Fmarketing-lists`
        );
      }
    }
  },
})(ExportModal);

export default ExportModalWithFormik;
