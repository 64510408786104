import React from "react";
import { generateCommunicationTemplateDataAndHTML } from "./Helpers";

function CommunicationPreview({ values }) {
  let commTemplateDataAndHTML =
    generateCommunicationTemplateDataAndHTML(values, true);

  let previewDevice = values.previewDevice
    ? values.previewDevice
    : "mobile";
  let previewOrientation = values.previewOrientation
    ? values.previewOrientation
    : "portrait";

  // use portrait dimensions by default
  var width = 320;
  var height = 480;

  if (previewDevice === "tablet") {
    width = 768;
    height = 1024;
  }

  // check the orientation
  if (
    previewOrientation === "landscape" &&
    previewDevice === "mobile"
  ) {
    width = 480;
    height = 320;
  } else if (
    previewOrientation === "landscape" &&
    previewDevice === "tablet"
  ) {
    width = 1024;
    height = 768;
  }
  let iframeId = previewDevice + "-preview-iframe";
  return (
    <div
      id={
        previewDevice +
        (previewOrientation === "landscape"
          ? "-preview-landscape"
          : "-preview-portrait")
      }
    >
      <iframe
        title={iframeId}
        id={iframeId}
        width={width}
        height={height}
        srcDoc={commTemplateDataAndHTML.templateHTMLRaw}
      />
    </div>
  );
}

export default CommunicationPreview;
