import classNames from "classnames";

function Toast({ message, onClick, style }) {
  return (
    <div
      className={classNames(
        "toast text-white align-items-center show",
        style
      )}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="d-flex">
        <div className="toast-body">{message}</div>
        <button
          type="button"
          className="btn-close me-2 m-auto"
          aria-label="Close"
          onClick={onClick}
        ></button>
      </div>
    </div>
  );
}

export default Toast;
