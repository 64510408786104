import { PrintTransformFormStages } from "./printTransform";
import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const PrintTransformsFormStages = [
  {
    title: "Print Transforms",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_printerjobtypeid",
              className: "col-name",
            },
            {
              name: "g4b_singleprintjob",
              className: "col-bool",
            },
            {
              name: "g4b_usexsltplus",
              className: "col-bool",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_printerjobtypeid",
              type: "select",
            },
            {
              name: "g4b_singleprintjob",
              type: "select",
            },
            {
              name: "g4b_usexsltplus",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/print-transform",
          lookup: lookups.g4b_printtransform.all,
          subFormStages: PrintTransformFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
