import { getImageBase64Src } from "../../js/utility";
import Alert from "./Alert";
import EntityAuditTable from "./EntityAuditTable";
import EntityLookupTable from "./EntityLookupTable";

function isImageAudit(audit) {
  // image audit must be for annotation entity
  return audit.EntityName === "annotation";
}

function getImageSrc(audit) {
  const mimeType = audit.Attributes.find((a) => a.Key === "mimetype");
  const documentBody = audit.Attributes.find(
    (a) => a.Key === "documentbody"
  );
  return getImageBase64Src(mimeType.Value, documentBody.Value);
}

function EntityAudit({ audit }) {
  return (
    <>
      <div>
        <EntityAuditTable audit={audit} />
        {audit.Related.sort((a, b) =>
          a.Label.localeCompare(b.Label)
        ).map((related, relatedIndex) => {
          return (
            <div key={relatedIndex} className="my-3">
              <h4>{related.Label}</h4>
              {isImageAudit(related) ? (
                <>
                  {related.Type === 2 ? (
                    <Alert text="Image deleted" />
                  ) : (
                    <>
                      <div>
                        <img src={getImageSrc(related)} alt="" />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <EntityAuditTable audit={related} />
              )}
            </div>
          );
        })}
        {audit.Linked.sort((a, b) =>
          a.Label.localeCompare(b.Label)
        ).map((linked, linkedIndex) => {
          return (
            <div key={linkedIndex} className="my-3">
              <h4>{linked.Label}</h4>
              {linked.EntityLookups.length > 0 ? (
                <EntityLookupTable linked={linked} />
              ) : (
                <p>No linked records</p>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default EntityAudit;
