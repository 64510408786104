import lookups from "./lookups";
import { FundFormStages } from "./fund";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const CharityFormStages = [
  {
    title: "Charity",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4d_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4d_description",
            className: "col-12",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            name: "g4d_charity_number",
            className: "col-md-6",
            description: "",
            label: "Charity Number",
            type: "int",
          },
          {
            name: "g4d_gift_aid_percent",
            className: "col-md-6",
            description: "",
            label: "Gift Aid Rate (Percentage)",
            type: "float",
          },
          {
            name: "g4d_declaration_future_years",
            className: "col-md-6",
            description: "",
            label: "Future (Years)",
            type: "int",
          },
          {
            name: "g4d_declaration_historical_years",
            className: "col-md-6",
            description: "",
            label: "Historical (Years)",
            type: "int",
          },
          {
            name: "g4d_declaration_statement",
            className: "col-12",
            description: "",
            label: "Statement",
            textAreaHeight: "100px",
            type: "textarea",
          },
        ],
      },
    ],
  },
  {
    title: "Funds",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4d_fund_number",
              label: "Fund Number",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4d_charity_id",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/fund",
          lookup: lookups.g4d_fund.all,
          subFormStages: FundFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
