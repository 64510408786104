export const CouponCategoryFormStages = [
  {
    title: "Coupon Type",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_description",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
