import NewField from "./NewField";

function getPageProperties(title, prefix) {
  return [
    {
      title: title,
      sections: [
        {
          name: "Properties",
          fields: [
            {
              name: `${prefix}Width`,
              label: "Width",
              type: "int",
            },
            {
              name: `${prefix}Height`,
              label: "Height",
              type: "int",
            },
            {
              name: `${prefix}TopOffset`,
              label: "Top",
              type: "int",
            },
            {
              name: `${prefix}RightOffset`,
              label: "Right",
              type: "int",
            },
            {
              name: `${prefix}BottomOffset`,
              label: "Bottom",
              type: "int",
            },
            {
              name: `${prefix}LeftOffset`,
              label: "Left",
              type: "int",
            },
          ],
        },
      ],
    },
  ];
}

function getStagesForPages(values) {
  const { back, front } = values;
  const stages = [];

  if (front) {
    stages.push(...getPageProperties("Front", "front."));
  }
  if (back) {
    stages.push(...getPageProperties("Back", "back."));
  }

  return stages;
}

function GetSidebarFormStages(values) {
  return [
    {
      title: "Components",
      sections: [
        {
          name: "",
          fields: [
            {
              name: "newField",
              component: NewField,
              type: "component",
            },
          ],
          wrapInCard: false,
        },
      ],
    },
    ...getStagesForPages(values),
  ];
}

export default GetSidebarFormStages;
