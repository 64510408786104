import { useState } from "react";
import { Button, Input, Modal } from "../elements/_Elements";
import { ButtonStyle } from "../../js/enums";
import { Create } from "../../js/service";
import {
  getAttributesForSave,
  handleRedirect,
} from "../form/Helpers";
import { useGlobalReducer } from "../../GlobalContext";
import { useFormReducer } from "../form/FormContext";
import { getEntityNameAttribute } from "../../js/utility";

function RecordCopy({
  entityName,
  setCustomAttributes,
  setDisplayRecordCopyModal,
  stages,
  state,
  values,
}) {
  const [isCopying, setIsCopying] = useState(false);

  const [newRecordName, setNewRecordName] = useState("");
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const copyRecord = async () => {
    let response = null;
    setIsCopying(true);
    try {
      const successMessage = `${newRecordName} successfully created`;
      let attributes = getAttributesForSave(null, stages, {
        ...state,
        ...values,
      });
      let nameAttribute = getEntityNameAttribute(entityName);
      attributes[nameAttribute] = newRecordName;

      if (setCustomAttributes) {
        setCustomAttributes(attributes, state);
      }

      const [serviceResponse] = await Promise.all([
        Create(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: newRecordName,
            Attributes: attributes,
          },
          entityName
        ),
      ]);
      response = serviceResponse.data;
    } catch (error) {
      console.error(error);
    } finally {
      setIsCopying(false);

      if (typeof response === "string") {
        // result is the id
        handleRedirect(null, dispatch, response, "copyRecord");
      } else {
        console.error("Invalid response returned.");
      }
    }
  };

  return (
    <>
      <Modal
        title={"Copy Record"}
        modalCloseButtonClick={() => setDisplayRecordCopyModal(null)}
        className="modal modal-dialog-scrollable"
      >
        <div className="modal-body">
          <small>{"Enter a name for the new record"}</small>
          <Input
            label={"New Record Name:"}
            mandatory={true}
            name={"NewRecordName"}
            onChange={(event) => setNewRecordName(event.target.value)}
            value={newRecordName}
          />
        </div>
        <div className="modal-footer">
          <Button
            text={"Confirm"}
            style={ButtonStyle.Primary}
            onClick={() => copyRecord()}
            showSpinner={isCopying}
            disabled={
              !newRecordName ||
              newRecordName.trim() === "" ||
              isCopying
            }
          />
          <Button
            text={"Cancel"}
            style={ButtonStyle.Info}
            onClick={() => setDisplayRecordCopyModal(null)}
            showSpinner={isCopying}
            disabled={isCopying}
          />
        </div>
      </Modal>
    </>
  );
}

export default RecordCopy;
