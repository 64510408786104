import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { EditElement, IconCard } from "../../elements/_Elements";
import {
  deleteRule,
  getCaptionForAudienceRule,
  getIconForAudienceRule,
  setEditRule,
} from "./Helpers";

function Rule({
  activeId,
  errors,
  index,
  rule,
  rulesData,
  setFieldValue,
  values,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const { Type } = rule;

  const { icon, iconClassName } = getIconForAudienceRule(Type);
  const id = `moverule-${index}`;

  const { attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: id,
    });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  const { editRule } = values;
  const isEditing = editRule && editRule.index === index;
  const hasError = errors && errors[`rule-${index}`];
  const isMissingRule =
    values && values.rule_data && values.rule_data[index].IsMissing;

  return (
    <EditElement
      className="rounded"
      hasError={hasError || isMissingRule}
      isActive={activeId === id}
      isEditing={isEditing}
      moveAttributes={attributes}
      moveListeners={listeners}
      onDelete={() => {
        deleteRule(globalDispatch, index, setFieldValue, values);
      }}
      onEdit={() => {
        setEditRule(
          dispatch,
          globalDispatch,
          index,
          rule,
          rulesData,
          values
        );
      }}
      setNodeRef={setNodeRef}
      showToolbar={!activeId}
      style={style}
      toolbarClassName="rounded-top"
    >
      <IconCard
        key={index}
        icon={icon}
        iconClassName={classNames(
          "rounded-start",
          "text-white",
          isMissingRule ? "bg-danger" : iconClassName
        )}
      >
        <span>{getCaptionForAudienceRule(index + 1, rule)}</span>
      </IconCard>
      {!activeId && (
        <div className="edit-element-overlay rounded"></div>
      )}
    </EditElement>
  );
}

export default Rule;
