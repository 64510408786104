import {
  BackgroundColour,
  BorderColour,
  BorderThickness,
  BorderType,
  Margin,
  Padding,
} from "../../../CommonProperties";

const DividerControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "",
        fields: [
          BackgroundColour(""),
          BorderType(""),
          BorderThickness(""),
          BorderColour(""),
          Padding(""),
          Margin(""),
        ],
      },
    ],
  },
];

export default DividerControlFormStages;
