import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { TelegramQueueStage } from "../js/enums";
import { checkIfSellFixturesAndSeriesSettingOn } from "../js/utility";
import lookups from "./lookups";
import { TelegramQueueFormStages } from "./telegramQueue";

export const MembershipAccessTokenFormStages = [
  {
    title: "Membership Access Token",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Telegram Queues",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_sequencenumber",
              label: "Sequence Number",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_type",
              label: "Type",
              className: "col-short-name",
            },
            {
              name: "g4b_stage",
              className: "col-name",
              label: "Stage",
              type: "picklist",
              enum: TelegramQueueStage,
            },
            {
              name: "g4b_accesscontrolprovideridname",
              label: "Access Control Provider",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_telegramqueue",
          lookup: lookups.g4b_telegramqueue.all,
          linkForNewRecord: "/telegram-queue",
          subFormStages: TelegramQueueFormStages,
          useSubForm: false,
        },
      },
    ],
  },
];
