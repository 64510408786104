import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Align,
  BackgroundColour,
  BorderColour,
  BorderThickness,
  BorderType,
  FontFamily,
  FontSize,
  FontWeight,
  Margin,
  Padding,
  TextColour,
} from "../../../CommonProperties";
import {
  ChevronDown,
  ChevronUp,
  DeleteIcon,
} from "../../../../Icons";
import { DispatchMethods } from "../../../enums";
import NewSocialPlatform from "./NewSocialPlatform";

function getSectionsForPlatforms(properties) {
  // if there aren't any social platforms then return an empty array
  if (!properties.socialPlatforms) {
    return [];
  }

  let sections = [];

  properties.socialPlatforms.split(",").forEach((platform) => {
    sections.push({
      identifier: platform,
      name: properties[platform + "Name"],
      fields: [
        {
          name: platform + "Name",
          description: "",
          hidden: true,
          label: "Name",
          type: "nvarchar",
        },
        {
          name: platform + "Url",
          description: "",
          label: "Url",
          type: "nvarchar",
        },
        {
          name: platform + "Text",
          description: "",
          displayIfFromValues: (values) => values.buttonShowText,
          label: "Text",
          type: "nvarchar",
        },
        {
          name: platform + "Src",
          description: "",
          label: "Src",
          mapValue: (value) => {
            return value
              ? `data:${value.MimeType};base64,${value.Base64String}`
              : "";
          },
          type: "image",
        },
        {
          name: platform + "Width",
          description: "",
          hidden: true,
          label: "Width",
          type: "nvarchar",
        },
        {
          name: platform + "Height",
          description: "",
          hidden: true,
          label: "Height",
          type: "nvarchar",
        },
      ],
      moveUp: (dispatch, section, values) => {
        const { editControl, rows, socialPlatforms } = values;
        const { columnIndex, controlIndex, rowIndex } = editControl;

        const socialPlatformsArray = socialPlatforms.split(",");

        const index = socialPlatformsArray.indexOf(
          section.identifier
        );

        // don't do anything if this is the first item
        if (index === 0) {
          return;
        }

        socialPlatformsArray.splice(index, 1);
        socialPlatformsArray.splice(index - 1, 0, section.identifier);

        const result = socialPlatformsArray.join(",");

        // update the properties within the rows
        const control =
          rows[rowIndex].Columns[columnIndex].Controls[controlIndex];

        control.Properties.find(
          (p) => p.Name === "socialPlatforms"
        ).Value = result;

        dispatch({
          type: DispatchMethods.SetReloadValues,
          reloadValues: {
            ...values,
            rows: rows,
            socialPlatforms: result,
          },
        });
      },
      moveDown: (dispatch, section, values) => {
        const { editControl, rows, socialPlatforms } = values;
        const { columnIndex, controlIndex, rowIndex } = editControl;

        const socialPlatformsArray = socialPlatforms.split(",");

        const index = socialPlatformsArray.indexOf(
          section.identifier
        );

        // don't do anything if this is the last item
        if (index === socialPlatformsArray.length) {
          return;
        }

        socialPlatformsArray.splice(index, 1);
        socialPlatformsArray.splice(index + 1, 0, section.identifier);

        const result = socialPlatformsArray.join(",");

        // update the properties within the rows
        const control =
          rows[rowIndex].Columns[columnIndex].Controls[controlIndex];

        control.Properties.find(
          (p) => p.Name === "socialPlatforms"
        ).Value = result;

        dispatch({
          type: DispatchMethods.SetReloadValues,
          reloadValues: {
            ...values,
            rows: rows,
            socialPlatforms: result,
          },
        });
      },
      onRemove: (dispatch, section, values) => {
        const { editControl, socialPlatforms } = values;
        const { columnIndex, controlIndex, rowIndex } = editControl;

        const result = socialPlatforms
          .split(",")
          .filter((socialPlatform) => {
            return socialPlatform !== section.identifier;
          })
          .join(",");

        const reloadValues = {};
        for (const key in values) {
          if (
            values.hasOwnProperty(key) &&
            !key.startsWith(section.identifier)
          ) {
            reloadValues[key] = values[key];
          }
        }
        reloadValues.socialPlatforms = result;

        // // update the properties within the rows
        const control =
          reloadValues.rows[rowIndex].Columns[columnIndex].Controls[
            controlIndex
          ];

        control.Properties.find(
          (p) => p.Name === "socialPlatforms"
        ).Value = result;

        dispatch({
          type: DispatchMethods.SetReloadValues,
          reloadValues: reloadValues,
        });
      },
      renderHeader: (dispatch, section, values) => {
        return (
          <div className="col-12">
            <h5 className="d-inline-block">{section.name}</h5>
            <div className="float-end">
              <FontAwesomeIcon
                className="me-2 cursor-pointer"
                icon={ChevronUp}
                onClick={() =>
                  section.moveUp(dispatch, section, values)
                }
              />
              <FontAwesomeIcon
                className="me-2 cursor-pointer"
                icon={ChevronDown}
                onClick={() =>
                  section.moveDown(dispatch, section, values)
                }
              />
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={DeleteIcon}
                onClick={() =>
                  section.onRemove(dispatch, section, values)
                }
              />
            </div>
          </div>
        );
      },
    });
  });

  return sections;
}

function GetSocialFollowControlFormStages(properties) {
  return [
    {
      title: "Content",
      sections: [
        ...getSectionsForPlatforms(properties),
        {
          name: "",
          fields: [
            {
              name: "socialPlatforms",
              component: NewSocialPlatform,
              type: "component",
            },
          ],
        },
      ],
    },
    {
      title: "Style",
      sections: [
        {
          name: "Buttons",
          fields: [
            Align("button"),
            TextColour("button"),
            FontFamily("button"),
            FontSize("button"),
            FontWeight("button"),
            Margin("button"),
          ],
        },
        {
          name: "Content",
          fields: [
            BackgroundColour(""),
            Margin(""),
            Padding(""),
            BorderType(""),
            BorderThickness(""),
            BorderColour(""),
          ],
        },
      ],
    },
    {
      title: "Settings",
      sections: [
        {
          name: "Settings",
          fields: [
            {
              name: "buttonShowText",
              description: "",
              label: "Show Text",
              type: "bit",
            },
            {
              name: "stackOnMobile",
              description: "",
              label: "Stack On Mobile",
              type: "bit",
            },
          ],
        },
      ],
    },
  ];
}

export default GetSocialFollowControlFormStages;
