import EditField from "./EditField";
import SidebarTabs from "./SidebarTabs";

function Sidebar({
  currentState,
  errors,
  handleBlur,
  handleChange,
  handlePicklistChange,
  saveUndoSnapshot,
  setFieldValue,
  state,
  touched,
  values,
}) {
  return (
    <>
      {values.editField ? (
        <EditField
          {...{
            currentState,
            errors,
            handleBlur,
            handleChange,
            saveUndoSnapshot,
            setFieldValue,
            state,
            touched,
            values,
          }}
        />
      ) : (
        <SidebarTabs
          {...{
            handleChange,
            handlePicklistChange,
            setFieldValue,
            state,
            values,
          }}
        />
      )}
    </>
  );
}

export default Sidebar;
