function JumpToContent({ scrollToElement, stages }) {
  return (
    <div className="dropdown">
      <button
        className="btn btn-info dropdown-toggle"
        type="button"
        id="jumpToContent"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Content
      </button>
      <ul className="dropdown-menu" aria-labelledby="jumpToContent">
        {stages.map((stage) => {
          return (
            <li
              key={stage.id}
              onClick={() => scrollToElement(stage.id)}
            >
              <span className="dropdown-item cursor-pointer">
                {stage.title}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default JumpToContent;
