import { BlockFormStages } from "./block";
import lookups from "./lookups";
import { SeatClassFormStages } from "./seatClass";

export const BlockSeatFormStages = [
  {
    title: "Block Seat",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_block",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Block",
            lookup: lookups.g4b_block.all,
            subFormStages: BlockFormStages,
            type: "lookup",
          },
          {
            name: "g4b_seatclass",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Seat Class",
            lookup: lookups.g4b_seatclass.all,
            subFormStages: SeatClassFormStages,
            type: "lookup",
          },
          {
            name: "g4b_row",
            description: "",
            label: "Row",
            type: "int",
          },
          {
            name: "g4b_seat",
            description: "",
            label: "Seat",
            type: "int",
          },
        ],
      },
    ],
  },
];
