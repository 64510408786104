import { Fragment } from "react";
import { Alert, DroppableElement } from "../../elements/_Elements";
import { AlertStyle } from "../../../js/enums";
import Trigger from "./Trigger";

function Triggers({ activeId, errors, setFieldValue, values }) {
  const { flow_data } = values;

  return (
    <>
      {!flow_data || flow_data.length === 0 ? (
        <DroppableElement id="trigger-0" className="rounded">
          <Alert
            className="m-0"
            style={AlertStyle.Warning}
            text="There are no triggers defined. Drag a new trigger here."
          />
        </DroppableElement>
      ) : (
        <>
          <DroppableElement id="trigger-0" className="mb-3" />
          {flow_data.map((flowControl, index) => {
            return (
              <Fragment key={index}>
                <div className="mb-3">
                  <Trigger
                    {...{
                      activeId,
                      errors,
                      flowControl,
                      index,
                      setFieldValue,
                      values,
                    }}
                  />
                </div>
                <DroppableElement
                  id={`trigger-${index + 1}`}
                  className="mb-3"
                />
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
}

export default Triggers;
