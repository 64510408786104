import ReactDOM from "react-dom";
import { Button, Modal } from "../_Elements";
import { ButtonStyle } from "../../../js/enums";
import { withFormik } from "formik";
import AddAttributeFormStages from "./AddAttributeFormStages";
import SidebarForm from "../../form/SidebarForm";

const className = "crmattribute";

function AddAttribute({
  editorState,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  setEditorState,
  setFieldValue,
  state,
  touched,
  values,
}) {
  const { addAttribute } = editorState;

  const modalRoot = document.getElementById("modal-root");

  return addAttribute && modalRoot ? (
    ReactDOM.createPortal(
      <form onSubmit={handleSubmit}>
        <Modal
          title="Add Attribute"
          modalCloseButtonClick={() =>
            setEditorState({
              ...editorState,
              addAttribute: false,
            })
          }
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body min-vh-50">
            <SidebarForm
              {...{
                errors,
                handleBlur,
                handleChange,
                state,
                touched,
                values,
              }}
              handleFilteredPicklistChange={(event, _, name) => {
                const value = event ? event.value : "";
                setFieldValue(name, value);
              }}
              stages={AddAttributeFormStages}
            />
          </div>
          <div className="modal-footer">
            <Button
              style={ButtonStyle.Primary}
              text={"Confirm"}
              type="submit"
            />
          </div>
        </Modal>
      </form>,
      modalRoot
    )
  ) : (
    <></>
  );
}

const AddAttributeWithFormik = withFormik({
  enableReinitialize: true,

  mapPropsToValues: ({ editorRef }) => {
    const editor = editorRef.current;
    const selectedNode = editor.selection.getNode();

    // check if updating an existing attribute
    const isUpdatingAttribute =
      selectedNode &&
      selectedNode.nodeName === "SPAN" &&
      selectedNode.className === className;
    if (isUpdatingAttribute) {
      let result = [
        "entity",
        "attribute",
        "attributeName",
        "defaultValue",
        "formatString",
      ].reduce((o, key) => {
        const fullKey = `data-g4-${key}`;
        if (selectedNode.attributes[fullKey]) {
          o[key] = selectedNode.attributes[fullKey].value;
        } else {
          o[key] = "";
        }
        return o;
      }, {});

      // handle legacy fields where attribute is set and entity is not
      if (result.attribute && !result.entity) {
        result.entity = result.attribute.startsWith("regarding_")
          ? "2"
          : "1";
      }

      // remove regarding from attribute
      if (
        result.entity === "2" &&
        result.attribute.startsWith("regarding_")
      ) {
        result.attribute = result.attribute.slice(10);
      }

      // set the attribute name
      result.attributeName = selectedNode.innerHTML;

      return result;
    }

    return {
      attribute: "",
      attributeName: "",
      entity: "1",
      defaultValue: "",
      formatString: "",
    };
  },

  validate: (values) => {
    const errors = {};

    const { attribute, entity } = values;

    if (!attribute) {
      errors["attribute"] = "Required";
    }

    if (!entity) {
      errors["entity"] = "Required";
    }

    return errors;
  },

  handleSubmit: async (values, { props }) => {
    const {
      attribute,
      attributeName,
      defaultValue,
      entity,
      formatString,
    } = values;
    const { editorRef, editorState, setEditorState } = props;

    const editor = editorRef.current;
    const selectedNode = editor.selection.getNode();

    // check if updating an existing attribute
    const isUpdatingAttribute =
      selectedNode &&
      selectedNode.nodeName === "SPAN" &&
      selectedNode.className === className;

    let element = document.createElement("span");

    element.className = className;
    element.setAttribute(
      "data-g4-attribute",
      entity === "2" ? `regarding_${attribute}` : attribute
    );
    element.setAttribute("data-g4-entity", entity);
    element.setAttribute("data-g4-defaultvalue", defaultValue);
    element.setAttribute("data-g4-formatstring", formatString);
    element.textContent = attributeName;

    if (isUpdatingAttribute) {
      editor.dom.remove(selectedNode);
    }

    editor.insertContent(element.outerHTML);

    setEditorState({
      ...editorState,
      addAttribute: false,
    });
  },
})(AddAttribute);

export default AddAttributeWithFormik;
