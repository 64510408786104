import { isSameOrLaterDate } from "../js/utility";

export const TaxRateFormStages = [
  {
    title: "Tax Rate",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_taxcomponentid",
            hidden: true,
          },
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Rate",
        fields: [
          {
            name: "g4b_percentage",
            description: "The percentage of tax to be applied",
            label: "Percentage",
            type: "decimal",
            validate: (value) =>
              value === "" ? "Percentage is required" : null,
          },
          {
            name: "g4b_zerotaxratereason",
            className: "col-md-8",
            description:
              "The reason for the zero percentage tax rate",
            displayIfFromValues: (values) =>
              values &&
              values.g4b_percentage &&
              values.g4b_percentage.toString() === "0",
            label: "Zero Tax Rate Reason",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Dates",
        fields: [
          {
            name: "g4b_startdate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "The date when the tax rate will be applied",
            label: "Start Date",
            required: true,
            type: "datetime",
          },
          {
            name: "g4b_enddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description:
              "The date when the tax rate will no longer be applied. If left blank, this rate is applied indefinitely.",
            filterDate: (date, values) => {
              return values.g4b_startdate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_startdate)
                  )
                : true;
            },
            label: "End Date",
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_startdate
                ? isSameOrLaterDate(date, values.g4b_startdate)
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
        ],
      },
    ],
  },
];
