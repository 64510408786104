import classNames from "classnames";
import { Button } from "../elements/_Elements";
import { ButtonStyle } from "../../js/enums";

function SubmitButtons({
  disabled,
  filterDescription,
  handleSubmitFilter,
  isSubmitting,
  orderedBy,
  orderedByAsc,
  recordsPerPage,
  setCurrentPage,
  setFilters,
  table,
}) {
  return (
    <div
      className={classNames(
        "align-self-center",
        filterDescription || table.filters.some((f) => f.description)
          ? "pt-2"
          : "pt-4"
      )}
    >
      <Button
        disabled={disabled || isSubmitting}
        type={"button"}
        showSpinner={isSubmitting}
        style={ButtonStyle.Info}
        text={"Clear Filters"}
        onClick={() => {
          setFilters([]);
        }}
      />
      <Button
        className={"ms-3"}
        disabled={disabled || isSubmitting}
        type={"submit"}
        showSpinner={isSubmitting}
        style={ButtonStyle.Primary}
        text={"Submit"}
        onClick={() => {
          handleSubmitFilter(
            1,
            orderedBy,
            orderedByAsc,
            recordsPerPage,
            true
          );
          setCurrentPage(1);
        }}
      />
    </div>
  );
}

export default SubmitButtons;
