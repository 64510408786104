import { CircleInfo } from "../Icons";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TooltipComponent({ name, tooltipDescription }) {
  return (
    <>
      <FontAwesomeIcon
        data-tooltip-id={`${name}-info-tooltip`}
        data-tooltip-place="bottom"
        className="ms-2"
        icon={CircleInfo}
      />
      <Tooltip
        className="w-50"
        id={`${name}-info-tooltip`}
        content={tooltipDescription}
        openOnClick={true}
        multiline={true}
      />
    </>
  );
}

export default TooltipComponent;
