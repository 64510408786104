const Aligns = Object.freeze({
  Left: "left",
  Center: "center",
  Right: "right",
});

const Directions = Object.freeze({
  Left: "rtl",
  Right: "ltr",
});

const DispatchMethods = Object.freeze({
  SetDefaultValues: "Set_Default_Values",
  SetEditRecord: "Set_Edit_Record",
  SetInitialLoad: "Set_Initial_Load",
  SetLookupOptions: "Set_Lookup_Options",
  SetName: "Set_Name",
  SetPreviousStage: "Set_Previous_Stage",
  SetRecordId: "Set_Record_Id",
  SetRedirect: "Set_Redirect",
  SetRelatedEntities: "Set_Related_Entities",
  SetReloadValues: "Set_Reload_Values",
  SetStage: "Set_Stage",
  UpdateRecord: "Update_Record",
  UpdateRecordAndGoToNextStage: "Update_Record_And_Go_To_Next_Stage",
});

const FontFamilies = Object.freeze({
  "Arial, Helvetica, sans-serif": "Arial, Helvetica, sans-serif",
  "'Comic Sans MS', cursive, sans-serif":
    "'Comic Sans MS', cursive, sans-serif",
  "'Courier New', Courier, monospace":
    "'Courier New', Courier, monospace",
  "Garamond, 'Times New Roman', Times, serif":
    "Garamond, 'Times New Roman', Times, serif",
  "Georgia, serif": "Georgia, serif",
  "'Lucida Sans Unicode', 'Lucida Grande', sans-serif":
    "'Lucida Sans Unicode', 'Lucida Grande', sans-serif",
  "'MS Sans Serif', sans-serif": "'MS Sans Serif', sans-serif",
  "'Segoe UI', Verdana, sans-serif":
    "'Segoe UI', Verdana, sans-serif",
  "Tahoma, Geneva, sans-serif": "Tahoma, Geneva, sans-serif",
  "'Times New Roman', Times, serif":
    "'Times New Roman', Times, serif",
  "'Trebuchet MS', Helvetica, sans-serif":
    "'Trebuchet MS', Helvetica, sans-serif",
  "Verdana, Geneva, sans-serif": "Verdana, Geneva, sans-serif",
});

const FontSizes = Object.freeze({
  "8px": "8px",
  "9px": "9px",
  "10px": "10px",
  "11px": "11px",
  "12px": "12px",
  "14px": "14px",
  "16px": "16px",
  "18px": "18px",
  "20px": "20px",
  "22px": "22px",
  "24px": "24px",
  "26px": "26px",
  "28px": "28px",
  "30px": "30px",
  "32px": "32px",
  "34px": "34px",
  "36px": "36px",
  "38px": "38px",
  "40px": "40px",
  "42px": "42px",
  "44px": "44px",
  "46px": "46px",
  "48px": "48px",
  "50px": "50px",
  "52px": "52px",
  "54px": "54px",
  "56px": "56px",
  "58px": "58px",
  "60px": "60px",
  "62px": "62px",
  "64px": "64px",
  "66px": "66px",
  "68px": "68px",
  "70px": "70px",
  "72px": "72px",
});

const FontSizesArray = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
  38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70,
  72,
];

const FontWeights = Object.freeze({
  Normal: "normal",
  Bold: "bold",
});

const LineHeights = Object.freeze({
  Normal: "100%",
  Slight: "125%",
  1.5: "150%",
  Double: "200%",
});

const LineTypes = Object.freeze({
  None: "none",
  Solid: "solid",
  Dashed: "dashed",
  Dotted: "dotted",
  Double: "double",
  Groove: "groove",
  Ridge: "ridge",
  Inset: "inset",
  Outset: "outset",
});

const PageSize = Object.freeze({
  10: "10",
  25: "25",
  50: "50",
  100: "100",
});

const VerticalAligns = Object.freeze({
  Top: "top",
  Middle: "middle",
  Bottom: "bottom",
});

export {
  Aligns,
  Directions,
  DispatchMethods,
  FontFamilies,
  FontSizes,
  FontSizesArray,
  FontWeights,
  LineHeights,
  LineTypes,
  PageSize,
  VerticalAligns,
};
