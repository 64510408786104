import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeft } from "../../Icons";
import {
  Badge,
  Button,
  JumpToContent,
  ProgressTracker,
  SetStatusModal,
} from "../../elements/_Elements";
import {
  ButtonStyle,
  LoadingState,
  RecordStatus,
} from "../../../js/enums";
import "../../elements/FormPageHeader.scss";
import { SetRecordState } from "../../../js/service";
import { useGlobalReducer } from "../../../GlobalContext";
import { handleRedirect } from "../Helpers";
import StopStartModal from "./StopStartModal";
import classNames from "classnames";
import RecordCopy from "../../elements/RecordCopy";
import { setCommCopyAttributes } from "./Helpers";

function CommunicationPageHeader({
  allowShowInformation,
  dispatch,
  entityName,
  filteredStages,
  header,
  loading,
  onBack,
  scrollToElement,
  setLoading,
  setShowInformation,
  showInformation,
  stages,
  state,
  values,
}) {
  const [
    showConfirmStatusChangeModal,
    setShowConfirmStatusChangeModal,
  ] = useState(false);
  const [displayCommCopyModal, setDisplayCommCopyModal] =
    useState(false);
  const [displayStopStartModal, setDisplayStopStartModal] =
    useState(false);
  const globalDispatch = useGlobalReducer();

  const handleRecordStatusChange = () => {
    const setStatus = async () => {
      setLoading(LoadingState.Loading);
      try {
        const [serviceResponse] = await Promise.all([
          SetRecordState(globalDispatch, {
            Ids: [state.id],
            EntityName: entityName,
            StateCode: state.statecode === 0 ? 1 : 0,
          }),
        ]);
        if (serviceResponse && serviceResponse.status === 200) {
          handleRedirect(null, dispatch, state.id, "refreshPage");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };
    setStatus();
  };

  const existingRecord =
    state &&
    state.id &&
    (state.statecode === 0 || state.statecode === 1);

  const displayJumpTo =
    stages && stages.length > 0 && stages.some((s) => s.id);
  return (
    <>
      {showConfirmStatusChangeModal && (
        <SetStatusModal
          handleRecordStatusChange={handleRecordStatusChange}
          recordStatusToShow={
            state.statecode === 0
              ? RecordStatus.Active
              : RecordStatus.Inactive
          }
          setShowConfirmStatusChangeModal={
            setShowConfirmStatusChangeModal
          }
          singleRecord={true}
          state={state}
        />
      )}
      {displayCommCopyModal && (
        <RecordCopy
          entityName={entityName}
          setCustomAttributes={setCommCopyAttributes}
          setDisplayRecordCopyModal={setDisplayCommCopyModal}
          stages={stages}
          state={state}
          values={values}
        />
      )}
      {displayStopStartModal && (
        <StopStartModal
          setDisplayStopStartModal={setDisplayStopStartModal}
          state={state}
        />
      )}
      <div id="form-page-header" className="sticky-top px-3">
        {state &&
          !state.id &&
          filteredStages &&
          filteredStages.length > 1 && (
            <ProgressTracker {...{ filteredStages, state }} />
          )}
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {state && header && !showInformation && onBack && (
              <Button
                className="btn-sm me-3"
                onClick={onBack}
                style={ButtonStyle.Info}
                text={<FontAwesomeIcon icon={ArrowLeft} />}
              />
            )}
            {header && (
              <h2 className="fw-bold text-dark me-3">{header}</h2>
            )}
            {existingRecord && (
              <>
                {state.g4c_sid && (
                  <Badge
                    className="me-3 py-2"
                    item={{ text: state.g4c_sid }}
                  />
                )}
                <div className="dropdown me-3">
                  <button
                    className={classNames(
                      "btn",
                      state.statecode === 0
                        ? "btn-success"
                        : "btn-warning",
                      "dropdown-toggle"
                    )}
                    type="button"
                    id="statusDropDown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    disabled={loading !== LoadingState.Loaded}
                  >
                    {state.statecode === 0 ? "Active" : "Inactive"}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="statusDropDown"
                  >
                    <li
                      key={"toggleStatus"}
                      onClick={() =>
                        setShowConfirmStatusChangeModal(true)
                      }
                    >
                      <span className="dropdown-item cursor-pointer">
                        {state.statecode === 0
                          ? "Deactivate"
                          : "Activate"}
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="me-2 d-flex align-items-end">
              {allowShowInformation && showInformation ? (
                <Button
                  onClick={() => setShowInformation(false)}
                  text="Content"
                />
              ) : (
                <>
                  {(existingRecord || displayJumpTo) && (
                    <>
                      {existingRecord && (
                        <div className="dropdown">
                          <button
                            className={classNames(
                              "btn",
                              "btn-info",
                              "dropdown-toggle",
                              "me-2"
                            )}
                            type="button"
                            id="statusDropDown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            disabled={loading !== LoadingState.Loaded}
                          >
                            More Actions
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="statusDropDown"
                          >
                            <li
                              key={"copyComm"}
                              onClick={() =>
                                setDisplayCommCopyModal(true)
                              }
                            >
                              <span className="dropdown-item cursor-pointer">
                                {"Copy Communication"}
                              </span>
                            </li>
                            {state.statuscode === 4 && (
                              <li
                                key={"stopStartComm"}
                                onClick={() =>
                                  setDisplayStopStartModal(true)
                                }
                              >
                                <span className="dropdown-item cursor-pointer">
                                  {state.g4c_active
                                    ? "Stop Communication"
                                    : "Start Communication"}
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                      {displayJumpTo && (
                        <JumpToContent
                          {...{ scrollToElement, stages }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {allowShowInformation && !showInformation && (
              <Button
                onClick={() => setShowInformation(true)}
                style={ButtonStyle.OutlinePrimary}
                text="Info"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunicationPageHeader;
