import lookups from "./lookups";
import { isSameOrLaterDate } from "../js/utility";
import { PromotionProductGroupFormStages } from "./promotionProductGroup";
import { PromotionTypeFormStages } from "./promotionType";
import { ExclusionDates } from "../js/enums";

export const PromotionFormStages = [
  {
    title: "Promotion",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            className: "col-4",
            description: "",
            label: "Code",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_description",
            className: "col-12",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_promotiontypeid",
            className: "col-12",
            description: "",
            label: "Promotion Type",
            lookup: lookups.g4b_promotiontype.all,
            required: true,
            type: "lookup",
            subFormStages: PromotionTypeFormStages,
          },
          {
            name: "g4b_percent",
            className: "col-md-3",
            description: "",
            label: "Percent",
            type: "decimal",
            validate: (value) => {
              const isValid = value >= 0 && value <= 100;
              return isValid
                ? ""
                : "You must enter a number between 0 and 100";
            },
          },
          {
            name: "g4b_value",
            className: "col-md-3",
            description: "",
            label: "Value",
            type: "money",
            validate: (value) => {
              const isValid = value >= 0;
              return isValid
                ? ""
                : "You must enter a non negative number";
            },
          },
          {
            name: "g4b_promotioncriteria",
            className: "col-12",
            description: "",
            label: "Promotion Criteria",
            textAreaHeight: "100px",
            type: "textarea",
          },
        ],
      },
      {
        name: "Valid Period",
        fields: [
          {
            name: "g4b_validfrom",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Valid From",
            required: true,
            type: "datetime",
          },
          {
            name: "g4b_validto",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy",
            description: "",
            filterDate: (date, values) => {
              return values.g4b_validfrom
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_validfrom)
                  )
                : true;
            },
            label: "Valid To",
            required: true,
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_validfrom
                ? isSameOrLaterDate(date, values.g4b_validfrom)
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4b_expireddescription",
            className: "col-md-12",
            label: "Expired Description",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Qualifying Details",
        fields: [
          {
            name: "g4b_activitiesfrom",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Activities From",
            type: "datetime",
          },
          {
            name: "g4b_activitiesto",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy",
            description: "",
            filterDate: (date, values) => {
              return values.g4b_activitiesfrom
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_activitiesfrom)
                  )
                : true;
            },
            label: "Activities To",
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_activitiesfrom
                ? isSameOrLaterDate(date, values.g4b_activitiesfrom)
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_productgroupid",
            className: "col-md-4",
            description: "",
            label: "Promotion Group",
            lookup: lookups.g4b_productgroup.all,
            type: "lookup",
            subFormStages: PromotionProductGroupFormStages,
          },
          {
            name: "g4b_communicationname",
            className: "col-md-4",
            description:
              "Uses the name of the communication to look up the ID and validate against it.",
            label: "Communication Name",
            type: "nvarchar",
          },
          {
            name: "g4b_communicationdays",
            className: "col-md-4",
            description:
              "The number of days an email voucher is valid from to a maximum of 366",
            label: "Communication Days",
            type: "int",
            validate: (value) => {
              const isValid = value >= 0 && value <= 366;
              return isValid
                ? ""
                : "You must enter a whole number between 0 and 366";
            },
          },
          {
            name: "g4b_validbookingsxdayssincecommunication",
            className: "col-md-4",
            description:
              "Can only be applied to bookings within this period since receiving the communication to a maximum of 366.",
            label: "Valid Bookings X Days Since Communication",
            type: "int",
            validate: (value) => {
              const isValid = value >= 0 && value <= 366;
              return isValid
                ? ""
                : "You must enter a whole number between 0 and 366";
            },
          },
        ],
      },
      {
        name: "Reward Details",
        fields: [
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_rewardproductgroupid",
            className: "col-md-4",
            description: "",
            label: "Promotion Group",
            lookup: lookups.g4b_productgroup.all,
            type: "lookup",
            subFormStages: PromotionProductGroupFormStages,
          },
          {
            name: "g4b_rewardactivitiesfrom",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Reward Activities From",
            type: "datetime",
          },
          {
            name: "g4b_rewardactivitiesto",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy",
            description: "",
            filterDate: (date, values) => {
              return values.g4b_rewardactivitiesfrom
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_rewardactivitiesfrom)
                  )
                : true;
            },
            label: "Reward Activities To",
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_rewardactivitiesfrom
                ? isSameOrLaterDate(
                    date,
                    values.g4b_rewardactivitiesfrom
                  )
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
        ],
      },
      {
        name: "Exclusions",
        fields: [
          {
            name: "g4b_monday",
            default: true,
            label: "Monday",
            type: "bit",
          },
          {
            name: "g4b_tuesday",
            default: true,
            label: "Tuesday",
            type: "bit",
          },
          {
            name: "g4b_wednesday",
            default: true,
            label: "Wednesday",
            type: "bit",
          },
          {
            name: "g4b_thursday",
            default: true,
            label: "Thursday",
            type: "bit",
          },
          {
            name: "g4b_friday",
            default: true,
            label: "Friday",
            type: "bit",
          },
          {
            name: "g4b_saturday",
            default: true,
            label: "Saturday",
            type: "bit",
          },
          {
            name: "g4b_sunday",
            default: true,
            label: "Sunday",
            type: "bit",
          },
          {
            lineBreak: true,
          },
          {
            name: "g4b_excludespecificdays",
            default: String(ExclusionDates.None),
            enum: ExclusionDates,
            label: "Exclude Specific Days",
            type: "picklist",
          },
        ],
      },
    ],
  },
];
