import React, { useEffect } from "react";
import CloseButton from "./CloseButton";
import "./modal.scss";

function Modal({
  children,
  className,
  hideScrollBar,
  modalCloseButtonClick,
  positionRelative,
  showBackdrop,
  small,
  title,
  titleAsHeader,
}) {
  useEffect(() => {
    positionRelative && document.body.classList.add("modal-open");
  }, [positionRelative]);

  const closeButtonClick = () => {
    // remove modal open class if there are no other modals open
    if (document.querySelectorAll(".modal").length <= 1) {
      document.body.classList.remove("modal-open");
    }
    if (modalCloseButtonClick !== null) {
      modalCloseButtonClick();
    }
  };

  const modalWrapperStyle = `modal 
              ${!showBackdrop && "pe-none no-backdrop"} 
              ${small && "modal-small"} 
              ${positionRelative && "position-relative"}
              ${hideScrollBar && "overflow-hidden"}`;

  const modalDialogStyle = `modal-dialog modal-dialog-centered 
                ${className} 
                ${!showBackdrop && "pe-none"}
                `;

  return (
    <>
      {showBackdrop && (
        <div className="modal-backdrop fade show"></div>
      )}
      <div
        className={modalWrapperStyle}
        tabIndex="-1"
        aria-modal="true"
        style={{ display: "block" }}
      >
        <div className={modalDialogStyle} role="document">
          <div className="modal-content">
            {(title || modalCloseButtonClick !== null) && (
              <div className="modal-header">
                {titleAsHeader ? (
                  <h3 className="modal-title">{title}</h3>
                ) : (
                  <p className="modal-title">{title}</p>
                )}
                {modalCloseButtonClick !== null && (
                  <CloseButton
                    className="float-end"
                    label="Close"
                    onClick={() => {
                      closeButtonClick();
                    }}
                  />
                )}
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

Modal.defaultProps = {
  className: "",
  modalCloseButtonClick: null,
  positionRelative: false,
  showBackdrop: true,
  small: false,
  title: "",
  titleAsHeader: true,
  hideScrollBar: false,
  priority: false,
};

export default Modal;
