import classNames from "classnames";
import { getLinkHref } from "../../Helpers";

function ButtonControl({ properties }) {
  const {
    align,
    backgroundColour,
    borderColour,
    borderRadius,
    borderThickness,
    borderType,
    buttonColour,
    fontFamily,
    fontSize,
    fontWeight,
    margin,
    padding,
    showOnMobile,
    text,
    textAlign,
    textColour,
    width,
  } = properties;

  const href = getLinkHref(properties, "");

  return (
    <table
      className={classNames(
        "full-width zero-margin",
        showOnMobile ? "" : "hide-for-small"
      )}
      style={{ backgroundColor: backgroundColour }}
    >
      <tbody>
        <tr>
          <td style={{ padding: margin, textAlign: align }}>
            <table
              border="0"
              cellSpacing="0"
              cellPadding="0"
              style={{
                display: width === "auto" ? "inline-block" : "table",
                width: width,
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: buttonColour,
                      borderColor: borderColour,
                      borderRadius: borderRadius,
                      borderStyle: borderType,
                      borderWidth: borderThickness,
                      MozBorderRadius: borderRadius,
                      padding: padding,
                      textAlign: textAlign,
                      WebkitBorderRadius: borderRadius,
                      width: "100%",
                    }}
                  >
                    <a
                      style={{
                        color: textColour,
                        fontFamily: fontFamily,
                        fontSize: fontSize,
                        fontWeight: fontWeight,
                      }}
                      href={href}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {text}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ButtonControl;
