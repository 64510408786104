import {
  AllYearsSelectHeader,
  PastYearsSelectHeader,
} from "../components/elements/DateSelectHeader";
import lookups from "./lookups";

export const VenueFormStages = [
  {
    title: "Venue",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_cityid",
            description: "",
            label: "City",
            lookup: lookups.g4_city.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Venue Configurations",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_allowprintathome",
              label: "Allow Print at Home",
              className: "col-bool",
            },
            {
              name: "g4b_printticketsforvenue",
              label: "Print Tickets for Venue",
              className: "col-bool",
            },
            {
              name: "g4b_accesscontrolprovideridname",
              label: "Access Control Provider",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: [
                "Name",
                "g4b_accesscontrolprovideridname",
              ],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_venueconfiguration",
          lookup: lookups.g4b_venueconfiguration.all,
          linkForNewRecord: "/venue-configuration",
          linkedEntityId: "g4b_venueid",
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
      {
        name: "Fixtures",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_code",
              label: "Code",
              className: "col-short-name",
            },
            {
              name: "g4b_salesplanidname",
              label: "Sales Plan",
              className: "col-short-name",
            },
            {
              name: "g4b_venueconfigurationidname",
              label: "Venue Configuration",
              className: "col-short-name",
            },
            {
              name: "g4b_selectseats",
              label: "Select Seats",
              className: "col-bool",
            },
            {
              name: "g4b_purchaserticketlimit",
              label: "Purchaser Ticket Limit",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_startdatetime",
              className: "col-date",
              label: "Start",
              type: "datetime",
            },
            {
              name: "g4b_enddatetime",
              className: "col-date",
              label: "End",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              label: "Created On",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              label: "Modified On",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: [
                "Name",
                "g4b_code",
                "g4b_salesplanidname",
                "g4b_venueconfigurationidname",
              ],
              type: "text",
            },
            {
              name: "g4b_startdatetime",
              renderCustomHeader: AllYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_enddatetime",
              renderCustomHeader: AllYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/fixture",
          linkedEntityId: "g4b_venueid",
          loadStateRelatedEntityName: "g4b_fixture",
          lookup: lookups.g4b_fixture.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
