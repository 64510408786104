import React, { useState } from "react";
import classNames from "classnames";
import { ButtonStyle } from "../../../js/enums";
import { Create } from "../../../js/service";
import { useGlobalReducer } from "../../../GlobalContext";
import { Button, IconCard, Input } from "../../elements/_Elements";
import { scrollToTopOfPage } from "../Helpers";
import { generateCommunicationTemplateDataAndHTML } from "./Helpers";

function Options({ setFieldValue, state, values }) {
  const [inPreviewMode, setInPreviewMode] = useState(
    values.previewComm ? values.previewComm : false
  );
  const [previewDevice, setPreviewDevice] = useState(
    values.previewDevice ? values.previewDevice : "mobile"
  );
  const [previewOrientation, setPreviewOrientation] = useState(
    values.previewOrientation ? values.previewOrientation : "portrait"
  );
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState("");
  const globalDispatch = useGlobalReducer();

  const saveLayout = async () => {
    let response = null;
    setIsSaving(true);

    try {
      let commTemplateDataAndHTML =
        generateCommunicationTemplateDataAndHTML(values);

      const successMessage = `${name} successfully saved as a saved layout`;
      const [serviceResponse] = await Promise.all([
        Create(
          globalDispatch,
          successMessage,
          {
            Name: name,
            Attributes: {
              templateData: commTemplateDataAndHTML.templateData,
              g4c_usedynamiccontent:
                state && state.g4c_usedynamiccontent
                  ? state.g4c_usedynamiccontent
                  : false,
              g4c_regardingtype:
                state && state.g4c_regardingtype
                  ? state.g4c_regardingtype
                  : null,
            },
          },
          "g4c_communicationsavedlayout"
        ),
      ]);
      response = serviceResponse.data;
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
      return response;
    }
  };

  return (
    <div className="rows">
      <Button
        className="w-100 mb-3"
        type="button"
        style={ButtonStyle.Primary}
        text={
          inPreviewMode ? "Exit Preview Mode" : "Enter Preview Mode"
        }
        onClick={() => {
          setFieldValue("previewComm", !inPreviewMode);
          setInPreviewMode(!inPreviewMode);
          scrollToTopOfPage();
        }}
      />

      {inPreviewMode && (
        <>
          <h5>Preview Mode</h5>

          <IconCard
            key={1}
            className="mb-3"
            containerClass={classNames(
              "border",
              "rounded",
              "cursor-pointer",
              previewDevice === "mobile" ? "border-primary" : ""
            )}
            onClick={() => {
              setPreviewDevice("mobile");
              setFieldValue("previewDevice", "mobile");
              scrollToTopOfPage();
            }}
          >
            Mobile
          </IconCard>
          <IconCard
            key={2}
            className="mb-3"
            containerClass={classNames(
              "border",
              "rounded",
              "cursor-pointer",
              previewDevice === "tablet" ? "border-primary" : ""
            )}
            onClick={() => {
              setPreviewDevice("tablet");
              setFieldValue("previewDevice", "tablet");
              scrollToTopOfPage();
            }}
          >
            Tablet
          </IconCard>
          <IconCard
            key={3}
            className="mb-3"
            containerClass={classNames(
              "border",
              "rounded",
              "cursor-pointer",
              previewDevice === "desktop" ? "border-primary" : ""
            )}
            onClick={() => {
              setPreviewDevice("desktop");
              setFieldValue("previewDevice", "desktop");
              scrollToTopOfPage();
            }}
          >
            Desktop
          </IconCard>
          {(previewDevice === "mobile" ||
            previewDevice === "tablet") && (
            <>
              <h5>Orientation</h5>
              <IconCard
                key={4}
                className="mb-3"
                containerClass={classNames(
                  "border",
                  "rounded",
                  "cursor-pointer",
                  previewOrientation === "portrait"
                    ? "border-primary"
                    : ""
                )}
                onClick={() => {
                  setPreviewOrientation("portrait");
                  setFieldValue("previewOrientation", "portrait");
                  scrollToTopOfPage();
                }}
              >
                Portrait
              </IconCard>
              <IconCard
                key={5}
                className="mb-3"
                containerClass={classNames(
                  "border",
                  "rounded",
                  "cursor-pointer",
                  previewOrientation === "landscape"
                    ? "border-primary"
                    : ""
                )}
                onClick={() => {
                  setPreviewOrientation("landscape");
                  setFieldValue("previewOrientation", "landscape");
                  scrollToTopOfPage();
                }}
              >
                Landscape
              </IconCard>
            </>
          )}
        </>
      )}

      {!inPreviewMode && (
        <>
          <h5>Save As Layout</h5>
          <Input
            errors={name ? null : { name: "required" }}
            label={"Name"}
            mandatory={true}
            name={"name"}
            onChange={(event) => setName(event.target.value)}
            placeholder=""
            type={"text"}
            value={name}
          />
          <Button
            className="me-auto mt-2"
            type="button"
            style={ButtonStyle.Primary}
            text={"Save"}
            onClick={() => saveLayout()}
            disabled={isSaving || !name}
          />
        </>
      )}
    </div>
  );
}

export default Options;
