import React, { useState } from "react";
import "./svglayout.scss";
function SVGLayout({ state, values }) {
  const [venueImageHeight, setVenueImageHeight] = useState(0);
  const [venueImageWidth, setVenueImageWidth] = useState(0);

  const matchedEntity =
    state && state.relatedEntities
      ? state.relatedEntities.find((r) => r.entityName === "g4b_area")
      : null;

  const onImageLoad = (event) => {
    setVenueImageHeight(
      event.target.height > 0 ? `${event.target.height}px` : ""
    );
    setVenueImageWidth(
      event.target.width > 0 ? `${event.target.width}px` : ""
    );
  };

  return !values ||
    !values.primaryImage ||
    !values.primaryImage.MimeType ||
    !values.primaryImage.Base64String ? (
    <p>No Image set for this venue configuration. Please set one.</p>
  ) : matchedEntity && matchedEntity.entities.length > 0 ? (
    <div
      className="svg-background"
      style={{ height: venueImageHeight }}
    >
      <img
        src={`data:${values.primaryImage.MimeType};base64,${values.primaryImage.Base64String}`}
        onLoad={(event) => onImageLoad(event)}
        alt="Primary"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0"
        y="0"
        height={venueImageHeight} //Use image height
        width={venueImageWidth} //Use image width
        viewBox={`0 0 ${venueImageWidth} ${venueImageHeight}`} //Set last two values to the image width and height respectively
      >
        <title>Areas used in this venue confiuration blocks</title>
        {matchedEntity.entities.map((m) =>
          m.Fields && m.Fields.g4b_svg ? (
            <path id={m.Id} d={m.Fields.g4b_svg} fill="green" />
          ) : (
            <></>
          )
        )}
      </svg>
    </div>
  ) : (
    <p>
      No Areas loaded. Please add a venue configuration block with a
      block and area set on it
    </p>
  );
}

export default SVGLayout;
