import {
  Align,
  BackgroundColour,
  BorderColour,
  BorderThickness,
  BorderType,
  Colour,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Margin,
  Padding,
  Radius,
  TextAlign,
  TextColour,
  TextPosition,
  VerticalAlign,
} from "../../../CommonProperties";
import { LinkProperties, Width } from "../CommonProperties";

const ImageTextControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "Options",
        fields: [
          {
            name: "showButton",
            description: "",
            label: "Show Button",
            type: "bit",
          },
        ],
      },
      {
        name: "Image",
        fields: [
          {
            name: "src",
            description: "",
            label: "Src",
            mapValue: (value) => {
              return value
                ? `data:${value.MimeType};base64,${value.Base64String}`
                : "";
            },
            type: "image",
          },
          {
            name: "altText",
            description: "",
            label: "Alternative Text",
            type: "nvarchar",
          },
          ...LinkProperties(),
        ],
      },
      {
        name: "Text",
        fields: [
          {
            name: "text",
            description: "",
            label: "Text",
            type: "richtexteditor",
          },
        ],
      },
      {
        name: "Button",
        displayIfFromValues: (values) => values.showButton,
        fields: [
          {
            name: "buttonText",
            description: "",
            label: "Button Text",
            type: "nvarchar",
          },
          ...LinkProperties("button"),
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Text",
        fields: [
          TextColour(""),
          TextAlign(""),
          FontFamily(""),
          FontSize(""),
          FontWeight(""),
          LineHeight(""),
        ],
      },
      {
        name: "Content",
        fields: [
          BackgroundColour(""),
          BorderType(""),
          BorderThickness(""),
          BorderColour(""),
          Padding(""),
          Margin(""),
        ],
      },
      {
        name: "Button",
        displayIfFromValues: (values) => values.showButton,
        fields: [
          Width("button"),
          Align("button"),
          Colour("button"),
          Radius("button", "Button Radius"),
          Padding("button"),
          Margin("button"),
          TextColour("button"),
          TextAlign("button"),
          FontFamily("button"),
          FontSize("button"),
          FontWeight("button"),
          BorderType("button"),
          BorderThickness("button"),
          BorderColour("button"),
        ],
      },
    ],
  },
  {
    title: "Settings",
    sections: [
      {
        name: "",
        fields: [
          VerticalAlign(""),
          Align("image", "Image Align"),
          {
            name: "stackColumns",
            description: "",
            label: "Stack Columns",
            type: "bit",
          },
          {
            name: "showOnMobile",
            description: "",
            label: "Show On Mobile",
            type: "bit",
          },
          TextPosition(""),
        ],
      },
    ],
  },
];

export default ImageTextControlFormStages;
