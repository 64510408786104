import lookups from "./lookups";

export const PrinterFormStages = [
  {
    title: "Printer",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_driverportname",
            label: "Driver / Port Name",
            type: "nvarchar",
          },
          {
            name: "g4b_sendpostfixbyte",
            label: "Send PostFix Byte",
            type: "bit",
          },
          {
            name: "g4b_opentillcommand",
            className: "col-12",
            label: "Open Till Command",
            textAreaHeight: "100px",
            type: "textarea",
          },
          {
            name: "g4b_terminalid",
            className: "col-md-6",
            description:
              "DEPRECATED: Terminal associated with the printer. Please note that printers should be associated to terminals from the Terminal form, in the Additional Printers section.",
            label: "Terminal",
            lookup: lookups.g4b_terminal.all,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
