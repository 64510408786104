export const TelegramFormStages = [
  {
    title: "Telegram",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4b_sequencenumber",
            className: "col-md-6",
            description: "",
            label: "Sequence Number",
            type: "int",
          },
          {
            name: "g4b_sendtime",
            className: "col-md-6",
            description: "",
            label: "Send Time",
            type: "datetime",
          },
          {
            name: "g4b_receivetime",
            className: "col-md-6",
            description: "",
            label: "Receive Time",
            type: "datetime",
          },
          {
            name: "g4b_accesscontrolprovideridname",
            className: "col-md-6",
            description: "",
            label: "Access Control Provider",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "XML",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_receivexml",
            className: "col-12",
            label: "Receive XML",
            textAreaHeight: "200px",
            type: "textarea",
          },
          {
            name: "g4b_sendxml",
            className: "col-12",
            label: "Send XML",
            textAreaHeight: "300px",
            type: "textarea",
          },
        ],
      },
    ],
  },
];
