export const PromotionTypeFormStages = [
  {
    title: "Promotion Type",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_description",
            className: "col-md-8",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            name: "g4b_sql",
            className: "col-12",
            description: "",
            label: "SQL",
            required: true,
            textAreaHeight: "450px",
            type: "textarea",
          },
        ],
      },
    ],
  },
];
