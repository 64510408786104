import { useState } from "react";
import { Button, CollapsibleSection } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";

function Interactions({
  Clicks,
  HardBounces,
  SoftBounces,
  Statistics,
  UniqueClicks,
}) {
  const [showAllLinks, setShowAllLinks] = useState(false);

  const { ClickThroughs, Engagements, OptOuts, Sent, Unsubscribes } =
    Statistics;
  const { TotalOpensUnique } = Engagements;

  const hardBounces = parseInt(HardBounces, 10);
  const softBounces = parseInt(SoftBounces, 10);
  const totalBounces = hardBounces + softBounces;
  const sent = parseInt(Sent, 10);

  const totalSent = sent - totalBounces;

  const clicks = parseInt(Clicks, 10);
  const clicksPercentage = ((clicks * 100) / totalSent).toFixed(2);

  const uniqueClicks = parseInt(UniqueClicks, 10);
  const uniqueClicksPercentage = (
    (uniqueClicks * 100) /
    totalSent
  ).toFixed(2);

  const totalOpensUnique = parseInt(TotalOpensUnique, 10);
  const clicksToOpenPercentage = (
    totalOpensUnique > 0 ? (uniqueClicks * 100) / totalOpensUnique : 0
  ).toFixed(2);

  const unsubscribes = parseInt(Unsubscribes, 10);
  const unsubscribesPercentage = (
    (unsubscribes * 100) /
    totalSent
  ).toFixed(2);

  const clickThroughs = showAllLinks
    ? ClickThroughs
    : ClickThroughs.slice(0, 5);

  function getOptOutPercentage(count) {
    return ((count * 100) / totalSent).toFixed(2);
  }

  return (
    <div className="border p-3 mb-3">
      <CollapsibleSection id={"interactions"} title={"Interactions"}>
        <h4 className="fw-bold text-center">
          {totalSent === 0 ? (
            <>N/A</>
          ) : (
            <>{uniqueClicksPercentage}%</>
          )}
        </h4>
        <p className="text-center">Click rate</p>
        <div className="row">
          <div className="col-12 col-xl-6">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col">Interaction</th>
                  <th scope="col" className="col-number">
                    Total
                  </th>
                  <th scope="col" className="col-number">
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Total Clicks</th>
                  <td>{clicks.toLocaleString()}</td>
                  <td>
                    {totalSent === 0 ? (
                      <>N/A</>
                    ) : (
                      <>{clicksPercentage}%</>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Unique Clicks</th>
                  <td>{uniqueClicks.toLocaleString()}</td>
                  <td>
                    {totalSent === 0 ? (
                      <>N/A</>
                    ) : (
                      <>{uniqueClicksPercentage}%</>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Clicks to Open</th>
                  <td>-</td>
                  <td>
                    {totalSent === 0 ? (
                      <>N/A</>
                    ) : (
                      <>{clicksToOpenPercentage}%</>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 col-xl-6">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col">Management</th>
                  <th scope="col" className="col-number">
                    Total
                  </th>
                  <th scope="col" className="col-number">
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Unsubscribe</th>
                  <td>{unsubscribes.toLocaleString()}</td>
                  <td>
                    {totalSent === 0 ? (
                      <></>
                    ) : (
                      <>{unsubscribesPercentage}%</>
                    )}
                  </td>
                </tr>
                {OptOuts &&
                  OptOuts.length > 0 &&
                  OptOuts.map((optOut, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row" className="ps-3 text-break">
                          {optOut.List}
                        </th>
                        <td>{optOut.Count.toLocaleString()}</td>
                        <td>
                          {totalSent === 0 ? (
                            <></>
                          ) : (
                            <>{getOptOutPercentage(optOut.Count)}%</>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="col-12">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col">Click Tracking</th>
                  <th scope="col" className="col-number">
                    Total
                  </th>
                  <th scope="col" className="col-number">
                    Unique
                  </th>
                </tr>
              </thead>
              <tbody>
                {clickThroughs.map((click, index) => {
                  const clicksPercentage = (
                    clicks > 0 ? (click.TotalCount * 100) / clicks : 0
                  ).toFixed(2);
                  const uniqueClicksPercentage = (
                    uniqueClicks > 0
                      ? (click.Count * 100) / uniqueClicks
                      : 0
                  ).toFixed(2);

                  return (
                    <tr key={index}>
                      <th scope="row" className="ps-3 text-break">
                        {click.Page}
                      </th>
                      <td>
                        {click.TotalCount.toLocaleString()}
                        <strong className="ms-1">
                          ({clicksPercentage}%)
                        </strong>
                      </td>
                      <td>
                        {click.Count.toLocaleString()}
                        <strong className="ms-1">
                          ({uniqueClicksPercentage}%)
                        </strong>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {ClickThroughs.length > 5 && (
              <Button
                className="mb-3"
                onClick={() => setShowAllLinks(!showAllLinks)}
                style={ButtonStyle.Link}
                text={showAllLinks ? "Hide" : "Show all"}
              />
            )}
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
}

export default Interactions;
