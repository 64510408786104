import { Fragment } from "react";
import ReadOnlyTrigger from "./ReadOnlyTrigger";
import { Alert } from "../../elements/_Elements";
import { AlertStyle } from "../../../js/enums";
import { validateFlowData } from "./Helpers";

function ReadOnlyTriggers({ state }) {
  const { g4ca_flowmode, flow_data } = state;

  validateFlowData(flow_data, g4ca_flowmode);

  return (
    <>
      {flow_data.length === 0 ? (
        <Alert
          style={AlertStyle.Warning}
          text="There are no triggers defined"
        />
      ) : (
        flow_data.map((flowControl, index) => {
          return (
            <Fragment key={index}>
              <ReadOnlyTrigger flowControl={flowControl} />
            </Fragment>
          );
        })
      )}
    </>
  );
}

export default ReadOnlyTriggers;
