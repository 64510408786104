import classNames from "classnames";
import "./DesignSection.scss";

function DesignSection({ children, className }) {
  return (
    <div
      className={classNames(
        "design-section-stage",
        "d-flex",
        "position-relative",
        className
      )}
    >
      {children}
    </div>
  );
}

DesignSection.Content = function Content({ children }) {
  return <div className="w-100">{children}</div>;
};

DesignSection.Sidebar = function Sidebar({ children }) {
  return <div className="design-section-sidebar">{children}</div>;
};

export default DesignSection;
