import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { ProductFormStages } from "./product";

export const ProductsFormStages = [
  {
    title: "Products",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_code",
              className: "col-short-name",
              description: "",
              label: "Code",
            },
            {
              name: "g4b_type",
              className: "col-filter",
            },
            {
              name: "g4b_productcalendarid",
              className: "col-filter",
            },
            {
              name: "g4b_categoryid",
              className: "col-filter",
            },
            {
              name: "g4b_sequence",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_channel",
              className: "col-filter",
              lookup: lookups.g4b_channel.all,
              label: "Channels",
            },
            {
              name: "g4_group",
              className: "col-filter",
              lookup: lookups.g4_group.widget,
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_code"],
              type: "text",
            },
            {
              name: "g4b_productcalendarid",
              type: "select",
            },
            {
              name: "g4b_categoryid",
              type: "select",
            },
            {
              name: "g4b_type",
              type: "select",
            },
            {
              name: "g4b_channel",
              filterAction: (filterValue, values) => {
                if (
                  filterValue &&
                  filterValue.length > 0 &&
                  values &&
                  values.Fields &&
                  values.Fields.g4b_channel &&
                  values.Fields.g4b_channel.length > 0 &&
                  values.Fields.g4b_channel.some((c) =>
                    filterValue.includes(c)
                  )
                ) {
                  return values.Fields.g4b_channel.find((c) =>
                    filterValue.includes(c)
                  );
                }
                return null;
              },
              label: "Channel",
              lookup: lookups.g4b_channel.all,
              type: "select",
            },
            {
              name: "g4_group",
              filterAction: (filterValue, values) => {
                if (
                  filterValue &&
                  filterValue.length > 0 &&
                  values &&
                  values.Fields &&
                  values.Fields.g4_group &&
                  values.Fields.g4_group.length > 0 &&
                  values.Fields.g4_group.some((g) =>
                    filterValue.includes(g)
                  )
                ) {
                  return values.Fields.g4_group.find((g) =>
                    filterValue.includes(g)
                  );
                }
                return null;
              },
              label: "Group",
              lookup: lookups.g4_group.widget,
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/product",
          lookup: lookups.g4b_productbase.all,
          subFormStages: ProductFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
