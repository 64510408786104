import lookups from "./lookups";
import {
  CardProvider,
  ChipPinProvider,
  CloverSignatureLocation,
  CloverTipMode,
  PaymentType,
  ThreeDSChallengeIndicator,
  VoucherProvider,
} from "../js/enums";
import { anyRealCardProvider } from "../components/form/Helpers";

export const PaymentMethodFormStages = [
  {
    title: "Payment Method",
    id: "payment-method",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_paymenttype",
            className: "col-md-6",
            description: "",
            enum: PaymentType,
            label: "Type",
            required: true,
            type: "picklist",
          },
        ],
      },
    ],
  },
  {
    title: "Card Provider",
    id: "card-provider",
    displayIfFromState: (values) =>
      [String(PaymentType.Card)].includes(
        String(values.g4b_paymenttype)
      ),
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_cardprovider",
            className: "col-12",
            enum: CardProvider,
            label: "Card Provider",
            required: true,
            type: "picklist",
          },
          {
            name: "g4b_description",
            className: "col-12",
            label: "Description",
            required: false,
            type: "nvarchar",
          },
          {
            name: "g4b_commideamerchant",
            displayIfFromValues: (values) => {
              return [
                CardProvider.Optomany,
                CardProvider.OptomanyPBL,
                CardProvider.WorldPay,
              ].includes(values.g4b_cardprovider);
            },
            getLabel: (values) => {
              if (
                values.g4b_cardprovider === CardProvider.Optomany ||
                values.g4b_cardprovider === CardProvider.OptomanyPBL
              ) {
                return "Client ID";
              }

              return "Merchant Number";
            },
            required: true,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_commideamerchantid",
            displayIfFromValues: (values) => {
              return [
                CardProvider.FirstDataSOAP,
                CardProvider.Optomany,
                CardProvider.OptomanyPBL,
              ].includes(values.g4b_cardprovider);
            },
            getLabel: (values) => {
              if (
                values.g4b_cardprovider === CardProvider.Optomany ||
                values.g4b_cardprovider === CardProvider.OptomanyPBL
              ) {
                return "Terminal Id";
              }

              return "Merchant Id";
            },
            required: true,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_commideapasscode",
            displayIfFromValues: (values) => {
              return anyRealCardProvider(values);
            },
            getLabel: (values) => {
              if (
                values.g4b_cardprovider === CardProvider.Optomany ||
                values.g4b_cardprovider === CardProvider.OptomanyPBL
              ) {
                return "Client Secret";
              }

              return "Passcode";
            },
            required: true,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_commideapayerauth",
            displayIfFromValues: (values) => {
              return [
                CardProvider.FirstDataSOAP,
                CardProvider.Optomany,
                CardProvider.OptomanyPBL,
              ].includes(values.g4b_cardprovider);
            },
            label: "Payer Auth URL",
            required: true,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_post",
            displayIfFromValues: (values) => {
              return [
                CardProvider.FirstDataSOAP,
                CardProvider.OptomanyPBL,
                CardProvider.WorldPay,
              ].includes(values.g4b_cardprovider);
            },
            label: "Post URL",
            required: true,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_redirect",
            displayIfFromValues: (values) => {
              return [
                CardProvider.Test,
                CardProvider.Optomany,
                CardProvider.WorldPay,
              ].includes(values.g4b_cardprovider);
            },
            label: "Redirect URL",
            required: true,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_errorredirect",
            displayIfFromValues: (values) => {
              return [CardProvider.WorldPay].includes(
                values.g4b_cardprovider
              );
            },
            label: "Error Redirect URL",
            required: false,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_commideaaccountid",
            displayIfFromValues: (values) => {
              return [
                CardProvider.Optomany,
                CardProvider.OptomanyPBL,
              ].includes(values.g4b_cardprovider);
            },
            label: "Account Id",
            required: false,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_commideaorgid",
            displayIfFromValues: (values) => {
              return [
                CardProvider.Optomany,
                CardProvider.OptomanyPBL,
              ].includes(values.g4b_cardprovider);
            },
            label: "Organisation Id",
            required: false,
            type: "nvarchar",
            className: "col-md-6",
          },
          {
            name: "g4b_3dschallengeindicator",
            displayIfFromValues: (values) => {
              return anyRealCardProvider(values);
            },
            enum: ThreeDSChallengeIndicator,
            label: "3D Secure Challenge Indicator",
            required: false,
            type: "picklist",
            className: "col-md-6",
          },
          {
            name: "g4b_3dsecure",
            displayIfFromValues: (values) => {
              return [
                CardProvider.Optomany,
                CardProvider.OptomanyPBL,
              ].includes(values.g4b_cardprovider);
            },
            label: "3-D Secure",
            required: false,
            type: "bit",
            className: "col-md-6",
          },
          {
            name: "g4b_useiframe",
            displayIfFromValues: (values) => {
              return anyRealCardProvider(values);
            },
            description: "Use an IFrame to render payment controls",
            label: "Use IFrame",
            required: false,
            type: "bit",
            className: "col-md-6",
          },
          {
            name: "g4b_commideabypass",
            displayIfFromValues: (values) => {
              return anyRealCardProvider(values);
            },
            description: "Yes for testing, No for Production",
            label: "Test Payment Method",
            required: false,
            type: "bit",
            className: "col-md-6",
          },
          {
            name: "g4b_currencyexponent",
            displayIfFromValues: (values) => {
              return [CardProvider.Optomany].includes(
                values.g4b_cardprovider
              );
            },
            description:
              "Number of decimal places when sending currency values to payment provider",
            label: "Number of decimal places",
            required: false,
            type: "int",
            className: "col-md-6",
          },
        ],
      },
      {
        name: "",
        fields: [
          {
            name: "Configuration-InterfaceURL",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [CardProvider.FirstDataSOAP].includes(
                values.g4b_cardprovider
              );
            },
            label: "Interface URL",
            required: true,
            type: "nvarchar",
          },
          {
            name: "Configuration-StoreId",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [CardProvider.FirstDataSOAP].includes(
                values.g4b_cardprovider
              );
            },
            label: "Store Id",
            required: true,
            type: "nvarchar",
          },
          {
            name: "Configuration-APIUserId",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [CardProvider.FirstDataSOAP].includes(
                values.g4b_cardprovider
              );
            },
            label: "API User Id",
            required: true,
            type: "nvarchar",
          },
          {
            name: "Configuration-APIUserPassword",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [CardProvider.FirstDataSOAP].includes(
                values.g4b_cardprovider
              );
            },
            label: "API User Password",
            required: true,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Chip & Pin Provider",
    id: "chip-and-pin-provider",
    displayIfFromState: (values) =>
      [String(PaymentType["Chip & Pin"])].includes(
        String(values.g4b_paymenttype)
      ),
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_chippinprovider",
            className: "col-md-6",
            enum: ChipPinProvider,
            label: "Chip & Pin Provider",
            required: true,
            type: "picklist",
          },
          {
            name: "g4b_chippinmerchantid",
            className: "col-md-6",
            label: "Merchant Id",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_chippinauthcodeisreadonly",
            className: "col-md-6",
            label: "Authcode Is Readonly",
            required: true,
            type: "bit",
          },
          {
            name: "g4b_chippinauthcoderequired",
            className: "col-md-6",
            label: "Authcode Is Required",
            required: true,
            type: "bit",
          },
          {
            name: "g4b_chippincardholderpresent",
            className: "col-md-6",
            label: "Cardholder Is Present",
            required: true,
            type: "bit",
          },
          {
            name: "Configuration-ManualEntry",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Manual Entry",
            required: true,
            type: "bit",
          },
          {
            name: "Configuration-MagStripe",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Mag Stripe",
            required: true,
            type: "bit",
          },
          {
            name: "Configuration-Chip",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Chip",
            required: true,
            type: "bit",
          },
          {
            name: "Configuration-Contactless",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Contactless",
            required: true,
            type: "bit",
          },
          {
            name: "Configuration-CardNotPresent",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Card Not Present",
            type: "bit",
          },
          {
            name: "Configuration-DisableCashBack",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Disable Cash Back",
            type: "bit",
          },
          {
            name: "Configuration-DisableRestartTransactionOnFailure",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Disable Restart Transaction On Failure",
            type: "bit",
          },
          {
            name: "Configuration-DisableReceiptOptions",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Disable Receipt Options",
            type: "bit",
          },
          {
            name: "Configuration-DisableDuplicateChecking",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Disable Duplicate Checking",
            type: "bit",
          },
          {
            name: "Configuration-DisablePrinting",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Disable Printing",
            type: "bit",
          },
          {
            name: "Configuration-AutomaticPaymentConfirmation",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Automatic Payment Confirmation",
            type: "bit",
          },
        ],
      },
      {
        name: "Offline",
        fields: [
          {
            name: "Configuration-OfflineDefault",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Offline Default",
            type: "bit",
          },
          {
            name: "Configuration-Offline",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Offline",
            type: "bit",
          },
          {
            name: "Configuration-ApproveOfflineDefault",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Approve Offline Default",
            type: "bit",
          },
          {
            name: "Configuration-ApproveOffline",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Approve Offline",
            type: "bit",
          },
          {
            name: "Configuration-ForceOfflineDefault",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Force Offline Default",
            type: "bit",
          },
          {
            name: "Configuration-ForceOffline",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Force Offline",
            type: "bit",
          },
        ],
      },
      {
        name: "Tips",
        fields: [
          {
            name: "Configuration-TipModeDefault",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Tip Mode Default",
            type: "bit",
          },
          {
            name: "Configuration-TipMode",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            enum: CloverTipMode,
            label: "Tip Mode",
            type: "picklist",
          },
          {
            name: "Configuration-TipAmount",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Tip Amount",
            type: "money",
          },
        ],
      },
      {
        name: "Signature",
        fields: [
          {
            name: "Configuration-SignatureLocationDefault",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Signature Location Default",
            type: "bit",
          },
          {
            name: "Configuration-AutomaticSignatureConfirmation",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Automatic Signature Confirmation",
            type: "bit",
          },
          {
            name: "Configuration-SignatureLocation",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            enum: CloverSignatureLocation,
            label: "Signature Location",
            type: "picklist",
          },
          {
            name: "Configuration-SignatureThreshold",
            className: "col-md-6",
            displayIfFromValues: (values) => {
              return [ChipPinProvider.Clover].includes(
                values.g4b_chippinprovider
              );
            },
            label: "Signature Threshold",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Finance Provider",
    id: "finance-provider",
    displayIfFromState: (values) =>
      [String(PaymentType.Finance)].includes(
        String(values.g4b_paymenttype)
      ),
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_commideabypass",
            description: "Yes for testing, No for Production",
            label: "Test Account",
            required: false,
            type: "bit",
            className: "col-md-4",
          },
          {
            name: "g4b_commideaaccountid",
            label: "Group Number",
            required: true,
            type: "nvarchar",
            className: "col-md-4",
          },
          {
            name: "g4b_commideapasscode",
            label: "Security Code",
            required: true,
            type: "nvarchar",
            className: "col-md-4",
          },
          {
            name: "g4b_autoadvancedays",
            description:
              "For auto payment types, optionally specify number of days in advance of nominal payment date when payment should be submitted",
            label: "Auto Advance Days",
            required: false,
            type: "int",
            className: "col-md-4",
          },
          {
            name: "g4b_autoexpirydays",
            description:
              "Number of expiry days for submitted auto payments",
            label: "Auto Expiry Days",
            required: false,
            type: "int",
            className: "col-md-4",
          },
        ],
      },
    ],
  },
  {
    title: "Voucher Provider",
    id: "voucher-provider",
    displayIfFromState: (values) =>
      [String(PaymentType.Voucher)].includes(
        String(values.g4b_paymenttype)
      ),
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_voucherprovider",
            className: "col-12",
            enum: VoucherProvider,
            label: "Voucher Provider",
            required: true,
            type: "picklist",
          },
          {
            name: "Configuration-RESTAPIURL",
            displayIfFromValues: (values) => {
              return [VoucherProvider.TescoClubcard].includes(
                values.g4b_voucherprovider
              );
            },
            label: "API URL",
            required: true,
            type: "nvarchar",
            className: "col-md-4",
          },
          {
            name: "Configuration-AppKey",
            displayIfFromValues: (values) => {
              return [VoucherProvider.TescoClubcard].includes(
                values.g4b_voucherprovider
              );
            },
            label: "App Key",
            required: true,
            type: "nvarchar",
            className: "col-md-4",
          },
          {
            name: "Configuration-AppKeyToken",
            displayIfFromValues: (values) => {
              return [VoucherProvider.TescoClubcard].includes(
                values.g4b_voucherprovider
              );
            },
            label: "App Key Token",
            required: true,
            type: "nvarchar",
            className: "col-md-4",
          },
          {
            name: "Configuration-SupplierCode",
            className: "col-md-4",
            displayIfFromValues: (values) => {
              return [VoucherProvider.TescoClubcard].includes(
                values.g4b_voucherprovider
              );
            },
            label: "Supplier Code",
            required: true,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Channels",
    id: "channels",
    sections: [
      {
        name: "",
        fields: [
          {
            className: "col-12 mb-3",
            name: "g4b_channel",
            description: "",
            label: "Payment Method Channels",
            inline: true,
            lookup: lookups.g4b_channel.all,
            type: "checklist",
          },
        ],
      },
    ],
  },
  {
    title: "Certificates",
    id: "certificates",
    displayIfFromState: (values) => {
      return [CardProvider.FirstDataSOAP].includes(
        values.g4b_cardprovider
      );
    },
    sections: [
      {
        name: "",
        fields: [
          {
            name: "ClientCertificateFileName",
            className: "col-md-6",
            label: "Client Certificate",
            getReadOnlyValue: (state) => {
              return state["ClientCertificateFileName"].Name;
            },
            type: "fileupload",
          },
          {
            name: "Configuration-ClientCertificateInstallationPassword",
            className: "col-md-6",
            displayIfFromState: (values) => {
              return [CardProvider.FirstDataSOAP].includes(
                values.g4b_cardprovider
              );
            },
            label: "Client Certificate Installation Password",
            required: true,
            type: "nvarchar",
          },
          {
            name: "TLSTrustCertificate",
            label: "TLS Trust Certificate",
            className: "col-md-6",
            getReadOnlyValue: (state) => {
              return state["TLSTrustCertificate"].Name;
            },
            type: "fileupload",
          },
          {
            name: "Configuration-TLSTrustCertificatePassword",
            className: "col-md-6",
            displayIfFromState: (values) => {
              return [CardProvider.FirstDataSOAP].includes(
                values.g4b_cardprovider
              );
            },
            label: "TLS Trust Certificate Password",
            required: true,
            type: "nvarchar",
          },
          {
            name: "ClientCertificatePem",
            label: "Client Certificate Pem",
            className: "col-md-6",
            getReadOnlyValue: (state) => {
              return state["ClientCertificatePem"].Name;
            },
            type: "fileupload",
          },
          {
            name: "Configuration-ClientCertificatePemPassword",
            className: "col-md-6",
            displayIfFromState: (values) => {
              return [CardProvider.FirstDataSOAP].includes(
                values.g4b_cardprovider
              );
            },
            label: "Client Certificate Pem Password",
            required: true,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
