import lookups from "../../../forms/lookups";
import { Regarding, TargetedAtType } from "../../../js/enums";
import {
  getEntityNameForRegarding,
  getEnumKey,
  isRegardingDifferentToListType,
} from "../../../js/utility";
import {
  getLookupFieldOptions,
  handleFilteredPicklistChange,
} from "../../form/Helpers";

const AddAttributeFormStages = [
  {
    title: "",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "entity",
            description: "",
            displayIfFromState: (state) => {
              const { g4c_listtype, g4c_regardingtype } = state;
              return (
                String(g4c_listtype) !==
                  String(TargetedAtType.Contact) ||
                String(g4c_regardingtype) !==
                  String(Regarding.Contact)
              );
            },
            label: "Entity",
            mapOptions: (_, state) => {
              const { g4c_listtype, g4c_regardingtype } = state;

              const listType = {
                Key: "1",
                Value: getEnumKey(TargetedAtType, g4c_listtype),
              };

              const regardingType = {
                Key: "2",
                Value: getEnumKey(Regarding, g4c_regardingtype),
              };

              const includeRegardingType =
                isRegardingDifferentToListType(
                  g4c_listtype,
                  g4c_regardingtype
                );

              return includeRegardingType
                ? [listType, regardingType]
                : [listType];
            },
            type: "lookup",
          },
          {
            name: "attributeName",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "attribute",
            description: "",
            handleChange: (
              event,
              handleChange,
              fieldName,
              values
            ) => {
              handleChange({
                event,
                target: {
                  name: "attributeName",
                  value: event.label,
                },
              });

              handleFilteredPicklistChange(
                event,
                handleChange,
                fieldName,
                values
              );
            },
            label: "Attribute",
            mapOptions: (_, state, values) => {
              const { g4c_listtype, g4c_regardingtype } = state;
              const { entity } = values;
              const useRegarding = entity === "2";

              let lookup = null;
              if (useRegarding) {
                const regardingEntityName =
                  getEntityNameForRegarding(g4c_regardingtype);
                lookup = lookups[regardingEntityName].attributes;
              } else {
                lookup =
                  g4c_listtype === "1"
                    ? lookups.account.attributes
                    : lookups.contact.attributes;
              }

              return getLookupFieldOptions(
                {
                  lookup: lookup,
                },
                state,
                values,
                null
              );
            },
            type: "lookup",
          },
          {
            name: "defaultValue",
            description:
              "Default value to use when the attribute is not set",
            label: "Default Value",
            type: "nvarchar",
          },
          {
            name: "formatString",
            description:
              "Format the value as a string. For example, represent a datetime value in the format dd/MM/yyyy.",
            label: "Format String",
            type: "nvarchar",
          },
        ],
        wrapInCard: false,
      },
    ],
  },
];

export default AddAttributeFormStages;
