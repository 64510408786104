import lookups from "./lookups";
import {
  AllYearsSelectHeader,
  PastYearsSelectHeader,
} from "../components/elements/DateSelectHeader";
import { FixtureFormStages } from "./fixture";

export const FixturesFormStages = [
  {
    title: "Fixtures",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_code",
              className: "col-short-name",
              description: "",
            },
            {
              name: "g4b_selectseats",
              className: "col-bool",
            },
            {
              name: "g4b_startdatetime",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_enddatetime",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_code"],
              type: "text",
            },
            {
              name: "g4b_startdatetime",
              renderCustomHeader: AllYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_enddatetime",
              renderCustomHeader: AllYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/fixture",
          lookup: lookups.g4b_fixture.all,
          subFormStages: FixtureFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
