import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lookups from "../../../forms/lookups";
import { Time, When } from "./Enums";
import {
  displayCanBeCancelled,
  getCanBeCancelledLabel,
  getLookupForTrigger,
  getOptionsForTrigger,
  getWhenOptions,
} from "./Helpers";
import { ChevronDown, ChevronUp, DeleteIcon } from "../../Icons";
import { DispatchMethods } from "../enums";
import NewCommunication from "./NewCommunication";
import { getLookupFieldOptions } from "../Helpers";

function getSectionsForCommunications(state, values) {
  const { numberOfCommunications } = values;

  let allTagsSelected = [];
  for (let i = 1; i <= numberOfCommunications; i++) {
    const tags = values[`tags${i}`];
    if (tags && tags.length) {
      tags.forEach((tag) => {
        if (!allTagsSelected.includes(tag)) {
          allTagsSelected.push(tag);
        }
      });
    }
  }

  const lookupData = getLookupFieldOptions(
    {
      lookup: lookups.g4b_productgroup.all,
    },
    state,
    values,
    null
  );

  const communicationLookup = getLookupForTrigger(values.trigger);
  const tags = getOptionsForTrigger(values.trigger, lookupData);

  let sections = [];
  if (tags.length === 0) {
    sections.push({
      identifier: 1,
      name: `Communication`,
      fields: [
        {
          name: `communication1`,
          label: "Communication",
          lookup: communicationLookup,
          type: "lookup",
        },
      ],
    });
  } else {
    for (let i = 1; i <= numberOfCommunications; i++) {
      const tagsName = `tags${i}`;
      sections.push({
        identifier: i,
        name: `Communication ${i}`,
        fields: [
          {
            name: `communication${i}`,
            label: "Communication",
            lookup: communicationLookup,
            type: "lookup",
          },
          {
            name: tagsName,
            label: "Tags",
            lookup: lookups.g4b_productgroup.all,
            mapOptions: (_, state, values) => {
              if (!values[tagsName]) {
                return [];
              }

              // the tags to exclude should be all the tags selected other than those for the current tag
              const excludeTags = allTagsSelected.filter(
                (tag) => !values[tagsName].includes(tag)
              );

              return tags.filter((x) => !excludeTags.includes(x.Key));
            },
            showErrorsBeforeTouched: true,
            type: "checklist",
          },
        ],
        moveUp: (dispatch, section, values) => {
          const { identifier } = section;

          // // don't do anything if this is the last item
          if (identifier === 1) {
            return;
          }

          // swap the communication and tags values
          dispatch({
            type: DispatchMethods.SetReloadValues,
            reloadValues: {
              ...values,
              [`communication${identifier - 1}`]:
                values[`communication${identifier}`],
              [`tags${identifier - 1}`]: values[`tags${identifier}`],
              [`communication${identifier}`]:
                values[`communication${identifier - 1}`],
              [`tags${identifier}`]: values[`tags${identifier - 1}`],
            },
          });
        },
        moveDown: (dispatch, section, values) => {
          const { numberOfCommunications } = values;
          const { identifier } = section;

          // // don't do anything if this is the last item
          if (identifier === numberOfCommunications) {
            return;
          }

          // swap the communication and tags values
          dispatch({
            type: DispatchMethods.SetReloadValues,
            reloadValues: {
              ...values,
              [`communication${identifier}`]:
                values[`communication${identifier + 1}`],
              [`tags${identifier}`]: values[`tags${identifier + 1}`],
              [`communication${identifier + 1}`]:
                values[`communication${identifier}`],
              [`tags${identifier + 1}`]: values[`tags${identifier}`],
            },
          });
        },
        onRemove: (dispatch, section, values) => {
          const { numberOfCommunications } = values;
          const { identifier } = section;

          // don't remove if there is only one communication
          if (numberOfCommunications <= 1) {
            return;
          }

          const reloadValues = {
            numberOfCommunications: numberOfCommunications - 1,
          };
          for (const key in values) {
            if (
              key !== "numberOfCommunications" &&
              !key.startsWith("communication") &&
              !key.startsWith("tags")
            ) {
              reloadValues[key] = values[key];
            }
          }

          for (let i = 1; i <= numberOfCommunications; i++) {
            if (i < identifier) {
              reloadValues[`communication${i}`] =
                values[`communication${i}`];
              reloadValues[`tags${i}`] = values[`tags${i}`];
            } else if (i > identifier) {
              reloadValues[`communication${i - 1}`] =
                values[`communication${i}`];
              reloadValues[`tags${i - 1}`] = values[`tags${i}`];
            }
          }

          dispatch({
            type: DispatchMethods.SetReloadValues,
            reloadValues: reloadValues,
          });
        },
        renderHeader: (dispatch, section, values) => {
          const { numberOfCommunications } = values;
          return (
            <div className="col-12">
              <h5 className="d-inline-block">{section.name}</h5>
              {numberOfCommunications > 1 && (
                <div className="float-end">
                  <FontAwesomeIcon
                    className="me-2 cursor-pointer"
                    icon={ChevronUp}
                    onClick={() =>
                      section.moveUp(dispatch, section, values)
                    }
                  />
                  <FontAwesomeIcon
                    className="me-2 cursor-pointer"
                    icon={ChevronDown}
                    onClick={() =>
                      section.moveDown(dispatch, section, values)
                    }
                  />
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={DeleteIcon}
                    onClick={() =>
                      section.onRemove(dispatch, section, values)
                    }
                  />
                </div>
              )}
            </div>
          );
        },
      });
    }

    sections.push({
      name: "",
      fields: [
        {
          name: "newCommunication",
          component: NewCommunication,
          type: "component",
        },
      ],
    });
  }

  return sections;
}

function GetTriggerFormStages(state, values) {
  return [
    {
      title: "",
      sections: [
        {
          name: "",
          fields: [
            {
              name: "when",
              label: "When",
              mapOptions: (_, s, values) =>
                getWhenOptions(values.trigger),
              type: "lookup",
            },
            {
              name: "interval",
              className: "col-6",
              displayIfFromValues: (values) =>
                values.when &&
                String(values.when) !== String(When.Immediately),
              label: "Time",
              type: "int",
            },
            {
              name: "timepart",
              className: "col-6",
              displayIfFromValues: (values) =>
                values.when &&
                String(values.when) !== String(When.Immediately),
              enum: Time,
              label: "Unit",
              type: "picklist",
            },
            {
              name: "canBeCancelled",
              displayIfFromValues: (values) =>
                displayCanBeCancelled(values),
              getLabel: (values) =>
                getCanBeCancelledLabel(values.trigger),
              type: "bit",
            },
          ],
        },
        ...getSectionsForCommunications(state, values),
      ],
    },
  ];
}

export default GetTriggerFormStages;
