import { useCallback, useEffect, useState } from "react";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../../GlobalContext";
import { Alert, Button } from "../../elements/_Elements";
import {
  AlertStyle,
  ButtonStyle,
  LoadingState,
} from "../../../js/enums";
import {
  GetBookingCommActivity,
  SendBookingConfirmation,
} from "../../../js/service";
import { DispatchMethods } from "../enums";
import {
  allNonReadOnlyRoles,
  userHasCorrectRole,
} from "../../../js/roles";

function ResendBookingConfirmation({ dispatch, state }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(null);
  const [triggerCommActivityReload, setTriggerCommActivityReload] =
    useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();
  const { user } = globalState;
  const hasCorrectRole = userHasCorrectRole(
    user,
    allNonReadOnlyRoles
  );

  const sendBookingConfirmation = async () => {
    setIsSubmitting(true);
    setDisplayAlert(null);
    try {
      const [serviceResponse] = await Promise.all([
        SendBookingConfirmation(globalDispatch, state.id),
      ]);
      if (serviceResponse && serviceResponse.data) {
        setDisplayAlert(LoadingState.Loading);
        setTriggerCommActivityReload(true);
        return;
      }
    } catch (error) {
      console.error(error);
      setDisplayAlert(null);
      setIsSubmitting(false);
    }
  };

  const reloadCommActivityData = useCallback(
    async (triggerAlert) => {
      setIsSubmitting(true);
      try {
        const [serviceResponse] = await Promise.all([
          GetBookingCommActivity(globalDispatch, state.id),
        ]);
        if (serviceResponse && serviceResponse.data) {
          // update the related entity records for g4c_communicationactivity
          var updatedRelatedEntities = state.relatedEntities;
          var updatedRelatedEntity = updatedRelatedEntities.find(
            (r) => r.entityName === "g4c_communicationactivity"
          );
          var highlightRelatedEntities = [];
          if (updatedRelatedEntity) {
            //Add list of new records to the highlightRelatedEntities array so it can be highlighted to indicate that its new
            let newRecords = serviceResponse.data.filter(
              (d) =>
                !updatedRelatedEntity.entities.some(
                  (r) => r.Id === d.Id
                )
            );
            newRecords.forEach((newRecord) => {
              highlightRelatedEntities.push({
                entityName: "g4c_communicationactivity",
                id: newRecord.Id,
              });
            });
            updatedRelatedEntity.entities = serviceResponse.data;
          }

          dispatch({
            type: DispatchMethods.SetRelatedEntities,
            relatedEntities: updatedRelatedEntities,
            highlightRelatedEntities: highlightRelatedEntities,
          });
          setTriggerCommActivityReload(false);
          if (triggerAlert) {
            setDisplayAlert(LoadingState.Loaded);
          }
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
        if (timeoutId) {
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }
      }
    },
    [
      dispatch,
      globalDispatch,
      state.id,
      state.relatedEntities,
      timeoutId,
    ]
  );

  useEffect(() => {
    if (triggerCommActivityReload) {
      //Wait 15 seconds before making ticketing call to reload comm activity data
      //as there may be a gap in time between the trigger being sent and the comm activity
      //record being created
      let timeoutIdValue = setTimeout(() => {
        reloadCommActivityData(true);
      }, 15000);
      if (!timeoutId) {
        setTimeoutId(timeoutIdValue);
      }
    }
  }, [
    dispatch,
    globalDispatch,
    reloadCommActivityData,
    state.id,
    state.relatedEntities,
    timeoutId,
    triggerCommActivityReload,
  ]);

  return hasCorrectRole ? (
    <>
      <div className="d-flex justify-content-between">
        <Button
          className="text-decoration-none mb-3"
          style={ButtonStyle.Primary}
          text={"Resend Booking Confirmation"}
          onClick={() => sendBookingConfirmation()}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
        />
        <Button
          className="text-decoration-none mb-3"
          style={ButtonStyle.Info}
          text={"Refresh Table"}
          onClick={() => {
            setDisplayAlert(null);
            reloadCommActivityData(false);
          }}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
      {displayAlert && (
        <Alert
          className="text-center m-0 "
          style={AlertStyle.Success}
          text={`Booking Confirmation email resent. ${
            displayAlert === LoadingState.Loading
              ? "Reloading communication activities"
              : ""
          }`}
        />
      )}
    </>
  ) : (
    <></>
  );
}

export default ResendBookingConfirmation;
