import EditRule from "./EditRule";
import RuleElements from "./RuleElements";

function Sidebar({
  handleBlur,
  handleChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  rulesData,
  setFieldValue,
  state,
  values,
}) {
  return values.editRule ? (
    <EditRule
      {...{
        handleBlur,
        handleChange,
        handleFilteredPicklistChange,
        handlePicklistChange,
        rulesData,
        setFieldValue,
        state,
        values,
      }}
    />
  ) : (
    <RuleElements {...{ values }} />
  );
}

export default Sidebar;
