import { useCallback, useEffect, useRef, useState } from "react";
import { HexColorPicker, RgbColorPicker } from "react-colorful";
import { useClickOutside } from "../../js/utility";
import "./ColourPicker.scss";

function ColourPicker({ handleChange, name, type = "Hex", value }) {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  var regexPattern = /rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/;

  let rgb = { r: "", g: "", b: "" };
  if (type === "RGB" && value) {
    const match = value.match(regexPattern);
    if (match) {
      rgb = {
        r: match[1],
        g: match[2],
        b: match[3],
      };
    }
  }
  const [colour, setColour] = useState(rgb);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  // set the value for RGB colours
  useEffect(() => {
    if (type === "RGB") {
      const colourString =
        colour && colour.r && colour.g && colour.b
          ? `rgb(${colour.r},${colour.g},${colour.b})`
          : "";
      if (colourString !== value) {
        handleChange({
          target: {
            name: name,
            value: colourString,
          },
        });
      }
    }
  }, [colour, handleChange, name, type, value]);

  return (
    <div className="position-relative">
      <div
        className="swatch"
        style={{ backgroundColor: value }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className="popover" ref={popover}>
          {type === "RGB" ? (
            <RgbColorPicker color={colour} onChange={setColour} />
          ) : (
            <HexColorPicker
              color={value}
              onChange={(colour) =>
                handleChange({
                  target: {
                    name: name,
                    value: colour,
                  },
                })
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ColourPicker;
