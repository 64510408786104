import { Alert } from "../../../../elements/_Elements";
import { AlertStyle } from "../../../../../js/enums";

function DateField({ field, isEditing, values, zoomFactor }) {
  const {
    BackgroundColour,
    Capitalize,
    ForegroundColour,
    FontName,
    FontSize,
    InvertColours,
    TextWrap,
    Value,
  } = isEditing ? values : field;
  const displayValue = Capitalize
    ? Value.DisplayValue.toUpperCase()
    : Value.DisplayValue;

  return displayValue ? (
    <p
      className="m-0"
      style={{
        whiteSpace: TextWrap ? "normal" : "nowrap",
        fontFamily: FontName,
        fontSize: `${FontSize * zoomFactor}mm`,
        color: InvertColours ? BackgroundColour : ForegroundColour,
      }}
    >
      {displayValue}
    </p>
  ) : (
    <Alert
      className="p-2"
      style={AlertStyle.Warning}
      text="Select a date"
    />
  );
}

export default DateField;
