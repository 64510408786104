import { ButtonStyle } from "../../js/enums";
import Button from "./Button";
import "./Footer.scss";

function Footer({ backText, children, disabled, isBusy, onBack }) {
  return (
    <footer className="sticky-bottom">
      <div className="d-flex justify-content-end">
        {onBack && (
          <Button
            className="me-auto"
            type="button"
            style={ButtonStyle.Info}
            text={backText}
            onClick={onBack}
            disabled={disabled || isBusy}
          />
        )}
        <div className="ms-3">{children}</div>
      </div>
    </footer>
  );
}

export default Footer;
