import { useState } from "react";
import {
  ButtonStyle,
  GlobalDispatchMethods,
} from "../../../js/enums";
import { Button, Modal } from "../../elements/_Elements";
import { handleRedirect } from "../Helpers";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import {
  StartCommunication,
  StopCommunication,
} from "../../../js/service";

function StopStartModal({ setDisplayStopStartModal, state }) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { g4c_active, g4c_name, id } = state;

  const toggleCommunication = async () => {
    let response = null;
    setIsSubmitting(true);
    try {
      const successMessage = g4c_active
        ? `${g4c_name} successfully stopped`
        : `${g4c_name} successfully started`;

      const method = g4c_active
        ? StopCommunication
        : StartCommunication;

      setIsSubmitting(true);

      const [serviceResponse] = await Promise.all([
        method(globalDispatch, id),
      ]);

      if (serviceResponse && serviceResponse.data) {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: successMessage,
            success: true,
          },
          dispatch: globalDispatch,
        });

        response = serviceResponse.data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (typeof response === "string") {
        // result is the id
        handleRedirect(null, dispatch, id, "stopStartComm");
      } else {
        console.error("Invalid response returned.");
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={
        g4c_active ? "Stop Communication" : "Start Communication"
      }
      modalCloseButtonClick={() => setDisplayStopStartModal(false)}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body">
        <small>
          {g4c_active
            ? "Do you want stop this communication?"
            : "Do you want to start this communication?"}
        </small>
        <br />
        <small>
          {g4c_active
            ? "This action will attempt to stop this communication. This will mean that no further communications will be sent, existing communications will no longer be viewable in a browser and future audits will not be collected."
            : "This action will attempt to start this communication. This will mean that any pending communications will be sent, existing communications will be viewable in a browser and audits will be collected."}
        </small>
      </div>
      <div className="modal-footer">
        <Button
          text={g4c_active ? "Stop" : "Start"}
          style={ButtonStyle.Primary}
          onClick={() => toggleCommunication()}
          showSpinner={isSubmitting}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => setDisplayStopStartModal(false)}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </Modal>
  );
}

export default StopStartModal;
