import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { TerminalFormStages } from "./terminal";

export const TerminalsFormStages = [
  {
    title: "Terminals",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            { name: "g4b_ip", className: "col-short-name" },
            {
              name: "g4b_sessiontimeoutminutes",
              className: "col-bool",
            },
            {
              name: "g4b_skipbeneficiarycollection",
              className: "col-bool",
            },
            {
              name: "g4b_receiptprinterid",
              className: "col-short-name",
            },
            {
              name: "g4b_receipttransformid",
              className: "col-short-name",
            },
            {
              name: "g4b_ticketprinterid",
              className: "col-short-name",
            },
            {
              name: "g4b_tickettransformid",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/terminal",
          lookup: lookups.g4b_terminal.all,
          subFormStages: TerminalFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
