//Documentation on the DatePicker and how to configure it
//https://reactdatepicker.com/
import React, { useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import "./formDatePicker.scss";
import FormLabel from "./FormLabel";
import classNames from "classnames";
import { AllYearsSelectHeader } from "./DateSelectHeader";
import TooltipComponent from "./TooltipComponent";

function FormDatePicker({
  dateFormat = "dd/MM/yyyy",
  description,
  disabled = false,
  errors,
  filterDate,
  filterTime,
  handleDateChange,
  isClearable = true,
  label,
  locale = "en-GB",
  mandatory = false,
  name,
  renderCustomHeader = AllYearsSelectHeader,
  selected,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  timeIntervals = 30,
  timeFormat = "HH:mm",
  tooltipDescription,
}) {
  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);

  return (
    <div
      className={classNames(
        "date-picker-wrapper",
        showTimeSelect ? "date-and-time" : "date-only",
        errors ? "is-invalid" : "is-valid"
      )}
    >
      {label && (
        <div>
          <FormLabel {...{ label, mandatory, name }} />
        </div>
      )}
      <DatePicker
        className={classNames(
          "form-control",
          errors
            ? "is-invalid"
            : mandatory && !disabled
            ? "is-valid"
            : "",
          "date-validation"
        )}
        dateFormat={dateFormat}
        disabled={disabled}
        filterDate={filterDate}
        filterTime={filterTime}
        id={name}
        isClearable={isClearable}
        locale={locale}
        onChange={handleDateChange}
        popperPlacement="top-start"
        selected={selected}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        renderCustomHeader={renderCustomHeader}
      />
      {description && (
        <div className="form-text">
          {description}
          {tooltipDescription ? (
            <TooltipComponent
              name={name}
              tooltipDescription={tooltipDescription}
            />
          ) : (
            ""
          )}
        </div>
      )}
      {errors && <div className="invalid-feedback">{errors}</div>}
    </div>
  );
}

export default FormDatePicker;
