import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { TargetedAtTypeNames } from "../js/enums";
import { CommunicationAudienceFormStages } from "./communicationAudience";
import lookups from "./lookups";

export const CommunicationAudiencesFormStages = [
  {
    title: "Audiences",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4c_entitytype",
              className: "col-short-name",
              enum: TargetedAtTypeNames,
              type: "picklist",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4c_entitytype",
              enum: TargetedAtTypeNames,
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "Name",
          initialOrderedByAsc: true,
          linkForNewRecord: "/communication-audience",
          lookup: lookups.g4c_communicationaudience.all,
          subFormStages: CommunicationAudienceFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
