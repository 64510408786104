import { useEffect, useState } from "react";
import moment from "moment";
import { useGlobalReducer } from "../../GlobalContext";
import { LoadingState } from "../../js/enums";
import { GetEntityAuditsByLoggingId } from "../../js/service";
import { convertDayFormatForMoment } from "../../js/utility";
import EntityAudit from "./EntityAudit";
import Loading from "./Loading";
import Modal from "./Modal";
import SelectWithFiltering from "./SelectWithFiltering";

function getAuditLabel(audit) {
  return `${audit.Type === 0 ? "Created by " : "Updated by "} ${
    audit.EmailAddress
  } on ${moment(audit.CreatedOn).format(
    convertDayFormatForMoment("dd/MM/yyyy HH:mm:ss")
  )}`;
}

function EntityAuditModal({
  audits,
  label,
  loggingId,
  setLoggingId,
}) {
  const globalDispatch = useGlobalReducer();

  const [auditState, setAuditState] = useState({
    audit: "",
    loading: LoadingState.NotLoaded,
    loggingId: "",
  });
  const [compareAuditState, setCompareAuditState] = useState({
    compareAudit: "",
    compareLoading: LoadingState.NotLoaded,
    compareLoggingId: "",
  });

  useEffect(() => {
    if (loggingId !== auditState.loggingId) {
      setAuditState({
        audit: "",
        loading: LoadingState.NotLoaded,
        loggingId: loggingId,
      });
    }
  }, [auditState, loggingId]);

  useEffect(() => {
    const getEntityAudits = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetEntityAuditsByLoggingId(
            globalDispatch,
            label,
            loggingId
          ),
        ]);
        if (serviceResponse && serviceResponse.data) {
          setAuditState({
            audit: serviceResponse.data,
            loading: LoadingState.Loaded,
            loggingId: loggingId,
          });
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    if (auditState.loading === LoadingState.NotLoaded && loggingId) {
      setAuditState({
        ...auditState,
        loading: LoadingState.Loading,
      });
      getEntityAudits();
    }
  }, [auditState, globalDispatch, label, loggingId]);

  useEffect(() => {
    const getEntityAudits = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetEntityAuditsByLoggingId(
            globalDispatch,
            label,
            compareAuditState.compareLoggingId
          ),
        ]);
        if (serviceResponse && serviceResponse.data) {
          setCompareAuditState({
            compareAudit: serviceResponse.data,
            compareLoading: LoadingState.Loaded,
            compareLoggingId: compareAuditState.compareLoggingId,
          });
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    if (
      compareAuditState.compareLoading === LoadingState.NotLoaded &&
      compareAuditState.compareLoggingId
    ) {
      setCompareAuditState({
        ...compareAuditState,
        compareLoading: LoadingState.Loading,
      });
      getEntityAudits();
    }
  }, [compareAuditState, globalDispatch, label]);

  return loggingId ? (
    <Modal
      title={"Audit"}
      modalCloseButtonClick={() => {
        setLoggingId("");
      }}
      className="modal modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-body">
        {auditState.loading !== LoadingState.Loaded ? (
          <Loading />
        ) : (
          <>
            <div className="row">
              <div className="col-6 border-end">
                <strong>
                  {auditState.audit.Type === 0
                    ? "Created by "
                    : "Updated by "}
                  {auditState.audit.EmailAddress}
                </strong>
                <p>
                  {moment(auditState.audit.CreatedOn).format(
                    convertDayFormatForMoment("dd/MM/yyyy HH:mm:ss")
                  )}
                </p>
              </div>
              <div className="col-6">
                {audits.length > 1 && (
                  <>
                    <SelectWithFiltering
                      label="Compare to"
                      mandatory={false}
                      name={"compare-audit-to"}
                      options={audits
                        .filter((x) => x.LoggingId !== loggingId)
                        .map((audit) => {
                          return {
                            Key: audit.LoggingId,
                            Value: getAuditLabel(audit),
                          };
                        })}
                      onChange={(event) =>
                        setCompareAuditState({
                          compareAudit: "",
                          compareLoading: LoadingState.NotLoaded,
                          compareLoggingId:
                            event && event.value ? event.value : "",
                        })
                      }
                      placeholder={"Please select"}
                      value={compareAuditState.compareLoggingId}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6 border-end">
                <EntityAudit audit={auditState.audit} />
              </div>
              <div className="col-6">
                {compareAuditState.compareLoggingId && (
                  <>
                    {compareAuditState.compareLoading !==
                    LoadingState.Loaded ? (
                      <Loading />
                    ) : (
                      <EntityAudit
                        audit={compareAuditState.compareAudit}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  ) : (
    <></>
  );
}

export default EntityAuditModal;
