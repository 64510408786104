import lookups from "./lookups";
import { AccessPointFormStages } from "./accessPoint";

export const ZoneFormStages = [
  {
    title: "Zone",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_zonenumber",
            description: "",
            label: "Zone number",
            required: true,
            type: "int",
          },
          {
            name: "g4b_graceminutes",
            description: "",
            label: "Grace Minutes",
            type: "int",
          },
          {
            name: "g4b_description",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            allowAdd: true,
            className: "col-12 mb-3",
            name: "g4b_accesspoint",
            description:
              "These are the access points which the zone will belong to",
            inline: true,
            label: "Access Points",
            lookup: lookups.g4b_accesspoint.all,
            subFormStages: AccessPointFormStages,
            type: "checklist",
          },
        ],
      },
    ],
  },
];
