import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { CouponTypeFormStages } from "./couponType";
import lookups from "./lookups";

export const CouponTypesFormStages = [
  {
    title: "Coupon Types",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_couponcategoryid",
              className: "col-short-name",
            },
            {
              name: "g4b_notvaliduntildate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_notvaliduntildays",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_redeembydate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_redeembydays",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_usebydate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_usebydays",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_couponcategoryid",
              type: "select",
            },
            {
              name: "g4b_notvaliduntildate",
              type: "datetime",
            },
            {
              name: "g4b_redeembydate",
              type: "datetime",
            },
            {
              name: "g4b_usebydate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/coupon-type",
          lookup: lookups.g4b_coupontype.all,
          subFormStages: CouponTypeFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
