import { BlockFormStages } from "./block";
import { BookableResourceFormStages } from "./bookableResource";
import lookups from "./lookups";
import { SeatClassFormStages } from "./seatClass";
import { VenueConfigurationFormStages } from "./venueConfiguration";

export const VenueConfigurationBlockFormStages = [
  {
    title: "Venue Configuration Block",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4b_blockid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Block",
            lookup: lookups.g4b_block.all,
            required: true,
            subFormStages: BlockFormStages,
            type: "lookup",
          },
          {
            name: "g4b_bookableresourceid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Bookable Resource",
            lookup: lookups.g4b_bookableresource.all,
            subFormStages: BookableResourceFormStages,
            type: "lookup",
          },
          {
            name: "g4b_seatclassid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Seat Class",
            lookup: lookups.g4b_seatclass.all,
            subFormStages: SeatClassFormStages,
            type: "lookup",
          },
          {
            name: "g4b_venueconfigurationid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Venue Configuration",
            lookup: lookups.g4b_venueconfiguration.all,
            subFormStages: VenueConfigurationFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
