import { useState } from "react";
import ReactDOM from "react-dom";
import { Button, Modal } from "../_Elements";
import { ButtonStyle } from "../../../js/enums";

function SourceCode({
  editorRef,
  editorState,
  setEditorState,
  value,
}) {
  const [htmlContent, setHtmlContent] = useState(value);
  const modalRoot = document.getElementById("modal-root");

  const closeModal = (newContent) => {
    if (newContent) {
      editorRef.current.setContent(newContent);
    }

    setEditorState({
      ...editorState,
      viewSourceCode: false,
    });
  };

  return modalRoot ? (
    ReactDOM.createPortal(
      <Modal
        title="HTML"
        modalCloseButtonClick={() => closeModal("")}
        className="modal modal-dialog-scrollable"
      >
        <div className="modal-body">
          <textarea
            className="form-control"
            style={{ height: "400px" }}
            onChange={(event) => setHtmlContent(event.target.value)}
            value={htmlContent}
          />
        </div>
        <div className="modal-footer">
          <Button
            text={"Confirm"}
            style={ButtonStyle.Primary}
            onClick={() => closeModal(htmlContent)}
          />
        </div>
      </Modal>,
      modalRoot
    )
  ) : (
    <></>
  );
}

export default SourceCode;
