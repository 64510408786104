import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { RedoIcon, UndoIcon } from "../../Icons";
import { useFormReducer } from "../FormContext";
import { DispatchMethods } from "../enums";
import "./UndoRedo.scss";

function UndoRedo({ setSnapshots, snapshots, values }) {
  const formDispatch = useFormReducer();

  const onUndo = () => {
    const { redo, undo } = snapshots;
    if (undo.length === 0) {
      return;
    }

    const reloadValues = undo.pop();
    redo.push(values);
    setSnapshots({
      undo: undo,
      redo: redo,
      currentState: reloadValues,
    });

    formDispatch({
      type: DispatchMethods.SetReloadValues,
      reloadValues: reloadValues,
    });
  };

  const onRedo = () => {
    const { currentState, redo, undo } = snapshots;
    if (redo.length === 0) {
      return;
    }

    const reloadValues = redo.pop();
    undo.push(currentState);
    setSnapshots({
      undo: undo,
      redo: redo,
      currentState: reloadValues,
    });

    formDispatch({
      type: DispatchMethods.SetReloadValues,
      reloadValues: reloadValues,
    });
  };

  return (
    <div className="undo-redo">
      <div
        onClick={onUndo}
        className={classNames(
          snapshots.undo.length === 0 ? "disabled" : ""
        )}
      >
        <FontAwesomeIcon icon={UndoIcon} />
      </div>
      <div
        onClick={onRedo}
        className={classNames(
          snapshots.redo.length === 0 ? "disabled" : ""
        )}
      >
        <FontAwesomeIcon icon={RedoIcon} />
      </div>
    </div>
  );
}

export default UndoRedo;
