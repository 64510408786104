import lookups from "./lookups";
import { DonationBatchLineStatus, DonationStatus } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { DonationBatchLineFormStages } from "./donationBatchLine";

export const DonationFormStages = [
  {
    title: "Donation",
    id: "donation",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4d_name",
            className: "col-md-4",
            description: "",
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4d_declarationidname",
            className: "col-md-4",
            description: "",
            label: "Declaration",
            type: "nvarchar",
          },
          {
            name: "g4d_fundidname",
            className: "col-md-4",
            description: "",
            label: "Fund",
            type: "nvarchar",
          },
          {
            name: "g4d_donationstatus",
            className: "col-md-4",
            label: "Donation Status",
            type: "picklist",
            enum: DonationStatus,
          },
          {
            name: "g4d_donationvalue",
            className: "col-md-4",
            description: "",
            label: "Donation Value",
            type: "money",
          },

          {
            name: "g4d_giftaidvalue",
            className: "col-md-4",
            description: "",
            label: "Gift Aid Value",
            type: "money",
          },
          {
            name: "g4m_membershipidname",
            className: "col-md-4",
            description: "",
            label: "Membership",
            type: "nvarchar",
          },
          {
            name: "g4b_bookingidname",
            className: "col-md-4",
            description: "",
            label: "Booking",
            type: "nvarchar",
          },
          {
            name: "g4b_bookingpaymentidname",
            className: "col-md-4",
            description: "",
            label: "Booking Payment",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Donation Batch Lines",
    id: "donation-batch-lines",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4d_batchlinestatus",
              className: "col-short-name",
              label: "Batch Line Status",
              type: "statusbadge",
              enum: DonationBatchLineStatus,
            },
            {
              name: "g4d_firstname",
              className: "col-short-name",
              label: "First Name",
              type: "string",
            },
            {
              name: "g4d_lastname",
              className: "col-short-name",
              label: "Last Name",
              type: "string",
            },
            {
              name: "g4d_addressline1",
              className: "col-short-name",
              label: "Address Line",
              type: "string",
            },
            {
              name: "g4d_postcode",
              className: "col-short-name",
              label: "Address Line",
              type: "string",
            },
            {
              name: "g4d_donationvalue",
              className: "col-short-name",
              label: "Donation value",
              type: "money",
            },
            {
              name: "g4d_giftaidvalue",
              className: "col-short-name",
              label: "Gift Aid Value",
              type: "money",
            },
            {
              name: "g4d_addressline1",
              className: "col-short-name",
              label: "Donation Status",
              type: "statusbadge",
              enum: DonationStatus,
            },
            {
              name: "g4d_paymentdate",
              className: "col-date",
              label: "Payment Date",
              type: "datetime",
            },
            {
              name: "g4b_channelidname",
              className: "col-short-name",
              label: "Channel",
              type: "string",
            },
            {
              name: "g4d_donationbatchheaderidname",
              className: "col-short-name",
              label: "Donation Batch Header",
              type: "string",
            },
            {
              name: "g4d_membershipnumber",
              className: "col-short-name",
              label: "Membership Number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4d_paymentdate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4d_paymentdate",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4d_donationbatchline",
          lookup: lookups.g4d_donationbatchline.all,
          subFormStages: DonationBatchLineFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
