import classNames from "classnames";
import FormLabel from "./FormLabel";
import "./FormCheckList.scss";
import TooltipComponent from "./TooltipComponent";

function FormCheckList({
  actions,
  actionsClass = "d-flex justify-content-end",
  className,
  description,
  disabled = false,
  errors,
  inline = false,
  label,
  name,
  onChange,
  options,
  showErrorsBeforeTouched,
  tooltipDescription,
  touched,
  value,
}) {
  return (
    <>
      {label && (
        <div className="d-flex">
          <FormLabel
            className={"form-label me-auto"}
            label={label}
            name={name}
          />
          {actions && <div className={actionsClass}>{actions}</div>}
        </div>
      )}
      <div
        className={classNames(
          inline ? "input-group justify-content-start" : "",
          errors ? "is-invalid" : "is-valid",
          className
        )}
      >
        {options &&
          options.map((option) => (
            <div
              key={option.Key}
              className={classNames(
                "form-check",
                inline ? "form-check-inline" : "mb-2"
              )}
            >
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                name={name}
                value={option.Key}
                checked={value && value.includes(option.Key)}
                onChange={onChange}
                disabled={disabled}
              />
              <label
                className={`form-check-label ${
                  value && value.includes(option.Key) ? "fw-bold" : ""
                }`}
                htmlFor={name}
              >
                {option.Value}
              </label>
            </div>
          ))}
      </div>
      {description && (
        <div className="form-text">
          {description}
          {tooltipDescription ? (
            <TooltipComponent
              name={name}
              tooltipDescription={tooltipDescription}
            />
          ) : (
            ""
          )}
        </div>
      )}
      {errors && (touched || showErrorsBeforeTouched) && (
        <div className="invalid-feedback">{errors}</div>
      )}
    </>
  );
}

export default FormCheckList;
