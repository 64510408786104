import { useCallback, useEffect, useState } from "react";
import {
  FormDatePicker,
  FormLabel,
  FutureYearsSelectHeader,
  PastYearsSelectHeader,
  SelectWithFiltering,
} from "./_Elements";
import {
  getDateRangeDateValues,
  getFieldLabelForFormTable,
} from "../form/Helpers";
import { isSameOrLaterDate } from "../../js/utility";
import {
  DateTimePickerRangesAll,
  DateTimePickerRangesFuture,
  DateTimePickerRangesPast,
} from "../../js/enums";

function DateTimeFilter({
  displayLabel,
  filter,
  filters,
  setFilters,
  subFormStages,
}) {
  const filterNameFrom = filter.name + "-From";
  const filterNameTo = filter.name + "-To";
  const [currentFilterFromRecord, setCurrentFilterFromRecord] =
    useState(null);
  const [currentFilterFromValue, setCurrentFilterFromValue] =
    useState(null);
  const [currentFilterToRecord, setCurrentFilterToRecord] =
    useState(null);
  const [currentFilterToValue, setCurrentFilterToValue] =
    useState(null);
  const [singleDateFilterFocus, setSingleDateFilterFocus] = //In the event that the filter.DateTimeRange is an option that presents the user with one datetime field,
    useState(null); //this will keep track of which of filterNameFrom or filterNameTo to use in the grabbing of the name and value
  //for if the filter.DateTimeRange is on "On", "On or Before" or "On or After" which will display the single date picker
  const [initialFilterSet, setInitialFilterSet] = useState(true);

  var dateTimeRangeSelected =
    (currentFilterFromRecord &&
      currentFilterFromRecord.DateTimeRange) ||
    (currentFilterToRecord && currentFilterToRecord.DateTimeRange);

  const updateFilterValue = useCallback(
    (dateFrom, dateTo, filter, dateRangeSelected) => {
      let filterNameFrom = filter.name + "-From";
      let filterNameTo = filter.name + "-To";

      let updateFilterFromRecord = {
        Key: filterNameFrom,
        FilterAction: filter.filterAction,
        DateTimeRange: dateRangeSelected,
        Type: "datetime",
        Value: dateFrom,
      };
      setCurrentFilterFromRecord(updateFilterFromRecord);
      setCurrentFilterFromValue(dateFrom);

      let updateFilterToRecord = {
        Key: filterNameTo,
        FilterAction: filter.filterAction,
        DateTimeRange: dateRangeSelected,
        Type: "datetime",
        Value: dateTo,
      };
      setCurrentFilterToRecord(updateFilterToRecord);
      setCurrentFilterToValue(dateTo);

      setFilters([
        ...filters.filter(
          (f) => f.Key !== filterNameFrom && f.Key !== filterNameTo
        ),
        updateFilterFromRecord,
        updateFilterToRecord,
      ]);
    },
    [filters, setFilters]
  );

  //This useEffect should only be ran once upon loading up the datetime filter modal
  useEffect(() => {
    if (initialFilterSet) {
      let currentFilterFrom = filters.some(
        (f) => f.Key === filterNameFrom
      )
        ? filters.find((f) => f.Key === filterNameFrom)
        : "";
      let currentFilterFromValue =
        currentFilterFrom && currentFilterFrom.Value
          ? new Date(currentFilterFrom.Value)
          : "";

      let currentFilterTo = filters.some(
        (f) => f.Key === filterNameTo
      )
        ? filters.find((f) => f.Key === filterNameTo)
        : "";
      let currentFilterToValue =
        currentFilterTo && currentFilterTo.Value
          ? new Date(currentFilterTo.Value)
          : "";

      const initialDateRangeSelected = currentFilterFrom.DateTimeRange
        ? currentFilterFrom.DateTimeRange
        : currentFilterTo.DateTimeRange
        ? currentFilterTo.DateTimeRange
        : null;

      updateFilterValue(
        currentFilterFromValue,
        currentFilterToValue,
        filter,
        initialDateRangeSelected
      );
      setSingleDateFilterFocus(
        initialDateRangeSelected ===
          DateTimePickerRangesAll["On or Before"]
          ? filterNameTo
          : initialDateRangeSelected ===
              DateTimePickerRangesAll["On or After"] ||
            initialDateRangeSelected === DateTimePickerRangesAll.On
          ? filterNameFrom
          : ""
      );
      setInitialFilterSet(false);
    }
  }, [
    filter,
    filterNameFrom,
    filterNameTo,
    filters,
    initialFilterSet,
    updateFilterValue,
  ]);

  const handleDateRangeChange = (event, filter) => {
    if (event && event.value) {
      var newDates = getDateRangeDateValues(event.value);
      switch (event.value) {
        case DateTimePickerRangesAll.On:
        case DateTimePickerRangesAll["On or After"]:
          updateFilterValue(null, null, filter, event.value);
          setSingleDateFilterFocus(filterNameFrom);
          break;
        case DateTimePickerRangesAll["On or Before"]:
          updateFilterValue(null, null, filter, event.value);
          setSingleDateFilterFocus(filterNameTo);
          break;
        case DateTimePickerRangesAll.Yesterday:
        case DateTimePickerRangesAll.Today:
        case DateTimePickerRangesAll.Tomorrow:
        case DateTimePickerRangesAll["Next 7 Days"]:
        case DateTimePickerRangesAll["Last 7 Days"]:
        case DateTimePickerRangesAll["Next Month"]:
        case DateTimePickerRangesAll["Last Month"]:
        case DateTimePickerRangesAll["This Month"]:
        case DateTimePickerRangesAll["Next Year"]:
        case DateTimePickerRangesAll["Last Year"]:
        case DateTimePickerRangesAll["This Year"]:
          updateFilterValue(
            newDates[0],
            newDates[1],
            filter,
            event.value
          );
          break;
        default:
          setSingleDateFilterFocus("");
          updateFilterValue(null, null, filter, event.value);
          break;
      }
    } else {
      setSingleDateFilterFocus("");
      updateFilterValue(null, null, filter, null);
    }
  };

  const handleSingleDateValueChange = (date, filter) => {
    var startOfdate = date ? new Date(date.setHours(0, 0, 0, 0)) : "";
    var endOfdate = date
      ? new Date(date.setHours(23, 59, 59, 999))
      : "";
    switch (dateTimeRangeSelected) {
      case DateTimePickerRangesAll.On:
        updateFilterValue(
          startOfdate,
          endOfdate,
          filter,
          dateTimeRangeSelected
        );
        break;
      case DateTimePickerRangesAll["On or After"]:
        updateFilterValue(
          startOfdate,
          null,
          filter,
          dateTimeRangeSelected
        );
        break;
      case DateTimePickerRangesAll["On or Before"]:
        updateFilterValue(
          null,
          endOfdate,
          filter,
          dateTimeRangeSelected
        );
        break;
      default:
        updateFilterValue(null, null, filter, null);
        break;
    }
  };

  const dateRangeEnum =
    filter.renderCustomHeader &&
    filter.renderCustomHeader === FutureYearsSelectHeader
      ? DateTimePickerRangesFuture
      : filter.renderCustomHeader &&
        filter.renderCustomHeader === PastYearsSelectHeader
      ? DateTimePickerRangesPast
      : DateTimePickerRangesAll;

  return (
    <div>
      {displayLabel && (
        <label className="form-label me-auto">
          {displayLabel
            ? getFieldLabelForFormTable(filter, subFormStages)
            : ""}
        </label>
      )}
      <SelectWithFiltering
        description={filter.description}
        name={filter.name}
        options={[
          { Key: "", Value: "" },
          ...Object.entries(dateRangeEnum).map(([Name, Number]) => ({
            Key: Number,
            Value: Name,
          })),
        ]}
        onChange={(event) => handleDateRangeChange(event, filter)}
        value={dateTimeRangeSelected}
      />
      {dateTimeRangeSelected && (
        <div className="d-flex flex-row justify-content-between mt-3">
          {[
            DateTimePickerRangesAll.On,
            DateTimePickerRangesAll["On or After"],
            DateTimePickerRangesAll["On or Before"],
          ].includes(dateTimeRangeSelected) && (
            <FormDatePicker
              handleDateChange={(date) =>
                handleSingleDateValueChange(date, filter)
              }
              labelInline={true}
              locale={"en-GB"}
              name={singleDateFilterFocus}
              renderCustomHeader={filter.renderCustomHeader}
              selected={
                filters.some(
                  (f) => f.Key === singleDateFilterFocus
                ) &&
                filters.filter(
                  (f) => f.Key === singleDateFilterFocus
                )[0].Value
                  ? new Date(
                      filters.filter(
                        (f) => f.Key === singleDateFilterFocus
                      )[0].Value
                    )
                  : ""
              }
            />
          )}
          {dateTimeRangeSelected ===
            DateTimePickerRangesAll.Custom && (
            <>
              <div>
                <FormLabel
                  className="form-label me-2"
                  label="From"
                  name={filterNameFrom}
                />
                <div className="d-inline-block">
                  <FormDatePicker
                    handleDateChange={(date) =>
                      updateFilterValue(
                        date
                          ? new Date(date.setHours(0, 0, 0, 0))
                          : "",
                        currentFilterToValue,
                        filter,
                        DateTimePickerRangesAll.Custom
                      )
                    }
                    labelInline={true}
                    locale={"en-GB"}
                    name={filterNameFrom}
                    renderCustomHeader={filter.renderCustomHeader}
                    selected={currentFilterFromValue}
                  />
                </div>
              </div>
              <div>
                <FormLabel
                  className="form-label me-2"
                  label="To"
                  name={filterNameTo}
                />
                <div className="d-inline-block">
                  <FormDatePicker
                    filterDate={(date) =>
                      currentFilterFromValue
                        ? isSameOrLaterDate(
                            date,
                            currentFilterFromValue
                          )
                        : true
                    }
                    handleDateChange={(date) =>
                      updateFilterValue(
                        currentFilterFromValue,
                        date
                          ? new Date(date.setHours(23, 59, 59, 999))
                          : "",
                        filter,
                        DateTimePickerRangesAll.Custom
                      )
                    }
                    locale={"en-GB"}
                    name={filterNameTo}
                    renderCustomHeader={filter.renderCustomHeader}
                    selected={currentFilterToValue}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default DateTimeFilter;
