export const ContactAuditFormStages = [
  {
    title: "Contact Audit",
    id: "contact-audit",
    editOnOverview: false,
    displayEditButtonFromState: (values) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "modifiedon",
            className: "col-md-3",
            label: "Modified On",
            dateFormat: "dd/MM/yyyy HH:mm:ss",
            type: "datetime",
          },
          {
            name: "modifiedbyname",
            className: "col-md-4",
            label: "Modified By",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Name",
    id: "name",
    editOnOverview: false,
    displayEditButtonFromState: (values) => false,
    sections: [
      {
        name: "Name",
        fields: [
          {
            name: "g4gdpr_salutation",
            className: "col-md-4",
            label: "Salutation",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_firstname",
            className: "col-md-4",
            label: "First Name",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_middlename",
            className: "col-md-4",
            label: "Middle Name",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_lastname",
            className: "col-md-4",
            label: "Last Name",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_mobilephone",
            label: "Mobile Phone",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_telephone1",
            label: "Business Phone",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_telephone2",
            label: "Home Phone",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_emailaddress1",
            className: "col-md-4",
            label: "EMail",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_jobtitle",
            className: "col-md-4",
            label: "Job Title",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_birthdate",
            className: "col-md-4",
            label: "Birthday",
            dateFormat: "dd/MM/yyyy",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Address",
    id: "address",
    editOnOverview: false,
    displayEditButtonFromState: (values) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4gdpr_address1_line1",
            className: "col-md-4",
            label: "Address Line 1",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_address1_line2",
            className: "col-md-4",
            label: "Address Line 2",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_address1_line3",
            className: "col-md-4",
            label: "Address Line 3",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_address1_city",
            className: "col-md-4",
            label: "City",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_address1_country",
            className: "col-md-4",
            label: "Country",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_address1_county",
            className: "col-md-4",
            label: "County",
            type: "nvarchar",
          },

          {
            name: "g4gdpr_address1_postalcode",
            className: "col-md-4",
            label: "Postcode",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "GDPR Status",
    id: "gdpr-status",
    editOnOverview: false,
    displayEditButtonFromState: (values) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4gdpr_g4_gdprstatus_text",
            label: "GDPR Status",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_g4_privacypolicyversion",
            label: "GDPR Version",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_modifiedbyname",
            label: "Last Modified By",
            type: "nvarchar",
          },
          {
            name: "g4gdpr_modifiedon",
            className: "col-md-3",
            label: "Last Modified On",
            dateFormat: "dd/MM/yyyy HH:mm:ss",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "GDPR Preferences",
    id: "gdpr-preferences",
    editOnOverview: false,
    displayEditButtonFromState: (values) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4gdpr_donotemail",
            className: "col-md-3",
            description: "",
            label: "Send Emails",
            required: false,
            type: "bit",
          },
          {
            name: "g4gdpr_donotbulkemail",
            className: "col-md-3",
            description: "",
            label: "Send Bulk Emails",
            required: false,
            type: "bit",
          },
          {
            name: "g4gdpr_donotpostalmail",
            className: "col-md-3",
            description: "",
            label: "Send Mail",
            required: false,
            type: "bit",
          },
          {
            name: "g4gdpr_donotbulkpostalmail",
            className: "col-md-3",
            description: "",
            label: "Send Bulk Mail",
            required: false,
            type: "bit",
          },
          {
            name: "g4gdpr_donotphone",
            className: "col-md-3",
            description: "",
            label: "Allow Phone Calls",
            required: false,
            type: "bit",
          },
          {
            name: "g4gdpr_g4_donotphone3rdparty",
            className: "col-md-3",
            description: "",
            label: "Allow Third Party Phone Calls",
            required: false,
            type: "bit",
          },
          {
            name: "g4gdpr_g4_donotbulkemail3rdparty",
            className: "col-md-3",
            description: "",
            label: "Send Third Party Bulk Emails",
            required: false,
            type: "bit",
          },
        ],
      },
    ],
  },
];
