import classNames from "classnames";
import FormLabel from "./FormLabel";
import TooltipComponent from "./TooltipComponent";

function TextArea({
  actions,
  actionsClass = "d-flex justify-content-end",
  description,
  disabled = false,
  errors,
  height = "60px",
  label,
  mandatory = false,
  name,
  onBlur,
  onChange,
  placeholder,
  showErrorsBeforeTouched = false,
  tooltipDescription,
  touched,
  value,
}) {
  return (
    <>
      {label && (
        <div className="d-flex">
          <FormLabel
            className={"form-label me-auto z-0"}
            label={label}
            mandatory={mandatory}
            name={name}
          />
          {actions && <div className={actionsClass}>{actions}</div>}
        </div>
      )}
      <div>
        <textarea
          className={classNames(
            "form-control",
            touched || showErrorsBeforeTouched
              ? errors
                ? "is-invalid"
                : value
                ? "is-valid"
                : ""
              : ""
          )}
          disabled={disabled}
          id={name}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          style={{ height: height }}
          value={value}
        />
        {description && (
          <div className="form-text">
            {description}
            {tooltipDescription ? (
              <TooltipComponent
                name={name}
                tooltipDescription={tooltipDescription}
              />
            ) : (
              ""
            )}
          </div>
        )}
        {errors && (touched || showErrorsBeforeTouched) && (
          <div className="invalid-feedback">{errors}</div>
        )}
      </div>
    </>
  );
}
TextArea.defaultProps = {
  placeholder: "",
  type: "textarea",
};
export default TextArea;
