import {
  BookingControl,
  ButtonControl,
  DividerControl,
  ImageControl,
  ImageTextControl,
  MembershipRenewalControl,
  SocialFollowControl,
  TextControl,
} from "./controls";

function CommunicationControl({ name, properties, template }) {
  return (
    <div control-name={name}>
      {template === "booking-control" ? (
        <BookingControl properties={properties} />
      ) : template === "button-control" ? (
        <ButtonControl properties={properties} />
      ) : template === "divider-control" ? (
        <DividerControl properties={properties} />
      ) : template === "image-control" ? (
        <ImageControl properties={properties} />
      ) : template === "image-text-control" ? (
        <ImageTextControl properties={properties} />
      ) : template === "membership-renewal-control" ? (
        <MembershipRenewalControl properties={properties} />
      ) : template === "social-follow-control" ? (
        <SocialFollowControl properties={properties} />
      ) : template === "text-control" ? (
        <TextControl properties={properties} />
      ) : (
        <p>Undefined control</p>
      )}
    </div>
  );
}

export default CommunicationControl;
