import lookups from "./lookups";
import { OperatorGroupFormStages } from "./operatorGroup";

export const OperatorFormStages = [
  {
    title: "Operator",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Operator Group",
        fields: [
          {
            name: "g4b_operatorgroupid",
            allowAdd: true,
            allowEdit: true,
            description:
              "The operator group that this operator belongs to",
            label: "Operator Group",
            lookup: lookups.g4b_operatorgroup.all,
            subFormStages: OperatorGroupFormStages,
            type: "lookup",
          },
        ],
      },
      {
        name: "Credentials",
        fields: [
          {
            name: "g4b_email",
            description: "Operator's email address",
            label: "Email",
            type: "nvarchar",
          },
          {
            name: "g4b_pin",
            description: "Operator's pin code",
            label: "Pin Code",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Permissions",
        fields: [
          {
            name: "g4b_allowdiscounts",
            default: false,
            description: "Permission to give discounts",
            label: "Allow Discounts",
            type: "bit",
          },
          {
            name: "g4b_allowrefunds",
            default: false,
            description: "Permission to give refunds",
            label: "Allow Refunds",
            type: "bit",
          },
        ],
      },
      {
        name: "Reporting",
        fields: [
          {
            name: "g4b_reportname",
            description: "The name to appear on reports",
            label: "Report Name",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
