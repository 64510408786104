import lookups from "./lookups";

export const OperatorGroupFormStages = [
  {
    title: "Operator Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_isallowedtotaketokenisedpayments",
            default: false,
            description: "",
            label: "Allow tokenised payments",
            type: "bit",
          },
          {
            name: "g4b_accountid",
            className: "col-md-4",
            description: "",
            label: "Account",
            lookup: lookups.account.all,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
