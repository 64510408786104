import {
  getAllYears,
  getFutureYears,
  getPastYears,
} from "../../js/utility";
import SelectWithFiltering from "./SelectWithFiltering";

function AllYearsSelectHeader({ date, changeYear, changeMonth }) {
  return (
    <DateSelectHeader
      {...{ date, changeMonth, changeYear }}
      years={getAllYears()}
    />
  );
}

function FutureYearsSelectHeader({ date, changeMonth, changeYear }) {
  return (
    <DateSelectHeader
      {...{ date, changeMonth, changeYear }}
      years={getFutureYears()}
    />
  );
}

function PastYearsSelectHeader({ date, changeMonth, changeYear }) {
  return (
    <DateSelectHeader
      {...{ date, changeMonth, changeYear }}
      years={getPastYears()}
    />
  );
}

function DateSelectHeader({ date, changeYear, changeMonth, years }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthOptions = months.map((month) => ({
    Key: month,
    Value: month,
  }));

  const selectedMonth = months[date.getMonth()];
  const selectedYear = date.getFullYear();
  return (
    <div className="d-flex justify-content-between mx-2">
      <SelectWithFiltering
        onChange={(event) => changeMonth(months.indexOf(event.value))}
        options={monthOptions}
        mandatory={true}
        value={selectedMonth}
      />
      <SelectWithFiltering
        onChange={(event) => changeYear(event.value)}
        options={years}
        mandatory={true}
        value={selectedYear}
      />
    </div>
  );
}

export {
  AllYearsSelectHeader,
  FutureYearsSelectHeader,
  PastYearsSelectHeader,
};
