import { Fragment } from "react";
import classNames from "classnames";
import { Card, FormField } from "../elements/_Elements";
import { useFormReducer } from "./FormContext";
import {
  checkIfFieldDisabled,
  displayElement,
  getFieldLabel,
  getFieldValue,
  getLookupFieldOptions,
} from "./Helpers";
import { useGlobalState } from "../../GlobalContext";

function SidebarForm({
  activeTab,
  errors,
  handleBlur,
  handleChange,
  handleCheckListChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  setFieldValue,
  stages,
  state,
  touched,
  useTabs,
  values,
}) {
  const dispatch = useFormReducer();
  const globalState = useGlobalState();
  return (
    <>
      {stages.map((stage, stageIndex) => {
        const { sections } = stage;
        const fieldSections = sections.filter(
          (section) => section.fields
        );
        return (
          <Fragment key={stageIndex}>
            {fieldSections
              .filter((field) =>
                displayElement(field, state, values, globalState)
              )
              .map((section, sectionIndex) => {
                // render a Fragment instead of Card if wrapInCard is set to false
                const { wrapInCard } = section;
                const CardComponent =
                  typeof wrapInCard !== "undefined" &&
                  wrapInCard === false
                    ? Fragment
                    : Card;

                return (
                  <div
                    key={sectionIndex}
                    className={classNames(
                      "mb-3",
                      useTabs && activeTab !== stage.title
                        ? "d-none"
                        : ""
                    )}
                  >
                    <CardComponent>
                      <div className="row g-3">
                        {section.renderHeader ? (
                          section.renderHeader(
                            dispatch,
                            section,
                            values
                          )
                        ) : section.name ? (
                          <div className="col-12">
                            <h5 className="d-inline-block">
                              {section.name}
                            </h5>
                          </div>
                        ) : (
                          <></>
                        )}
                        {section.fields
                          .filter((field) =>
                            displayElement(
                              field,
                              state,
                              values,
                              globalState
                            )
                          )
                          .map((field, fieldsIndex) => {
                            return (
                              <div
                                key={fieldsIndex}
                                className={
                                  field.className
                                    ? field.className
                                    : "col-12"
                                }
                              >
                                <FormField
                                  {...{
                                    field,
                                    handleBlur,
                                    handleChange,
                                    handleCheckListChange,
                                    handlePicklistChange,
                                    setFieldValue,
                                    state,
                                    touched,
                                    values,
                                  }}
                                  description={field.description}
                                  disabled={
                                    field.disabled ||
                                    checkIfFieldDisabled(
                                      field,
                                      state,
                                      values
                                    )
                                  }
                                  errors={
                                    errors ? errors[field.name] : null
                                  }
                                  handleFilteredPicklistChange={(
                                    event
                                  ) =>
                                    handleFilteredPicklistChange(
                                      event,
                                      handleChange,
                                      field.name,
                                      values
                                    )
                                  }
                                  label={getFieldLabel(
                                    field,
                                    state,
                                    values
                                  )}
                                  mandatory={field.mandatory}
                                  options={getLookupFieldOptions(
                                    field,
                                    state,
                                    values,
                                    null
                                  )}
                                  optionsEnum={field.enum}
                                  value={getFieldValue(field, values)}
                                  values={values}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </CardComponent>
                  </div>
                );
              })}
          </Fragment>
        );
      })}
    </>
  );
}

export default SidebarForm;
