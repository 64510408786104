function MembershipRenewalControl({ properties }) {
  const {
    backgroundColour,
    borderColour,
    borderThickness,
    borderType,
    directDebitReferenceNumber,
    footerBackgroundColour,
    footerCellPadding,
    footerFontFamily,
    footerFontSize,
    footerFontWeight,
    footerTextColour,
    footerVerticalAlign,
    headerBackgroundColour,
    headerCellPadding,
    headerFontFamily,
    headerFontSize,
    headerFontWeight,
    headerTextColour,
    headerVerticalAlign,
    fontFamily,
    fontSize,
    fontWeight,
    margin,
    memberDetailsFontFamily,
    memberDetailsFontSize,
    memberDetailsFontWeight,
    memberDetailsTextColour,
    membership,
    padding,
    price,
    rowBackgroundColour,
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowFontWeight,
    rowLineColour,
    rowLineThickness,
    rowLineType,
    rowTextColour,
    showDirectDebitReference,
    showFooter,
    tableMargin,
    textAlign,
    textColour,
    totalToBeDebited,
  } = properties;

  return (
    <div
      className="plugin-placeholder"
      data-plugin-type="Green4.CRM.Agent.Plugins.MembershipRenewalPlugin"
      data-plugin-assembly="Green4.CRM.Agent.Plugins"
      data-contains-xslt="true"
      data-plugin-inline-css="true"
    >
      <div
        data-xsl-wrap="xsl:template"
        data-xsl-attributes="match='MembershipAccount'"
      >
        <table className="full-width zero-margin" cellSpacing="0">
          <tbody>
            <tr>
              <td style={{ padding: margin }}>
                <table
                  className="full-width zero-margin"
                  cellSpacing="0"
                  style={{ backgroundColor: backgroundColour }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: padding }}>
                        <table
                          className="full-width zero-margin"
                          cellSpacing="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: tableMargin,
                                }}
                              >
                                <table
                                  className="full-width zero-margin"
                                  style={{
                                    borderStyle: borderType,
                                    borderWidth: borderThickness,
                                    borderColor: borderColour,
                                  }}
                                >
                                  <tbody>
                                    <tr
                                      style={{
                                        backgroundColor:
                                          headerBackgroundColour,
                                      }}
                                    >
                                      <td
                                        className="ten"
                                        style={{
                                          color: headerTextColour,
                                          fontFamily:
                                            headerFontFamily,
                                          fontSize: headerFontSize,
                                          fontWeight:
                                            headerFontWeight,
                                          padding: headerCellPadding,
                                          verticalAlign:
                                            headerVerticalAlign,
                                        }}
                                      >
                                        <span>{membership}</span>
                                      </td>
                                      <td
                                        className="two"
                                        style={{
                                          color: headerTextColour,
                                          fontFamily:
                                            headerFontFamily,
                                          fontSize: headerFontSize,
                                          fontWeight:
                                            headerFontWeight,
                                          padding: headerCellPadding,
                                          textAlign: "right",
                                          verticalAlign:
                                            headerVerticalAlign,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span>{price}</span>
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        backgroundColor:
                                          rowBackgroundColour,
                                      }}
                                    >
                                      <td
                                        style={{
                                          color: rowTextColour,
                                          fontFamily: rowFontFamily,
                                          fontSize: rowFontSize,
                                          fontWeight: rowFontWeight,
                                          padding: rowCellPadding,
                                        }}
                                      >
                                        <span className="remove-item">
                                          Locals Pass DD
                                        </span>
                                        <span
                                          className="hide-on-designer"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              '<xsl:value-of select="Membership/Type"></xsl:value-of>',
                                          }}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          color: rowTextColour,
                                          fontFamily: rowFontFamily,
                                          fontSize: rowFontSize,
                                          fontWeight: rowFontWeight,
                                          padding: rowCellPadding,
                                          textAlign: "right",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span className="remove-item">
                                          £50.00
                                        </span>
                                        <span
                                          className="hide-on-designer"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              '<xsl:value-of select="g4:FormatCurrency(Membership/RenewalPeriodFee, Membership/CurrencyCode)"></xsl:value-of>',
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        backgroundColor:
                                          rowBackgroundColour,
                                      }}
                                    >
                                      <td
                                        colSpan="2"
                                        style={{
                                          borderBottomStyle:
                                            rowLineType,
                                          borderBottomWidth:
                                            rowLineThickness,
                                          borderBottomColor:
                                            rowLineColour,
                                          color: rowTextColour,
                                          fontFamily: rowFontFamily,
                                          fontSize: rowFontSize,
                                          fontWeight: rowFontWeight,
                                          padding: rowCellPadding,
                                        }}
                                      >
                                        <table
                                          className="full-width zero-margin"
                                          cellSpacing="0"
                                        >
                                          <tbody>
                                            <tr
                                              style={{
                                                backgroundColor:
                                                  rowBackgroundColour,
                                              }}
                                            >
                                              <td
                                                style={{
                                                  width: "10px",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  color:
                                                    memberDetailsTextColour,
                                                  fontFamily:
                                                    memberDetailsFontFamily,
                                                  fontSize:
                                                    memberDetailsFontSize,
                                                  fontWeight:
                                                    memberDetailsFontWeight,
                                                  textAlign: "left",
                                                  whiteSpace:
                                                    "nowrap",
                                                }}
                                              >
                                                <span className="remove-item">
                                                  Theresa Green
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                  M-12345678
                                                </span>
                                                <span
                                                  className="hide-on-designer"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      '<xsl:value-of select="concat(Membership/ContactName,"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;", Membership/Number)"></xsl:value-of>',
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    {showFooter && (
                                      <>
                                        <tr
                                          style={{
                                            backgroundColor:
                                              footerBackgroundColour,
                                          }}
                                        >
                                          <td
                                            colSpan="2"
                                            style={{
                                              color: footerTextColour,
                                              fontFamily:
                                                footerFontFamily,
                                              fontSize:
                                                footerFontSize,
                                              fontWeight:
                                                footerFontWeight,
                                              padding:
                                                footerCellPadding,
                                              textAlign: "right",
                                              verticalAlign:
                                                footerVerticalAlign,
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {totalToBeDebited}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="remove-item">
                                              £50.00
                                            </span>
                                            <span
                                              className="hide-on-designer"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  '<xsl:value-of select="g4:FormatCurrency(Membership/RenewalPeriodFee, Membership/CurrencyCode)"></xsl:value-of>',
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {showDirectDebitReference && (
                          <p
                            style={{
                              color: textColour,
                              fontFamily: fontFamily,
                              fontSize: fontSize,
                              fontWeight: fontWeight,
                              padding: footerCellPadding,
                              textAlign: textAlign,
                            }}
                          >
                            {directDebitReferenceNumber}
                            &nbsp;
                            <span className="remove-item">5555</span>
                            <span
                              className="hide-on-designer"
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<xsl:value-of select="PaymentMandateReference"></xsl:value-of>',
                              }}
                            />
                          </p>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MembershipRenewalControl;
