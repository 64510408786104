import { administratorRoles } from "../js/roles";

export const VehicleRegistrationNumberFormStages = [
  {
    title: "Vehicle Registration Numbers",
    statusEditAllowedRoles: administratorRoles,
    recordEditRoles: administratorRoles,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Number",
            type: "nvarchar",
          },
          {
            name: "g4b_bookingid",
            hidden: true,
          },
        ],
      },
    ],
  },
];
