import { AnswerOptionFormStages } from "./answerOption";
import lookups from "./lookups";

export const AnswerGroupOptionFormStages = [
  {
    title: "Answer Group Option",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            hidden: true,
            isNameField: true,
          },
          {
            name: "g4_answergroupid",
            hidden: true,
          },
          {
            name: "g4_answeroptionid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Answer Option",
            lookup: lookups.g4_answeroption.all,
            required: true,
            subFormStages: AnswerOptionFormStages,
            type: "lookup",
          },
          {
            name: "g4_sequence",
            className: "col-md-3",
            description: "",
            label: "Sequence",
            required: true,
            type: "int",
          },
        ],
      },
    ],
  },
];
