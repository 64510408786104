import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { PaymentBatchFormStages } from "./paymentBatch";

export const PaymentBatchesFormStages = [
  {
    title: "Payment Batches",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          fields: [
            {
              name: "g4b_reference",
              className: "col-name",
              type: "string",
            },
            {
              name: "g4b_batchdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_batchdate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/payment-batch",
          subFormStages: PaymentBatchFormStages,
          lookup: lookups.g4b_paymentbatch.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
