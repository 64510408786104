import {
  BackgroundColour,
  BorderColour,
  BorderThickness,
  BorderType,
  Colour,
  FontFamily,
  FontSize,
  FontWeight,
  LineThickness,
  LineType,
  Margin,
  Padding,
  TextAlign,
  TextColour,
  VerticalAlign,
} from "../../../CommonProperties";

const MembershipRenewalControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "Options",
        fields: [
          {
            name: "showFooter",
            description: "",
            label: "Show Footer",
            type: "bit",
          },
          {
            name: "showDirectDebitReference",
            description: "",
            label: "Show Direct Debit Reference",
            type: "bit",
          },
        ],
      },
      {
        name: "Labels",
        fields: [
          {
            name: "membership",
            description: "",
            label: "Membership",
            type: "nvarchar",
          },
          {
            name: "price",
            description: "",
            label: "Price",
            type: "nvarchar",
          },
          {
            name: "totalToBeDebited",
            description: "",
            label: "Total To Be Debited",
            type: "nvarchar",
          },
          {
            name: "directDebitReferenceNumber",
            description: "",
            label: "Direct Debit Reference Number",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Content",
        fields: [
          BackgroundColour(""),
          Margin(""),
          Padding(""),
          Margin("table", "Table Margin"),
          BorderType(""),
          BorderThickness(""),
          BorderColour(""),
          TextAlign(""),
          TextColour(""),
          FontFamily(""),
          FontSize(""),
          FontWeight(""),
        ],
      },
      {
        name: "Header",
        fields: [
          BackgroundColour("header"),
          TextColour("header"),
          Padding("headerCell", "Cell Padding"),
          FontFamily("header"),
          FontSize("header"),
          FontWeight("header"),
          VerticalAlign("header"),
        ],
      },
      {
        name: "Rows",
        fields: [
          BackgroundColour("row"),
          Padding("rowCell", "Cell Padding"),
          LineType("row"),
          LineThickness("row"),
          Colour("rowLine", "Line Colour"),
          TextColour("row"),
          FontFamily("row"),
          FontSize("row"),
          FontWeight("row"),
        ],
      },

      {
        name: "Member Details Text",
        fields: [
          TextColour("memberDetails"),
          FontFamily("memberDetails"),
          FontSize("memberDetails"),
          FontWeight("memberDetails"),
        ],
      },
      {
        name: "Footer",
        fields: [
          BackgroundColour("footer"),
          TextColour("footer"),
          Padding("footerCell", "Cell Padding"),
          FontFamily("footer"),
          FontSize("footer"),
          FontWeight("footer"),
          VerticalAlign("footer"),
        ],
      },
    ],
  },
];

export default MembershipRenewalControlFormStages;
