import { useDroppable } from "@dnd-kit/core";
import classNames from "classnames";

function DroppableElement({ children, className, id }) {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        "droppable-element",
        className,
        isOver ? "is-over" : ""
      )}
    >
      {children}
    </div>
  );
}

export default DroppableElement;
