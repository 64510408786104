import { FlowMode } from "../js/enums";
import { When } from "../components/form/communicationFlow/Enums";
import { applyTriggerChanges } from "../components/form/communicationFlow/Helpers";
import PublishedActions from "../components/form/communicationFlow/PublishedActions";
import Designer from "../components/form/communicationFlow/Designer";
import ReadOnlyTriggers from "../components/form/communicationFlow/ReadOnlyTriggers";

export const CommunicationFlowFormStages = [
  {
    title: "Flow",
    displayEditButtonFromState: (state) => !state.g4ca_published,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4ca_flowmode",
            description:
              "When Live, the trigger actions will apply for all contacts. When Test, the trigger actions will only apply to contacts in the Flows Seed List, which is configured in Communication Settings.",
            enum: FlowMode,
            label: "Mode",
            required: true,
            type: "picklist",
          },
          {
            name: "",
            component: PublishedActions,
            displayComponentOnOverview: true,
            displayIfFromState: (state) => state.g4ca_published,
            type: "component",
          },
          {
            name: "g4ca_published",
            description: "",
            hidden: true,
            label: "Published",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Triggers",
    displayEditButtonFromState: (state) => !state.g4ca_published,
    formClass: "design-stage is-fixed",
    handleSubmit: (dispatch, state, values) => {
      const { editTrigger } = values;
      if (!editTrigger) {
        return false;
      }

      applyTriggerChanges(dispatch, state, values);
      return true;
    },
    sections: [
      {
        name: "",
        fields: [
          {
            name: "triggers",
            className: "col-12",
            component: Designer,
            overviewRender: (state, _, values) => {
              return <ReadOnlyTriggers {...{ state, values }} />;
            },
            type: "component",
            validate: (_, values) => {
              // don't validate if there isn't a trigger being modified
              const { editTrigger } = values;
              if (!editTrigger) {
                return "";
              }

              const {
                interval,
                numberOfCommunications,
                timepart,
                when,
              } = values;
              let errors = {};

              // when
              if (!when) {
                errors["when"] = "Required";
              } else if (String(when) !== String(When.Immediately)) {
                // interval and timepart are required
                if (!interval || interval < 1) {
                  errors["interval"] = "Must be greater than 1";
                }
                if (!timepart) {
                  errors["timepart"] = "Required";
                }
              }

              // communications and tags
              for (let i = 1; i <= numberOfCommunications; i++) {
                const communicationName = `communication${i}`;
                const tagsName = `tags${i}`;
                const communication = values[communicationName];
                const tags = values[tagsName];
                if (!communication) {
                  errors[communicationName] = "Required";
                }
                if (i < numberOfCommunications && tags.length === 0) {
                  errors[tagsName] = "Please select at least 1 tag";
                }
              }

              return Object.keys(errors).length > 0 ? errors : "";
            },
          },
          {
            name: "flow_data",
            hidden: true,
            type: "nvarchar",
          },
        ],
        render: (formFields, index) => {
          return (
            <div key={index} className="mb-3">
              {formFields}
            </div>
          );
        },
      },
    ],
  },
];
