import lookups from "./lookups";

export const CouponProductFormStages = [
  {
    title: "Coupon Product",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_coupontypeid",
            hidden: true,
          },
          {
            name: "g4b_additionalcost",
            description: "",
            label: "Additional Cost",
            type: "decimal",
          },
          {
            name: "g4b_productvariantid",
            description: "",
            label: "Product Variant",
            lookup: lookups.g4b_protoproduct.all,
            required: true,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
