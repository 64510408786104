import React, { createContext, useContext, useReducer } from "react";
import { formReducer } from "./FormReducer";

export const FormContext = createContext();

export function useFormState() {
  return useContext(FormContext)[0];
}

export function useFormReducer() {
  return useContext(FormContext)[1];
}

export function FormProvider({ children }) {
  let initialState = {
    initialLoad: true,
    stage: 1,
  };

  const value = useReducer(formReducer, initialState);

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
}
