import { useState } from "react";
import classNames from "classnames";
import {
  EntityInformation,
  FormNavigationButtons,
  Loading,
} from "./_Elements";
import { LoadingState } from "../../js/enums";
import { getHeaderComponent } from "../../js/utility";
import { administratorRoles } from "../../js/roles";
import { checkForValidRoles } from "../form/Helpers";
import { useGlobalState } from "../../GlobalContext";

function FormPage({
  auditLabel,
  backText,
  children,
  className,
  dispatch,
  disabled,
  entityName,
  filteredStages,
  header,
  id,
  isBusy,
  loading,
  onBack,
  onSubmit,
  onSubmitSubForm,
  setLoading,
  showButtonSection = true,
  showInfo,
  state,
  submitText,
  values,
}) {
  const globalState = useGlobalState();

  const [showInformation, setShowInformation] = useState(false);

  const allowShowInformation =
    showInfo &&
    state.id &&
    checkForValidRoles(administratorRoles, globalState);

  const HeaderComponent = getHeaderComponent(
    entityName,
    onSubmitSubForm
  );

  return (
    <form
      id={id}
      onSubmit={onSubmit}
      className={classNames(
        "d-flex",
        "flex-column",
        "min-h-100",
        className
      )}
    >
      <HeaderComponent
        {...{
          allowShowInformation,
          dispatch,
          entityName,
          filteredStages,
          header,
          loading,
          onBack,
          setLoading,
          setShowInformation,
          showInformation,
          state,
          values,
        }}
      />
      <div className="mb-auto">
        <div className="p-3">
          {loading !== LoadingState.Loaded ? (
            <Loading />
          ) : showInformation ? (
            <EntityInformation
              {...{ state }}
              label={auditLabel ? auditLabel : entityName}
            />
          ) : (
            children
          )}
        </div>
      </div>
      {showButtonSection && !showInformation && (
        <FormNavigationButtons
          backText={backText}
          disabled={disabled}
          isBusy={isBusy}
          onBack={onBack}
          submitText={submitText}
        />
      )}
    </form>
  );
}

export default FormPage;
