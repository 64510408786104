import lookups from "./lookups";

export const FundFormStages = [
  {
    title: "Fund",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4d_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4d_fund_number",
            description: "",
            label: "Fund Number",
            required: true,
            type: "int",
          },
          {
            name: "g4d_description",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            name: "g4d_charity_id",
            description: "",
            label: "Charity",
            lookup: lookups.g4d_charity.all,
            required: true,
            type: "lookup",
            autoFillValue: (
              _,
              parentFormState,
              __,
              parentFormEntityName
            ) => {
              if (
                parentFormEntityName === "Charities" &&
                parentFormState &&
                parentFormState.g4d_charityid
              ) {
                return parentFormState.g4d_charityid;
              } else {
                return "";
              }
            },
          },
        ],
      },
    ],
  },
];
