export const DateDefaultValues = {
  BackgroundColour: "rgb(255,255,255)",
  BarcodeFormat: 0,
  Capitalized: false,
  DateFormat: "dd/MM/yyyy HH:mm",
  FontName: "Helvetica",
  FontSize: 3.5,
  ForegroundColour: "rgb(0,0,0)",
  Height: 10,
  InvertColours: false,
  KeepAspectRatio: false,
  Left: 0,
  Orientation: 0,
  ShowHumanReadable: false,
  ShowTotalSeparator: false,
  TextWrap: false,
  Top: 0,
  Type: 6,
  UseOrdinalDateSuffix: false,
  Value: {
    DisplayValue: "",
    Format: 0,
    IsStatic: false,
    Value: "",
  },
  Width: 50,
};
