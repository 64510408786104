import { CollapsibleSection } from "../../elements/_Elements";

function Engagements({
  HardBounces,
  PublishedItems,
  SoftBounces,
  Statistics,
}) {
  const { Blacklisted, Engagements, Invalid, Sent } = Statistics;
  const {
    Inbox,
    InboxUnique,
    LinksUnique,
    TotalOpens,
    TotalOpensUnique,
    Web,
    WebUnique,
  } = Engagements;
  const publishedItems = parseInt(PublishedItems, 10);
  const hardBounces = parseInt(HardBounces, 10);
  const softBounces = parseInt(SoftBounces, 10);
  const totalBounces = hardBounces + softBounces;
  const blacklisted = parseInt(Blacklisted, 10);
  const invalid = parseInt(Invalid, 10);
  const sent = parseInt(Sent, 10);
  const totalOpensUnique = parseInt(TotalOpensUnique, 10);

  const totalSent = sent - totalBounces;

  const openRate =
    sent > 0 ? (totalOpensUnique * 100) / totalSent : 0;

  const totalSentPercentage = (
    (totalSent * 100) /
    publishedItems
  ).toFixed(2);

  const failedPercentage = ((invalid & 100) / publishedItems).toFixed(
    2
  );

  const suppressedPercentage = (
    (blacklisted * 100) /
    publishedItems
  ).toFixed(2);

  const bouncesPercentage = (
    (totalBounces * 100) /
    publishedItems
  ).toFixed(2);

  const hardBouncesPercentage = (
    (hardBounces * 100) /
    totalBounces
  ).toFixed(2);

  const softBouncesPercentage = (
    (softBounces * 100) /
    totalBounces
  ).toFixed(2);

  const totalOpens = parseInt(TotalOpens, 10);
  const totalOpensPercentage = (
    (totalOpens * 100) /
    totalSent
  ).toFixed(2);
  const totalOpensUniquePercentage = (
    (totalOpensUnique * 100) /
    totalSent
  ).toFixed(2);

  const inbox = parseInt(Inbox, 10);
  const inboxPercentage = ((inbox * 100) / totalOpens).toFixed(2);
  const inboxUnique = parseInt(InboxUnique, 10);
  const inboxUniquePercentage = (
    (inboxUnique * 100) /
    totalSent
  ).toFixed(2);

  const web = parseInt(Web, 10);
  const webPercentage = ((web * 100) / totalOpens).toFixed(2);
  const webUnique = parseInt(WebUnique, 10);
  const webUniquePercentage = ((webUnique * 100) / totalSent).toFixed(
    2
  );

  const linksUnique = parseInt(LinksUnique, 10);
  const linksUniquePercentage = (
    (linksUnique * 100) /
    totalSent
  ).toFixed(2);

  return (
    <div className="border p-3 mb-3">
      <CollapsibleSection id={"engagements"} title={"Engagements"}>
        <h4 className="fw-bold text-center">
          {openRate.toFixed(2)}%
        </h4>
        <p className="text-center">Open rate</p>
        <div className="row">
          <div className="col-12 col-xl-6">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col">Delivery</th>
                  <th scope="col" className="col-number">
                    Total
                  </th>
                  <th scope="col" className="col-number">
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Audience</th>
                  <td>{publishedItems.toLocaleString()}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <th scope="row">Sent</th>
                  <td>{totalSent.toLocaleString()}</td>
                  <td>
                    {publishedItems === 0 ? (
                      <></>
                    ) : (
                      <>{totalSentPercentage}%</>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Failed</th>
                  <td>{invalid.toLocaleString()}</td>
                  <td>
                    {publishedItems === 0 ? (
                      <></>
                    ) : (
                      <>{failedPercentage}%</>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Suppressed</th>
                  <td>{blacklisted.toLocaleString()}</td>
                  <td>
                    {publishedItems === 0 ? (
                      <></>
                    ) : (
                      <>{suppressedPercentage}%</>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Bounced</th>
                  <td>{totalBounces.toLocaleString()}</td>
                  <td>
                    {publishedItems === 0 ? (
                      <></>
                    ) : (
                      <>{bouncesPercentage}%</>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="ps-3">
                    Hard
                  </th>
                  <td>{hardBounces.toLocaleString()}</td>
                  <td>
                    {totalBounces > 0 && (
                      <>{hardBouncesPercentage}%</>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="ps-3">
                    Soft
                  </th>
                  <td>{softBounces.toLocaleString()}</td>
                  <td>
                    {totalBounces > 0 && (
                      <>{softBouncesPercentage}%</>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 col-xl-6">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col">Engagement</th>
                  <th scope="col" className="col-number">
                    Total
                  </th>
                  <th scope="col" className="col-number">
                    Unique
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Open</th>
                  <td>
                    {totalOpens.toLocaleString()}
                    {sent > 0 && (
                      <strong className="ms-1">
                        ({totalOpensPercentage})%
                      </strong>
                    )}
                  </td>
                  <td>
                    {totalOpensUnique.toLocaleString()}
                    {sent > 0 && (
                      <strong className="ms-1">
                        ({totalOpensUniquePercentage})%
                      </strong>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="ps-3">
                    Inbox
                  </th>
                  <td>
                    {inbox.toLocaleString()}
                    {totalOpens > 0 && (
                      <strong className="ms-1">
                        ({inboxPercentage})%
                      </strong>
                    )}
                  </td>
                  <td>
                    {inboxUnique.toLocaleString()}
                    {sent > 0 && (
                      <strong className="ms-1">
                        ({inboxUniquePercentage})%
                      </strong>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="ps-3">
                    Web
                  </th>
                  <td>
                    {web.toLocaleString()}
                    {totalOpens > 0 && (
                      <strong className="ms-1">
                        ({webPercentage})%
                      </strong>
                    )}
                  </td>
                  <td>
                    {webUnique.toLocaleString()}
                    {sent > 0 && (
                      <strong className="ms-1">
                        ({webUniquePercentage})%
                      </strong>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="ps-3">
                    Links
                  </th>
                  <td></td>
                  <td>
                    {linksUnique.toLocaleString()}
                    {sent > 0 && (
                      <strong className="ms-1">
                        ({linksUniquePercentage})%
                      </strong>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
}

export default Engagements;
