import React from "react";
import { Card } from "../../elements/_Elements";
import classNames from "classnames";
import { displayFieldValue } from "../../../js/utility";

export default function JournalDailyReportsTable({
  data,
  tableDetails,
}) {
  return (
    <Card className="mb-3">
      <div className="row g-3">
        <div className="col-12">
          <h4 className="d-inline fw-bold text-dark">
            {tableDetails.Title}
          </h4>
        </div>
        <div className="list-view">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="table-info">
                  {tableDetails.Columns.map((c, i) => (
                    <th
                      key={i}
                      scope="col"
                      className={classNames(c.className)}
                    >
                      {c.headingName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((record, i) => {
                  return (
                    <tr key={i}>
                      {tableDetails.Columns.map((c, j) => (
                        <td key={j}>
                          {displayFieldValue(
                            record[c.fieldName],
                            c.type,
                            c
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Card>
  );
}
