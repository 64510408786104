import {
  Card,
  Input,
  SelectWithFiltering,
} from "../../elements/_Elements";
import { PageType, ZoomScale } from "./Enums";

function LayoutOptions({ designerState, setDesignerState, values }) {
  const { back, front } = values;

  const pageOptions = Object.entries(PageType).map(
    ([key, value]) => ({
      Key: value,
      Value: key,
    })
  );

  const zoomOptions = Object.entries(ZoomScale).map(
    ([key, value]) => ({
      Key: key,
      Value: value,
    })
  );

  return (
    <Card className="mb-3">
      <div className="d-flex">
        <div className="me-3">
          {front && back ? (
            <SelectWithFiltering
              label="Page"
              name="layout-page"
              onChange={(event) =>
                setDesignerState({
                  ...designerState,
                  layoutIndex: parseInt(event.value),
                })
              }
              options={pageOptions}
              value={designerState.layoutIndex}
            />
          ) : (
            <Input
              disabled={true}
              label="Page"
              name="layout-page"
              value={Object.keys(PageType).find(
                (key) => PageType[key] === designerState.layoutIndex
              )}
            />
          )}
        </div>
        <div className="me-auto">
          <Input
            label="Snap Increment"
            name="layout-snap-increment"
            onChange={(event) =>
              setDesignerState({
                ...designerState,
                snapIncrement: event.target.value,
              })
            }
            type="int"
            value={designerState.snapIncrement}
          />
        </div>
        <SelectWithFiltering
          label="Zoom"
          mandatory={true}
          name="layout-zoom"
          onChange={(event) =>
            setDesignerState({
              ...designerState,
              zoom: event.value,
            })
          }
          options={zoomOptions}
          value={designerState.zoom}
        />
      </div>
    </Card>
  );
}

export default LayoutOptions;
