import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { ChannelFormStages } from "./channel";
import lookups from "./lookups";

export const ChannelsFormStages = [
  {
    title: "Channels",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            { name: "g4b_minutesinthepast", className: "col-number" },
            {
              name: "g4b_monthsinthefuture",
              className: "col-number",
            },
            {
              name: "g4b_automarkticketsprinted",
              className: "col-bool",
            },
            {
              name: "g4b_selectdeliveryaddress",
              className: "col-bool",
            },
            { name: "g4b_enableprintathome", className: "col-bool" },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/channel",
          lookup: lookups.g4b_channel.all,
          subFormStages: ChannelFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
