export const AccessPointFormStages = [
  {
    title: "Access Point",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_password",
            description: "",
            label: "Password",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_displayurl",
            description: "",
            label: "Display URL",
            type: "nvarchar",
          },
          {
            name: "g4b_id",
            description: "",
            label: "Gate",
            type: "int",
          },
        ],
      },
    ],
  },
];
