import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeft } from "../Icons";
import {
  Button,
  JumpToContent,
  ProgressTracker,
  SetStatusModal,
} from "./_Elements";
import {
  ButtonStyle,
  LoadingState,
  RecordStatus,
} from "../../js/enums";
import "./FormPageHeader.scss";
import { SetRecordState } from "../../js/service";
import { useGlobalReducer } from "../../GlobalContext";
import { handleRedirect } from "../form/Helpers";

function FormPageHeaderSimple({
  allowShowInformation,
  dispatch,
  entityName,
  filteredStages,
  header,
  onBack,
  scrollToElement,
  setLoading,
  setShowInformation,
  showInformation,
  stages,
  state,
}) {
  const [
    showConfirmStatusChangeModal,
    setShowConfirmStatusChangeModal,
  ] = useState(false);
  const globalDispatch = useGlobalReducer();

  const handleRecordStatusChange = () => {
    const setStatus = async () => {
      setLoading(LoadingState.Loading);
      try {
        const [serviceResponse] = await Promise.all([
          SetRecordState(globalDispatch, {
            Ids: [state.id],
            EntityName: entityName,
            StateCode: state.statecode === 0 ? 1 : 0,
          }),
        ]);
        if (serviceResponse && serviceResponse.status === 200) {
          handleRedirect(null, dispatch, state.id, "refreshPage");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };
    setStatus();
  };

  const displayJumpTo =
    stages && stages.length > 0 && stages.some((s) => s.id);

  return (
    <>
      {showConfirmStatusChangeModal && (
        <SetStatusModal
          handleRecordStatusChange={handleRecordStatusChange}
          recordStatusToShow={
            state.statecode === 0
              ? RecordStatus.Active
              : RecordStatus.Inactive
          }
          setShowConfirmStatusChangeModal={
            setShowConfirmStatusChangeModal
          }
          singleRecord={true}
          state={state}
        />
      )}
      <div id="form-page-header" className="sticky-top px-3">
        {state &&
          !state.id &&
          filteredStages &&
          filteredStages.length > 1 && (
            <ProgressTracker {...{ filteredStages, state }} />
          )}
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {state && header && !showInformation && onBack && (
              <Button
                className="btn-sm me-3"
                onClick={onBack}
                style={ButtonStyle.Info}
                text={<FontAwesomeIcon icon={ArrowLeft} />}
              />
            )}
            {header && (
              <h2 className="fw-bold text-dark me-3">{header}</h2>
            )}
          </div>
          <div className="d-flex align-items-end">
            <div className="me-2">
              {allowShowInformation && showInformation ? (
                <Button
                  onClick={() => setShowInformation(false)}
                  text="Content"
                />
              ) : (
                <>
                  {displayJumpTo && (
                    <JumpToContent {...{ scrollToElement, stages }} />
                  )}
                </>
              )}
            </div>
            {allowShowInformation && !showInformation && (
              <Button
                onClick={() => setShowInformation(true)}
                style={ButtonStyle.OutlinePrimary}
                text="Info"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPageHeaderSimple;
