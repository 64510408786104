import { AlertStyle } from "../../../../../js/enums";
import { Alert } from "../../../../elements/_Elements";

function ImageField({ field, isEditing, values }) {
  const { Value } = isEditing ? values : field;

  return (
    <>
      {Value && Value.DisplayValue ? (
        <img
          src={`data:image/jpeg;base64,${Value.DisplayValue}`}
          alt=""
        />
      ) : (
        <Alert
          className="p-2"
          style={AlertStyle.Warning}
          text="No image"
        />
      )}
    </>
  );
}

export default ImageField;
