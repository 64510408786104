export const AreaFormStages = [
  {
    title: "Area",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            className: "col-md-6",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "g4b_svg",
            className: "col-12",
            description: "",
            label: "SVG",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
