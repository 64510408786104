import { DraggableIconCard } from "../../elements/_Elements";

function RowElements() {
  return (
    <>
      <DraggableIconCard id="1-column" title="1 Column" />
      <DraggableIconCard
        id="2-columns"
        className="mb-3"
        title="2 Columns"
      />
      <DraggableIconCard id="3-columns" title="3 Columns" />
    </>
  );
}

export default RowElements;
