import React, { useState } from "react";
import { Badge, Button } from "../../elements/_Elements";
import { BackgroundStyle, ButtonStyle } from "../../../js/enums";
import { handleRedirect } from "../Helpers";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { Update } from "../../../js/service";

function AccessControlProviderHasError({ state, values }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();
  const clearError = async () => {
    setIsSubmitting(true);
    try {
      const successMessage = "Error Cleared";
      const [serviceResponse] = await Promise.all([
        Update(
          globalDispatch,
          successMessage,
          {
            Id: values.g4a_accesscontrolstatusid,
            Name: values.g4a_name,
            Attributes: {},
          },
          "g4a_accesscontrolstatus"
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        handleRedirect(null, dispatch, state.id, "refreshPage");
        return;
      }
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {values.g4a_haserror ? (
        <>
          <Badge
            backgroundStyle={BackgroundStyle.Danger}
            className="me-2"
            item={{ text: "Has error" }}
          />
          <Button
            className="text-decoration-none"
            style={ButtonStyle.Primary}
            text={"Clear Error"}
            onClick={() => clearError()}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
          />
        </>
      ) : (
        <Badge
          backgroundStyle={BackgroundStyle.Success}
          className="me-2"
          item={{ text: "No error" }}
        />
      )}
    </>
  );
}

export default AccessControlProviderHasError;
