import { ColumnsOptions } from "../../enums";
import {
  BackgroundColour,
  BorderColour,
  BorderThickness,
  BorderType,
  FontFamily,
  FontSize,
  FontWeight,
  Margin,
  LineHeight,
  Padding,
  TextAlign,
  TextColour,
} from "../../../CommonProperties";
import { Columns, ColumnSplit } from "../CommonProperties";

const TextControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "text1",

            description: "",
            label: "Text",
            type: "richtexteditor",
          },
          {
            name: "text2",
            description: "",
            displayIfFromValues: (values) =>
              String(values.columns) ===
              String(ColumnsOptions["2 Columns"]),
            label: "Text 2",
            type: "richtexteditor",
          },
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Text",
        fields: [
          TextColour(""),
          TextAlign(""),
          FontFamily(""),
          FontSize(""),
          FontWeight(""),
          LineHeight(""),
        ],
      },
      {
        name: "Content",
        fields: [
          BackgroundColour(""),
          BorderType(""),
          BorderThickness(""),
          BorderColour(""),
          Padding(""),
          Margin(""),
        ],
      },
    ],
  },
  {
    title: "Settings",
    sections: [
      {
        name: "",
        fields: [
          Columns(""),
          ColumnSplit(""),
          {
            name: "stackColumns",
            description: "",
            label: "Stack Columns",
            type: "bit",
          },
          {
            name: "showOnMobile",
            description: "",
            label: "Show On Mobile",
            type: "bit",
          },
        ],
      },
    ],
  },
];

export default TextControlFormStages;
