import { useState } from "react";
import { Alert, Button, Input } from "../../../../elements/_Elements";
import { AlertStyle, ButtonStyle } from "../../../../../js/enums";
import { useFormReducer } from "../../../FormContext";
import { DispatchMethods } from "../../../enums";

function NewSocialPlatform({ values }) {
  const dispatch = useFormReducer();

  const [newSocialPlatform, setNewSocialPlatform] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const addNewSocialPlatform = () => {
    setErrorMessage("");

    if (!newSocialPlatform) {
      return;
    }

    const { editControl, rows, socialPlatforms } = values;
    const { columnIndex, controlIndex, rowIndex } = editControl;

    const identifier = newSocialPlatform
      .toLowerCase()
      .trim()
      .replace(" ", "");

    const socialPlatformsArray = socialPlatforms.split(",");
    // prevent the identifier being added if it already exists
    if (socialPlatformsArray.includes(identifier)) {
      return;
    }

    // add the new platform
    socialPlatformsArray.push(identifier);

    // prevent more than 8 social platforms
    if (socialPlatformsArray.length > 8) {
      setErrorMessage(
        "Unable to add social platform. The maximum number of platforms is 8."
      );
      return;
    }

    const result = socialPlatformsArray.join(",");

    // update the properties within the rows
    const control =
      rows[rowIndex].Columns[columnIndex].Controls[controlIndex];

    control.Properties.find(
      (p) => p.Name === "socialPlatforms"
    ).Value = result;

    // add properties for the platform
    const properties = [
      { Name: identifier + "Name", Value: newSocialPlatform },
      { Name: identifier + "Url", Value: "" },
      { Name: identifier + "Text", Value: newSocialPlatform },
      { Name: identifier + "Src", Value: "" },
      { Name: identifier + "Width", Value: "48" },
      { Name: identifier + "Height", Value: "48" },
    ];

    control.Properties = [...control.Properties, ...properties];

    dispatch({
      type: DispatchMethods.SetReloadValues,
      reloadValues: {
        ...values,
        rows: rows,
        socialPlatforms: result,
        [identifier + "Name"]: newSocialPlatform,
        [identifier + "Url"]: "",
        [identifier + "Text"]: newSocialPlatform,
        [identifier + "Src"]: "",
        [identifier + "Width"]: "48",
        [identifier + "Height"]: "48",
      },
    });
  };

  return (
    <div className="row g-3">
      <div className="col-12">
        <Input
          description="Enter the name of a social platform e.g. YouTube. Up to 8 platforms are allowed."
          label="New social platform"
          onChange={(event) =>
            setNewSocialPlatform(event.target.value)
          }
          name="new-social-platform"
          type="text"
          value={newSocialPlatform}
        />
      </div>
      <div className="col-12">
        <Button
          className="w-100"
          onClick={addNewSocialPlatform}
          style={ButtonStyle.Primary}
          text="Add"
          type="button"
        />
      </div>
      {errorMessage && (
        <div className="col-12">
          <Alert
            className="m-0"
            style={AlertStyle.Danger}
            text={errorMessage}
          />
        </div>
      )}
    </div>
  );
}

export default NewSocialPlatform;
