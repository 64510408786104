import classNames from "classnames";
import { displayFieldValue } from "../../../js/utility";
import { DeliveryFulfilmentStatus } from "../../../js/enums";
import StatusBadge from "../../elements/StatusBadge";
function BookingAddresses({ state }) {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr className="table-info">
            <th scope="col" className={classNames("col-primary")}>
              Type
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              Label
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              Line 1
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              Line 2
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              Line 3
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              City
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              County
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              Postcode
            </th>
            <th scope="col" className={classNames("col-short-name")}>
              Country
            </th>
            <th scope="col" className={classNames("col-name")}>
              Delivery Fulfilment Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Payment Address</th>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_label,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_line1,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_line2,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_line3,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_city,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_county,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_postalcode,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_paymentaddress_country,
                "string"
              )}
            </td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">Delivery Address</th>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_label,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_line1,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_line2,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_line3,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_city,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_county,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_postalcode,
                "string"
              )}
            </td>
            <td>
              {displayFieldValue(
                state.g4b_deliveryaddress_country,
                "string"
              )}
            </td>
            <td>
              <StatusBadge
                enumList={DeliveryFulfilmentStatus}
                value={state.g4b_deliveryfulfilmentstatus}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BookingAddresses;
