import { PrinterJobType } from "../../../js/enums";
import { DraggableIconCard } from "../../elements/_Elements";
import {
  BarcodeIcon,
  CalendarIcon,
  ImageIcon,
  QrcodeIcon,
  TextIcon,
} from "../../Icons";

function NewField({ state }) {
  const { g4b_printerjobtypeidname } = state;

  // only show the barcodes for relevant printer job types
  const showBardcodeFields =
    [
      PrinterJobType.Card,
      PrinterJobType.Coupon,
      PrinterJobType.Membership,
      PrinterJobType.Receipt,
      PrinterJobType.Ticket,
      PrinterJobType.Voucher,
    ].indexOf(g4b_printerjobtypeidname) > 0;

  return (
    <>
      <DraggableIconCard
        icon={TextIcon}
        id="Text-field"
        title="Text"
      />
      <DraggableIconCard
        icon={ImageIcon}
        id="Image-field"
        title="Image"
      />
      {showBardcodeFields && (
        <>
          <DraggableIconCard
            icon={BarcodeIcon}
            id="LinearBarcode-field"
            title="Linear Barcode"
          />
          <DraggableIconCard
            icon={QrcodeIcon}
            id="TwoDimensionalBarcode-field"
            title="2D Barcode"
          />
        </>
      )}
      <DraggableIconCard
        icon={CalendarIcon}
        id="Date-field"
        title="Date"
      />
    </>
  );
}

export default NewField;
