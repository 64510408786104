import { Fragment } from "react";
import Badge from "./Badge";

function BadgeList({
  backgroundStyle,
  badgeClassName = "me-2",
  className,
  items,
  label,
  onDeleteBadge,
}) {
  return (
    <>
      {label && <p className="fw-bold m-0">{label}</p>}
      {items && items.length > 0 && (
        <div className={className}>
          {items.map((item, i) => {
            return item && item.text && item.text.trim() ? (
              <Badge
                key={i}
                backgroundStyle={
                  item.backgroundStyle
                    ? item.backgroundStyle
                    : backgroundStyle
                }
                className={badgeClassName}
                onDelete={onDeleteBadge}
                item={item}
              />
            ) : (
              <Fragment key={i} />
            );
          })}
        </div>
      )}
    </>
  );
}

export default BadgeList;
