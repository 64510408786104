import lookups from "./lookups";
import {
  DeclarationCancellationReason,
  DonationStatus,
} from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { DonationFormStages } from "./donation";

export const DeclarationFormStages = [
  {
    title: "Gift Aid Declaration",
    id: "declaration",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4d_name",
            className: "col-12",
            description: "",
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4d_contactidname",
            className: "col-md-4",
            description: "",
            label: "Contact",
            type: "nvarchar",
          },
          {
            name: "g4d_charityidname",
            className: "col-md-4",
            description: "",
            label: "Charity",
            type: "nvarchar",
          },
          {
            name: "g4b_channelidname",
            className: "col-md-4",
            description: "",
            label: "Channel",
            type: "nvarchar",
          },
          {
            name: "g4d_declarationstartdate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Start Date",
            type: "datetime",
          },
          {
            name: "g4d_declarationenddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "End Date",
            type: "datetime",
          },
          {
            name: "g4d_currentdonation",
            className: "col-md-4",
            description: "",
            label: "Current Donation",
            type: "bit",
          },
          {
            name: "g4d_historicaldonation",
            className: "col-md-4",
            description: "",
            label: "Historical Donation",
            type: "bit",
          },
          {
            name: "g4d_futuredonation",
            className: "col-md-4",
            description: "",
            label: "Future Donation",
            type: "bit",
          },
          {
            name: "g4d_cancelleddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            displayIfFromState: (values) => values.g4d_cancelleddate,
            label: "Cancelled Date",
            type: "datetime",
          },
          {
            name: "g4d_declarationcancelled",
            className: "col-md-6",
            description: "",
            displayIfFromState: (values) => values.g4d_cancelleddate,
            label: "Cancellation Reason",
            type: "picklist",
            enum: DeclarationCancellationReason,
          },
        ],
      },
    ],
  },
  {
    title: "Donations",
    id: "donations",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4b_bookingidname",
              className: "col-short-name",
              label: "Booking",
              type: "string",
            },
            {
              name: "g4d_fundidname",
              className: "col-short-name",
              label: "Fund",
              type: "string",
            },
            {
              name: "g4d_donationstatus",
              className: "col-short-name",
              label: "Donation Status",
              type: "statusbadge",
              enum: DonationStatus,
            },
            {
              name: "g4d_donationvalue",
              className: "col-short-name",
              label: "Donation value",
              type: "money",
            },
            {
              name: "g4d_giftaidvalue",
              className: "col-short-name",
              label: "Gift Aid Value",
              type: "money",
            },
            {
              name: "g4m_membershipidname",
              className: "col-short-name",
              label: "Membership",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4d_donation",
          lookup: lookups.g4d_donation.all,
          subFormStages: DonationFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
