import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { FieldType } from "./Enums";
import {
  deleteField,
  getFieldTransform,
  setEditField,
} from "./Helpers";
import { EditElement } from "../../elements/_Elements";
import DateField from "./fields/date/DateField";
import ImageField from "./fields/image/ImageField";
import LinearBarcodeField from "./fields/linearBarcode/LinearBarcodeField";
import TextField from "./fields/text/TextField";
import TwoDimensionalBarcodeField from "./fields/TwoDimensional/TwoDimensionalBarcodeField";

function Field({
  activeId,
  allowEdit,
  field,
  fieldIndex,
  layerIndex,
  layoutIndex,
  page,
  setFieldValue,
  snapIncrement,
  values,
  zoomFactor,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const id = `movefield-${layoutIndex}-${layerIndex}-${fieldIndex}`;
  const { attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: id,
    });

  const editField = values ? values.editField : null;
  const isEditing =
    editField &&
    editField.fieldIndex === fieldIndex &&
    editField.layerIndex === layerIndex &&
    editField.layoutIndex === layoutIndex;

  const {
    BackgroundColour,
    ForegroundColour,
    Height,
    InvertColours,
    Type,
    Width,
  } = isEditing ? values : field;
  const toolbarWidth = Math.max(Width * zoomFactor, 30);

  return (
    <EditElement
      iconSize="sm"
      isActive={activeId === id}
      isEditing={isEditing}
      moveAttributes={attributes}
      moveListeners={listeners}
      onDelete={() => {
        deleteField(
          globalDispatch,
          fieldIndex,
          layerIndex,
          layoutIndex,
          setFieldValue,
          values
        );
      }}
      onEdit={() => {
        setEditField(
          dispatch,
          globalDispatch,
          fieldIndex,
          layerIndex,
          layoutIndex,
          values
        );
      }}
      setNodeRef={setNodeRef}
      showToolbar={allowEdit && !editField}
      style={{
        transform: getFieldTransform(
          field,
          isEditing,
          page,
          activeId === id ? snapIncrement : 1, // only apply the snapIncrement while dragging
          transform,
          values,
          zoomFactor
        ),
        width: `${toolbarWidth}mm`,
      }}
    >
      <div
        className={classNames(
          "print-transform-field",
          activeId === id ? "is-active" : "",
          allowEdit ? "allow-edit" : ""
        )}
        style={{
          width: `${Width * zoomFactor}mm`,
          height: `${Height * zoomFactor}mm`,
          backgroundColor: InvertColours
            ? ForegroundColour
            : BackgroundColour,
        }}
      >
        {Type === FieldType.Image ? (
          <ImageField {...{ field, isEditing, values, zoomFactor }} />
        ) : Type === FieldType.Text ? (
          <TextField {...{ field, isEditing, values, zoomFactor }} />
        ) : Type === FieldType.Date ? (
          <DateField {...{ field, isEditing, values, zoomFactor }} />
        ) : Type === FieldType.LinearBarcode ? (
          <LinearBarcodeField
            {...{ field, isEditing, values, zoomFactor }}
          />
        ) : Type === FieldType.TwoDimensionalBarcode ? (
          <TwoDimensionalBarcodeField
            {...{ field, isEditing, values, zoomFactor }}
          />
        ) : (
          <></>
        )}
      </div>
    </EditElement>
  );
}

export default Field;
