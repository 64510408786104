import {
  GlobalDispatchMethods,
  PrinterJobType,
} from "../../../js/enums";
import { DispatchMethods } from "../enums";
import { BarcodeFormatType, DragType, FieldType } from "./Enums";
import {
  AccountAddressFields,
  CardFields,
  CommonFields,
  ContactAddressFields,
  CouponFields,
  MembershipFields,
  ReceiptFields,
  TicketFields,
  VoucherFields,
} from "./FieldOptions";
import { TwoDimensionalBarcodeDefaultValues } from "./fields/TwoDimensional/TwoDimensionalBarcodeDefaultValues";
import TwoDimensionalFormStages from "./fields/TwoDimensional/TwoDimensionalBarcodeFormStages";
import { DateDefaultValues } from "./fields/date/DateDefaultValues";
import DateFormStages from "./fields/date/DateFormStages";
import { ImageDefaultValues } from "./fields/image/ImageDefaultValues";
import ImageFormStages from "./fields/image/ImageFormStages";
import { LinearBarcodeDefaultValues } from "./fields/linearBarcode/LinearBarcodeDefaultValues";
import LinearBarcodeFormStages from "./fields/linearBarcode/LinearBarcodeFormStages";
import { TextDefaultValues } from "./fields/text/TextDefaultValues";
import TextFormStages from "./fields/text/TextFormStages";

function applyFieldChanges(dispatch, values) {
  const { back, editField, front } = values;
  const { fieldIndex, layerIndex, layoutIndex } = editField;

  const page = layoutIndex === 0 ? front : back;
  const newValue = {
    ...page,
    Layers: page.Layers.map((layer, j) =>
      j === layerIndex
        ? {
            ...layer,
            Fields: layer.Fields.map((field, k) => {
              if (k === fieldIndex) {
                const newField = {};
                for (const prop in field) {
                  if (values && values.hasOwnProperty(prop)) {
                    newField[prop] = values[prop];
                  } else {
                    newField[prop] = field[prop];
                  }
                }
                return newField;
              } else {
                return field;
              }
            }),
          }
        : layer
    ),
  };

  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      back: layoutIndex === 1 ? newValue : back,
      editField: "",
      front: layoutIndex === 0 ? newValue : front,
    },
  });
}

function cancelFieldChanges(dispatch, values) {
  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      back: values.back,
      editField: "",
      front: values.front,
    },
  });
}

function convertPxToMm(value) {
  return (value * 25.4) / 96;
}

function deleteField(
  globalDispatch,
  index,
  layerIndex,
  layoutIndex,
  setFieldValue,
  values
) {
  const { back, editField, front } = values;

  // prevent if a field is being edited
  if (editField) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot delete while a field is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  const fieldName = layoutIndex === 0 ? "front" : "back";
  const page = layoutIndex === 0 ? front : back;

  setFieldValue(fieldName, {
    ...page,
    Layers: page.Layers.map((layer, j) =>
      j === layerIndex
        ? {
            ...layer,
            Fields: layer.Fields.filter((_, k) => k !== index),
          }
        : layer
    ),
  });
}

function getFieldDisplayValue(value, jobType) {
  if (jobType !== PrinterJobType.Membership) {
    // check if the value matches to a common field
    const commonField = CommonFields.find((x) => x.Key === value);
    if (commonField) {
      return commonField.Value;
    }
  }

  // check if the value matches to a field for the current type
  let typeField = null;
  switch (jobType) {
    case PrinterJobType["Account Address"]:
      typeField = AccountAddressFields.find((x) => x.Key === value);
      break;
    case PrinterJobType.Card:
      typeField = CardFields.find((x) => x.Key === value);
      break;
    case PrinterJobType["Contact Address"]:
      typeField = ContactAddressFields.find((x) => x.Key === value);
      break;
    case PrinterJobType.Coupon:
      typeField = CouponFields.find((x) => x.Key === value);
      break;
    case PrinterJobType.Membership:
      typeField = MembershipFields.find((x) => x.Key === value);
      break;
    case PrinterJobType.Receipt:
      typeField = ReceiptFields.find((x) => x.Key === value);
      break;
    case PrinterJobType.Ticket:
      typeField = TicketFields.find((x) => x.Key === value);
      break;
    case PrinterJobType.Voucher:
      typeField = VoucherFields.find((x) => x.Key === value);
      break;
    default:
      break;
  }
  if (typeField) {
    return typeField.Value;
  }

  return "Invalid field";
}

const getDragType = (activeId) => {
  return activeId.indexOf("movefield") === 0
    ? DragType.MoveField
    : DragType.NewField;
};

function getFieldTransform(
  field,
  isEditing,
  page,
  snapIncrement,
  transform,
  values,
  zoomFactor
) {
  const { Left, Top } = isEditing ? values : field;

  // determine the co-ordinates
  const x = transform ? transform.x : 0;
  const left = getFieldLeftPosition(
    field.Width,
    page,
    snapIncrement * zoomFactor,
    Left * zoomFactor + convertPxToMm(x),
    zoomFactor
  );

  const y = transform ? transform.y : 0;
  const top = getFieldTopPosition(
    field.Height,
    page,
    snapIncrement * zoomFactor,
    Top * zoomFactor + convertPxToMm(y),
    zoomFactor
  );

  return `translate(${left}mm, ${top}mm)`;
}

function getOptionsForPrintJobType(type) {
  let fields = [];

  switch (type) {
    case PrinterJobType["Account Address"]:
      fields = AccountAddressFields.concat(CommonFields);
      break;
    case PrinterJobType.Card:
      fields = CardFields.concat(CommonFields);
      break;
    case PrinterJobType["Contact Address"]:
      fields = ContactAddressFields.concat(CommonFields);
      break;
    case PrinterJobType.Coupon:
      fields = CouponFields.concat(CommonFields);
      break;
    case PrinterJobType.Membership:
      fields = MembershipFields;
      break;
    case PrinterJobType.Receipt:
      fields = ReceiptFields.concat(CommonFields);
      break;
    case PrinterJobType.Ticket:
      fields = TicketFields.concat(CommonFields);
      break;
    case PrinterJobType.Voucher:
      fields = VoucherFields.concat(CommonFields);
      break;
    default:
      break;
  }

  fields = fields.sort((x, y) => x.Value.localeCompare(y.Value));

  return fields;
}

function getStagesForField(values) {
  const { Type } = values;

  switch (Type) {
    case FieldType.Date:
      return DateFormStages;
    case FieldType.Image:
      return ImageFormStages;
    case FieldType.LinearBarcode:
      return LinearBarcodeFormStages;
    case FieldType.Text:
      return TextFormStages;
    case FieldType.TwoDimensionalBarcode:
      return TwoDimensionalFormStages;
    default:
      return [];
  }
}

function handleDragEnd(
  event,
  layoutIndex,
  setFieldValue,
  snapIncrement,
  state,
  values,
  zoomFactor
) {
  const { active } = event;

  if (!active) {
    return;
  }

  const dragType = getDragType(active.id);
  if (dragType === null) {
    return;
  }

  const pageName = layoutIndex === 0 ? "front" : "back";
  const page = layoutIndex === 0 ? values.front : values.back;

  // deep copy the page to avoid changing the original value
  let pageData = {};
  const pageCopy = JSON.parse(JSON.stringify(page));

  switch (dragType) {
    case DragType.NewField:
      pageData = handleNewField(
        pageCopy,
        active.id,
        event,
        snapIncrement,
        state,
        zoomFactor
      );
      break;
    case DragType.MoveField:
      pageData = handleMoveField(
        pageCopy,
        active.id,
        event,
        snapIncrement,
        zoomFactor
      );
      break;
    default:
      break;
  }

  setFieldValue(pageName, pageData);
}

function handleMoveField(
  page,
  activeId,
  event,
  snapIncrement,
  zoomFactor
) {
  const { delta } = event;

  // field being moved
  const activeIdentifiers = activeId.split("-");
  if (activeIdentifiers.length !== 4) {
    return page;
  }

  const layerIndex = parseInt(activeIdentifiers[2]);
  if (page.Layers.length <= layerIndex) {
    throw new Error("Invalid layer");
  }
  const fieldIndex = parseInt(activeIdentifiers[3]);
  if (page.Layers[layerIndex].Fields.length <= fieldIndex) {
    throw new Error("Invalid field");
  }

  return {
    ...page,
    Layers: page.Layers.map((layer, i) =>
      i === layerIndex
        ? {
            ...layer,
            Fields: layer.Fields.map((field, j) =>
              j === fieldIndex
                ? {
                    ...field,
                    Left: getFieldLeftPosition(
                      field.Width,
                      page,
                      snapIncrement,
                      field.Left +
                        convertPxToMm(delta.x) / zoomFactor,
                      zoomFactor
                    ),
                    Top: getFieldTopPosition(
                      field.Height,
                      page,
                      snapIncrement,
                      field.Top + convertPxToMm(delta.y) / zoomFactor,
                      zoomFactor
                    ),
                  }
                : field
            ),
          }
        : layer
    ),
  };
}

function handleNewField(
  page,
  activeId,
  event,
  snapIncrement,
  state,
  zoomFactor
) {
  const { active, over } = event;
  const { g4b_printerjobtypeidname } = state;

  // field being moved
  const activeIdentifiers = activeId.split("-");
  if (activeIdentifiers.length !== 2) {
    return page;
  }

  const fieldName = activeIdentifiers[0];
  const layer = page.Layers[0];

  const newField = {
    Name: getNameForNewField(activeIdentifiers, layer),
    ...getDefaultPropertiesForField(
      fieldName,
      g4b_printerjobtypeidname
    ),
  };

  let left = 0;
  let top = 0;

  if (
    active &&
    active.rect &&
    active.rect.current &&
    active.rect.current.translated &&
    over &&
    over.rect
  ) {
    left = convertPxToMm(
      active.rect.current.translated.left - over.rect.left
    );
    top = convertPxToMm(
      active.rect.current.translated.top - over.rect.top
    );
  }

  newField.Left =
    getFieldLeftPosition(
      newField.Width,
      page,
      snapIncrement,
      left / zoomFactor,
      zoomFactor
    ) - page.LeftOffset;

  newField.Top =
    getFieldTopPosition(
      newField.Height,
      page,
      snapIncrement,
      top / zoomFactor,
      zoomFactor
    ) - page.TopOffset;

  layer.Fields.push(newField);

  return {
    ...page,
  };
}

function getBarcodeFormat(value) {
  for (const key in BarcodeFormatType) {
    if (String(BarcodeFormatType[key]) === String(value)) {
      return key.toUpperCase();
    }
  }
  return "auto";
}

function getDefaultPropertiesForField(fieldName, jobType) {
  switch (fieldName) {
    case "Date":
      return DateDefaultValues;
    case "Image":
      return ImageDefaultValues;
    case "LinearBarcode":
      return getLinearBarcodeDefaultValues(jobType);
    case "Text":
      return TextDefaultValues;
    case "TwoDimensionalBarcode":
      return getTwoDimensionalBarcodeDefaultValues(jobType);
    default:
      return {};
  }
}

function getFieldLeftPosition(
  fieldWidth,
  page,
  snapIncrement,
  value,
  zoomFactor
) {
  const maxLeft =
    (page.Width - page.LeftOffset - page.RightOffset - fieldWidth) *
    zoomFactor;
  return roundForTicketBoundaries(maxLeft, snapIncrement, value);
}

function getFieldTopPosition(
  fieldHeight,
  page,
  snapIncrement,
  value,
  zoomFactor
) {
  const maxTop =
    (page.Height - page.TopOffset - page.BottomOffset - fieldHeight) *
    zoomFactor;
  return roundForTicketBoundaries(maxTop, snapIncrement, value);
}

function getLinearBarcodeDefaultValues(jobType) {
  let result = LinearBarcodeDefaultValues;

  switch (jobType) {
    case PrinterJobType.Coupon:
      result.Value.Value =
        "coupons/coupon[number($position)]/g4b_couponcode";
      break;
    case PrinterJobType.Membership:
      result.Value.Value =
        "membershiptypes/membershiptype[number($position)]/g4b_barcode";
      break;
    case PrinterJobType.Card:
    case PrinterJobType.Receipt:
    case PrinterJobType.Ticket:
      result.Value.Value =
        "stadiumtickets/stadiumticket[number($position)]/barcode";
      break;
    case PrinterJobType.Voucher:
      result.Value.Value =
        "vouchers/voucher[number($position)]/g4b_referencenumber";
      break;
    default:
      break;
  }

  return result;
}

function getNameForNewField(activeIdentifiers, layer) {
  // get the field name and type from the activeIdentifiers
  const fieldName = activeIdentifiers[0];
  const fieldType = FieldType[fieldName];

  // count how many fields of this type exist already, and add 1
  const index =
    layer && layer.Fields
      ? layer.Fields.filter((field) => field.Type === fieldType)
          .length + 1
      : 0;

  return `${fieldName} ${index}`;
}

function getTwoDimensionalBarcodeDefaultValues(jobType) {
  let result = TwoDimensionalBarcodeDefaultValues;

  switch (jobType) {
    case PrinterJobType.Membership:
      result.Value.Value =
        "membershiptypes/membershiptype[number($position)]/g4b_barcode";
      break;
    case PrinterJobType.Card:
    case PrinterJobType.Ticket:
      result.Value.Value =
        "stadiumtickets/stadiumticket[number($position)]/barcode";
      break;
    default:
      break;
  }

  return result;
}

function roundForTicketBoundaries(max, snapIncrement, value) {
  // round for the snap increment
  const roundedResult =
    Math.round(value / snapIncrement) * snapIncrement;
  // result must be between min and max
  return Math.max(Math.min(roundedResult, max), 0);
}

function setEditField(
  dispatch,
  globalDispatch,
  fieldIndex,
  layerIndex,
  layoutIndex,
  values
) {
  const { back, editField, front } = values;

  // prevent if a field is being edited
  if (editField) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot edit while a field is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  // find the matching field
  const page = layoutIndex === 0 ? front : back;
  const layer =
    page && page.Layers && page.Layers.length >= layerIndex + 1
      ? page.Layers[layerIndex]
      : null;
  const field =
    layer && layer.Fields && layer.Fields.length >= fieldIndex + 1
      ? layer.Fields[fieldIndex]
      : null;

  if (!field) {
    return;
  }

  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      ...field,
      editField: {
        fieldIndex: fieldIndex,
        layerIndex: layerIndex,
        layoutIndex: layoutIndex,
      },
    },
  });
}

export {
  applyFieldChanges,
  cancelFieldChanges,
  convertPxToMm,
  deleteField,
  getBarcodeFormat,
  getFieldDisplayValue,
  getFieldLeftPosition,
  getFieldTopPosition,
  getFieldTransform,
  getOptionsForPrintJobType,
  getStagesForField,
  handleDragEnd,
  setEditField,
};
