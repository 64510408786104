import {
  Align,
  BackgroundColour,
  BorderColour,
  BorderRadius,
  BorderThickness,
  BorderType,
  Colour,
  FontFamily,
  FontSize,
  FontWeight,
  Margin,
  Padding,
  TextAlign,
  TextColour,
} from "../../../CommonProperties";
import { LinkProperties, Width } from "../CommonProperties";

const ButtonControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "text",
            description: "",
            label: "Text",
            type: "nvarchar",
          },
          ...LinkProperties(""),
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Text",
        fields: [
          TextColour(""),
          TextAlign(""),
          FontFamily(""),
          FontSize(""),
          FontWeight(""),
        ],
      },
      {
        name: "Content",
        fields: [BackgroundColour("")],
      },
      {
        name: "Button",
        fields: [
          Colour("button"),
          BorderRadius(""),
          BorderType(""),
          BorderThickness(""),
          BorderColour(""),
          Padding(""),
          Margin(""),
        ],
      },
    ],
  },
  {
    title: "Settings",
    sections: [
      {
        name: "",
        fields: [
          Align(""),
          Width(""),
          {
            name: "showOnMobile",
            description: "",
            label: "Show On Mobile",
            type: "bit",
          },
        ],
      },
    ],
  },
];

export default ButtonControlFormStages;
