function EntityAuditTable({ audit }) {
  return (
    <table className="table">
      <thead>
        <tr className="table-info">
          <th scope="col" className="col-name">
            Column
          </th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        {audit.Attributes.sort((a, b) =>
          a.Key.localeCompare(b.Key)
        ).map((attribute, attributeIndex) => {
          return (
            <tr key={attributeIndex}>
              <td>{attribute.Key}</td>
              <td>{attribute.Value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default EntityAuditTable;
