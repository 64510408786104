import { RecordStatus } from "../../js/enums";

function StatusTabs({ recordStatusToShow, setStatus }) {
  return (
    <div className="form-table-nav">
      <ul className="nav me-auto">
        <li className="nav-item">
          <span
            className={
              recordStatusToShow === RecordStatus.Active
                ? "text-primary"
                : ""
            }
            onClick={() => setStatus(RecordStatus.Active)}
          >
            Active
          </span>
        </li>
        <li className="nav-item">
          <span
            className={
              recordStatusToShow === RecordStatus.Inactive
                ? "text-primary"
                : ""
            }
            onClick={() => setStatus(RecordStatus.Inactive)}
          >
            Inactive
          </span>
        </li>
      </ul>
    </div>
  );
}

export default StatusTabs;
