function PromotionCriteria({ properties }) {
  const {
    promotionCriteriaBackgroundColour,
    promotionCriteriaBorderColour,
    promotionCriteriaBorderThickness,
    promotionCriteriaBorderType,
    promotionCriteriaFontFamily,
    promotionCriteriaFontSize,
    promotionCriteriaFontWeight,
    promotionCriteriaMargin,
    promotionCriteriaPadding,
    promotionCriteriaTextAlign,
    promotionCriteriaTextColour,
  } = properties;

  return (
    <table
      cellSpacing="0"
      className="full-width zero-margin"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes='test="PromotionCriteria"'
    >
      <tbody>
        <tr>
          <td style={{ padding: promotionCriteriaMargin }}>
            <table
              cellSpacing="0"
              className="full-width zero-margin"
              style={{
                borderColor: promotionCriteriaBorderColour,
                borderStyle: promotionCriteriaBorderType,
                borderWidth: promotionCriteriaBorderThickness,
              }}
            >
              <tbody>
                <tr
                  style={{
                    backgroundColor:
                      promotionCriteriaBackgroundColour,
                  }}
                >
                  <td
                    style={{
                      color: promotionCriteriaTextColour,
                      fontFamily: promotionCriteriaFontFamily,
                      fontSize: promotionCriteriaFontSize,
                      fontWeight: promotionCriteriaFontWeight,
                      padding: promotionCriteriaPadding,
                      textAlign: promotionCriteriaTextAlign,
                    }}
                  >
                    <p className="hide-on-designer">
                      <strong
                        dangerouslySetInnerHTML={{
                          __html:
                            '<xsl:value-of select="PromotionCriteria/Code" />',
                        }}
                      ></strong>
                      <span> - </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            '<xsl:value-of select="PromotionCriteria/Criteria" />',
                        }}
                      ></span>
                    </p>
                    <p className="remove-item">
                      <strong>HALFPRICE</strong>
                      <span> - </span>
                      <span>
                        Please remember to bring proof of
                        identification when you arrive
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default PromotionCriteria;
