import { PromotionProductGroupFormStages } from "./promotionProductGroup";
import lookups from "./lookups";
import { ProductGroupType } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const PromotionProductGroupsFormStages = [
  {
    title: "Promotion Product Groups",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_grouptype",
              className: "col-name",
              type: "picklist",
              enum: ProductGroupType,
            },
            {
              name: "g4b_order",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              name: "g4b_grouptype",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/promotion-product-group",
          lookup: lookups.g4b_productgroup.all,
          subFormStages: PromotionProductGroupFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
