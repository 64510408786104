import { Button, Footer } from "./_Elements";
import { ButtonStyle } from "../../js/enums";
import { hideNavigationButtons } from "../form/Helpers";
import { useGlobalState } from "../../GlobalContext";

function SubmitFormNavigationButtonsSimple({
  backText,
  disabled = false,
  isBusy = false,
  onBack,
  onSubmit,
  showSaveAndCloseButton,
  showSaveButton,
  stage,
  stages,
  state,
  submitButtonClickEvent,
  submitText = "Save",
}) {
  const globalState = useGlobalState();

  const handleSaveButton = (submitButtonEvent) => {
    submitButtonEvent();
    onSubmit();
  };
  return (!onBack && !showSaveButton && !showSaveAndCloseButton) ||
    hideNavigationButtons(globalState, stage, stages, state) ? (
    <></>
  ) : (
    <Footer {...{ backText, disabled, isBusy, onBack }}>
      <Button
        style={ButtonStyle.Primary}
        text={submitText}
        disabled={disabled || isBusy}
        showSpinner={isBusy}
        onClick={() => handleSaveButton(submitButtonClickEvent)}
      />
    </Footer>
  );
}

export default SubmitFormNavigationButtonsSimple;
