import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import EditElement from "../../elements/EditElement";
import CommunicationControl from "./CommunicationControl";
import { deleteRow } from "./Helpers";

function EditCommunicationTableRow({
  activeId,
  row,
  rowIndex,
  saveUndoSnapshot,
  setFieldValue,
  values,
}) {
  const id = `moverow-${rowIndex}`;

  const { attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: id,
    });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const columnClass =
    row.Columns.length === 3
      ? "four"
      : row.Columns.length === 2
      ? "six"
      : "twelve";

  return (
    <EditElement
      isActive={activeId === id}
      moveAttributes={attributes}
      moveListeners={listeners}
      onDelete={() => {
        saveUndoSnapshot(values);
        deleteRow(rowIndex, setFieldValue, values);
      }}
      setNodeRef={setNodeRef}
      showToolbar={!activeId}
      style={style}
    >
      <table key={rowIndex} className="content-table twelve columns">
        <tbody>
          <tr>
            {row.Columns.map((column, columnIndex) => {
              return (
                <td
                  key={columnIndex}
                  className={classNames(
                    "content-table column",
                    columnClass
                  )}
                >
                  {column.Controls.length === 0 && (
                    <div className="empty-content-zone">
                      Empty content zone
                    </div>
                  )}
                  {column.Controls.map((control, controlIndex) => {
                    const { Properties, Template } = control;

                    const properties = Properties.reduce(
                      (result, prop) => {
                        result[prop.Name] = prop.Value;
                        return result;
                      },
                      {}
                    );

                    return (
                      <CommunicationControl
                        key={controlIndex}
                        properties={properties}
                        template={Template}
                      />
                    );
                  })}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
      {!activeId && <div className="edit-element-overlay"></div>}
    </EditElement>
  );
}

export default EditCommunicationTableRow;
