import lookups from "./lookups";
import { MembershipFormStages } from "./membership";
import { MandateStatus } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const MembershipAccountFormStages = [
  {
    title: "General Information",
    id: "general-information",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4m_name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4m_owneridname",
            className: "col-md-4",
            description: "",
            label: "Owner",
            type: "nvarchar",
          },
          {
            name: "g4m_membershipaccountnumber",
            className: "col-md-4",
            description: "",
            label: "Account Number",
            type: "int",
          },
          {
            name: "g4m_accountbalance",
            className: "col-md-4",
            description: "",
            label: "Account Balance",
            type: "money",
          },
          {
            name: "g4m_accountbalance_date",
            className: "col-md-4",
            description: "",
            label: "Account Balance Last Updated Date",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Payment Mandate",
    id: "payment-mandate",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) => state.g4m_paymentmandateid,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_mandatestatus",
            className: "col-md-4",
            type: "picklist",
            label: "Mandate Status",
            enum: MandateStatus,
          },
          {
            name: "g4b_bankname",
            className: "col-md-4",
            description: "",
            label: "Bank Name",
            type: "nvarchar",
          },
          {
            name: "g4b_accountname",
            className: "col-md-4",
            description: "",
            label: "Account Name",
            type: "nvarchar",
          },
          {
            name: "g4b_accountnumber",
            className: "col-md-4",
            description: "",
            label: "Account Number",
            type: "nvarchar",
          },
          {
            name: "g4b_sortcode",
            className: "col-md-4",
            description: "",
            label: "Sort Code",
            type: "nvarchar",
          },
          {
            name: "g4b_reference",
            className: "col-md-4",
            description: "",
            label: "Reference",
            type: "nvarchar",
          },
          {
            name: "g4b_externalreference",
            className: "col-md-4",
            description: "",
            label: "External Reference",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Memberships",
    id: "memberships",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4m_membershipnumber",
              className: "col-name",
              label: "Membership Number",
              type: "string",
            },
            {
              name: "g4m_memberidname",
              className: "col-name",
              label: "Member Name",
            },
            {
              name: "g4m_productidname",
              className: "col-name",
              label: "Product",
            },
            {
              name: "g4m_varianttypeidname",
              className: "col-name",
              label: "Variant Type",
            },
            {
              name: "g4m_startdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4m_cancellationdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4m_enddate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4m_renewaldate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: [
                "g4m_membershipnumber",
                "g4m_memberidname",
              ],
              type: "text",
            },
            {
              name: "g4m_productidname",
              label: "Product",
              type: "select",
            },
            {
              name: "g4m_varianttypeidname",
              label: "Variant Type",
              type: "select",
            },
            {
              name: "g4m_startdate",
              type: "datetime",
            },
            {
              name: "g4m_cancellationdate",
              type: "datetime",
            },
            {
              name: "g4m_enddate",
              type: "datetime",
            },
            {
              name: "g4m_renewaldate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4m_membership",
          lookup: lookups.g4m_membership.all,
          subFormStages: MembershipFormStages,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Transactions",
    id: "transactions",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "g4m_name",
              className: "col-short-name",
              label: "Transaction Type",
            },
            {
              name: "g4m_transactiondate",
              className: "col-date",
              label: "Transaction Date",
              type: "datetime",
            },
            {
              name: "g4m_membershipname",
              className: "col-name",
              label: "Membership Name",
            },
            {
              name: "g4m_amount",
              label: "Amount",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_taxamount",
              label: "Tax Amount",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_netamount",
              label: "Net Amount",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_bookingpaymentname",
              className: "col-name",
              label: "Booking Payment",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4m_membershipname"],
              type: "text",
            },
            {
              name: "g4m_name",
              label: "Transaction Type",
              type: "select",
            },
            {
              name: "g4m_transactiondate",
              type: "datetime",
            },
            {
              name: "g4m_bookingpaymentname",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4m_transactiondate",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName:
            "g4m_membershipaccounttransaction",
          lookup: lookups.g4m_membershipaccounttransaction.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
