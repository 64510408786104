import DefaultLayout from "./layouts/DefaultLayout";
import { Alert } from "./components/elements/_Elements";
import { AlertStyle } from "./js/enums";

function NotFound() {
  return (
    <DefaultLayout>
      <Alert
        className="m-3"
        style={AlertStyle.Danger}
        text={"Page not found"}
      />
    </DefaultLayout>
  );
}
export default NotFound;
