import lookups from "./lookups";
import { CountryFormStages } from "./country";

export const RegionFormStages = [
  {
    title: "Region",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4_countryid",
            allowAdd: true,
            allowEdit: true,
            description: "The country the region belongs to",
            label: "Country",
            lookup: lookups.g4_country.all,
            required: true,
            subFormStages: CountryFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
