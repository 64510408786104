import { useState } from "react";
import CloseButton from "./CloseButton";
import "./imageUpload.scss";

function ImageUpload({
  displayValue,
  errors,
  handleChange,
  mapValue,
  name,
  showErrorsBeforeTouched,
  touched,
  value,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const maxFileSize = 1024000;

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (!file.type.startsWith("image")) {
      setErrorMessage("Only images can be uploaded");
      return;
    }

    if (file.size > maxFileSize) {
      setErrorMessage("File can not be larger than 1MB");
      return;
    }

    setErrorMessage("");

    const reader = new FileReader();

    reader.onload = () => {
      const base64Result = reader.result;
      const base64Image = base64Result.split(",")[1];

      handleImageChange(event, base64Image, file.type);
    };

    reader.readAsDataURL(file);
  };

  const handleImageChange = (event, base64String, mimeType) => {
    const imageValue = base64String
      ? { Base64String: base64String, MimeType: mimeType }
      : null;

    handleChange({
      ...event,
      target: {
        name: name,
        value:
          mapValue && imageValue ? mapValue(imageValue) : imageValue,
      },
    });
  };

  return (
    <div className="row">
      <div className="col-md-3 position-relative">
        {value &&
          (displayValue || mapValue || value.Base64String) && (
            <>
              <img
                src={
                  displayValue
                    ? displayValue(value)
                    : mapValue
                    ? value
                    : `data:${value.MimeType};base64,${value.Base64String}`
                }
                className="mw-100"
                alt="Primary"
              />
              <div className="position-absolute top-0 end-0 pt-2 pe-3">
                <CloseButton
                  className="bg-white"
                  onClick={(event) =>
                    handleImageChange(event, null, null)
                  }
                />
              </div>
            </>
          )}
      </div>
      {!value && (
        <div className="col-md-9 d-flex align-items-stretch">
          <div
            className="image-upload w-100 d-flex justify-content-center align-items-center"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <p className="my-5">Drop your image here</p>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="col-md-9 offset-md-3">
          <div className="d-block invalid-feedback">
            {errorMessage}
          </div>
        </div>
      )}
      {errors && (touched || showErrorsBeforeTouched) && (
        <div className="col-md-9 offset-md-3">
          <div className="d-block invalid-feedback">{errors}</div>
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
