import { Alert, Button, Modal } from "../../elements/_Elements";
import { AlertStyle, ButtonStyle } from "../../../js/enums";

function CommunicationNoFinalCountMessageModal({
  setConfirmationModal,
}) {
  return (
    <Modal
      title={"Communication audience has no contacts"}
      modalCloseButtonClick={() => {
        setConfirmationModal(null);
      }}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body">
        <Alert
          style={AlertStyle.Danger}
          text={
            "The selected communication audience has a final count of 0"
          }
        />
      </div>
      <div className="modal-footer">
        <Button
          text={"Close"}
          style={ButtonStyle.Info}
          onClick={() => {
            setConfirmationModal(null);
          }}
        />
      </div>
    </Modal>
  );
}

export default CommunicationNoFinalCountMessageModal;
