import classNames from "classnames";
import FormLabel from "./FormLabel";
import Select from "react-select";
import "./selectWithFiltering.scss";
import TooltipComponent from "./TooltipComponent";

function SelectWithFiltering({
  actions,
  actionsClass = "d-flex justify-content-end",
  className,
  description,
  disabled = false,
  errors,
  label,
  isClearable = true,
  isMulti = false,
  mandatory = false,
  name,
  onBlur,
  onChange,
  optionGroups,
  options,
  placeholder = "",
  reverseLookup = false,
  showErrorsBeforeTouched = false,
  tooltipDescription,
  touched,
  value,
}) {
  const selectOptions = options
    ? options.map((option) => ({
        label: option.Value,
        value: reverseLookup ? option.Value : option.Key,
        key: option.Key,
      }))
    : optionGroups
    ? optionGroups.map((optionGroup) => ({
        label: optionGroup.Key,
        options: optionGroup.Value.map((v, i) => ({
          label: v.Value,
          value: reverseLookup ? v.Value : v.Key,
          key: v.Key,
        })),
      }))
    : [];

  let setValue =
    value !== undefined && value !== null && String(value)
      ? isMulti
        ? selectOptions.filter(
            (s) =>
              s.label &&
              value &&
              value.some((v) => String(v) === String(s.key)) //If s has a label then it is assumed it has a value in its key
          ) || //This is to avoid the potential issue where s.key = 0 which means it wouldn't count it in the filter
          []
        : selectOptions.find(
            (o) => String(o.key) === String(value)
          ) || null
      : null;

  let activeOptionText = "";
  if (
    setValue &&
    options.some((o) => o.Key === value) &&
    options.filter((o) => o.Key === value)[0].InActive
  ) {
    activeOptionText = "Selected " + label + " is inactive.";
  }
  if (reverseLookup && value && !setValue) {
    // if using reverseLookup and no match was found on the key (i.e. initial load)
    // then try to match using the value
    setValue =
      selectOptions.find((o) => String(o.value) === String(value)) ||
      null;
  }

  const wrapperId = `${name}-wrapper`;

  return (
    <>
      <div>
        {label && (
          <div className="d-flex">
            <FormLabel
              className={"form-label me-auto"}
              label={label}
              mandatory={mandatory}
              name={name}
            />
            {actions && <div className={actionsClass}>{actions}</div>}
          </div>
        )}
        <div
          className={classNames(
            className,
            "form-control searchable_select",
            touched || showErrorsBeforeTouched
              ? errors
                ? "is-invalid"
                : value
                ? "is-valid"
                : ""
              : ""
          )}
        >
          <Select
            className={"custom-select"}
            classNamePrefix="select"
            id={wrapperId}
            inputId={name}
            isMulti={isMulti}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            isDisabled={disabled}
            isSearchable={true}
            isClearable={isClearable && !mandatory}
            options={selectOptions}
            placeholder={placeholder}
            value={setValue}
          />
        </div>
        {description && (
          <div className="form-text">
            {description}
            <p>{activeOptionText}</p>
            {tooltipDescription ? (
              <TooltipComponent
                name={name}
                tooltipDescription={tooltipDescription}
              />
            ) : (
              ""
            )}
          </div>
        )}
        {errors && (touched || showErrorsBeforeTouched) && (
          <div className="invalid-feedback">{errors}</div>
        )}
      </div>
    </>
  );
}

export default SelectWithFiltering;
