import { Button, Modal } from "./_Elements";
import { ButtonStyle, RecordStatus } from "../../js/enums";

function SetStatusModal({
  handleRecordStatusChange,
  recordStatusToShow,
  setShowConfirmStatusChangeModal,
  singleRecord = false,
  state,
}) {
  const getBodyText = () => {
    if (
      state &&
      state.name &&
      String(recordStatusToShow) === String(RecordStatus.Inactive)
    ) {
      return singleRecord
        ? `Are you sure you want to activate the ${state.name} record?`
        : "Are you sure you want to activate the selected record(s)?";
    } else {
      return singleRecord
        ? `Are you sure you want to deactivate the ${state.name} record?  You can reactivate it later, if you wish.`
        : "Are you sure you want to deactivate the selected record(s)?  You can reactivate it later, if you wish.";
    }
  };
  return (
    <Modal
      title={
        String(recordStatusToShow) === String(RecordStatus.Inactive)
          ? "Confirm Activation"
          : "Confirm Deactivation"
      }
      modalCloseButtonClick={() => {
        setShowConfirmStatusChangeModal(false);
      }}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body">
        <p>{getBodyText()}</p>
      </div>
      <div className="modal-footer">
        <Button
          text={
            String(recordStatusToShow) ===
            String(RecordStatus.Inactive)
              ? "Activate"
              : "Deactivate"
          }
          style={ButtonStyle.Primary}
          onClick={() => handleRecordStatusChange()}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => setShowConfirmStatusChangeModal(false)}
        />
      </div>
    </Modal>
  );
}

export default SetStatusModal;
