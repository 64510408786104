import { useGlobalState } from "../../GlobalContext";
import NavigationItem from "./NavigationItem";
import NavigationLink from "./NavigationLink";
import {
  getLinksToShow,
  getNavigationItemsToShow,
  getSubItemsToShow,
  shouldDisplayPage,
} from "./Helpers";

function Navigation({
  display,
  navigationItems,
  sitemap,
  subDisplay,
  toggleDisplay,
  toggleSubDisplay,
}) {
  const globalState = useGlobalState();
  return (
    <div id="nav-wrapper">
      <ul className="nav flex-column mb-3">
        {getNavigationItemsToShow(
          globalState,
          navigationItems,
          sitemap
        ).map((item, index) => {
          const { id, links, subItems, text } = item;
          return (
            <NavigationItem
              className={"parentItem"}
              key={index}
              id={id}
              onClick={() => toggleDisplay(id)}
              show={display === id}
              text={text}
            >
              {getLinksToShow(globalState, links, sitemap).map(
                (link, linkIndex) => (
                  <NavigationLink
                    key={linkIndex}
                    to={link.to}
                    text={link.text}
                  />
                )
              )}
              {getSubItemsToShow(globalState, sitemap, subItems).map(
                (subItem, subItemIndex) => (
                  <NavigationItem
                    className={"subItem"}
                    key={subItemIndex}
                    id={subItem.id}
                    onClick={() => toggleSubDisplay(subItem.id)}
                    show={subDisplay === subItem.id}
                    text={subItem.text}
                  >
                    {subItem.links
                      .filter((link) =>
                        shouldDisplayPage(globalState, link, sitemap)
                      )
                      .map((link, linkIndex) => (
                        <NavigationLink
                          key={linkIndex}
                          to={link.to}
                          text={link.text}
                        />
                      ))}
                  </NavigationItem>
                )
              )}
            </NavigationItem>
          );
        })}
      </ul>
    </div>
  );
}

export default Navigation;
