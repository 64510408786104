import {
  FontFamilies,
  FontSizes,
  FontWeights,
  LineTypes,
  VerticalAligns,
} from "../../../enums";

const BookingControlDefaultValues = [
  {
    Name: "showBookingSummary",
    Value: true,
  },
  {
    Name: "showPromotionCriteria",
    Value: false,
  },
  {
    Name: "showPaymentMandate",
    Value: false,
  },
  {
    Name: "showDirectDebit",
    Value: false,
  },
  {
    Name: "showVouchers",
    Value: false,
  },
  {
    Name: "showCoupons",
    Value: false,
  },
  {
    Name: "showGiftAid",
    Value: false,
  },
  {
    Name: "showVehicleRegistrationNumbers",
    Value: false,
  },
  {
    Name: "showTax",
    Value: false,
  },
  {
    Name: "localeCode",
    Value: "en-GB",
  },
  {
    Name: "shortDateFormat",
    Value: "dd/MM/yyyy",
  },
  {
    Name: "longDateFormat",
    Value: "dddd dd MMM yyyy",
  },
  {
    Name: "timeFormat",
    Value: "HH:mm",
  },
  {
    Name: "product",
    Value: "Product",
  },
  {
    Name: "quantity",
    Value: "Qty",
  },
  {
    Name: "price",
    Value: "Price",
  },
  {
    Name: "area",
    Value: "Area",
  },
  {
    Name: "block",
    Value: "Block",
  },
  {
    Name: "seat",
    Value: "Seat",
  },
  {
    Name: "beneficiary",
    Value: "Beneficiary",
  },
  {
    Name: "totalPrice",
    Value: "Total Price",
  },
  {
    Name: "totalCharges",
    Value: "Total Charges",
  },
  {
    Name: "totalDiscount",
    Value: "Total Discount",
  },
  {
    Name: "subTotal",
    Value: "Sub-Total",
  },
  {
    Name: "totalPaid",
    Value: "Total Paid",
  },
  {
    Name: "totalDue",
    Value: "Total Due",
  },
  {
    Name: "membershipNumber",
    Value: "Membership Number:",
  },
  {
    Name: "promotionCriteriaBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "promotionCriteriaTextColour",
    Value: "#333333",
  },
  {
    Name: "promotionCriteriaTextAlign",
    Value: "left",
  },
  {
    Name: "promotionCriteriaMargin",
    Value: "5px",
  },
  {
    Name: "promotionCriteriaPadding",
    Value: "10px",
  },
  {
    Name: "promotionCriteriaFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "promotionCriteriaFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "promotionCriteriaFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "promotionCriteriaBorderType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "promotionCriteriaBorderThickness",
    Value: "1px",
  },
  {
    Name: "promotionCriteriaBorderColour",
    Value: "#333333",
  },
  {
    Name: "paymentMandateBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "paymentMandateTextColour",
    Value: "#333333",
  },
  {
    Name: "paymentMandateMargin",
    Value: "5px",
  },
  {
    Name: "paymentMandatePadding",
    Value: "10px",
  },
  {
    Name: "paymentMandateFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "paymentMandateFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "paymentMandateFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "paymentMandateBorderType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "paymentMandateBorderThickness",
    Value: "1px",
  },
  {
    Name: "paymentMandateBorderColour",
    Value: "#333333",
  },
  {
    Name: "bankName",
    Value: "Bank Name:",
  },
  {
    Name: "accountName",
    Value: "Account Name:",
  },
  {
    Name: "accountNumber",
    Value: "Account Number:",
  },
  {
    Name: "sortCode",
    Value: "Sort Code:",
  },
  {
    Name: "serviceNumber",
    Value: "Service Number:",
  },
  {
    Name: "referenceNumber",
    Value: "Reference Number:",
  },
  {
    Name: "directDebitMargin",
    Value: "5px",
  },
  {
    Name: "voucherBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "voucherTextColour",
    Value: "#333333",
  },
  {
    Name: "voucherFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "voucherFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "voucherFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "voucherMargin",
    Value: "5px",
  },
  {
    Name: "voucherPadding",
    Value: "10px",
  },
  {
    Name: "voucherBorderType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "voucherBorderThickness",
    Value: "1px",
  },
  {
    Name: "voucherBorderColour",
    Value: "#333333",
  },
  {
    Name: "voucherRedeemLink",
    Value: "",
  },
  {
    Name: "voucherTermsLink",
    Value: "",
  },
  {
    Name: "couponBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "couponTextColour",
    Value: "#333333",
  },
  {
    Name: "couponFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "couponFontSize",
    Value: "12px",
  },
  {
    Name: "couponFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "couponMargin",
    Value: "5px",
  },
  {
    Name: "couponPadding",
    Value: "10px",
  },
  {
    Name: "couponBorderType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "couponBorderThickness",
    Value: "1px",
  },
  {
    Name: "couponBorderColour",
    Value: "#333333",
  },
  {
    Name: "couponCode",
    Value: "Code:",
  },
  {
    Name: "couponRedeemFrom",
    Value: "Valid From:",
  },
  {
    Name: "couponRedeemBy",
    Value: "Redeem By:",
  },
  {
    Name: "couponUseBy",
    Value: "Start Before:",
  },
  {
    Name: "couponRedeemLink",
    Value: "",
  },
  {
    Name: "couponTermsLink",
    Value: "",
  },
  {
    Name: "giftAidBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "giftAidTextColour",
    Value: "#333333",
  },
  {
    Name: "giftAidMargin",
    Value: "5px",
  },
  {
    Name: "giftAidPadding",
    Value: "10px",
  },
  {
    Name: "giftAidFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "giftAidFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "giftAidFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "giftAidBorderType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "giftAidBorderThickness",
    Value: "1px",
  },
  {
    Name: "giftAidBorderColour",
    Value: "#333333",
  },
  {
    Name: "giftAidHeader",
    Value: "Thank you for your Gift Aid donation",
  },
  {
    Name: "vehicleRegistrationNumberBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "vehicleRegistrationNumberTextColour",
    Value: "#333333",
  },
  {
    Name: "vehicleRegistrationNumberFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "vehicleRegistrationNumberFontSize",
    Value: "12px",
  },
  {
    Name: "vehicleRegistrationNumberFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "vehicleRegistrationNumberMargin",
    Value: "5px",
  },
  {
    Name: "vehicleRegistrationNumberPadding",
    Value: "10px",
  },
  {
    Name: "vehicleRegistrationNumberBorderType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "vehicleRegistrationNumberBorderThickness",
    Value: "1px",
  },
  {
    Name: "vehicleRegistrationNumberBorderColour",
    Value: "#333333",
  },
  {
    Name: "vehicleRegistrationNumber",
    Value: "CAR REG:",
  },
  {
    Name: "backgroundColour",
    Value: "",
  },
  {
    Name: "margin",
    Value: "15px 5px",
  },
  {
    Name: "padding",
    Value: "0",
  },
  {
    Name: "borderType",
    Value: String(LineTypes.None),
  },
  {
    Name: "borderThickness",
    Value: "0",
  },
  {
    Name: "borderColour",
    Value: "",
  },
  {
    Name: "headerBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "headerTextColour",
    Value: "#333333",
  },
  {
    Name: "headerCellPadding",
    Value: "5px 5px",
  },
  {
    Name: "headerFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "headerFontSize",
    Value: String(FontSizes["14px"]),
  },
  {
    Name: "headerFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "rowBackgroundColour",
    Value: "#ffffff",
  },
  {
    Name: "rowTextColour",
    Value: "#333333",
  },
  {
    Name: "rowCellPadding",
    Value: "5px 5px",
  },
  {
    Name: "rowFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "rowFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "rowFontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "rowVerticalAlign",
    Value: String(VerticalAligns.Middle),
  },
  {
    Name: "rowLineType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "rowLineThickness",
    Value: "1px",
  },
  {
    Name: "rowLineColour",
    Value: "#333333",
  },
  {
    Name: "footerBackgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "footerTextColour",
    Value: "#333333",
  },
  {
    Name: "footerCellPadding",
    Value: "5px 5px",
  },
  {
    Name: "footerFontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "footerFontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "footerFontWeight",
    Value: String(FontWeights.Normal),
  },
];

export default BookingControlDefaultValues;
