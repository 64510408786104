import classNames from "classnames";
import { getLinkHref } from "../../Helpers";

function ImageControl({ properties }) {
  const {
    altText,
    backgroundColour,
    height,
    imageAlign,
    padding,
    showOnMobile,
    src,
    width,
  } = properties;

  const href = getLinkHref(properties, "");

  return !src ? (
    <table className="full-width zero-margin no-content">
      <tbody>
        <tr>
          <td
            style={{
              backgroundColor: backgroundColour,
              padding: padding,
            }}
          >
            <p className="text-center">No image</p>
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <table
      className={classNames(
        "full-width zero-margin",
        showOnMobile ? "" : "hide-for-small"
      )}
    >
      <tbody>
        <tr>
          <td
            align={imageAlign}
            style={{
              backgroundColor: backgroundColour,
              lineHeight: "1px",
              padding: padding,
              textAlign: imageAlign,
            }}
          >
            {href ? (
              <a href={href}>
                <img
                  alt={altText}
                  className={imageAlign}
                  height={height}
                  src={src}
                  width={width}
                  style={{ width: `${width}px` }}
                />
              </a>
            ) : (
              <img
                alt={altText}
                className={imageAlign}
                height={height}
                src={src}
                width={width}
                style={{ width: `${width}px` }}
              />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ImageControl;
