const DragType = Object.freeze({
  NewTrigger: 1,
  MoveTrigger: 2,
});

const Time = Object.freeze({
  "Hour(s)": 1,
  "Day(s)": 2,
  "Week(s)": 3,
  "Month(s)": 4,
  "Year(s)": 5,
});

const When = Object.freeze({
  Immediately: 1,
  Before: 2,
  After: 3,
});

export { DragType, Time, When };
