import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { TaxRateFormStages } from "./taxRate";

export const TaxComponentFormStages = [
  {
    title: "Tax Component",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_taxcodeid",
            hidden: true,
          },
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Rates",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            { name: "g4b_percentage", className: "col-number" },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4b_taxcomponentid",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/tax-rate",
          linkedEntityId: "g4b_taxcomponentid",
          lookup: lookups.g4b_taxrate.all,
          subFormStages: TaxRateFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
