import classNames from "classnames";
import "./editor.scss";

function Editor({ children, className }) {
  return (
    <div className={classNames("editor", className)}>{children}</div>
  );
}

export default Editor;
