import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloneIcon, DeleteIcon, EditIcon, MoveIcon } from "../Icons";
import classNames from "classnames";
import "./EditElement.scss";

function EditElement({
  children,
  className,
  hasError,
  iconSize = "lg",
  isActive,
  isEditing,
  onClone,
  onEdit,
  onDelete,
  moveAttributes,
  moveListeners,
  setNodeRef,
  showToolbar = true,
  style,
  toolbarClassName,
}) {
  return (
    <div
      className={classNames(
        "edit-element",
        className,
        isActive ? "is-active" : "",
        isEditing ? "is-editing" : "",
        hasError ? "border border-danger" : ""
      )}
      ref={setNodeRef}
      style={style}
    >
      <div
        className={classNames(
          "edit-element-toolbar",
          toolbarClassName,
          !showToolbar ? "d-none" : ""
        )}
      >
        <div className="d-flex">
          <div className="flex-fill">
            {moveAttributes && moveListeners && (
              <FontAwesomeIcon
                className="cursor-move"
                icon={MoveIcon}
                size={iconSize}
                {...moveAttributes}
                {...moveListeners}
              />
            )}
          </div>
          {onEdit && (
            <div className="cursor-pointer me-4">
              <FontAwesomeIcon
                icon={EditIcon}
                size={iconSize}
                onClick={onEdit}
              />
            </div>
          )}
          {onClone && (
            <div className="cursor-pointer me-4">
              <FontAwesomeIcon
                icon={CloneIcon}
                size="lg"
                onClick={onClone}
              />
            </div>
          )}
          {onDelete && (
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={DeleteIcon}
                size={iconSize}
                onClick={onDelete}
              />
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

export default EditElement;
