import { useState } from "react";
import { useGlobalReducer } from "../../../GlobalContext";
import { Button, Modal } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";
import { Update } from "../../../js/service";
import { getAttributesForSave, handleRedirect } from "../Helpers";

function ApproveBookingPayment({
  dispatch,
  parentFormState,
  stages,
  state,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const globalDispatch = useGlobalReducer();

  const approveBookingPayment = async () => {
    setIsSubmitting(true);
    try {
      const [serviceResponse] = await Promise.all([
        Update(
          globalDispatch,
          "Booking Payment Approved",
          {
            Id: state.id,
            Name: state.name,
            Attributes: getAttributesForSave("", stages, state),
          },
          "g4b_bookingpayment"
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        handleRedirect(
          "",
          dispatch,
          parentFormState.id,
          "refreshPage",
          null
        );
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {displayConfirmationModal && (
        <Modal
          title={"Confirm Approval"}
          modalCloseButtonClick={() => {
            setDisplayConfirmationModal(false);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <p>
              Are you sure you want to approve this booking payment?
            </p>
          </div>
          <div className="modal-footer">
            <Button
              text="Confirm"
              style={ButtonStyle.Primary}
              onClick={() => approveBookingPayment()}
              disabled={isSubmitting}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => setDisplayConfirmationModal(false)}
            />
          </div>
        </Modal>
      )}
      <Button
        className="text-decoration-none mb-3"
        style={ButtonStyle.Primary}
        text={"Approve Booking Payment"}
        onClick={() => setDisplayConfirmationModal(true)}
        showSpinner={isSubmitting}
        disabled={isSubmitting}
      />
    </>
  );
}

export default ApproveBookingPayment;
