import { Button, Footer } from "./_Elements";
import { ButtonStyle } from "../../js/enums";
import { hideNavigationButtons } from "../form/Helpers";
import { useGlobalState } from "../../GlobalContext";
import { useState } from "react";
import RecordCopy from "./RecordCopy";

function SubmitFormNavigationButtons({
  backText,
  customCopyComponent,
  disabled = false,
  entityName,
  isBusy = false,
  onBack,
  onSubmit,
  saveAndCloseSubmitButtonClickEvent,
  saveAndCloseSubmitButtonText = "Save and Close",
  showSaveAndCloseButton,
  saveAndNewSubmitButtonClickEvent,
  saveAndNewSubmitButtonText = "Save and New",
  showCopyButton,
  copySubmitButtonText = "Copy",
  showSaveAndNewButton,
  showSaveButton,
  stage,
  stages,
  state,
  submitButtonClickEvent,
  submitText = "Save",
  values,
}) {
  const globalState = useGlobalState();

  const [displayRecordCopyModal, setDisplayRecordCopyModal] =
    useState(false);
  const handleSaveButton = (submitButtonEvent) => {
    submitButtonEvent();
    onSubmit();
  };

  const RecordCopyComponent = customCopyComponent
    ? customCopyComponent
    : RecordCopy;

  return (!onBack && !showSaveButton && !showSaveAndCloseButton) ||
    hideNavigationButtons(globalState, stage, stages, state) ? (
    <></>
  ) : (
    <>
      {displayRecordCopyModal && (
        <RecordCopyComponent
          entityName={entityName}
          setDisplayRecordCopyModal={setDisplayRecordCopyModal}
          stages={stages}
          state={state}
          values={values}
        />
      )}
      <Footer {...{ backText, disabled, isBusy, onBack }}>
        {showSaveButton && (
          <Button
            style={ButtonStyle.Primary}
            text={submitText}
            disabled={disabled || isBusy}
            showSpinner={isBusy}
            onClick={() => handleSaveButton(submitButtonClickEvent)}
          />
        )}
        {showCopyButton && (
          <Button
            className="ms-3"
            style={ButtonStyle.Primary}
            text={copySubmitButtonText}
            disabled={disabled || isBusy}
            showSpinner={isBusy}
            onClick={() => setDisplayRecordCopyModal(true)}
          />
        )}
        {showSaveAndCloseButton && (
          <Button
            className="ms-3"
            style={ButtonStyle.Primary}
            text={saveAndCloseSubmitButtonText}
            disabled={disabled || isBusy}
            showSpinner={isBusy}
            onClick={() =>
              handleSaveButton(saveAndCloseSubmitButtonClickEvent)
            }
          />
        )}
        {showSaveAndNewButton && (
          <Button
            className="ms-3"
            style={ButtonStyle.Primary}
            text={saveAndNewSubmitButtonText}
            disabled={disabled || isBusy}
            showSpinner={isBusy}
            onClick={() =>
              handleSaveButton(saveAndNewSubmitButtonClickEvent)
            }
          />
        )}
      </Footer>
    </>
  );
}

export default SubmitFormNavigationButtons;
