import { useState } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { handleDragEnd } from "./Helpers";
import { DesignSection } from "../../elements/_Elements";
import UndoRedo from "./UndoRedo";
import CommunicationTable from "./CommunicationTable";
import CommunicationDesignerSidebar from "./CommunicationDesignerSidebar";
import CommunicationPreview from "./CommunicationPreview";

const MaxSnapshots = 5;

function CommunicationDesigner({
  handleChange,
  handlePicklistChange,
  setFieldValue,
  state,
  values,
}) {
  const [activeId, setActiveId] = useState(null);
  const [snapshots, setSnapshots] = useState({
    undo: [],
    redo: [],
    currentState: values,
  });

  //If snapshots.currentState is null then that means we are on the latest
  //version of the comm which is stored in values. If it isn't then we are using one
  //of the snapshots that comes from either undoing or redoing
  const {
    globalBackgroundColour,
    globalContentBackgroundColour,
    globalContentPadding,
    globalLinkColour,
    globalPadding,
    rows,
  } = snapshots.currentState ? snapshots.currentState : values;

  const onDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const onDragEnd = (event) => {
    setActiveId(null);
    handleDragEnd(event, saveUndoSnapshot, setFieldValue, values);
  };

  const saveUndoSnapshot = (snapshot) => {
    const undo = [...snapshots.undo];
    // Push the new value to the snapshots array
    undo.push(snapshot);

    // Check if an item needs to be removed
    if (undo.length > MaxSnapshots) {
      undo.shift();
    }

    setSnapshots({ undo: undo, redo: [] });
  };

  const saveCurrentState = (currentState) => {
    setSnapshots({
      undo: [...snapshots.undo],
      redo: [...snapshots.redo],
      currentState: currentState,
    });
  };

  //If a value in values is changed in a component that doesn't have
  //the snapshot such as the NewElement, this if statement will make sure the
  //snapshot currentstate will have the latest value for something specific to NewElement
  //like DesignMode. Although we don't want to save the snapshot of the editControl as
  //that includes a whole bunch of fields that aren't needed
  if (
    !values.editControl &&
    snapshots &&
    snapshots.currentState &&
    values &&
    snapshots.currentState !== values
  ) {
    saveCurrentState(values);
  }

  return (
    <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <DesignSection className={activeId ? "is-dragging" : ""}>
        <DesignSection.Content>
          {values.previewComm &&
            values.previewDevice !== "desktop" && (
              <CommunicationPreview values={values} />
            )}
          <div
            className={
              !values.previewComm ||
              values.previewDevice === "desktop"
                ? ""
                : "d-none"
            }
          >
            {!values.previewComm && !values.editControl && (
              <UndoRedo
                setSnapshots={setSnapshots}
                snapshots={snapshots}
                values={values}
              />
            )}
            <CommunicationTable
              allowEdit={!values.previewComm}
              backgroundColour={globalBackgroundColour}
              contentBackgroundColour={globalContentBackgroundColour}
              contentPadding={globalContentPadding}
              linkColour={globalLinkColour}
              padding={globalPadding}
              {...{
                activeId,
                rows,
                saveCurrentState,
                saveUndoSnapshot,
                setFieldValue,
                values,
              }}
            />
          </div>
        </DesignSection.Content>
        <DesignSection.Sidebar>
          <CommunicationDesignerSidebar
            {...{
              handleChange,
              handlePicklistChange,
              saveUndoSnapshot,
              setFieldValue,
              state,
              values,
            }}
            saveCurrentState={saveCurrentState}
            snapshots={snapshots}
            values={
              values.editControl || !snapshots.currentState
                ? values
                : snapshots.currentState
            }
          />
        </DesignSection.Sidebar>
      </DesignSection>
      <DragOverlay>
        {activeId ? <div id="drag-overlay" /> : null}
      </DragOverlay>
    </DndContext>
  );
}

export default CommunicationDesigner;
