import { Fragment, useState } from "react";
import classNames from "classnames";
import { useWindowResize } from "../../../js/utility";
import { CollapsibleSection, Input } from "../../elements/_Elements";
import { renderHeatMap } from "./Helpers";
import "./HeatMap.scss";

function HeatMap({ EmailHtml, Statistics, UniqueClicks }) {
  const [heatMapState, setHeatMapState] = useState({
    anchors: [],
    height: 0,
    show: true,
  });

  function updateHeatMap() {
    renderHeatMap(setHeatMapState, Statistics, UniqueClicks);
  }

  // update the heat map when the window is resized
  useWindowResize(updateHeatMap);

  const { anchors, height, show } = heatMapState;

  return (
    <>
      <div className="border p-3 mb-3">
        <CollapsibleSection id={"heat-map"} title={"Heat Map"}>
          <div className="d-flex justify-content-end mb-3">
            <Input
              checked={heatMapState.show}
              label="Show Heat Map"
              onChange={() =>
                setHeatMapState((previous) => {
                  return { ...previous, show: !previous.show };
                })
              }
              type="bit"
            />
          </div>
          <div
            className="position-relative mb-3"
            style={{ height: `${height}px` }}
          >
            <div
              id="heat-map-overlay"
              className={classNames(
                heatMapState.show ? "active" : ""
              )}
            ></div>
            {show &&
              anchors.map((anchor, index) => {
                return (
                  <Fragment key={index}>
                    <div
                      className={classNames(
                        "heat-map-anchor",
                        `heat-${anchor.heat}`
                      )}
                      style={{
                        top: anchor.offsetTop,
                        left: anchor.offsetLeft,
                        width: anchor.offsetWidth,
                        height: anchor.offsetHeight,
                      }}
                    ></div>
                    <div
                      className="heat-map-item"
                      style={{
                        top: anchor.offsetTop + anchor.offsetHeight,
                        left: anchor.offsetLeft,
                      }}
                    >
                      <div className="heat-map-item-percentage">
                        {anchor.percentage}
                      </div>
                      <div className="heat-map-item-details">
                        <p className="m-0">
                          <strong className="me-1">
                            Total Clicks:
                          </strong>
                          {anchor.totalCount}
                        </p>
                        <p className="m-0">
                          <strong className="me-1">
                            Unique Clicks:
                          </strong>
                          {anchor.count}
                        </p>
                        <p className="m-0">
                          <strong className="me-1">
                            Destination Url:
                          </strong>
                          {anchor.href}
                        </p>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            <iframe
              id="communication-heat-map"
              title="Heat Map"
              className="w-100"
              onLoad={updateHeatMap}
              srcDoc={EmailHtml}
              style={{ height: `${height}px` }}
            />
          </div>
        </CollapsibleSection>
      </div>
    </>
  );
}

export default HeatMap;
