import { getDateString } from "../../Helpers";

function HeaderTableCell({
  children,
  className,
  properties,
  textAlign,
}) {
  const {
    headerCellPadding,
    headerFontFamily,
    headerFontSize,
    headerFontWeight,
    headerTextColour,
    headerVerticalAlign,
  } = properties;

  return (
    <td
      className={className}
      style={{
        color: headerTextColour,
        padding: headerCellPadding,
        fontFamily: headerFontFamily,
        fontSize: headerFontSize,
        fontWeight: headerFontWeight,
        textAlign: textAlign,
        verticalAlign: headerVerticalAlign,
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
}

function PaymentTableCell({
  children,
  className,
  properties,
  textAlign,
  whiteSpace,
}) {
  const {
    rowCellPadding,
    rowFontFamily,
    rowFontSize,
    rowFontWeight,
    rowLineColour,
    rowLineThickness,
    rowLineType,
    rowTextColour,
    rowVerticalAlign,
  } = properties;

  return (
    <td
      className={className}
      style={{
        borderBottomColor: rowLineColour,
        borderBottomStyle: rowLineType,
        borderBottomWidth: rowLineThickness,
        color: rowTextColour,
        fontFamily: rowFontFamily,
        fontSize: rowFontSize,
        fontWeight: rowFontWeight,
        padding: rowCellPadding,
        textAlign: textAlign,
        verticalAlign: rowVerticalAlign,
        whiteSpace: whiteSpace,
      }}
    >
      {children}
    </td>
  );
}

function ActualPayments({ properties }) {
  const {
    localeCode,
    longDateFormat,
    rowBackgroundColour,
    shortDateFormat,
  } = properties;

  return (
    <tr
      className="hide-on-designer"
      style={{ backgroundColor: rowBackgroundColour }}
      data-xsl-wrap="xsl:for-each"
      data-xsl-attributes="select='BookingPayment'"
    >
      <PaymentTableCell className="six" properties={properties}>
        <p
          dangerouslySetInnerHTML={{
            __html: '<xsl:value-of select="PaymentMethod" />',
          }}
        ></p>
      </PaymentTableCell>
      <PaymentTableCell
        className="three"
        properties={properties}
        whiteSpace="nowrap"
      >
        <div style={{ display: "block" }}>
          <p
            className="hide-for-small"
            dangerouslySetInnerHTML={{
              __html: `<xsl:value-of select="g4:TranslateDate(PaymentDate, '${longDateFormat}', '${localeCode}')" />`,
            }}
          ></p>
          <p
            className="show-for-small"
            dangerouslySetInnerHTML={{
              __html: `<xsl:value-of select="ms:format-date(PaymentDate, ${shortDateFormat})" />`,
            }}
            style={{ msoHide: "all" }}
          ></p>
        </div>
      </PaymentTableCell>
      <PaymentTableCell
        className="three"
        properties={properties}
        textAlign="right"
        whiteSpace="nowrap"
      >
        <p
          dangerouslySetInnerHTML={{
            __html:
              '<xsl:value-of select="g4:FormatCurrency(PaymentAmount, ../../CurrencyCode)" />',
          }}
        ></p>
      </PaymentTableCell>
    </tr>
  );
}

function SamplePayments({ properties }) {
  const { rowBackgroundColour } = properties;

  return (
    <>
      <tr
        className="remove-item"
        style={{ backgroundColor: rowBackgroundColour }}
      >
        <PaymentTableCell className="six" properties={properties}>
          <p>Monthly Payments</p>
        </PaymentTableCell>
        <PaymentTableCell
          className="three"
          properties={properties}
          whiteSpace="nowrap"
        >
          <div style={{ display: "block" }}>
            <p className="hide-for-small">
              {getDateString(0, 1, "dddd D MMMM YYYY")}
            </p>
            <p className="show-for-small">
              {getDateString(0, 1, "DD/MM/YYYY")}
            </p>
          </div>
        </PaymentTableCell>
        <PaymentTableCell
          className="three"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <p>£16.67</p>
        </PaymentTableCell>
      </tr>
      <tr
        className="remove-item"
        style={{ backgroundColor: rowBackgroundColour }}
      >
        <PaymentTableCell className="four" properties={properties}>
          <p>Monthly Payments</p>
        </PaymentTableCell>
        <PaymentTableCell
          className="four"
          properties={properties}
          whiteSpace="nowrap"
        >
          <div style={{ display: "block" }}>
            <p className="hide-for-small">
              {getDateString(0, 2, "dddd D MMMM YYYY")}
            </p>
            <p className="show-for-small">
              {getDateString(0, 2, "DD/MM/YYYY")}
            </p>
          </div>
        </PaymentTableCell>
        <PaymentTableCell
          className="four"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <p>£16.67</p>
        </PaymentTableCell>
      </tr>
      <tr
        className="remove-item"
        style={{ backgroundColor: rowBackgroundColour }}
      >
        <PaymentTableCell className="four" properties={properties}>
          <p>Monthly Payments</p>
        </PaymentTableCell>
        <PaymentTableCell
          className="four"
          properties={properties}
          whiteSpace="nowrap"
        >
          <div style={{ display: "block" }}>
            <p className="hide-for-small">
              {getDateString(0, 3, "dddd D MMMM YYYY")}
            </p>
            <p className="show-for-small">
              {getDateString(0, 3, "DD/MM/YYYY")}
            </p>
          </div>
        </PaymentTableCell>
        <PaymentTableCell
          className="four"
          properties={properties}
          textAlign="right"
          whiteSpace="nowrap"
        >
          <p>£16.67</p>
        </PaymentTableCell>
      </tr>
    </>
  );
}

function DirectDebit({ properties }) {
  const {
    borderColour,
    borderThickness,
    borderType,
    directDebitMargin,
    headerBackgroundColour,
  } = properties;

  return (
    <div
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='BookingPayments/BookingPayment'"
    >
      <table
        className="full-width zero-margin"
        cellSpacing="0"
        data-xsl-wrap="xsl:template"
        data-xsl-attributes="match='BookingPayments'"
      >
        <tbody>
          <tr>
            <td style={{ padding: directDebitMargin }}>
              <table
                className="full-width zero-margin"
                cellSpacing="0"
                style={{
                  borderColor: borderColour,
                  borderStyle: borderType,
                  borderWidth: borderThickness,
                }}
              >
                <tbody>
                  <tr
                    style={{
                      backgroundColor: headerBackgroundColour,
                    }}
                  >
                    <HeaderTableCell
                      className="four"
                      properties={properties}
                    >
                      Method
                    </HeaderTableCell>
                    <HeaderTableCell
                      className="four"
                      properties={properties}
                    >
                      Date
                    </HeaderTableCell>
                    <HeaderTableCell
                      className="four"
                      properties={properties}
                      textAlign="right"
                    >
                      Amount
                    </HeaderTableCell>
                  </tr>
                  <ActualPayments properties={properties} />
                  <SamplePayments properties={properties} />
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DirectDebit;
