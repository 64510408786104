import React, { useState } from "react";
import {
  ButtonStyle,
  GlobalDispatchMethods,
} from "../../../js/enums";
import { ActivateCommunicationOptionsAccount } from "../../../js/service";
import { useGlobalReducer } from "../../../GlobalContext";
import { Button, Input } from "../../elements/_Elements";
import { handleRedirect } from "../Helpers";

function ActivationCode({ dispatch, state }) {
  const [activationCode, setActivationCode] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const globalDispatch = useGlobalReducer();

  const SubmitActivationCode = async () => {
    setSubmitting(true);
    try {
      const [response] = await Promise.all([
        ActivateCommunicationOptionsAccount(null, activationCode),
      ]);
      if (response && response.status && response.status === 200) {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: "Successfully activated code",
            success: true,
          },
          dispatch: globalDispatch,
        });
        handleRedirect(null, dispatch, state.id, "refreshPage");
      } else {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: "Activation failed",
            success: false,
          },
          dispatch: globalDispatch,
        });
      }
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: GlobalDispatchMethods.AddNotification,
        notification: {
          message: error,
          success: false,
        },
        dispatch: globalDispatch,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Input
        label="Activation Code"
        name="ActivationCode"
        onChange={(event) => setActivationCode(event.target.value)}
        value={activationCode}
        disabled={submitting}
      />
      <Button
        className={"float-start p-0"}
        style={ButtonStyle.Link}
        text={"Activate"}
        onClick={() => SubmitActivationCode()}
        type={"button"}
        disabled={submitting}
      />
    </>
  );
}

export default ActivationCode;
