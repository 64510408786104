import lookups from "../../../forms/lookups";
import {
  LinkType,
  PredefinedLink,
} from "../../form/communication/enums";
import { getLookupFieldOptions } from "../../form/Helpers";

const AddLinkFormStages = [
  {
    title: "",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "linkType",
            enum: LinkType,
            label: "Type",
            type: "picklist",
          },
          {
            name: "url",
            description: "",
            displayIfFromValues: (values) =>
              String(values.linkType) ===
              String(LinkType["Web Address"]),
            label: "URL",
            type: "nvarchar",
          },
          {
            name: "emailAddress",
            description: "",
            displayIfFromValues: (values) =>
              String(values.linkType) ===
              String(LinkType["Email Address"]),
            label: "Email Address",
            type: "nvarchar",
          },
          {
            name: "emailSubject",
            description: "",
            displayIfFromValues: (values) =>
              String(values.linkType) ===
              String(LinkType["Email Address"]),
            label: "Email Subject",
            type: "nvarchar",
          },
          {
            name: "emailContent",
            description: "",
            displayIfFromValues: (values) =>
              String(values.linkType) ===
              String(LinkType["Email Address"]),
            label: "Email Content",
            type: "nvarchar",
          },
          {
            name: "predefinedLink",
            displayIfFromValues: (values) =>
              String(values.linkType) === String(LinkType.Predefined),
            enum: PredefinedLink,
            label: "Link",
            type: "picklist",
          },
          {
            name: "attribute",
            description: "",
            displayIfFromValues: (values) =>
              String(values.linkType) === String(LinkType.Attribute),
            label: "Attribute",
            mapOptions: (_, state, values) => {
              const { g4c_listtype } = state;

              let lookup =
                g4c_listtype === "1"
                  ? lookups.account.attributes
                  : lookups.contact.attributes;

              return getLookupFieldOptions(
                {
                  lookup: lookup,
                },
                state,
                values,
                null
              );
            },
            type: "lookup",
          },
          {
            name: "text",
            description: "",
            label: "Text",
            type: "nvarchar",
          },
          {
            name: "openInNewTab",
            label: "Open link in new tab",
            type: "bit",
          },
        ],
        wrapInCard: false,
      },
    ],
  },
];

export default AddLinkFormStages;
