import { Button } from "./_Elements";

function PaginationArrows({
  index,
  recordsToShow,
  setIndex,
  totalRecords,
}) {
  const handleNext = () => {
    setIndex((prevIndex) =>
      Math.min(prevIndex + 1, totalRecords - 1)
    );
  };

  const handlePrevious = () => {
    setIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  if (totalRecords <= recordsToShow) {
    return <></>;
  }

  return (
    <div className="btn-group">
      <Button
        onClick={handlePrevious}
        disabled={index === 0}
        text="<"
        type="button"
      />
      <Button
        onClick={handleNext}
        disabled={index + recordsToShow >= totalRecords}
        text=">"
        type="button"
      />
    </div>
  );
}

export default PaginationArrows;
