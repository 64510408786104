export const CountryFormStages = [
  {
    title: "Country",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4_sequence",
            description: "",
            label: "Sequence",
            type: "int",
          },
          {
            name: "g4_addressformat",
            description:
              "Used for formatting addresses. The following values are available for substitions: {line1}, {line2}, {line3}, {city}, {region}, {postcode} and {country}. Use \\\\ for a line break.",
            label: "Address Format",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
