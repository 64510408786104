import { Fragment } from "react";
import styled, { StyleSheetManager } from "styled-components";
import { DesignMode } from "./enums";
import CommunicationTableRow from "./CommunicationTableRow";
import EditCommunicationTableRow from "./EditCommunicationTableRow";
import { DroppableElement } from "../../elements/_Elements";
import "./Ink.css";
import "./Preview.css";

const StyledLinks = styled.div`
  #ink a {
    color: ${(props) => props.linkColour};
  }
`;

function CommunicationTable({
  activeId,
  allowEdit,
  backgroundColour,
  contentBackgroundColour,
  contentPadding,
  linkColour,
  padding,
  rows,
  saveCurrentState,
  saveUndoSnapshot,
  setFieldValue,
  values,
}) {
  const allowEditRows =
    allowEdit && values.designMode === String(DesignMode.Rows);

  return (
    <StyleSheetManager
      shouldForwardProp={(props) => props !== "linkColour"}
    >
      <StyledLinks linkColour={linkColour}>
        <table
          id="ink"
          className="body"
          style={{ backgroundColor: backgroundColour }}
        >
          <tbody>
            <tr>
              <td
                className="center"
                align="center"
                valign="top"
                style={{ padding: padding }}
              >
                <center>
                  <table
                    className="container"
                    style={{
                      backgroundColor: contentBackgroundColour,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ padding: contentPadding }}>
                          {allowEditRows && (
                            <DroppableElement id={"row-0"} />
                          )}
                          {rows &&
                            rows.map((row, rowIndex) => {
                              return allowEditRows ? (
                                <Fragment key={rowIndex}>
                                  <EditCommunicationTableRow
                                    {...{
                                      activeId,
                                      row,
                                      rowIndex,
                                      saveUndoSnapshot,
                                      setFieldValue,
                                      values,
                                    }}
                                  />
                                  <DroppableElement
                                    id={`row-${rowIndex + 1}`}
                                  />
                                </Fragment>
                              ) : (
                                <Fragment key={rowIndex}>
                                  <CommunicationTableRow
                                    {...{
                                      activeId,
                                      allowEdit,
                                      row,
                                      rowIndex,
                                      saveCurrentState,
                                      saveUndoSnapshot,
                                      setFieldValue,
                                      values,
                                    }}
                                  />
                                </Fragment>
                              );
                            })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </center>
              </td>
            </tr>
          </tbody>
        </table>
      </StyledLinks>
    </StyleSheetManager>
  );
}

export default CommunicationTable;
