const ImageTextControlDefaultValues = [
  {
    Name: "src",
    Value: "",
  },
  {
    Name: "width",
    Value: "",
  },
  {
    Name: "height",
    Value: "",
  },
  {
    Name: "text",
    Value: "<p>Please enter your text</p>",
  },
  {
    Name: "filename",
    Value: "",
  },
  {
    Name: "filesize",
    Value: "",
  },
  {
    Name: "altText",
    Value: "",
  },
  {
    Name: "backgroundColour",
    Value: "",
  },
  {
    Name: "padding",
    Value: "5px",
  },
  {
    Name: "margin",
    Value: "0 0",
  },
  {
    Name: "verticalAlign",
    Value: "top",
  },
  {
    Name: "textColour",
    Value: "#222",
  },
  {
    Name: "fontSize",
    Value: "14px",
  },
  {
    Name: "fontWeight",
    Value: "normal",
  },
  {
    Name: "lineHeight",
    Value: "125%",
  },
  {
    Name: "textAlign",
    Value: "left",
  },
  {
    Name: "borderType",
    Value: "none",
  },
  {
    Name: "borderThickness",
    Value: "0",
  },
  {
    Name: "borderColour",
    Value: "",
  },
  {
    Name: "imageAlign",
    Value: "left",
  },
  {
    Name: "stackColumns",
    Value: false,
  },
  {
    Name: "showOnMobile",
    Value: true,
  },
  {
    Name: "textPosition",
    Value: "ltr",
  },
  {
    Name: "showButton",
    Value: false,
  },
  {
    Name: "buttonText",
    Value: "Button",
  },
  {
    Name: "buttonWidth",
    Value: "auto",
  },
  {
    Name: "buttonAlign",
    Value: "left",
  },
  {
    Name: "buttonColour",
    Value: "#ddd",
  },
  {
    Name: "buttonRadius",
    Value: "0",
  },
  {
    Name: "buttonPadding",
    Value: "5px 30px",
  },
  {
    Name: "buttonMargin",
    Value: "5px 0",
  },
  {
    Name: "buttonTextColour",
    Value: "#222",
  },
  {
    Name: "buttonTextAlign",
    Value: "center",
  },
  {
    Name: "buttonFontSize",
    Value: "14px",
  },
  {
    Name: "buttonFontWeight",
    Value: "normal",
  },
  {
    Name: "buttonBorderType",
    Value: "none",
  },
  {
    Name: "buttonBorderThickness",
    Value: "",
  },
  {
    Name: "buttonBorderColour",
    Value: "",
  },
  {
    Name: "linkType",
    Value: null,
  },
  {
    Name: "selectedLinkAttribute",
    Value: "",
  },
  {
    Name: "link",
    Value: "",
  },
  {
    Name: "emailAddress",
    Value: "",
  },
  {
    Name: "emailSubject",
    Value: "",
  },
  {
    Name: "emailContent",
    Value: "",
  },
  {
    Name: "predefinedLinks",
    Value: "",
  },
  {
    Name: "buttonLinkType",
    Value: "",
  },
  {
    Name: "buttonSelectedLinkAttribute",
    Value: "",
  },
  {
    Name: "buttonLink",
    Value: "",
  },
  {
    Name: "buttonEmailAddress",
    Value: "",
  },
  {
    Name: "buttonEmailSubject",
    Value: "",
  },
  {
    Name: "buttonEmailContent",
    Value: "",
  },
  {
    Name: "buttonPredefinedLinks",
    Value: "",
  },
  {
    Name: "fontFamily",
    Value: "Arial, Helvetica, sans-serif",
  },
  {
    Name: "buttonFontFamily",
    Value: "Arial, Helvetica, sans-serif",
  },
];

export default ImageTextControlDefaultValues;
