import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { VariantTypeFormStages } from "./variantType";

export const VariantTypesFormStages = [
  {
    title: "Variant Types",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_people",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_description",
              className: "col-name",
            },
            {
              name: "g4b_varianttypecategoryid",
              className: "col-name",
            },
            {
              name: "g4b_sequence",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_varianttypecategoryid",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/variant-type",
          lookup: lookups.g4b_varianttype.all,
          subFormStages: VariantTypeFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
