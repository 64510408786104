import { LinkType } from "../../form/communication/enums";

function getHref(
  attribute,
  emailAddress,
  emailContent,
  emailSubject,
  linkType,
  predefinedLink,
  url
) {
  let href = "";
  switch (linkType) {
    case LinkType["Email Address"]:
      href = `mailto:${emailAddress}`;
      const params = [];

      if (emailSubject)
        params.push(`subject=${encodeURIComponent(emailSubject)}`);
      if (emailContent)
        params.push(`body=${encodeURIComponent(emailContent)}`);

      if (params.length) {
        href += `?${params.join("&")}`;
      }
      break;
    case LinkType.Predefined:
      href = predefinedLink;
      break;
    case LinkType.Attribute:
      href = `actions/redirect.aspx?url=$$attribute:${attribute}-$`;
      break;
    case LinkType["Web Address"]:
    default:
      href = url;
      break;
  }
  return href;
}

function setupButtons(editor, editorState, setEditorState) {
  editor.ui.registry.addButton("sourceCodeButton", {
    icon: "sourcecode",
    onAction: function () {
      setEditorState({
        ...editorState,
        viewSourceCode: true,
      });
    },
  });
  editor.ui.registry.addButton("linkButton", {
    icon: "link",
    onAction: function () {
      setEditorState({
        ...editorState,
        addLink: true,
      });
    },
  });
  editor.ui.registry.addButton("attributeButton", {
    icon: "plus",
    onAction: function () {
      setEditorState({
        ...editorState,
        addAttribute: true,
      });
    },
  });
}

export { getHref, setupButtons };
