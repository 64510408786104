function EntityLookupTable({ linked }) {
  return (
    <table className="table">
      <thead>
        <tr className="table-info">
          <th scope="col" className="col-name">
            Name
          </th>
          <th scope="col">Id</th>
        </tr>
      </thead>
      <tbody>
        {linked.EntityLookups.map((lookup, lookupIndex) => {
          return (
            <tr key={lookupIndex}>
              <td>{lookup.Name}</td>
              <td>{lookup.Id}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default EntityLookupTable;
