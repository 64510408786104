import { useEffect } from "react";
import { GetInitialState } from "../js/service";
import { useGlobalReducer, useGlobalState } from "../GlobalContext";
import { GlobalDispatchMethods } from "../js/enums";

function PageState() {
  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();
  const { initialStateBound } = globalState;

  useEffect(() => {
    const getInitialState = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetInitialState(globalDispatch),
        ]);
        if (serviceResponse && serviceResponse.data) {
          globalDispatch({
            type: GlobalDispatchMethods.SetInitialState,
            initialState: serviceResponse.data,
          });
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    if (!initialStateBound) {
      getInitialState();
    }
  }, [globalDispatch, initialStateBound]);

  return <></>;
}

export default PageState;
