import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Loading,
  Modal,
  SelectWithFiltering,
} from "../../elements/_Elements";
import { ButtonStyle, LoadingState } from "../../../js/enums";
import { Create, GetAllEntityBasicInfo } from "../../../js/service";
import {
  getAttributesForSave,
  getLookupInState,
  handleRedirect,
} from "../../form/Helpers";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../../form/FormContext";
import { getEntityNameAttribute } from "../../../js/utility";
import lookups from "../../../forms/lookups";

function ChannelPriceListCopy({
  entityName,
  setDisplayRecordCopyModal,
  stages,
  state,
  values,
}) {
  const [isCopying, setIsCopying] = useState(false);
  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [channelData, setChannelData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState(
    state.g4b_channelid
  );
  const [
    newChannelPriceListRecordName,
    setNewChannelPriceListRecordName,
  ] = useState("");
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const channelLookupOptions = getLookupInState(
          state,
          lookups.g4b_channel.all
        );
        const [serviceResponse] = await Promise.all([
          channelLookupOptions &&
          channelLookupOptions.data &&
          channelLookupOptions.data.length > 0
            ? channelLookupOptions
            : GetAllEntityBasicInfo(
                globalDispatch,
                "g4b_channel",
                null
              ),
        ]);
        if (serviceResponse && serviceResponse.data) {
          setChannelData(serviceResponse.data);
        } else {
          setChannelData([]);
        }
        setLoading(LoadingState.Loaded);
        setInitialLoad(false);
      } catch (error) {
        console.error(error);
      }
    };

    if (initialLoad) {
      fetchData();
    }
  }, [globalDispatch, initialLoad, state]);

  const copyChannelPriceListRecord = async () => {
    let response = null;
    setIsCopying(true);
    try {
      const successMessage = `${newChannelPriceListRecordName} successfully created`;
      let attributes = getAttributesForSave(null, stages, {
        ...state,
        ...values,
      });
      let nameAttribute = getEntityNameAttribute(entityName);
      attributes[nameAttribute] = newChannelPriceListRecordName;
      attributes["g4b_channelid"] = selectedChannel;

      const [serviceResponse] = await Promise.all([
        Create(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: newChannelPriceListRecordName,
            Attributes: attributes,
          },
          entityName
        ),
      ]);
      response = serviceResponse.data;
    } catch (error) {
      console.error(error);
    } finally {
      setIsCopying(false);

      if (typeof response === "string") {
        // result is the id
        handleRedirect(null, dispatch, response, "copyRecord");
      } else {
        console.error("Invalid response returned.");
      }
    }
  };

  return (
    <>
      <Modal
        title={"Copy Channel Price List Record"}
        modalCloseButtonClick={() => setDisplayRecordCopyModal(null)}
        className="modal modal-dialog-scrollable"
      >
        {loading !== LoadingState.Loaded ? (
          <Loading />
        ) : (
          <>
            <div className="modal-body">
              <small>
                {
                  "Enter a name and select a channel for the new channel price list record"
                }
              </small>
              <Input
                className={"mb-3"}
                label={"Name"}
                mandatory={true}
                name={"NewChannelPriceListRecordName"}
                onChange={(event) =>
                  setNewChannelPriceListRecordName(event.target.value)
                }
                value={newChannelPriceListRecordName}
              />
              {channelData && channelData.length > 0 && (
                <SelectWithFiltering
                  className={"mb-3"}
                  label={"Channel"}
                  mandatory={true}
                  name={"NewChannelPriceListRecordChannel"}
                  options={channelData}
                  onChange={(event) =>
                    setSelectedChannel(event.value)
                  }
                  value={selectedChannel}
                />
              )}
            </div>
            <div className="modal-footer">
              <Button
                text={"Confirm"}
                style={ButtonStyle.Primary}
                onClick={() => copyChannelPriceListRecord()}
                showSpinner={isCopying}
                disabled={
                  !newChannelPriceListRecordName ||
                  newChannelPriceListRecordName.trim() === "" ||
                  isCopying
                }
              />
              <Button
                text={"Cancel"}
                style={ButtonStyle.Info}
                onClick={() => setDisplayRecordCopyModal(null)}
                showSpinner={isCopying}
                disabled={isCopying}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default ChannelPriceListCopy;
