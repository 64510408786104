import lookups from "./lookups";
import { CityFormStages } from "./city";

export const AdminSettingsFormStages = [
  {
    title: "Company Details",
    id: "company-details",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "Company name",
            isNameField: true,
            label: "Company Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_cityid",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-6",
            description: "City",
            label: "City",
            lookup: lookups.g4_city.all,
            subFormStages: CityFormStages,
            type: "lookup",
          },
          {
            name: "g4b_customerreferencestart",
            className: "col-md-6",
            label: "Customer Reference Starting Number ",
            description: "Customer reference starting number",
            type: "int",
          },
          {
            name: "g4b_customerreferenceformat",
            className: "col-md-6",
            label: "Customer Reference Format",
            description:
              "The starting number for the customer reference. This value will be incremented for each customer.",
            type: "nvarchar",
          },
          {
            name: "g4b_sellfixturesandseries",
            className: "col-md-4",
            description: "Enables the sale of fixture and series",
            label: "Enable Fixtures and Series ",
            type: "bit",
          },
        ],
      },
    ],
  },
];
