import { useState } from "react";
import { useGlobalReducer } from "../../GlobalContext";
import {
  Button,
  FormDatePicker,
  SelectWithFiltering,
} from "./_Elements";
import { ButtonStyle, DirectDebitProcesses } from "../../js/enums";
import { handleRedirect } from "../form/Helpers";

import {
  SendPaymentRequest,
  RetrievePaymentResponse,
  ProcessPaymentResponse,
} from "../../js/service";
import {
  isSameOrLaterDate,
  createDateFromToday,
} from "../../js/utility";

function DirectDebitProcessing({ state }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(
    DirectDebitProcesses["Send Payment Requests"]
  );
  const today = new Date();
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [isSendSubmitting, setIsSendSubmitting] = useState(false);
  const [isRetrieveSubmitting, setIsRetrieveSubmitting] =
    useState(false);
  const [isProcessSubmitting, setIsProcessSubmitting] =
    useState(false);
  const globalDispatch = useGlobalReducer();
  const handleSendRequest = async () => {
    setIsSubmitting(true);
    setIsSendSubmitting(true);
    try {
      const [serviceResponse] = await Promise.all([
        SendPaymentRequest(globalDispatch, state.id),
      ]);
      if (serviceResponse) {
        handleRedirect("", globalDispatch, state.id, "save", state);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setIsSendSubmitting(false);
    }
  };
  const handleRetrieveResponse = async () => {
    //selectedStartDate
    let startDate = new Date(
      selectedStartDate.getFullYear(),
      selectedStartDate.getMonth(),
      selectedStartDate.getDate()
    );
    let endDate = selectedEndDate
      ? new Date(
          selectedEndDate.getFullYear(),
          selectedEndDate.getMonth(),
          selectedEndDate.getDate()
        )
      : today;

    setIsSubmitting(true);
    setIsRetrieveSubmitting(true);
    try {
      const [serviceResponse] = await Promise.all([
        RetrievePaymentResponse(
          globalDispatch,
          "Payment Responses Retrieved",
          {
            PaymentBatchId: state.id,
            StartDate: startDate,
            EndDate: endDate,
            PaymentMethodId: null,
          }
        ),
      ]);
      if (serviceResponse) {
        handleRedirect("", globalDispatch, state.id, "save", state);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setIsRetrieveSubmitting(false);
    }
  };
  const handleProcessResponse = async () => {
    setIsSubmitting(true);
    setIsProcessSubmitting(true);
    try {
      const [serviceResponse] = await Promise.all([
        ProcessPaymentResponse(globalDispatch, state.id),
      ]);
      if (serviceResponse) {
        handleRedirect("", globalDispatch, state.id, "save", state);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setIsProcessSubmitting(false);
    }
  };
  const handleProcessChange = (event) => {
    setSelectedProcess(event.value);
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <SelectWithFiltering
          label={"Select Batch Process"}
          name={"process"}
          options={Object.entries(DirectDebitProcesses).map(
            ([Name, Label]) => ({
              Key: Label,
              Value: Name,
            })
          )}
          onChange={handleProcessChange}
          value={selectedProcess}
        />
      </div>
      {selectedProcess ===
      DirectDebitProcesses["Send Payment Requests"] ? (
        <>
          <div className="col-md-4">
            <div className="form-label-spacer"></div>
            <Button
              className="text-decoration-none"
              style={ButtonStyle.Primary}
              text={"Send"}
              onClick={() => handleSendRequest()}
              showSpinner={isSendSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {selectedProcess ===
      DirectDebitProcesses["Retrieve Payment Responses"] ? (
        <>
          <div className="col-md-3">
            <FormDatePicker
              dateFormat={"dd/MM/yyyy"}
              description={""}
              disabled={false}
              filterDate={(date) => {
                return isSameOrLaterDate(
                  date,
                  createDateFromToday(0, 0, -6)
                );
              }}
              handleDateChange={(date) => {
                setSelectedStartDate(date);
              }}
              label={"Start"}
              locale={"en-GB"}
              mandatory={true}
              name={"start"}
              selected={selectedStartDate}
              showTimeSelect={false}
              showTimeSelectOnly={false}
            />
          </div>
          <div className="col-md-3">
            <FormDatePicker
              dateFormat={"dd/MM/yyyy"}
              description={""}
              disabled={false}
              filterDate={(date) => {
                return isSameOrLaterDate(
                  date,
                  createDateFromToday(0, 0, -6)
                );
              }}
              handleDateChange={(date) => {
                setSelectedEndDate(date);
              }}
              label={"End"}
              locale={"en-GB"}
              mandatory={false}
              name={"end"}
              selected={selectedEndDate}
              showTimeSelect={false}
              showTimeSelectOnly={false}
            />
          </div>
          <div className="col-md-3">
            <div className="form-label-spacer"></div>
            <Button
              className="text-decoration-none"
              style={ButtonStyle.Primary}
              text={"Retrieve"}
              onClick={(selectedStartDate, selectedEndDate) =>
                handleRetrieveResponse(
                  selectedStartDate,
                  selectedEndDate
                )
              }
              showSpinner={isRetrieveSubmitting}
              disabled={isSubmitting || !selectedStartDate}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {selectedProcess ===
      DirectDebitProcesses["Process Payment Responses"] ? (
        <>
          <div className="col-md-4">
            <div className="form-label-spacer"></div>
            <Button
              className="text-decoration-none"
              style={ButtonStyle.Primary}
              text={"Process"}
              onClick={() => handleProcessResponse()}
              showSpinner={isProcessSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DirectDebitProcessing;
