import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { SeatClassFormStages } from "./seatClass";

export const SeatClassesFormStages = [
  {
    title: "Seat Classes",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_colour",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4b_colour"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/seat-class",
          lookup: lookups.g4b_seatclass.all,
          subFormStages: SeatClassFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
