export const TextDefaultValues = {
  BackgroundColour: "rgb(255,255,255)",
  BarcodeFormat: 0,
  Capitalized: false,
  DateFormat: null,
  FontName: "Helvetica",
  FontSize: 3.5,
  ForegroundColour: "rgb(0,0,0)",
  Height: 10,
  InvertColours: false,
  KeepAspectRatio: false,
  Left: 0,
  Orientation: 0,
  ShowHumanReadable: false,
  ShowTotalSeparator: false,
  TextWrap: false,
  Top: 0,
  Type: 1,
  UseOrdinalDateSuffix: false,
  Value: {
    DisplayValue: "",
    Format: 0,
    IsStatic: true,
    Value: "",
  },
  Width: 50,
};
