import lookups from "./lookups";
import { MarketingListFormStages } from "./marketingList";
import { TargetedAtType } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const MarketingListsFormStages = [
  {
    title: "Marketing Lists",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "createdfromcode",
              className: "col-name",
              type: "picklist",
              enum: TargetedAtType,
            },
            {
              name: "g4c_issubscription",
              className: "col-bool",
            },
            {
              name: "membercount",
              className: "col-number",
              label: "Member Count",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/marketing-list",
          lookup: lookups.list.all,
          subFormStages: MarketingListFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
