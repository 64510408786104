import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";

export const TaxCodeFormStages = [
  {
    title: "Tax Code",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Components",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4b_taxcodeid",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/tax-component",
          linkedEntityId: "g4b_taxcodeid",
          lookup: lookups.g4b_taxcomponent.all,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
