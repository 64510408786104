import Barcode from "react-barcode";
import { getBarcodeFormat } from "../../Helpers";

function LinearBarcodeField({ field, isEditing, values }) {
  const { BarcodeFormat } = isEditing ? values : field;

  return (
    <Barcode
      displayValue={false}
      format={getBarcodeFormat(BarcodeFormat)}
      renderer="img"
      value="123456789012"
    />
  );
}

export default LinearBarcodeField;
